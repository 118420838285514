import React from "react";
import { Epg, Layout } from "@nessprim/planby-pro";
import moment from 'moment-timezone';
import cn from 'classnames'
import Card from '../../../../components/Card'
import { endOfDay, format } from "date-fns";
import styles from './app.module.sass'
import Dropdown from "../../../../components/Dropdown";
import {ReactComponent as LeftArrow} from "../../../../media/images/leftArrow.svg";
import {ReactComponent as RightArrow} from "../../../../media/images/rightArrow.svg";
import {ReactComponent as ZoomIn} from "../../../../media/images/icons/zoomIn.svg"
import {ReactComponent as ZoomOut} from "../../../../media/images/icons/zoomOut.svg"
import newTimezones from '../../../../components/CustomTimeZones'


import { useApp } from "./useApp";
import {addDays} from "date-fns"


import {
  CustomTimeline,
  ChannelItem,
  Line,
  LiveTime,
  Program,
  GridCell,
} from "./components";
import { layouts } from "chart.js";
import Timezones from "../../../../jsonMockData/Timezones";

function UseCalendar({CalendarType}) {

  const [isFullscreen, setFullscreen] = React.useState(false);
  const lazyLoadInterval = {
    day:2,
    week:2,
    month:2,
    year:2
  };
  const [currentInterval,setCurrentInterval]= React.useState({day:0,week:0,month:0,year:0})
  const { isLoading,setIsLoading,startDate,endDate,dayPrevChange,dayNextChange, getEpgProps, getLayoutProps, onChangeTimezone, onChangeCalendarMode,handleFetchResources,getAllScheduleCompetition,loadedDaysData,loadedWeeksData,loadedMonthsData,loadedYearsData,channels} = useApp({CalendarType,isFullscreen,lazyLoadInterval,currentInterval,setCurrentInterval});
  const modes = [{name:"Day",value:"day"}, {name:"Week",value:"week"}, {name:"Month",value:"30Days"}, {name:"Year",value:"month"}];
  const currentDate = format(new Date(), "MMMM dd, yyyy");
  const timeZones = moment.tz.names();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [selectedZone, setSelectedZone] = React.useState({"zoneName": "Asia/Kolkata", "abbreviation": "IST"});
  const [leftAnimationClass, setLeftAnimationClass] = React.useState('');
  const [rightAnimationClass, setRightAnimationClass] = React.useState('');


  const toggleFullscreen = () => {
    setFullscreen(!isFullscreen);
    const temActive=activeIndex;
    setActiveIndex(activeIndex!==3?activeIndex+1:activeIndex-1);
    setTimeout(() => {
      setActiveIndex(temActive);
    }, 5);
    setTimeout(() => {
      handleFetchResources(modes[activeIndex].value)
    }, 250);
  };

  function switchCalendarMode(index) {
    onLoadAnimation();
    setActiveIndex(index);
  }

  function getCurrentTimeAndZoneCode(timezone) {
    const now = moment.tz(timezone.zoneName);
  
  return " ("+timezone.abbreviation+")";
}
  
  function getDayOfWeek(date) {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayIndex = new Date(date).getDay();
    return days[dayIndex];
  }

  function getDateOfMonth(date) {
    return new Date(date).getDate();
  }

  const dayPrevChan = () => {
    setLeftAnimationClass(styles.slideInFromLeft);
    setTimeout(() => {
      setLeftAnimationClass('');
    }, 500);
  };
  
  const dayNextChan = () => {
    setRightAnimationClass(styles.slideInFromRight);
    setTimeout(() => {
      setRightAnimationClass('');
    }, 500);
  };
  
  React.useEffect(()=>{
    const abc={
      target:{
        value:modes[activeIndex].value
      }
    }
    onChangeCalendarMode(abc)
  },[activeIndex])


  const onLoadAnimation=()=>{
    setLeftAnimationClass(styles.fadeIn);
    setTimeout(() => {
      setLeftAnimationClass('');
    }, 500);
  }
  
  React.useEffect(() => {
    onLoadAnimation()
  }, []);

  let containerHeight
  function adjustLineHeight() {
      const container = document.getElementsByClassName('planby-layout')[0];
      if (container?.scrollHeight ) {
        containerHeight=`${container.scrollHeight-84}px`
      }
  }
  adjustLineHeight();
  

  return (

    <div style={isFullscreen?{height:"100vh",width:"100vw",position:"fixed",left:0,top:0,zIndex:102}:{}}>
    <Card >
      <div style={{height:"52px",display:"flex"}}>
      <div style={{margin: "auto 0 auto 0",fontSize:"16px", fontWeight:"600"}}>{modes[activeIndex].value!=="day"?`${format(startDate, "MMM dd")+" - "+ format(addDays(endDate,-1), "MMM dd, yyyy") }`:format(startDate, "MMMM dd, yyyy")}</div>
      <div style={{width:"260px",height: "40px" , margin: "auto 0 auto auto"}}>
      <Dropdown customHandleFunction={onChangeTimezone} setSelectedZone={setSelectedZone} value={selectedZone.zoneName+getCurrentTimeAndZoneCode(selectedZone)} options={
        newTimezones.map((place)=>{
          return {name:place.zoneName+getCurrentTimeAndZoneCode(place), value:place}
        })
      }/></div>
    <div className={styles.toggle} style={{margin: "auto 0 auto 16px"}}>
      {modes && modes.map((x, index) => (
          <button className={cn(styles.link, { [styles.active]: index === activeIndex,})} onClick={() => switchCalendarMode(index)} key={index}>
            <p style={index !== activeIndex?{color:"#6F767E"}:{}}>{x.name}</p>
          </button>
      ))}
    </div>
      <div style={{display:"flex",margin: "auto 0 auto 0"}}>
          <div
          style={{width:"24px", height:"24px",marginRight:"8px",marginLeft:"16px",cursor: "pointer"}}
          onClick={()=>{dayPrevChange();dayPrevChan()}}
          >
          <LeftArrow/>
        </div>
          <div
          style={{width:"24px", height:"24px", marginRight:"0px",cursor: "pointer"}}
          onClick={()=>{dayNextChange();dayNextChan()}}
          >
          <RightArrow/>
        </div>
      </div>
    </div>
      
      <div style={{border: "1px solid #E4E4E4",borderRadius: "20px",marginTop:"18px",height:(isFullscreen ? "calc(100vh - 120px)" : "400px"), overflow:"hidden"}}>
        {modes[activeIndex].value=="days"&&
      <div style={{
          height: "50px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop:"4px"
        }}>
          <p style={{ fontSize: "15px", margin: 0 }}>{getDayOfWeek(currentDate)}</p>
          <div style={{
            height: "24px",
            width: "24px",
            background: "#33383F",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "4px" 
          }}>
            <p style={{ fontSize: "12px", color: "#FFFFFF", margin: 0 }}>{getDateOfMonth(currentDate)}</p>
          </div>
      </div>}
        <Epg style={{padding:"0px"}} isLoading={isLoading} {...getEpgProps()}>
          <Layout
            {...getLayoutProps()}

            renderLine={(props) => <Line containerHeight={containerHeight} {...props} isFullscreen={isFullscreen} />}
            renderCurrentTime={(props) => <LiveTime {...props} selectedZone={selectedZone}/>}
            renderTimeline={(props) => <CustomTimeline calendarMode={modes[activeIndex].value} {...props} />}
            renderProgram={({ program,index, ...rest }) => (
              <Program
                key={`${index}-${program.data.id}`}
                program={program}
                leftAnimationClass={leftAnimationClass}
                rightAnimationClass={rightAnimationClass}
                calendarMode={modes[activeIndex].value}
                calendarStartDate={startDate}
                calendarEndDate={endDate}
                {...rest}
              />
            )}
            renderChannel={({ channel, ...rest }) => (
              <ChannelItem key={channel.uuid} channel={channel} {...rest} />
            )}
            renderGridCell={({ id, ...rest }) => (
              <GridCell containerHeight={containerHeight} channelNo={channels.length} channel={channels} isFullscreen={isFullscreen} key={id} {...rest} />
            )}
          />
        </Epg>  
        <div onClick={()=>{toggleFullscreen();}} style={{cursor: "pointer"}}>
          <div style={{width:"32px",height:"32px",position:"absolute",right:"40px",bottom:"40px",zIndex:20,border:"4px",borderRadius:"8px",background:"#fcfcfc",boxShadow:"0px 3.2px 6.4px -3.2px #0000001A, 0px 1.6px 0px 0px #FFFFFF40 inset, 0px -0.8px 0.8px 0px #0000000A inset"}}>
        {isFullscreen ? 
          <ZoomOut style={{width:"20px",height:"20px",margin:"6px"}}/>
        :
          <ZoomIn style={{width:"20px",height:"20px",margin:"6px"}}/>
        }</div>
        </div>
      </div>
    </Card>
    </div>
  );
}

export default UseCalendar;
