import React, { useEffect, useRef, useState } from 'react'
import Card from '../../../../../../components/Card'
import TextInput from '../../../../../../components/TextInput'
import styles from './profile.module.sass'
import moment from 'moment'
import Icon from '../../../../../../components/Icon'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ProfilePlaceholder from '../../../../../../media/images/placeholders/Profile.png'
import { changeMemberPasswordAction, editUserProfileAction, getUserProfileAction } from '../../../../../../redux/action/organisation/ProfileAction/profileAction'
import { toast } from 'react-toastify'
import Toast from '../../../../../../components/Toast'
import { ReactComponent as AddButtonIcon } from '../../../../../../media/images/icons/add_button.svg'
import axios from 'axios'
import SearchDropdown from '../../../../../../components/Dropdown/SearchDropdown'
import {ReactComponent as Internet} from '../../../../../../media/images/icons/internet.svg'
import { getAllMetaDataAction } from '../../../../../../redux/action/common/commonAction'
import Dropdown from '../../../../../../components/TypeHeadDropdown/SingleTypeHeadDropdown'
import newTimezones from '../../../../../../components/CustomTimeZones'
import { BASE_URL } from '../../../../../../resources/APIEndpoints'
import app,{increaseUnreadCountForAllUsers} from "../../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";
import cn from 'classnames'
import _ from 'lodash'

const Profile = ({data, className, setShowLoader}) => {

    const {appid} = useParams()
    const {id} = useParams()
    const dispatch = useDispatch()
    const logoRef = useRef()
    const [selectedZone, setSelectedZone] = React.useState("");
    const [selectedZoneID, setSelectedZoneID] = React.useState("");
    
    const [rules, setRules] = useState({lengthRule: null, capitalRule: null, smallRule: null, numberRule: null, specialRule: null})

    let UserProfile = useSelector((state) => {
        return state.organisation.userProfile
    })

    const placeholder = ProfilePlaceholder
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        let folderName = 'org-assets'
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}&category=${folderName}&fileName=${file.name}`)
        setUserProfile(org => ({...org, profileUrl : res.data.getUrl}))
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        });
    }

        // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));     
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
        
        // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
            const fileReader = new FileReader();
            fileReader.onload = (eve) =>{
                resolve(fileReader.result)
            }
            fileReader.readAsDataURL(file)
        })
    }

    const Cancel = () => {
        setUserProfile(user => ({...user, profileUrl : null}))
        setLogo({src: placeholder , alt: 'Upload an Image'})
        logoRef.current.value = ''
    }

    const [userProfile, setUserProfile] = useState({profileUrl : null, name : '', email : '', location: '', timezone: ''})
    const [location, setLocation] = useState('')
    const [locationId, setLocationId] = useState(null)


    const configureUserProfile = () => {
        if(UserProfile && (typeof UserProfile === 'object' && UserProfile !== null && !Array.isArray(UserProfile))){
            setLogo({src: (UserProfile && UserProfile.profile_url != null && UserProfile.profile_url != '' ? UserProfile.profile_url : ProfilePlaceholder), alt: 'Upload an Image'}); 
            setUserProfile(user => ({...user, profileUrl : (UserProfile && UserProfile.profile_url != null && UserProfile.profile_url != '' ? UserProfile.profile_url : null)}))
            setUserProfile(user => ({...user, name : (UserProfile && UserProfile.name != null && UserProfile.name != '' ? UserProfile.name : '')}))
            setUserProfile(user => ({...user, contactNo : (UserProfile && UserProfile.contact_no != null && UserProfile.contact_no != '' ? UserProfile.contact_no : null)}))
            setUserProfile(user => ({...user, email : (UserProfile && UserProfile.email != null && UserProfile.email != '' ? UserProfile.email : '')}))
            setUserProfile(user => ({...user, location : (UserProfile && UserProfile.location != null && UserProfile.location != '' ? UserProfile.location : '')}))
            setUserProfile(user => ({...user, timezone : (UserProfile && UserProfile.timezone!= null && UserProfile.timezone != '' ? newTimezones && newTimezones.filter((e,i) => (e.zoneName === UserProfile.timezone))[0] : '')}))
            setSelectedZone(newTimezones && newTimezones.filter((e,i) => (e.zoneName === UserProfile.timezone))[0])
        }
    }
    
    let locationOption = useSelector((state) => {
        return state.common.metaData.countryMaster
    })

    useEffect(() => {
        getAllLocation()
    }, [])

    const getAllLocation = () => {
        dispatch(getAllMetaDataAction())
    }


    useEffect(() => {
        configureUserProfile()
    }, [UserProfile])

    const editUserProfile =async () => {
        let userDetail = {
            name : userProfile.name,
            profileUrl : userProfile.profileUrl,
            email : userProfile.email,
            location : location,
            timezone : selectedZone.zoneName,
            contactNo: userProfile.contactNo
        }
        try {
            await dispatch(editUserProfileAction(userDetail))
            // Access the response data (e.g., game ID) 
            // setIsChanged(false);
            const user = JSON.parse(localStorage.getItem('user'))
            const memberID = user?.id
            const memberName = user?.name
            const orgId = (localStorage.getItem('orgId'))

            // Log activity in Firestore
            const activityLog = {
                timestamp: new Date().toISOString(),
                action_type: "UPDATE",
                // app_id:appid,
                resource: "USER",
                // resource_id: id, // Use the ID from response
                description: `User Profile '${userProfile.name}' updated successfully`,
                quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
                operation_status: "SUCCESS",
                activity_feed:  false,
                priority: "LOW",
                performed_by_id: memberID,
                organization_id: orgId,
                performed_by:memberName,
                request_json:JSON.stringify(userDetail),
            };
            await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
            console.log("Activity logged successfully");
        
            // setIsChanged(false)
        } catch (error) {
            
        }
    }

    const cancelChanges = () => {
        configureUserProfile()
    }

    const db = getFirestore(app);
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [errors, setErrors] = useState({passWordErr : ''})


    function getCurrentTimeAndZoneCode(timezone) {
        const now = moment.tz(timezone.zoneName);
        return " ("+timezone.abbreviation+")";
    }
    
    
    const validation = (passWord, confirmPassword) => {
        let error = {}
        let isValid = true
        if(passWord !== confirmPassword){
            error.passWordErr = 'Password does not match'
            isValid = false
        }

        setErrors(error)
        if(isValid){
            return true
        }
    }

    const onChangePassword = (e) => {
        setNewPassword(e.target.value)
        setRules(validatePassword(e.target.value))
    }

    const validatePassword = (password) => {
        const rules = {
            lengthRule: password.length >= 8,
            capitalRule: /[A-Z]/.test(password),
            smallRule: /[a-z]/.test(password),
            numberRule: /\d/.test(password),
            specialRule: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        }
        return rules
    }

    const editPassword =async () => {
        if(validation(newPassword, confirmPassword)){
           if(rules.lengthRule && rules.smallRule && rules.capitalRule && rules.numberRule && rules.specialRule){
            let newPassword = {
                email : UserProfile.email,
                currentPassword : currentPassword,
                newPassword : confirmPassword
            }
            try {
                await dispatch(changeMemberPasswordAction(newPassword, setCurrentPassword, setNewPassword, setConfirmPassword, setRules))
                // Access the response data (e.g., game ID) 
                // setIsChanged(false);
                const user = JSON.parse(localStorage.getItem('user'))
                const memberID = user?.id
                const memberName = user?.name
                const orgId = (localStorage.getItem('orgId'))
    
                // Log activity in Firestore
                const activityLog = {
                    timestamp: new Date().toISOString(),
                    action_type: "UPDATE",
                    // app_id:appid,
                    resource: "MEMBER",
                    // resource_id: id, // Use the ID from response
                    description: `Member Password for '${UserProfile.email}' updated successfully`,
                    quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
                    operation_status: "SUCCESS",
                    activity_feed:  false,
                    priority: "LOW",
                    performed_by_id: memberID,
                    organization_id: orgId,
                    performed_by:memberName,
                    request_json:JSON.stringify(newPassword),
                };
                await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
                console.log("Activity logged successfully");
            
                // setIsChanged(false)
            } catch (error) {
                
            }
           }
        }
    }

    const cancelPasswordChanges = () => {
        setConfirmPassword('')
        setNewPassword('')
        setCurrentPassword('')
    }

    const isObjectEmpty = (obj) => {
        // Iterate through all keys of the object
        for (let key in obj) {
            if (obj[key] !== null && obj[key] !== '') {
            // If any key has a value that's not null or '', return true
            return true;
            }
        }
        // If all values are either null or '', return false
        return false
    }

    const hasObjectChanged = (obj1, obj2) => {
        // Compare profileUrl with profile_url
        if (obj1.profile_url !== obj2.profileUrl) {
            return true;
        }
        // Compare timezone.zoneName with timezone
        if (obj1.timezone !== selectedZone.zoneName) {
            return true;
        }
        // Compare email with email
        if (obj1.email !== obj2.email) {
            return true;
        }
        console.log(obj1, obj2)
        if (obj1.contact_no !== obj2.contactNo) {
            return true;
        }
        // Compare name with name
        if (obj1.name.trim() !== obj2.name.trim()) {
            return true;
        }
        // If no changes found, return false
        return false
    }

    const [isChanged, setIsChanged] = useState(false)

    const checkChanges = () => {
        if(isObjectEmpty(userProfile)){
            let isChanged = hasObjectChanged(UserProfile, userProfile)
            setIsChanged(isChanged)
        }
    }

    useEffect(() => {
        checkChanges()
    }, [userProfile, location, selectedZone])

    return (
        <>
            <div className={styles.container}>
                <Card classTitle="title-purple">
                    <div className={styles.overview}>

                        <div className={styles.cardHead}>
                            <p className={styles.title}>User Profile</p>
                            <p className={styles.info}>Your personal information and other information.</p>
                        </div>
                        <div className={styles.cardBody}>
                        <div className={styles.preview}>
                            <div className={styles.previewImage}>
                                <img src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                                <label for='icon' className={styles.edit}>
                                    <Icon name='edit' size='12' />
                                </label>
                            </div>
                            <div className={styles.actions}>
                                <div className={styles.previewInput}> 
                                    <input id={`icon`} type="file" className='file-input' accept="image/jpeg, image/png, .jpeg, .jpg, .png, .webp" ref={logoRef} onChange={(e)=>{uploadLogo(e)}}/>
                                    {/* <label for='icon' className={cn(styles.button, 'button-save-small')}>Upload</label> */}
                                </div>
                                {/* {(userProfile != null && userProfile.profileUrl != null) ?
                                    <div className={styles.previewCancel}>
                                        <button className={cn(styles.button, 'button-cancel-small')} onClick={Cancel}>Remove</button>
                                    </div>
                                : ''} */}
                            </div>
                        </div>

                        <div className={styles.info}>
                            <div className={styles.group}>
                                <TextInput className={styles.field} value={userProfile.name != null ? userProfile.name : ''} label='Name' name="name" type="text" onChange={(e) => setUserProfile(user => ({...user, name : e.target.value}))}/>
                                <TextInput className={styles.field} value={userProfile.email != null ? userProfile.email : ''} label='Email' name='email' type='text' onChange={(e) => setUserProfile(user => ({...user, email : e.target.value}))}/>
                            </div>

                            <div className={styles.group}>
                                <div className={styles.field} ><SearchDropdown currency={<Internet />} label='Location' placeholder='Where are you based?' options={locationOption} value={location} setValue={setLocation} setId={setLocationId}/></div>
                            
                                <div className={styles.field}>
                                    <Dropdown customFunc={()=>{""}} currency={<Internet fill="#000000" size="16"/>} label={"Preferred Time Zone"} placeholder={"Preferred Time Zone"} setSelectedZone={setSelectedZone} setId={setSelectedZoneID} value={selectedZone.zoneName&& selectedZone.zoneName+getCurrentTimeAndZoneCode(selectedZone)} options={
                                            newTimezones.map((place)=>{return {name:place.zoneName+getCurrentTimeAndZoneCode(place), value:place}})
                                        }/>
                                </div>
                            </div>

                            <div className={styles.group}>
                                <TextInput className={styles.field} value={userProfile.contactNo != null ? userProfile.contactNo : ''} label='Contact' name="contactNo" type="text" onChange={(e) => setUserProfile(user => ({...user, contactNo : e.target.value}))}/>
                            </div>

                            <div className={styles.group}>
                                <div className={styles.cardAction}>
                                    <div className={styles.cardSave}>
                                        <button disabled={!isChanged} className={cn(styles.button, 'button-save-small')} onClick={editUserProfile}>Save</button>
                                    </div>
                                    <div className={styles.cardCancel}>
                                        <button disabled={!isChanged} className={cn(styles.button, 'button-cancel-small')} onClick={cancelChanges}>Cancel</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        </div>
                        
                    </div>
                </Card>

                <Card classTitle="title-purple">
                    <div className={styles.passWord}>
                        <div className={styles.cardHead}>
                            <p className={styles.title}>Password</p>
                            <p className={styles.info}>Your password and reset old password.</p>
                        </div>
                        <div className={styles.cardBody}>
                        {UserProfile.password !== null &&
                            <div className={styles.group}>
                                <TextInput className={styles.field} label='Current Password' name='currentPassword' type='password' value={currentPassword} onChange={(e) => {setCurrentPassword(e.target.value)}} />
                            </div>
                        }
                        <div className={styles.group}>
                            <TextInput errorMessage={errors.passWordErr} className={styles.field} label='New Password' name='newPassword' type='password' value={newPassword} onChange={(e) => {onChangePassword(e)}} />
                            <TextInput errorMessage={errors.passWordErr} className={styles.field} label='Confirm Password' name='confirmPassword' type='password' value={confirmPassword} onChange={(e) => {setConfirmPassword(e.target.value)}} />
                        </div>

                        <div className={styles.rules}>
                            <div className={styles.passwordRule}>
                                <div className={styles.heading}>
                                    <span>Password must contain: </span>
                                </div>
                                <div className={styles.ruleGroup}>
                                    <div className={styles.rule}>
                                        {rules.lengthRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                        {rules.lengthRule != null && !rules.lengthRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                        {rules.lengthRule != null && rules.lengthRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                        <span>at least 8 characters</span>
                                    </div>
                                    <div className={styles.rule}>
                                        {rules.smallRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                        {rules.smallRule != null && !rules.smallRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                        {rules.smallRule != null && rules.smallRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                        <span>at least one lowercase letter (a-z)</span>
                                    </div>
                                    <div className={styles.rule}>
                                        {rules.capitalRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                        {rules.capitalRule != null && !rules.capitalRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                        {rules.capitalRule != null && rules.capitalRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                        <span>at least one uppercase letter (A-Z)</span>
                                    </div>
                                    <div className={styles.rule}>
                                        {rules.numberRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                        {rules.numberRule != null && !rules.numberRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                        {rules.numberRule != null && rules.numberRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                        <span>at least one number (0-9)</span>
                                    </div>
                                    <div className={styles.ruleFull}>
                                        {rules.specialRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                        {rules.specialRule != null && !rules.specialRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                        {rules.specialRule != null && rules.specialRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                        <span>at least one special character (! @ # $ % ^ & )</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.group}>
                            <div className={styles.cardAction}>
                                <div className={styles.cardSave}>
                                    <button disabled={currentPassword === '' || newPassword === '' || confirmPassword === ''} className={cn(styles.button, 'button-save-small')} onClick={editPassword}>Save</button>
                                </div>
                                <div className={styles.cardCancel}>
                                    <button disabled={currentPassword === '' || newPassword === '' || confirmPassword === ''} className={cn(styles.button, 'button-cancel-small')} onClick={cancelPasswordChanges}>Cancel</button>
                                </div>
                            </div>
                        </div>

                        </div>
                    </div>
                </Card>
            </div>
        </>
    )
}

export default Profile