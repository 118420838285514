import react, { useState } from 'react'
import Card from '../../../components/Card'
import cn from 'classnames'
import styles from './events.module.sass'
import {ReactComponent as EmptyFolder} from '../../../media/images/placeholders/emptyFolder.svg'
import { Link, useParams } from 'react-router-dom'
import CompetitionToggle from '../Competition/CompetitionToggle'

const Events = ({className, data}) => {

    const {appid} = useParams()

    const Tabs = ["Subscribed"]
    const [activeIndex, setActiveIndex] = useState(0)

    const toggleOption = [{id : 1, name : 'Subscribed'}]
    const [toggle, setToggle] = useState(toggleOption[0].name)


    if(data){
        return(
            <>
                <Card className={cn(styles.card, className)} title="Events" classTitle="title-red"
                    head={<div className={styles.toggle}><CompetitionToggle type='event' value={toggle} setValue={setToggle} options={toggleOption} /></div>}
                >
                
                    <div className={styles.eventContainer}>
                        
                        {(data && ((data?.liveEvents?.length == 0 && toggle == 'Subscribed') || (data?.topEvents?.length === 0 && toggle == 'Top')))&&
                            <div className={styles.event}>
                                <div className={styles.emptyState}>
                                    <div className={styles.emptyFolder}><EmptyFolder /></div>
                                    <div className={styles.emptyTitle}><span>You have no <a href='#' target='_blank'>Events</a> yet!</span></div>
                                 </div>
                            </div>
                        }

                        {data && data.liveEvents && data.liveEvents.length > 0 && toggle == 'Subscribed' &&
                        <>
                            <div className={styles.wrapper}> 
                                <div className={styles.table}>
                                    <div className={styles.row}>
                                    <div className={styles.col}>Name</div>
                                    </div>
                                    {data.liveEvents && data.liveEvents.slice(0,5).map((e, index) => (
                                        <>
                                            <div className={styles.tableRow}>
                                                <div className={styles.tableCol}><div className={styles.overflowText}>{(e.name && e.name != '' && e.name != null) ? e.name : '-'}</div></div>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                            <div className={styles.viewAll}>
                                <Link className={styles.all} to={`/default-events/${appid}`}>View All</Link>
                            </div>
                        </>
                        }

                        {data && data.topEvents && data.topEvents.length > 0 && toggle == 'Top' &&
                        <>
                            <div className={styles.wrapper}> 
                                <div className={styles.table}>
                                    <div className={styles.row}>
                                    <div className={styles.col}>Name</div>
                                    </div>
                                    {data.topEvents  && data.topEvents.slice(0,5).map((e, index) => (
                                        <>
                                            <div className={styles.tableRow}>
                                                <div className={styles.tableCol}><div className={styles.overflowText}>{(e.name && e.name != '' && e.name != null) ? e.name : '-'}</div></div>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                            <div className={styles.viewAll}>
                                <Link className={styles.all} to={`/default-events/${appid}`}>View All</Link>
                            </div>
                        </>
                        }
    
                    </div>
                </Card>
            </>
        )
    }
}

export default Events