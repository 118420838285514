import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./File.module.sass";
import Icon from "../../Icon";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from "../../Toast";

const FileUpload = ({ className, type, placeHolder, multiple, onChange, selection, ...props }) => {
    
    const fileInputRef = useRef(null);

    const handleFiles = (selectedFiles) => {
        onFileUpload(selectedFiles)
    };

    const CheckFiles = (file, type, length) => {
        let fileName = file.name.split('.')[0]
        let maxLength = Object.entries(selection).filter(([key, value]) => value === true).length
        if(maxLength === length){
            if(selection.main && fileName === (type === 'Items' ? 'File_Items' : type === 'Bundles' ? 'File_Bundles' : type === 'Stores' ? 'File_Stores' : type === 'Currency' ? 'File_Currency' : null)){
                return true
            }else if(selection.pricing && fileName === 'File_Pricing'){
                return true
            }else if(selection.lockBy && fileName === 'File_LockBy'){
                return true
            }else{
                toast.error(<Toast type='Error' messages={`Please upload selected file with proper file name corrosponding to your file selection.`}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 10000})
                return false
            }
        }else{
            toast.error(<Toast type='Error' messages={`Please upload files which are selected in previous step.`}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 10000})
            return false
        }
    }

    const onFileUpload = async(e) => {
        let files = e?.target?.files ? e?.target?.files : e?.dataTransfer?.files ? e?.dataTransfer?.files : []
        let fileValidation = []
        for(var i = 0; i < files.length; i++){
            fileValidation.push(await CheckFiles(files[i], type, files?.length))
        }
        if(fileValidation.every(file => file === true)){
            if(type === 'Currency'){
                onChange.onCSVCurrency(files[0])
            }else{
                for(var i = 0; i < files.length; i++){
                    if((files[i].name).split('.')[0] === (type === 'Items' ? 'File_Items' : type === 'Bundles' ? 'File_Bundles' : type === 'Stores' ? 'File_Stores' : type === 'Currency' ? 'File_Currency' : null)){
                        onChange.onCSVItem(files[i])
                    }else if((files[i].name).split('.')[0] === 'File_Pricing'){
                        onChange.onCSVPricing(files[i])
                    }else if((files[i].name).split('.')[0] === 'File_LockBy'){
                        onChange.onCSVFile(files[i])
                    }
                }
            }
        }
        // let files = e?.target?.files ? e?.target?.files : e?.dataTransfer?.files ? e?.dataTransfer?.files : []
        // if(type === 'Items'){
        //     for(var i = 0; i < files.length; i++){
        //         if(selection.main && (files[i].name).split('.')[0] === 'File_Items'){
        //             onChange.onCSVItem(files[i])
        //         }else if(selection.pricing && (files[i].name).split('.')[0]  === 'File_Pricing'){
        //             onChange.onCSVPricing(files[i])
        //         }else if(selection.lockBy && (files[i].name).split('.')[0]  === 'File_LockBy'){
        //             onChange.onCSVFile(files[i])
        //         }else{
        //             toast.error(<Toast type='Error' messages='Please Upload Valid Files.'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 10000})
        //         }
        //     }
        // }else if(type === 'Bundles'){
        //     for(var i = 0; i < files.length; i++){
        //         if(selection.main && (files[i].name).split('.')[0] === 'File_Bundles'){
        //             onChange.onCSVItem(files[i])
        //         }else if(selection.pricing && (files[i].name).split('.')[0]  === 'File_Pricing'){
        //             onChange.onCSVPricing(files[i])
        //         }else if(selection.lockBy && (files[i].name).split('.')[0]  === 'File_LockBy'){
        //             onChange.onCSVFile(files[i])
        //         }else{
        //             toast.error(<Toast type='Error' messages='Please Upload Valid Files.'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 10000})
        //         }
        //     }
        // }else if(type === 'Stores'){
        //     for(var i = 0; i < files.length; i++){
        //         if(selection.main && (files[i].name).split('.')[0] === 'File_Stores'){
        //             onChange.onCSVItem(files[i])
        //         }else if(selection.pricing && (files[i].name).split('.')[0]  === 'File_Pricing'){
        //             onChange.onCSVPricing(files[i])
        //         }else if(selection.lockBy && (files[i].name).split('.')[0]  === 'File_LockBy'){
        //             onChange.onCSVFile(files[i])
        //         }else{
        //             toast.error(<Toast type='Error' messages='Please Upload Valid Files.'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 10000})
        //         }
        //     }
        // }else if(type === 'Currency'){
        //     onChange.onCSVCurrency(files[0])
        // }
    }

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.classList.add('dragover');
    };
    
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.classList.add('dragover');
    };
    
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.classList.remove('dragover');
    };
    
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.classList.remove('dragover');
        const droppedFiles = e;
        // handleFiles(droppedFiles)
    }

    return (
        <div className={cn(styles.file, className)} onDragEnter={handleDragEnter} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
            <div className={styles.wrap} for='import'>
                <input ref={fileInputRef} id='import' className={styles.input} type="file" multiple={multiple} onChange={(e)=>{onFileUpload(e)}}/>
                <div className={styles.box}>
                    <label for='import'><Icon name="upload" size="24" />{placeHolder}</label>
                </div>
            </div>
        </div>
    );
};



export default FileUpload;
