import React, { useEffect, useState, useRef, useContext} from "react";
import cn from "classnames";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WithContext as ReactTags } from "react-tag-input";
import { useDispatch, useSelector } from "react-redux";
import styles from "./ScheduleCompetitionInformation.module.sass";
import Card from "../../../../../components/Card";
import TextInput from "../../../../../components/TextInput";
import Radio from '../../../../../components/Radio';
import Tooltip from "../../../../../components/Tooltip";
import {ReactComponent as AddMetaIcon} from '../../../../../media/images/icons/add_meta.svg';
import {ReactComponent as Remove} from '../../../../../media/images/icons/remove.svg';
import CategoryDropdown from '../../../../../components/Dropdown/RadioDropdown'
import CreatableDropdown from '../../../../../components/Dropdown/CreatableDropdown'
import Dropdown from '../../../../../components/Dropdown'
import { getAllMetaDataAction } from '../../../../../redux/action/common/commonAction';
import { getCompetitionInstantBattleAction} from '../../../../../redux/action/engage/Competitions/competitionAction';
import { getLiveOpsCategoryAction,editScheduleLiveOpsAction} from '../../../../../redux/action/engage/LiveOps/LiveOpsAction';
import { BASE_URL, GET_SCHEDULE_COMPETITION } from '../../../../../resources/APIEndpoints';
import TagInputWithDropdown from "../../../../../components/TagInputWithDropdown";
import moment from 'moment';
import customAxios from "../../../../../utils/interceptor";
import TooltipTitle from "../../../../../Tooltip/TooltipTitle";
import Tooltips from "../../../../../components/Tooltip";
import JSONMetaDataValueInput from "../../../../../components/MetaInput";
import GlobalStateContext from "../../../../../components/Context/GlobalStates/GlobalStateContext";
import Icon from "../../../../../components/Icon";
import app,{increaseUnreadCountForAllUsers} from "../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";
import Toast from "../../../../../components/Toast";


const StoreInformation = ({className, isChanged, setIsChanged}) => {

    const globalStates = useContext(GlobalStateContext)

    const [ID, setID] = useState()
    const [CategoryOptions, setCategoryOptions] = useState([])
    const [CategoryValue, setCategoryValue] = useState()
    const [CategoryID, setCategoryID] = useState()
    const [CompetitionOptions, setCompetitionOption] = useState()
    const [Competitions, setCompetitions] = useState()
    const [frequency, setFrequency] = useState(true)
    const [recurringOptions, setRecurringOption] = useState()
    const [recurring, setRecurring] = useState('')
    const [recurringId, setRecurringId] = useState()
    const [competitionId,setCompetitionId] = useState()
    const [competitionIdName,setCompetitionIdName] = useState()
    const [startDateTime, setStartDateTime] = useState('')
    const [endDateTime, setEndDateTime] = useState('')
    const [offset, setOffset] = useState(true)
    const [hours, setHours] = useState(null)
    const [minutes, setMinutes] = useState(null)
    const [tags, setTags] = useState([]);
    const dispatch = useDispatch();
    const {appid} = useParams();
    const {id} = useParams()

    const navigate = useNavigate();

    const [userAccess, setUserAccess] = useState(false)
    const [editAccess, setEditAccess] = useState(true)

    const getUserAccess = () => {
        if(globalStates && globalStates.user && globalStates.user.type === 'admin'){
            setEditAccess(globalStates.access)
            setUserAccess(false)
        }else if(globalStates && globalStates.user && globalStates.user.type === 'invited_member' && globalStates.user.memberAccessConfig?.length > 0){
            let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
            if(appPermission?.length > 0){
                setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
                setEditAccess(globalStates.access)
            }
        }else{

        }
    }

    useEffect(() => {
        getUserAccess()
    }, [globalStates.access])


    // const getUserAccess = () => {
    //     if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
    //         let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
    //         if(appPermission?.length > 0){
    //             setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
    //             globalStates.setAccess(true)
    //         }
    //     }
    // }
    
    // useEffect(() => {
    //     getUserAccess()
    // }, [])

    // const onToggleAccess = () => {
    //     if(!userAccess){
    //         setEditAccess(!editAccess)
    //         globalStates.setAccess(false)
    //     }
    // }

    const onChangeStartDateTime = (e) => {
        setStartDateTime(e.target.value)
    }
  
    const onChangeEndDateTime = (e) => {
        setEndDateTime(e.target.value)
    }
    const onChangeHours = (e) => {
        setHours(parseInt(e.target.value))
    }
    
    const onChangeMinutes = (e) => {
        setMinutes(parseInt(e.target.value))
    }
    const onTagInput = () => {
        if(document.getElementById('tagsInput')){
            document.getElementById('tagsInput').focus()
        }
    }
    const [bgColor, setBgColor] = useState();
    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]);
        }else{
            toast.warning(<Toast type='Warning' messages='Only 10 tags are allowed'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        }
    };
  
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };
    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }


    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const handleInputChange = (e) => {

    }
    useEffect(()=>{
        getMetaData()
    },[])
      
    const getMetaData = () => {
        dispatch(getAllMetaDataAction())
    }

    let allMetaData = useSelector((state) => {
        return state.common.metaData
      })
  
      useEffect(()=>{ 
        configureMetaData()
      }, [allMetaData])


    const configureMetaData = () => {

        setRecurringOption(allMetaData.leaderboardInterval && allMetaData.leaderboardInterval.filter(obj => obj.intervalName !== 'monthly' && obj.intervalName !== 'yearly' && obj.intervalName !== 'all_time' && obj.intervalName !== 'custom').map((obj) => {
            return {
              id: obj.id,
              name: (obj.intervalName.charAt(0).toUpperCase() + obj.intervalName.slice(1))?.replace(/_/g, ' '),
            };
        }))

    }
    const getCompetitionTournament = async() => {
        let getTournaments = {
            projectId : appid,
        }
        dispatch(getCompetitionInstantBattleAction(getTournaments))
    }

    const getLiveOpsCategory = async() => {
        let getLiveOpsCategory ={
            projectId:appid,
        }
        dispatch(getLiveOpsCategoryAction(getLiveOpsCategory))
    }

    useEffect(() => {
        getCompetitionTournament()
        getLiveOpsCategory()
        getCompetitionScheduleById()
    }, [])

    let allLiveOpsCategory = useSelector((state) => {
        return state.liveOps.liveOpsCategory
    })
    useEffect(()=>{ 
        configureCategoryData()
    }, [allLiveOpsCategory])

    const cancel = () => {
        setEditAccess(true)
        globalStates.setAccess(true)
        // navigate(`/competition-schedule/${appid}`)
        setIsChanged(false)
    }


    let allCompetitions = useSelector((state) => {
      return state.competition.instantBattle
    })
    const configureCategoryData = () => {
        setCategoryOptions(allLiveOpsCategory && allLiveOpsCategory.map((obj) => {
            return {
                id: obj.id,
                name: obj.name,
                colorCode: obj.colorCode,
            };
        }))

    }

    const configureCompetitionData = () => {
    setCompetitionOption(allCompetitions && allCompetitions.map((obj) => {
        return {
            id: obj.id,
            name: obj.name,
            competitionId: obj.competitionId,
        };
    }))
    }
    useEffect(()=>{
        configureCompetitionData()
    }, [allCompetitions])

    useEffect(()=>{
        allCompetitions&& competitionId&& allCompetitions.map((obj)=>{
            if (obj.id==competitionId) {
                setCompetitionIdName(obj.competitionId)
            }
        })
    },[competitionId])
    useEffect(()=>{
        allLiveOpsCategory&& CategoryID && allLiveOpsCategory.map((obj)=>{
            if (obj.id==CategoryID) {
                setCategoryValue(obj.name)
                setBgColor(obj.colorCode)
            }
        })
    },[CategoryID])

 // Edit API for ScheduleCompetitons

 const [errors, setErrors] = useState({compError : ''})

 const validation = (data) => {

    const error = {}
    let isValid = true

    if (data.competitionId == null) {
       error.compError = 'Competition is required';
       isValid = false;
       const element = document.getElementById('scheduleName');
       const offset = 140;
       const bodyRect = document.body.getBoundingClientRect().top;
       const elementRect = element.getBoundingClientRect().top;
       const elementPosition = elementRect - bodyRect;
       const offsetPosition = elementPosition - offset;
       window.scrollTo({top: offsetPosition, behavior: 'smooth'});
    }


    setErrors(error);

    if(isValid){
      return true
    }
}

const [disable, setDisable] = useState(false)

const db = getFirestore(app);

 const submit = async () => {


    const metaDataList = [...metaData];
    const finalMetaData = {};
    for(var m = 0; m < metaDataList.length ; m++){
        finalMetaData[metaDataList[m].key] = metaDataList[m].value;
    }

    let storeTags = []
    tags && tags.forEach((e)=>{
        storeTags.push(e.text)
    })

    const ScheduleCompetition={
        id:ID,
        competitionId:competitionId,
        projectId: appid,
        startDate: moment.utc(moment(startDateTime)).format("YYYY-MM-DD HH:mm") ,
        endDate : moment.utc(moment(endDateTime)).format("YYYY-MM-DD HH:mm"),
        categoryId: CategoryID,
        scheduleType:(frequency ? "recurring" : "normal"),
        recurrenceFrequencyId: (frequency? recurringId : null),
        offset: (offset ? null : parseInt(((hours * 60 * 60) + (minutes * 60)))),
        meta: finalMetaData,
        tags: storeTags,
        recurrenceCount : (frequency ? recurrence : null)
    }
    
    if(validation(ScheduleCompetition)){
        try {
            await dispatch(editScheduleLiveOpsAction(ScheduleCompetition, navigate, appid, setDisable))
            const user = JSON.parse(localStorage.getItem('user'))
            const memberID = user?.id
            const memberName = user?.name
            const orgId = (localStorage.getItem('orgId'))
  
            // Log activity in Firestore
            const activityLog = {
                timestamp: new Date().toISOString(),
                action_type: "UPDATE",
                app_id:appid,
                resource: "COMPETITION",
                resource_id: competitionId, // Use the ID from response
                description: `Competition Schedule '${competitionIdName}' rescheduled successfully`,
                quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
operation_status: "SUCCESS",
                activity_feed: true,
                priority: "LOW",
                performed_by_id: memberID,
                organization_id: orgId,
                performed_by:memberName,
                request_json:JSON.stringify(ScheduleCompetition),
            };
            await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
            console.log("Activity logged successfully");
        
      } catch (error) {
          console.error("Error whie logging activity:", error);
      }
        setIsChanged(false)
    }
    
}
const [checkObject, setCheckObject] = useState()
// Load ScheduleCompetitions
const getCompetitionScheduleById = async() => {
    let competitionScheduleById = {
        projectId : appid,
        ids : [id]
    }
    const token = localStorage.getItem('token')
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    let res = await customAxios.post(BASE_URL + GET_SCHEDULE_COMPETITION, competitionScheduleById, headers)
    let itemId = res.data.data.liveOps[0]
    setCheckObject(itemId)
    itemId.competition&& setCompetitions(itemId.competition.name)
    itemId.competition&& setCompetitionId(itemId.competition.id)
    itemId.competition&& setCompetitionIdName(itemId.competition.competitionId)
    itemId.categoryId && setCategoryID(itemId.categoryId)
    allLiveOpsCategory&& itemId.categoryId && allLiveOpsCategory.map((obj)=>{
        if (obj.id==itemId.categoryId) {
            setCategoryValue(obj.name)
            setBgColor(obj.colorCode)
        }
    })
    if (itemId.scheduleType&&itemId.scheduleType=="recurring") {
        setFrequency(true)
        setRecurringId(itemId.recurrenceFrequencyId)
        itemId.startDate&& setStartDateTime(moment.utc(itemId.startDate).local().format('YYYY-MM-DD HH:mm'))
        itemId.endDate&& setEndDateTime(moment.utc(itemId.endDate).local().format('YYYY-MM-DD HH:mm'))
        setRecurring(itemId.recurrenceFrequencyId == 1 ? 'Daily' : itemId.recurrenceFrequencyId== 2 ? 'Weekly' : itemId.recurrenceFrequencyId == 3 ? 'Monthly' : itemId.recurrenceFrequencyId == 4 ? 'Yearly' : itemId.recurrenceFrequencyId == 5 ? 'All Time' : '')

    } else {
        setFrequency(false)
        itemId.startDate && setStartDateTime(moment.utc(itemId.startDate).local().format('YYYY-MM-DD HH:mm'))
        itemId.endDate && setEndDateTime(moment.utc(itemId.endDate).local().format('YYYY-MM-DD HH:mm'))
    }
    setRecurrence(itemId.recurrenceCount != null ? itemId.recurrenceCount : null)
    setOffset(itemId.offset != null ? false : true)
    setHours(itemId.offset != null ? Math.floor((itemId.offset % (3600*24))/3600) : null)
    setMinutes(itemId.offset != null ? Math.floor((itemId.offset % 3600)/60) : null)


    
    let liveOpsTags = []
    itemId.tagsMapping && itemId.tagsMapping.forEach(e => {
        e.tag && liveOpsTags.push({id : e.tag.id, text : e.tag.name})
    })
    setTags(liveOpsTags)
    let meta = []
    if(itemId.meta == null || JSON.stringify(itemId.meta) == '{}'){
        setMetaData([{key : '', value : null}])
    }else{
        itemId.meta && Object.keys(itemId.meta).forEach(e => {
        meta.push({key : e, value : itemId.meta[e]})
      })
      setMetaData(meta)
    }
    setID(itemId.id)
}

const setchange = () => {
    let metaDataList = [...metaData];
    let finalMetaData = {};
    for(var i = 0; i < metaDataList.length ; i++){
        finalMetaData[metaDataList[i].key] = metaDataList[i].value;
    }

    let itemTags = []
    tags && tags.forEach((e)=>{
        itemTags.push(e.text)
    })
    let checkTags = []
        checkObject?.tagsMapping && checkObject?.tagsMapping.forEach((e)=>{
            if (e.tag!==null&&e.tag!==undefined) {
            checkTags.push(e?.tag?.name)
            }
        })
    if (checkObject&&
        (CategoryID==checkObject.categoryId)&&
        (competitionId==checkObject.competition.id)&&
        (frequency==(checkObject.recurrenceFrequencyId !=null?true:false))&&
        (recurringId==checkObject.recurrenceFrequencyId)&&
        //(startDateTime==moment.utc(checkObject.startDate).local().format('YYYY-MM-DD HH:mm')||(checkObject.startDate==null&&startDateTime==''))&&
        //(endDateTime==moment.utc(checkObject.endDate).local().format('YYYY-MM-DD HH:mm')||(checkObject.endDate==null&&endDateTime==''))&&
        (offset==(checkObject.offset != null ? false : true))&&
        (hours==(checkObject.offset != null ? Math.floor((checkObject.offset % (3600*24))/3600) : null))&&
        (minutes==(checkObject.offset != null ? Math.floor((checkObject.offset % 3600)/60) :null))&&
        //((JSON.stringify(finalMetaData) == JSON.stringify(checkObject.meta))||((JSON.stringify(finalMetaData)=='{"":""}')&&(checkObject.meta==null)))&&
        (JSON.stringify(itemTags) == JSON.stringify(checkTags)|| (checkObject.tagsMapping==null && itemTags.length==0))
    )
    {
        setIsChanged(false)
    }
    else
    {
        setIsChanged(true)
    }
}

    const [recurrence, setRecurrence] = useState(null)

    const onChangeCount = (e) => {
        setRecurrence(parseInt(e.target.value))
    }

    useEffect(()=>{
        setchange()
    },[CategoryID,competitionId,frequency,recurringId,startDateTime,endDateTime,offset,hours,minutes,tags,metaData])

    return (
        <>
            <ToastContainer />
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-purple">
            <div className={styles.description}>
                <div className={styles.group}>
                    <div className={styles.field}>
                        <CreatableDropdown disabled={editAccess} tooltip={TooltipTitle.CompetitionName} id='scheduleName' errorMessage={errors.compError} label="Select Competition*" placeholder="Select Competition" className={styles.selectField} groupBy={1} options={CompetitionOptions} setId={setCompetitionId} value={Competitions} setValue={setCompetitions}/>
                    </div>
                    <TextInput tooltip={TooltipTitle.CompetitionId} className={styles.field} type="text" label="ID" onWheelCapture={e => {e.currentTarget.blur()}} disabled value={competitionIdName} />
                </div>
                <div className={styles.group}>
                    <div className={styles.field}>
                        <CategoryDropdown disabled={editAccess} placeholder='Select Category' label="Category" setBgColor={setBgColor} bgColor={bgColor} className={styles.selectField} setId={setCategoryID} options={CategoryOptions} value={CategoryValue} setValue={setCategoryValue}/>
                    </div>
                </div>
            </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Schedule" classTitle="title-blue">
                <div className={styles.group}>
                    <div className={styles.fieldRadio}>
                        <div className={styles.radioLabel}>
                            <span>Schedule Type<span><Tooltips className={styles.tooltip} title={TooltipTitle.CompetitionType} icon="info"/></span></span>
                        </div>
                        <div className={styles.variants}>
                            <Radio disabled={editAccess} className={styles.radio} name="frequency" value={!frequency} onChange={() => setFrequency(false)} content="Normal" />
                            <Radio disabled={editAccess} className={styles.radio} name="frequency" value={frequency} onChange={() => setFrequency(true)} content="Recurring" />
                        </div>
                    </div>
                    {frequency &&
                        <div className={styles.field}>
                            <Dropdown disabled={editAccess} tooltip={TooltipTitle.CompetitionRecurring} label='Recurring' placeholder='Select Recurring Frequency' className={styles.selectField} value={recurring} setValue={setRecurring} setId={setRecurringId} options={recurringOptions}/>
                        </div>
                    }
                </div>

                {frequency &&
                    <div className={styles.group}>
                        <TextInput disabled={editAccess} label='Recurrence Count' name='count' type='number' value={recurrence} onChange={(e) => {onChangeCount(e)}} className={styles.field} />
                    </div>
                }
                  
                <div className={styles.group}>
                    <TextInput disabled={editAccess} tooltip={TooltipTitle.CompetitionStartDate} label='Start Date/Time' name='startDateTime' type='datetime-local' className={styles.field} value={startDateTime} onChange={(e)=>{onChangeStartDateTime(e)}}/>
                    <TextInput disabled={editAccess} tooltip={TooltipTitle.CompetitionEndDate} label='End Date/Time' min={startDateTime} name='endDateTime' type='datetime-local' className={styles.field} value={endDateTime} onChange={(e)=>{onChangeEndDateTime(e)}}/>
                </div>
                
                <div className={styles.pdGroup}>
                    <div className={styles.fieldRadio}>
                    <div className={styles.radioLabel}>
                        <span>Prize Distribution<span><Tooltips className={styles.tooltip} title={TooltipTitle.CompetitionOffeset} icon="info"/></span></span>
                    </div>
                    <div className={styles.variants}>
                        <Radio disabled={editAccess} className={styles.radio} name="PDOffset" value={offset} onChange={() => setOffset(true)} content="On End" />
                        <Radio disabled={editAccess} className={styles.radio} name="PDOffset" value={!offset} onChange={() => setOffset(false)} content="Offset" />
                    </div>
                    </div>
                    {!offset && 
                    <>
                        <TextInput disabled={editAccess} type='number' className={styles.fields} label='Hours' value={hours} onChange={(e) => {onChangeHours(e)}}/>
                        <TextInput disabled={editAccess} type='number' className={styles.fields} label='Minutes' value={minutes} onChange={(e) => {onChangeMinutes(e)}}/>
                    </>
                    }
                </div> 
                
            </Card>
            <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                <div className={styles.cardDescription}>
                    
                    <TagInputWithDropdown disabled={editAccess} tooltip={TooltipTitle.CompetitionTags} tags={tags} setTags={setTags}/>

                    <div className={styles.appInformation}>
                        <div className={styles.groupHeading}>
                            <span>Meta Data</span><span><Tooltips className={styles.tooltip} title={TooltipTitle.CompetitionMetaData} icon="info"/></span>{!editAccess && <span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>}
                        </div>

                        {metaData && metaData.length > 0 &&
                            <div className={styles.groupMeta}>
                                <div className={styles.field}>Key</div>
                                <div className={styles.field}>Value</div>
                            </div>
                        }
                        
                        <div className={styles.groupMeta}>
                            {metaData && metaData.map((e,i)=>{
                                return(
                                    <>
                                        <TextInput disabled={editAccess} className={styles.field} label="" name="key" type="text" value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''} required  onChange={(e)=>{onChangeKey(e,i)}}/>
                                        <JSONMetaDataValueInput disabled={editAccess} key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                        {/* <TextInput className={styles.field} label="" name="value" type="text" value={(metaData[i].value != '' || metaData[i].value != undefined || metaData[i].value != null) ? metaData[i].value : ''} required onChange={(e)=>{onChangeValue(e,i)}}/> */}
                                        {!editAccess && <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><Remove className={styles.icon}/></span>}
                                    </>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </Card>
            {!editAccess &&
            <Card className={cn(styles.card, className)}>
                <div className={styles.cardAction}>
                    <div className={styles.cardSave}>
                        <button disabled={disable} onClick={submit} className={styles.Save}>Save</button>
                    </div>
                    <div className={styles.cardCancel}>
                        <button onClick={cancel} className={styles.Cancel}>Cancel</button>
                    </div>
                </div>
            </Card>
            }
        </>
    )
}

export default StoreInformation;
