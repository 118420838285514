import React, { useState } from "react";
import styles from "./table.module.sass";
import cn from "classnames";
import Checkbox from "../../../Checkbox";
import Row from "./Row";


const Table = ({items, access, value, setValue }) => {

  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Wallet Name</div>
          <div className={styles.col}>Wallet Id</div>
          <div className={styles.col}>Type</div>
          <div className={styles.col}>Enabled</div>
          <div className={styles.col}>Action</div>
        </div>
        {items && items.map((x, index) => (
          <Row access={access} value={items} setValue={setValue} item={x} key={index} index={index} />
        ))}
      </div>
    </div>
  );
};

export default Table;
