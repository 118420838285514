import react, { useState } from 'react'
import Card from '../../../components/Card'
import cn from 'classnames'
import styles from './competition.module.sass'
import {ReactComponent as EmptyFolder} from '../../../media/images/placeholders/emptyFolder.svg'
import { Link, useParams } from 'react-router-dom'
import CompetitionPlaceHolder from '../../../media/images/placeholders/Currency.png'
import moment from 'moment'
import CompetitionToggle from './CompetitionToggle'

const Competition = ({className, data}) => {

    const {appid} = useParams()
    const toggleOption = [{id : 1, name : 'Live'}, {id : 2, name : 'Upcoming'}]
    const [toggle, setToggle] = useState(toggleOption[0].name)

    if(data){
        return(
            <>
                <Card className={cn(styles.card, className)} title="Competition" classTitle="title-blue" head={<div className={styles.toggle}><CompetitionToggle type='competition' value={toggle} setValue={setToggle} options={toggleOption} /></div>}>
                    <div className={styles.progressionContainer}>
                        <div className={styles.progression}>
                            {data && data.liveCompetitions.length === 0 && toggle == 'Live'  &&
                                <div className={styles.emptyState}>
                                    <div className={styles.emptyFolder}><EmptyFolder /></div>
                                    <div className={styles.emptyTitle}><span>You have no <Link to={`/competition-tournament/${appid}`}>Competitions</Link> yet!</span></div>
                                </div>
                            }

                            {data && data.upcomingCompetitions.length === 0 && toggle == 'Upcoming'  &&
                                <div className={styles.emptyState}>
                                    <div className={styles.emptyFolder}><EmptyFolder /></div>
                                    <div className={styles.emptyTitle}><span>You have no <Link to={`/competition-tournament/${appid}`}>Competitions</Link> yet!</span></div>
                                </div>
                            }

                            {data && data.liveCompetitions && data.liveCompetitions.length > 0 && toggle == 'Live' &&
                                <>
                                    <div className={styles.wrapper}>
                                        <div className={styles.table}>
                                            <div className={styles.row}>
                                            <div className={styles.col}>Icon</div>
                                            <div className={styles.col}>Name</div>
                                            <div className={styles.col}>Min Player</div>
                                            <div className={styles.col}>Max Player</div>
                                            <div className={styles.col}>Start Time</div>
                                            <div className={styles.col}>End Time</div>
                                            </div>
                                            {data.liveCompetitions && data.liveCompetitions.slice(0,4).map((e, index) => (
                                                <>
                                                    <div className={styles.tableRow}>
                                                        <div className={styles.tableCol}>
                                                            <img src={(e.iconUrl && e.iconUrl != null && e.iconUrl != '') ? e.iconUrl : CompetitionPlaceHolder} alt={`${e.name}`} />
                                                        </div>
                                                        <div className={styles.tableCol}><div className={styles.overflowText}>{(e.name && e.name != '' && e.name != null) ? e.name : '-'}</div></div>
                                                        <div className={styles.tableCol}>{(e.minPlayers && e.minPlayers != null) ? e.minPlayers : '-'}</div>
                                                        <div className={styles.tableCol}>{(e.maxPlayers && e.maxPlayers != null) ? e.maxPlayers : '-'}</div>
                                                        <div className={styles.tableCol}>{(e.scheduleDetails && e.scheduleDetails != null && e.scheduleDetails.length != 0 && e.scheduleDetails[0].startDate != '') ?  moment(e.scheduleDetails[0].startDate).format('DD/MM/YYYY hh:mm A') : '-'}</div>
                                                        <div className={styles.tableCol}>{(e.scheduleDetails && e.scheduleDetails != null && e.scheduleDetails.length != 0 && e.scheduleDetails[0].endDate != '') ?  moment(e.scheduleDetails[0].endDate).format('DD/MM/YYYY hh:mm A') : '-'}</div>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                    <div className={styles.viewAll}>
                                        <Link className={styles.all} to={`/competition-tournament/${appid}`}>View All</Link>
                                    </div>
                                </>
                            }

                            {data && data.upcomingCompetitions && data.upcomingCompetitions.length > 0 && toggle == 'Upcoming' &&
                                <>
                                    <div className={styles.wrapper}>
                                        <div className={styles.table}>
                                            <div className={styles.row}>
                                            <div className={styles.col}>Icon</div>
                                            <div className={styles.col}>Name</div>
                                            <div className={styles.col}>Min Player</div>
                                            <div className={styles.col}>Max Player</div>
                                            <div className={styles.col}>Start Time</div>
                                            <div className={styles.col}>End Time</div>
                                            </div>
                                            {data.upcomingCompetitions && data.upcomingCompetitions.slice(0,4).map((e, index) => (
                                                <>
                                                    <div className={styles.tableRow}>
                                                        <div className={styles.tableCol}>
                                                            <img src={(e.iconUrl && e.iconUrl != null && e.iconUrl != '') ? e.iconUrl : CompetitionPlaceHolder} alt={`${e.name}`} />
                                                        </div>
                                                        <div className={styles.tableCol}><div className={styles.overflowText}>{(e.name && e.name != '' && e.name != null) ? e.name : '-'}</div></div>
                                                        <div className={styles.tableCol}>{(e.minPlayers && e.minPlayers != null) ? e.minPlayers : '-'}</div>
                                                        <div className={styles.tableCol}>{(e.maxPlayers && e.maxPlayers != null) ? e.maxPlayers : '-'}</div>
                                                        <div className={styles.tableCol}>{(e.scheduleDetails && e.scheduleDetails != null && e.scheduleDetails.length != 0 && e.scheduleDetails[0].startDate != '') ?  moment(e.scheduleDetails[0].startDate).format('DD/MM/YYYY hh:mm A') : '-'}</div>
                                                        <div className={styles.tableCol}>{(e.scheduleDetails && e.scheduleDetails != null && e.scheduleDetails.length != 0 && e.scheduleDetails[0].endDate != '') ?  moment(e.scheduleDetails[0].endDate).format('DD/MM/YYYY hh:mm A') : '-'}</div>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                    <div className={styles.viewAll}>
                                        <Link className={styles.all} to={`/competition-tournament/${appid}`}>View All</Link>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </Card>
            </>
        )
    }

}

export default Competition