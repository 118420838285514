import React, {useEffect, useRef, useState} from 'react'
import cn from "classnames";
import styles from './OrgProfile.module.sass'
import Card from "../../../../../components/Card";
import Form from '../../../../../components/Form';
import Profile from './Profile'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer , toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Icon from '../../../../../components/Icon';
import TextInput from '../../../../../components/TextInput';
import Toast from '../../../../../components/Toast';
import { editOrgProfileAction, getOrgProfileAction, getUserProfileAction } from '../../../../../redux/action/organisation/ProfileAction/profileAction';
import ProfilePlaceHolder from '../../../../../media/images/placeholders/Profile.png'
import Address from './Address';
import { getAllMetaDataAction } from '../../../../../redux/action/common/commonAction';
import OrganizationHeader from '../../../../../components/Header/OrganizationHeader'
import axios from 'axios';
import { BASE_URL } from '../../../../../resources/APIEndpoints';
import ProfilePlaceholder from '../../../../../media/images/placeholders/Profile.png'

const OrgProfile = ({className, setAppName, setShowLoader}) => {

    const {appid} = useParams()
    const dispatch = useDispatch()
    const logoRef = useRef()

    const [UID, setUID] = useState('')
    const placeholder = ProfilePlaceholder
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'})
    const [profile, setProfile] = useState(null)

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        let folderName = 'org-assets'
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}&category=${folderName}&fileName=${file.name}`)
        setProfile(res.data.getUrl)
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            save(res.data.getUrl)
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        });
    }

        // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));     
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
        
        // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
            const fileReader = new FileReader();
            fileReader.onload = (eve) =>{
                resolve(fileReader.result)
            }
            fileReader.readAsDataURL(file)
        })
    }
    
    const copyUIDClipboard = () => {
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = UID;
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand("copy");
        document.body.removeChild(tempTextArea);
        toast.success(<Toast type='Success' messages='copied to clipboard'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
    }

    let OrgProfile = useSelector((state) => {
        return state.organisation.orgProfile
    })
    
    let locationOption = useSelector((state) => {
        return state.common.metaData.countryMaster
    })

    useEffect(() => {
        getAllLocation()
    }, [])

    const getAllLocation = () => {
        dispatch(getAllMetaDataAction())
    }

    const configureProfile = () => {
        setLogo({src: (OrgProfile && OrgProfile.defaultLogoUrl != null && OrgProfile.defaultLogoUrl != '' ? OrgProfile.defaultLogoUrl : ProfilePlaceholder), alt: 'Upload an Image'}); 
        setProfile(OrgProfile && OrgProfile.defaultLogoUrl != null && OrgProfile.defaultLogoUrl != '' ? OrgProfile.defaultLogoUrl : ProfilePlaceholder)
    }

    useEffect(() => {
        configureProfile()
    }, [OrgProfile])

    const save = async (url) => {
        let orgDetails = {
            defaultLogoUrl : url,
            organisationId: localStorage.getItem('orgId')
        }
        try {
            await dispatch(editOrgProfileAction(orgDetails, setAppName))
        } catch (error) {

        }
    }
 
    return (
        <>
            <ToastContainer />
            <OrganizationHeader title='Organisation Settings' />
            <div className={styles.wrapper}>
            <Card className={cn(styles.card, className)}>
                <div className={styles.groups}>
                    <div className={styles.playerInformation}>
                        <div className={styles.preview}>
                            <div className={styles.primaryInfo}>
                                <div className={styles.preview}>
                                    <div className={styles.previewImage}>
                                        <img src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                                        <label for='icon' className={styles.edit}>
                                            <Icon name='edit' size='12' />
                                        </label>
                                    </div>
                                    <div className={styles.actions}>
                                        <div className={styles.previewInput}> 
                                            <input disabled={false}  id={`icon`} type="file" className='file-input' accept="image/jpeg, image/png, .jpeg, .jpg, .png, .webp" ref={logoRef} onChange={(e)=>{uploadLogo(e)}}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.otherInfo}>
                                    <div className={styles.orgName}>
                                        <span className={styles.userName}>{OrgProfile && OrgProfile.name != null && OrgProfile.name != '' ? OrgProfile.name : '-'}</span>
                                    </div>
                                    <div className={styles.secondaryInfo}>
                                        <div className={styles.playerId}>
                                            <div className={styles.ids}>
                                                <span className={styles.label}>Org ID:&nbsp;</span><span className={styles.UIDNO}>{OrgProfile && OrgProfile.id != null && OrgProfile.id != '' ? OrgProfile.id : '-'}<span className={styles.copyToClipboard} onClick={copyUIDClipboard}><Icon name='copy' size='16' /></span></span>
                                            </div>
                                            <div className={styles.ids}>
                                                <span className={styles.label}>No of Members:&nbsp;</span><span className={styles.UUIDNO}>10</span>
                                            </div>
                                        </div>
                                        <div className={styles.playerLocation}>
                                            <div className={styles.ids}>
                                                <span className={styles.label}>Location:&nbsp;</span><span className={styles.UUIDNO}>{OrgProfile && OrgProfile.location != null && OrgProfile.location != '' ? OrgProfile.location : '-'}</span>
                                            </div>
                                            <div className={styles.ids}>
                                                <span className={styles.label}>Created At:&nbsp;</span><span className={styles.UUIDNO}>{OrgProfile && OrgProfile.createdAt != null && OrgProfile.createdAt != '' ? moment(OrgProfile.createdAt).format('DD/MM/YYYY HH:mm A') : '-'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Card>
            <Profile data={OrgProfile} setAppName={setAppName} setShowLoader={setShowLoader} locationOption={locationOption}/>
            <Address data={OrgProfile} setAppName={setAppName} setShowLoader={setShowLoader} locationOption={locationOption}/>
            </div>
        </>
    )
}

export default OrgProfile