import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import styles from './depositWallet.module.sass'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Card from '../../../../Card'
import TextInput from '../../../../TextInput'
import Switch from '../../../../Switch'
import TooltipTitle from '../../../../../Tooltip/TooltipTitle'
import Toast from '../../../../Toast'
import { toast } from 'react-toastify'
import Radio from '../../../../Radio'
import Dropdown from '../../../../Dropdown/DaysDropdown'
import Tooltip from '../../../../Tooltip'


const AddOnModal = ({className, visible, value, setValue, item, onClose}) => {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [toggle, setToggle] = useState()
  const [activeIndex, setActiveIndex] = useState(1)

  const configureToggle = () => {
    if(item.entitySubType === 'winning'){
        setToggle([{id: 1, name: 'Wallet Info'}, {id: 3, name: 'Withdrawal Controls'}, {id: 4, name: 'Balance Controls'}, {id: 5, name: 'Tax and Compliance'}])
    }else{
        setToggle([{id: 1, name: 'Wallet Info'}, {id: 2, name: 'Deposit Controls'}, {id: 3, name: 'Withdrawal Controls'}, {id: 4, name: 'Balance Controls'}, {id: 5, name: 'Tax and Compliance'}])
    }
  }

  useEffect(() => {
    configureToggle()
  }, [item])

  // Wallte Info
  const [taxType, setTaxType] = useState((item?.rules?.tax?.taxType && item?.rules?.tax?.taxType !== null && item?.rules?.tax?.taxType !== '') ? item?.rules?.tax?.taxType : null)
  const [taxInclusionMethod, setTaxInclusionMethod] = useState((item?.rules?.tax?.taxInclusionMethod && item?.rules?.tax?.taxInclusionMethod !== null && item?.rules?.tax?.taxInclusionMethod !== '' && item?.rules?.tax?.taxInclusionMethod === 'Exclusive') ? false : (item?.rules?.tax?.taxInclusionMethod && item?.rules?.tax?.taxInclusionMethod !== null && item?.rules?.tax?.taxInclusionMethod !== '' && item?.rules?.tax?.taxInclusionMethod === 'Inclusive') ? true : false)
  const [taxBasis, setTaxBasis] = useState((item?.rules?.tax?.taxDeductionApplicationBasis && item?.rules?.tax?.taxDeductionApplicationBasis !== null && item?.rules?.tax?.taxDeductionApplicationBasis !== '' && item?.rules?.tax?.taxDeductionApplicationBasis === 'On Net Winnings') ? true : (item?.rules?.tax?.taxDeductionApplicationBasis && item?.rules?.tax?.taxDeductionApplicationBasis !== null && item?.rules?.tax?.taxDeductionApplicationBasis !== '' && item?.rules?.tax?.taxDeductionApplicationBasis === 'On Every Transaction') ? false : false)
  const [walletDetails, setWalletDetails] = useState(
    {
        enableWithdrawal: (item?.entitySubType === 'winning' ? true: item?.rules?.enableWithdrawal), isTaxApplicable: item?.rules?.isTaxApplicable, isKycRequired: item?.rules?.isKycRequired, entitySubType: item?.entitySubType,
        walletId: item?.rules?.info?.walletId, walletName: item?.rules?.info?.walletName, walletType: item?.rules?.info?.walletType,
        
        isDailyDeposit: item?.rules?.depositControls?.all?.find(x => x.fact === 'dailyDepositLimit'),
        isWeeklyDeposit: item?.rules?.depositControls?.all?.find(x => x.fact === 'weeklyDepositLimit'),
        isMonthlyDeposit: item?.rules?.depositControls?.all?.find(x => x.fact === 'monthlyDepositLimit'),

        isDailyWithdrawal: item?.rules?.withdrawalControls?.all?.find(x => x.fact === 'dailyWithdrawalLimit'),
        isWeeklyWithdrawal: item?.rules?.withdrawalControls?.all?.find(x => x.fact === 'weeklyWithdrawalLimit'),
        isMonthlyWithdrawal: item?.rules?.withdrawalControls?.all?.find(x => x.fact === 'monthlyWithdrawalLimit'),
        isWithdrawalLockPeriod: item?.rules?.withdrawalControls?.all?.find(x => x.fact === 'withdrawalLockPeriodInDays'),
        isCommission: item?.rules?.withdrawalControls?.all?.find(x => x.fact === 'commissionPercentage'), 
        isMinWalletBalance: item?.rules?.withdrawalControls?.all?.find(x => x.fact === 'minWalletBalanceForWithdrawal'),

        isMaxWalletBalance: item?.rules?.balanceControls?.all?.find(x => x.fact === 'maxWalletBalance'),

        isTaxFree: item?.rules?.tax?.taxFreeAllowancePerTransaction ? true : false,
        isKYCThreshold: item?.rules?.compliance?.all?.find(x => (x.fact === 'kycApplicabilityThresholdForTotalDeposits' || x.fact === 'kycApplicabilityThresholdForTotalWinnings')),

        maxDepositPerTransaction : item?.rules?.depositControls?.all?.find(x => x.fact === 'maxDepositPerTransaction')?.value, 
        minDepositPerTransaction : item?.rules?.depositControls?.all?.find(x => x.fact === 'minDepositPerTransaction')?.value,
        dailyDepositLimit: item?.rules?.depositControls?.all?.find(x => x.fact === 'dailyDepositLimit')?.value,
        weeklyDepositLimit: item?.rules?.depositControls?.all?.find(x => x.fact === 'weeklyDepositLimit')?.value,
        monthlyDepositLimit: item?.rules?.depositControls?.all?.find(x => x.fact === 'monthlyDepositLimit')?.value,

        maxWithdrawalPerTransaction: item?.rules?.withdrawalControls?.all?.find(x => x.fact === 'maxWithdrawalPerTransaction')?.value,
        minWithdrawalPerTransaction: item?.rules?.withdrawalControls?.all?.find(x => x.fact === 'minWithdrawalPerTransaction')?.value,
        dailyWithdrawalLimit: item?.rules?.withdrawalControls?.all?.find(x => x.fact === 'dailyWithdrawalLimit')?.value,
        weeklyWithdrawalLimit: item?.rules?.withdrawalControls?.all?.find(x => x.fact === 'weeklyWithdrawalLimit')?.value, 
        monthlyWithdrawalLimit : item?.rules?.withdrawalControls?.all?.find(x => x.fact === 'monthlyWithdrawalLimit')?.value,
        withdrawalLockPeriodInDays: item?.rules?.withdrawalControls?.all?.find(x => x.fact === 'withdrawalLockPeriodInDays')?.value,
        commissionPercentage: item?.rules?.withdrawalControls?.all?.find(x => x.fact === 'commissionPercentage')?.value,
        minWalletBalanceForWithdrawal: item?.rules?.withdrawalControls?.all?.find(x => x.fact === 'minWalletBalanceForWithdrawal')?.value,
        
        maxWalletBalance: item?.rules?.balanceControls?.all?.find(x => x.fact === 'maxWalletBalance')?.value,
        
        taxRate: item?.rules?.tax?.taxRate, 
        applicabilityThreshold: item?.rules?.tax?.applicabilityThreshold,
        taxType: item?.rules?.tax?.taxType, 
        taxInclusionMethod: item?.rules?.tax?.taxInclusionMethod, 
        taxFreeAllowancePerTransaction: item?.rules?.tax?.taxFreeAllowancePerTransaction, 

        kycApplicabilityThresholdForTransactions : item?.rules?.compliance?.all?.find(x => x.fact === 'kycApplicabilityThresholdForTransactions')?.value,
        kycApplicabilityThresholdForTotalDeposits: item?.rules?.compliance?.all?.find(x => x.fact === 'kycApplicabilityThresholdForTotalDeposits')?.value,
        kycApplicabilityThresholdForTotalWinnings: item?.rules?.compliance?.all?.find(x => x.fact === 'kycApplicabilityThresholdForTotalWinnings')?.value,
    }
  )

    const [taxTypeOptions, setTaxTypeOptions] = useState([])
  
    const configureMetaData = () => {
        if(allMetaData && allMetaData?.tax){
            let meta = allMetaData.tax
            setTaxTypeOptions(Object.values(meta?.taxTypes))
        }
    }
  
    let allMetaData = useSelector((state) => {return state.common.metaData})

    useEffect(()=>{ 
        configureMetaData()
    }, [allMetaData])

    // Wallet Details
    const onChangeWallet = (e, type) => {
        const {name, value} = e.target
        setWalletDetails(walletDetails => ({...walletDetails, [name]: (type === 'integer' ? ((value !== '' && value !== null) ? parseInt(value) : '') : value)}))
    }

    // Wallet Deposit Controls
    const onChangeDepositConfig = (e, key, id, fact) => {
        const {name, value} = e.target
        setWalletDetails(walletDetails => ({...walletDetails, [name]: e?.target?.checked}))
        setValue(wallets => wallets && wallets.map(x => x?.entitySubType === id ? 
            {
                ...x,
                rules: {
                    ...x?.rules,
                    [key] : {
                        all : e?.target?.checked ?
                            [
                                ...(x?.rules?.[key]?.all || []),
                                {fact: fact, operator: 'lessThanInclusive', value: ''}
                            ]
                        :
                            (x?.rules?.[key]?.all || []).filter(item => item.fact !== fact)
                    }
                    // [key] : (e?.target?.checked ?
                    //     {all : [...(x?.rules?.all || []), {fact: fact, operator: 'lessThanInclusive', value: ''}]}
                    // :
                    //     {all : (x?.rules?.all || []).filter((item) => item.fact !== fact)}
                    // )
                }
            }
        :
            x
        ))
    }

    // Wallet Transaction
    const toggleWithdrawal = (e, id) => {
        setWalletDetails(walletDetails => ({...walletDetails, enableWithdrawal: e?.target?.checked, maxWithdrawalPerTransaction: '', minWithdrawalPerTransaction: '', dailyWithdrawalLimit: '', weeklyWithdrawalLimit: '', monthlyWithdrawalLimit : '', withdrawalLockPeriodInDays: ''})) 
        setValue(wallets => wallets && wallets.map(x => x?.entitySubType === id ? 
            {
                ...x,
                rules: {
                    ...x?.rules,
                    enableWithdrawal: e?.target?.checked,
                    withdrawalControls: (e?.target?.checked ?
                        {
                            all: [
                                {fact: 'maxWithdrawalPerTransaction', operator: 'lessThanInclusive', value: ''},
                                {fact: 'minWithdrawalPerTransaction', operator: 'greaterThanInclusive', value: ''},
                                // {fact: 'dailyWithdrawalLimit', operator: 'lessThanInclusive', value: ''},
                                // {fact: 'weeklyWithdrawalLimit', operator: 'lessThanInclusive', value: ''},
                                // {fact: 'monthlyWithdrawalLimit', operator: 'lessThanInclusive', value: ''},
                                // {fact: 'withdrawalLockPeriodInDays', operator: 'greaterThanInclusive', value: ''},
                            ]
                        }
                    : null)
                }
            } 
            : x
        ))
    }

    // Wallet Trasaction Control
    const onChangeWithdrawalConfig = (e, key, id, fact) => {
        const {name, value} = e.target
        setWalletDetails(walletDetails => ({...walletDetails, [name]: e?.target?.checked}))
        setValue(wallets => wallets && wallets.map(x => x?.entitySubType === id ? 
            {
                ...x,
                rules: {
                    ...x?.rules,
                    [key] : {
                        all : e?.target?.checked ?
                            [
                                ...(x?.rules?.[key]?.all || []),
                                (fact === 'withdrawalLockPeriodInDays' || fact === 'minWalletBalanceForWithdrawal') ? {fact: fact, operator: 'greaterThanInclusive', value: ''} : (fact === 'commissionPercentage') ? {fact: fact, operator: 'equal', value: ''} : {fact: fact, operator: 'lessThanInclusive', value: ''}
                            ]
                        :
                            (x?.rules?.[key]?.all || []).filter(item => item.fact !== fact)
                    }
                    // [key] : (e?.target?.checked ?
                    //     {all : [...(x?.rules?.all || []), (fact === 'isWithdrawalLockPeriod' || fact === 'minWalletBalanceForWithdrawal') ? {fact: fact, operator: 'greaterThanInclusive', value: ''} : {fact: name, operator: 'lessThanInclusive', value: ''}]}
                    // :
                    //     {all : (x?.rules?.all || []).filter((item) => item.fact !== fact)}
                    // )
                }
            }
        :
            x
        ))
    }

    // Balance Control Config
    const onChangeBalanceConfig = (e, key, id, fact) => {
        const {name, value} = e.target
        setWalletDetails(walletDetails => ({...walletDetails, [name]: e?.target?.checked}))
        setValue(wallets => wallets && wallets.map(x => x?.entitySubType === id ? 
            {
                ...x,
                rules: {
                    ...x?.rules,
                    [key] : {
                        all : e?.target?.checked ?
                            [
                                ...(x?.rules?.[key]?.all || []),
                                {fact: fact, operator: 'lessThanInclusive', value: ''}
                            ]
                        :
                            (x?.rules?.[key]?.all || []).filter(item => item.fact !== fact)
                    }
                    // [key] : (e?.target?.checked ?
                    //     {all : [...(x?.rules?.all || []), {fact: fact, operator: 'lessThanInclusive', value: ''}]}
                    // :
                    //     {all : (x?.rules?.all || []).filter((item) => item.fact !== fact)}
                    // )
                }
            }
        :
            x
        ))
    }

    // Wallet Tax
    const toggleTax = (e, id) => {
        setWalletDetails(walletDetails => ({...walletDetails, isTaxApplicable: e?.target?.checked, taxType: '', taxRate: '', taxInclusionMethod: '', applicabilityThreshold: ''})) 
        setValue(wallets => wallets && wallets.map(x => x?.entitySubType === id ? 
            {
                ...x,
                rules: {
                    ...x?.rules, 
                    isTaxApplicable: e?.target?.checked,
                    tax: (e?.target?.checked ?
                        {
                            taxType: null,
                            taxInclusionMethod: null,
                            taxRate: null,
                            applicabilityThreshold: null,
                        }
                    : null)
                }
            } 
            : x
        ))
    }

    // Wallet KYC
    const toggleKYC = (e, id) => {
        setWalletDetails(walletDetails => ({...walletDetails, isKycRequired: e?.target?.checked, kycApplicabilityThresholdForTransactions : ''})) 
        setValue(wallets => wallets && wallets.map(x => x?.entitySubType === id ? 
            {
                ...x,
                rules: {
                    ...x?.rules, 
                    isKycRequired: e?.target?.checked,
                    compliance: (e?.target?.checked ?
                        {
                            all: [
                                {fact: 'kycApplicabilityThresholdForTransactions', operator: 'lessThanInclusive', value: ''},
                            ]
                        }
                    : null)
                }
            } 
            : x
        ))
    }

    // Tax Configuration
    const onChangeTaxConfig = (e, key, id) => {
        const {name, value} = e.target
        setWalletDetails(walletDetails => ({...walletDetails, [name]: e?.target?.checked}))
        setValue(wallets => wallets && wallets.map(x => x?.entitySubType === id ? 
            {
                ...x,
                rules: {
                   ...x.rules,
                   tax: (e?.target?.checked ? {...x?.rules?.tax, taxFreeAllowancePerTransaction: null} : (() => {const { taxFreeAllowancePerTransaction, ...rest } = x?.rules?.tax || {}; return rest;})())
                }
            }
        :
            x
        ))
    }

    // KYC Configuration
    const onChangeComplianceConfig = (e, key, id) => {
        const {name, value} = e.target
        let kycApplicabilityThreshold = (id === 'deposit' ? 'kycApplicabilityThresholdForTotalDeposits' : 'kycApplicabilityThresholdForTotalWinnings')
        setWalletDetails(walletDetails => ({...walletDetails, [name]: e?.target?.checked}))
        setValue(wallets => wallets && wallets.map(x => x?.entitySubType === id ? 
            {
                ...x,
                rules: {
                   ...x.rules,
                   compliance: (e?.target?.checked ? {...x?.rules?.compliance, kycApplicabilityThreshold: null } : (() => {const { kycApplicabilityThreshold, ...rest } = x?.rules?.tax || {}; return rest;})())
                }
            }
        :
            x
        ))
    }

    const validation = (obj) => {

        const generalKeys = ["walletId", "walletName", "walletType"]
        const balanceKeys = []
        const depositKeys = ["maxDepositPerTransaction", "minDepositPerTransaction"]
        const withdrawalKeys = ["maxWithdrawalPerTransaction", "minWithdrawalPerTransaction"]
        const taxKeys = ['taxRate', 'applicabilityThreshold']
        const kycKeys = walletDetails?.entitySubType === 'deposit' ? ['kycApplicabilityThresholdForTransactions'] : ['kycApplicabilityThresholdForTransactions']

        const isGeneralValid = generalKeys.every((key) => obj[key] !== null && obj[key] !== "" && obj[key] !== undefined)
        if (!isGeneralValid){
            toast.error(<Toast type='Error' messages='All the fields in wallet info are required.' />, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            return false;
        }

        if(obj?.entitySubType === 'deposit'){
            if(obj.isDailyDeposit) depositKeys.push("dailyDepositLimit")
            if(obj.isWeeklyDeposit) depositKeys.push("weeklyDepositLimit")
            if(obj.isMonthlyDeposit) depositKeys.push("monthlyDepositLimit")
            const isDepositValid = depositKeys.every((key) => obj[key] !== null && obj[key] !== "" && obj[key] !== undefined)
            if (!isDepositValid){
                toast.error(<Toast type='Error' messages='All required or selected fields in deposit control must have valid values' />, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                return false;
            }
        }

        if (obj.enableWithdrawal) {
            if(obj.isDailyWithdrawal) withdrawalKeys.push("dailyWithdrawalLimit")
            if(obj.isWeeklyWithdrawal) withdrawalKeys.push("weeklyWithdrawalLimit")
            if(obj.isMonthlyWithdrawal) withdrawalKeys.push("monthlyWithdrawalLimit")
            if(obj.isWithdrawalLockPeriod) withdrawalKeys.push("withdrawalLockPeriodInDays")
            if(obj.isMinWalletBalance) withdrawalKeys.push("minWalletBalanceForWithdrawal")
            if(obj.isCommission) withdrawalKeys.push('commissionPercentage')
            const isWithdrawalValid = withdrawalKeys.every((key) => obj[key] !== null && obj[key] !== "" && obj[key] !== undefined)
            if(!isWithdrawalValid){
                toast.error(<Toast type='Error' messages='All required or selected fields in withdrawal control must have valid values' />, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                return false;
            } 
        }

        if(obj.isMaxWalletBalance) balanceKeys.push('maxWalletBalance')
        const isBalanceValid = balanceKeys.every((key) => obj[key] !== null && obj[key] !== "" && obj[key] !== undefined)
        if (!isBalanceValid){
            toast.error(<Toast type='Error' messages='All the fields in balance control are required.' />, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            return false;
        }

        if (obj.isKycRequired) {
            if(obj?.entitySubType === 'deposit' && obj?.isKYCThreshold) kycKeys.push('kycApplicabilityThresholdForTotalDeposits')
            if(obj?.entitySubType === 'winning' && obj?.isKYCThreshold) kycKeys.push('kycApplicabilityThresholdForTotalWinnings')
            const isKYCValid = kycKeys.every((key) => obj[key] !== null && obj[key] !== "" && obj[key] !== undefined) 
            if (!isKYCValid){
                toast.error(<Toast type='Error' messages='All the fields in tax and compliance are required.' />, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                return false;
            } 
        }
        
        // Check Tax-related keys if isTaxApplicable is true
        if (obj.isTaxApplicable) {
            if(obj.isTaxFree) taxKeys.push('taxFreeAllowancePerTransaction')
            const isTaxValid = taxKeys.every((key) => obj[key] !== null && obj[key] !== "" && obj[key] !== undefined)
            const isTaxType = ((taxType !== null && taxType !== '' && taxType !== undefined))
            if (!isTaxValid || !isTaxType){
                toast.error(<Toast type='Error' messages='All the fields in tax and compliance are required.' />, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                return false;
            }
        }
        
        return true
    }

    const save = () => {
        if(validation(walletDetails)){
            setValue(wallets => wallets.map(x => x?.entitySubType === walletDetails?.entitySubType ?
                {
                    ...x,
                    rules: {
                        enableWithdrawal : walletDetails?.enableWithdrawal,
                        isKycRequired : walletDetails?.isKycRequired,
                        isTaxApplicable : walletDetails?.isTaxApplicable,
                        info: {walletName: walletDetails?.walletName, walletId: walletDetails?.walletId, walletType: walletDetails?.walletType},
                        ...(walletDetails?.walletType === 'deposit' ?
                            {
                                depositControls: {
                                    all: [
                                        {fact: 'maxDepositPerTransaction', operator: 'lessThanInclusive', value: walletDetails?.maxDepositPerTransaction},
                                        {fact: 'minDepositPerTransaction', operator: 'greaterThanInclusive', value: walletDetails?.minDepositPerTransaction},
                                        ...(walletDetails?.isDailyDeposit ? [{fact: 'dailyDepositLimit', operator: 'lessThanInclusive', value: walletDetails?.dailyDepositLimit}] : []),
                                        ...(walletDetails?.isWeeklyDeposit ? [{fact: 'weeklyDepositLimit', operator: 'lessThanInclusive', value: walletDetails?.weeklyDepositLimit}] : []),
                                        ...(walletDetails?.isMonthlyDeposit ? [{fact: 'monthlyDepositLimit', operator: 'lessThanInclusive', value: walletDetails?.monthlyDepositLimit}] : []),
                                    ]
                                }
                            }
                        : {depositControls: null}
                        ), 
                        ...(walletDetails?.isMaxWalletBalance ?
                            {
                                balanceControls: {
                                    all: [
                                        {fact: 'maxWalletBalance', operator: 'lessThanInclusive', value: walletDetails?.maxWalletBalance}
                                    ]
                                }
                            }
                        :
                            {balanceControls : null}
                        )
                        ,
                        ...(walletDetails?.enableWithdrawal ?
                            {
                                withdrawalControls: {
                                    all: [
                                        {fact: 'maxWithdrawalPerTransaction', operator: 'lessThanInclusive', value: walletDetails?.maxWithdrawalPerTransaction},
                                        {fact: 'minWithdrawalPerTransaction', operator: 'greaterThanInclusive', value: walletDetails?.minWithdrawalPerTransaction},
                                        ...(walletDetails?.isDailyWithdrawal ? [{fact: 'dailyWithdrawalLimit', operator: 'lessThanInclusive', value: walletDetails?.dailyWithdrawalLimit}] : []),
                                        ...(walletDetails?.isWeeklyWithdrawal ? [{fact: 'weeklyWithdrawalLimit', operator: 'lessThanInclusive', value: walletDetails?.weeklyWithdrawalLimit}] : []),
                                        ...(walletDetails?.isMonthlyWithdrawal ? [{fact: 'monthlyWithdrawalLimit', operator: 'lessThanInclusive', value: walletDetails?.monthlyWithdrawalLimit}] : []),
                                        ...(walletDetails?.isWithdrawalLockPeriod ? [{fact: 'withdrawalLockPeriodInDays', operator: 'greaterThanInclusive', value: walletDetails?.withdrawalLockPeriodInDays}] : []),
                                        ...(walletDetails?.isMinWalletBalance ?  [{fact: 'minWalletBalanceForWithdrawal', operator: 'greaterThanInclusive', value: walletDetails?.minWalletBalanceForWithdrawal}] : []),
                                        ...(walletDetails?.isCommission ? [{fact: 'commissionPercentage', operator: 'equal', value: walletDetails?.commissionPercentage}] : [])
                                    ]
                                }
                            }
                        : {withdrawalControls: null}),
                        ...(walletDetails?.isTaxApplicable ?
                            {
                                tax: {
                                    taxRate: walletDetails?.taxRate,
                                    ...(walletDetails?.walletType === 'deposit' ? {taxType: taxType} : {}),
                                    ...(walletDetails?.walletType === 'deposit' ? {taxInclusionMethod: (taxInclusionMethod ? 'Inclusive' : 'Exclusive')} : {}),
                                    ...(walletDetails?.walletType === 'winning' ? {taxDeductionApplicationBasis: (taxBasis ? 'On Net Winnings' : 'On Every Transaction')} : {}),
                                    applicabilityThreshold: walletDetails?.applicabilityThreshold,
                                    ...(walletDetails?.isTaxFree ? {taxFreeAllowancePerTransaction: walletDetails?.taxFreeAllowancePerTransaction} : {})
                                }
                            }
                        : 
                            {tax: null}
                        ),
                        ...(walletDetails?.isKycRequired ? 
                            {
                                compliance : {
                                    all: [
                                        {fact: 'kycApplicabilityThresholdForTransactions', operator: 'lessThanInclusive', value: walletDetails?.kycApplicabilityThresholdForTransactions},
                                        ...(walletDetails?.walletType === 'deposit' && walletDetails?.isKYCThreshold ? 
                                            [{fact: 'kycApplicabilityThresholdForTotalDeposits', operator: 'lessThanInclusive', value: walletDetails?.kycApplicabilityThresholdForTotalDeposits}]
                                        : 
                                            []
                                        ),
                                        ...(walletDetails?.walletType === 'winning' && walletDetails?.isKYCThreshold ? 
                                            [{fact: 'kycApplicabilityThresholdForTotalWinnings', operator: 'lessThanInclusive', value: walletDetails?.kycApplicabilityThresholdForTotalWinnings}]
                                        : 
                                            []
                                        )
                                    ]
                                }
                            }
                        : 
                            {compliance : null}
                    ),
                    }
                }
                :
                x
            ))
            onClose()
        }
    }

    return (
        <>
        <div className={styles.container}>
            <div className={styles.deposit}>
                <div className={styles.modalHeading}>
                    <div className={styles.block}></div><span>Edit {walletDetails?.walletType?.charAt(0).toUpperCase() + walletDetails?.walletType?.slice(1)} Wallet</span>
                </div>
                <div className={styles.modalBody}>
                    <div className={styles.toggle}>
                        {toggle && toggle.map((x, index) => (
                            <button className={cn(styles.link, {[styles.active]: activeIndex === x?.id}, {[styles.grid]: item?.entitySubType === 'winning'}, {[styles.disabled] : (x?.name === 'Withdrawal Controls' && !walletDetails?.enableWithdrawal) ? true : false})} onClick={() => setActiveIndex((x.id))} key={index}>
                                <p>{x?.name}</p>
                            </button>
                        ))}
                    </div>
                    {activeIndex === 1 &&
                        <div className={styles.wrapper}>
                            <div className={styles.group}>
                                <TextInput name='walletName' className={styles.field} label='Wallet Name*' placeholder='Enter Wallet Name' tooltip={TooltipTitle.RMGWalletName} value={walletDetails?.walletName} onChange={(e) => {onChangeWallet(e, 'text')}} />
                                <TextInput name='walletId' className={styles.field} label='Wallet Id*' placeholder='Enter Wallet Id' tooltip={TooltipTitle.RMGWalletID} value={walletDetails?.walletId} onChange={(e) => {onChangeWallet(e, 'text')}} />
                            </div>
                            <div className={styles.group}>
                                {item?.entitySubType === 'deposit' &&
                                    <div className={styles.field}>
                                        <div className={styles.info}>
                                            Enable Withdrawals
                                        </div>
                                        <div className={styles.switch}>
                                            <Switch size='small' disabled={walletDetails?.entitySubType === 'winning'} value={walletDetails?.enableWithdrawal} onChange={(e) => toggleWithdrawal(e, walletDetails?.entitySubType)}/>
                                        </div>
                                    </div>
                                }
                                <TextInput disabled className={styles.field} label='Wallet Type*' placeholder='Enter Wallet Type' tooltip={TooltipTitle.RMGWalletType} value={walletDetails?.walletType !== 'winning' ? walletDetails?.walletType?.charAt(0).toUpperCase() + walletDetails?.walletType?.slice(1) : 'Winnings'} />
                            </div>
                        </div>
                    }
                    {activeIndex === 2 &&
                        <div className={styles.wrapper}>
                            <div className={styles.group}>
                                <TextInput className={styles.field} label='Max Deposit Per Transaction*' placeholder='Enter Max Deposit Per Transaction' tooltip={TooltipTitle.RMGMaxDeposit} name='maxDepositPerTransaction' value={walletDetails?.maxDepositPerTransaction} onChange={(e) => {onChangeWallet(e, 'integer')}}/>
                                <TextInput className={styles.field} label='Min Deposit Per Transaction*' placeholder='Enter Min Deposit Per Transaction' tooltip={TooltipTitle.RMGMinDeposit} name='minDepositPerTransaction' value={walletDetails?.minDepositPerTransaction} onChange={(e) => {onChangeWallet(e, 'integer')}}/>
                            </div>
                            <div className={styles.group}>
                                <div className={styles.field}>
                                    <div className={styles.info}>
                                        Daily Deposit Limit
                                    </div>
                                    <div className={styles.switch}>
                                        <Switch size='small' value={walletDetails?.isDailyDeposit} name='isDailyDeposit' onChange={(e) => {onChangeDepositConfig(e, 'depositControls', walletDetails?.entitySubType, 'dailyDepositLimit')}}/>
                                    </div>
                                </div>
                                {walletDetails?.isDailyDeposit && <TextInput className={styles.field} label='Daily Deposit Limit' placeholder='Enter Daily Deposit Limit' tooltip={TooltipTitle.RMGDailyDepositLimit} name='dailyDepositLimit' value={walletDetails?.dailyDepositLimit} onChange={(e) => {onChangeWallet(e, 'integer')}}/>}  
                            </div>

                            <div className={styles.group}>
                                <div className={styles.field}>
                                    <div className={styles.info}>
                                        Weekly Deposit Limit
                                    </div>
                                    <div className={styles.switch}>
                                        <Switch size='small' value={walletDetails?.isWeeklyDeposit} name='isWeeklyDeposit' onChange={(e) => {onChangeDepositConfig(e, 'depositControls', walletDetails?.entitySubType, 'weeklyDepositLimit')}}/>
                                    </div>
                                </div>
                                {walletDetails?.isWeeklyDeposit && <TextInput className={styles.field} label='Weekly Deposit Limit' placeholder='Enter Weekly Deposit Limit' tooltip={TooltipTitle.RMGWeeklyDepositLimit} name='weeklyDepositLimit' value={walletDetails?.weeklyDepositLimit} onChange={(e) => {onChangeWallet(e, 'integer')}}/>}
                            </div>

                            <div className={styles.group}>
                                <div className={styles.field}>
                                    <div className={styles.info}>
                                        Monthly Deposit Limit
                                    </div>
                                    <div className={styles.switch}>
                                        <Switch size='small' value={walletDetails?.isMonthlyDeposit} name='isMonthlyDeposit' onChange={(e) => {onChangeDepositConfig(e, 'depositControls', walletDetails?.entitySubType, 'monthlyDepositLimit')}}/>
                                    </div>
                                </div>
                                {walletDetails?.isMonthlyDeposit && <TextInput className={styles.field} label='Monthly Deposit Limit' placeholder='Enter Monthly Deposit Limit' tooltip={TooltipTitle.RMGMonthlyDepositLimit} name='monthlyDepositLimit' value={walletDetails?.monthlyDepositLimit} onChange={(e) => {onChangeWallet(e, 'integer')}}/>}
                            </div>
                        </div>
                    }
                    {activeIndex === 3 &&
                        <div className={styles.wrapper}>
                            <div className={styles.group}>
                                <TextInput className={styles.field} label='Max Withdrawal Per Transaction*' placeholder='Enter Max Withdrawal Per Transaction' tooltip={TooltipTitle.RMGMaxWithdrawl} name='maxWithdrawalPerTransaction' value={walletDetails?.maxWithdrawalPerTransaction} onChange={(e) => {onChangeWallet(e, 'integer')}}/>
                                <TextInput className={styles.field} label='Min Withdrawal Per Transaction*' placeholder='Enter Min Withdrawal Per Transaction' tooltip={TooltipTitle.RMGMinWithdrawl} name='minWithdrawalPerTransaction' value={walletDetails?.minWithdrawalPerTransaction} onChange={(e) => {onChangeWallet(e, 'integer')}}/>
                            </div>

                            <div className={styles.group}>
                                <div className={styles.field}>
                                    <div className={styles.info}>
                                        Daily Withdrawal Limit
                                    </div>
                                    <div className={styles.switch}>
                                        <Switch size='small' value={walletDetails?.isDailyWithdrawal} name='isDailyWithdrawal' onChange={(e) => onChangeWithdrawalConfig(e, 'withdrawalControls', walletDetails?.entitySubType, 'dailyWithdrawalLimit')}/>
                                    </div>
                                </div>
                                {walletDetails?.isDailyWithdrawal && <TextInput className={styles.field} label='Daily Withdrawal Limit' placeholder='Enter Daily Withdrawal Limit' tooltip={TooltipTitle.RMGDailyWithdrawalLimit} name='dailyWithdrawalLimit' value={walletDetails?.dailyWithdrawalLimit} onChange={(e) => {onChangeWallet(e, 'integer')}}/>}
                            </div>

                            <div className={styles.group}>
                                <div className={styles.field}>
                                    <div className={styles.info}>
                                        Weekly Withdrawal Limit
                                    </div>
                                    <div className={styles.switch}>
                                        <Switch size='small' value={walletDetails?.isWeeklyWithdrawal} name='isWeeklyWithdrawal' onChange={(e) => onChangeWithdrawalConfig(e, 'withdrawalControls', walletDetails?.entitySubType, 'weeklyWithdrawalLimit')}/>
                                    </div>
                                </div>
                                {walletDetails?.isWeeklyWithdrawal && <TextInput className={styles.field} label='Weekly Withdrawal Limit' placeholder='Enter Weekly Withdrawal Limit 'tooltip={TooltipTitle.RMGWeeklyWithdrawalLimit} name='weeklyWithdrawalLimit' value={walletDetails?.weeklyWithdrawalLimit} onChange={(e) => {onChangeWallet(e, 'integer')}}/>}
                            </div>

                            <div className={styles.group}>
                                <div className={styles.field}>
                                    <div className={styles.info}>
                                        Monthly Withdrawal Limit
                                    </div>
                                    <div className={styles.switch}>
                                        <Switch size='small' value={walletDetails?.isMonthlyWithdrawal} name='isMonthlyWithdrawal' onChange={(e) => onChangeWithdrawalConfig(e, 'withdrawalControls', walletDetails?.entitySubType, 'monthlyWithdrawalLimit')}/>
                                    </div>
                                </div>
                                {walletDetails?.isMonthlyWithdrawal && <TextInput className={styles.field} label='Monthly Withdrawal Limit' placeholder='Monthly Withdrawal Limit' tooltip={TooltipTitle.RMGMonthlyWithdrawalLimit} name='monthlyWithdrawalLimit' value={walletDetails?.monthlyWithdrawalLimit} onChange={(e) => {onChangeWallet(e, 'integer')}}/>}
                            </div>

                            <div className={styles.group}>
                                <div className={styles.field}>
                                    <div className={styles.info}>
                                        Withdrawal Lock Period
                                    </div>
                                    <div className={styles.switch}>
                                        <Switch size='small' value={walletDetails?.isWithdrawalLockPeriod} name='isWithdrawalLockPeriod' onChange={(e) => onChangeWithdrawalConfig(e, 'withdrawalControls', walletDetails?.entitySubType, 'withdrawalLockPeriodInDays')}/>
                                    </div>
                                </div>
                                {walletDetails?.isWithdrawalLockPeriod && <TextInput className={styles.field} label='Withdrawal Lock Period (days)' placeholder='Withdrawal Lock Period (days)' tooltip={TooltipTitle.RMGWithdrawalLockPeriod} name='withdrawalLockPeriodInDays' value={walletDetails?.withdrawalLockPeriodInDays} onChange={(e) => {onChangeWallet(e, 'integer')}}/>}
                            </div>
                            <div className={styles.group}>
                                <div className={styles.field}>
                                    <div className={styles.info}>
                                        Apply Commission
                                    </div>
                                    <div className={styles.switch}>
                                        <Switch size='small' value={walletDetails?.isCommission} name='isCommission' onChange={(e) => onChangeWithdrawalConfig(e, 'withdrawalControls', walletDetails?.entitySubType, 'commissionPercentage')} />
                                    </div>
                                </div>
                                {walletDetails?.isCommission && <TextInput className={styles.field} label='Commission Percentage' placeholder='Enter Commission Percentage' name='commissionPercentage' value={walletDetails?.commissionPercentage} onChange={(e) => {onChangeWallet(e, 'integer')}}/>}
                            </div>

                            <div className={styles.group}>
                                <div className={styles.field}>
                                    <div className={styles.info}>
                                        Min Wallet Balance for Withdrawals
                                    </div>
                                    <div className={styles.switch}>
                                        <Switch size='small' value={walletDetails?.isMinWalletBalance} name='isMinWalletBalance' onChange={(e) => onChangeWithdrawalConfig(e, 'withdrawalControls', walletDetails?.entitySubType, 'minWalletBalanceForWithdrawal')}/>
                                    </div>
                                </div>
                                {walletDetails?.isMinWalletBalance && <TextInput className={styles.field} label='Min Wallet Balance for Withdrawals' placeholder='Enter Min Wallet Balance for Withdrawals' tooltip={TooltipTitle.RMGMinBalance} name='minWalletBalanceForWithdrawal' value={walletDetails?.minWalletBalanceForWithdrawal} onChange={(e) => {onChangeWallet(e, 'integer')}}/>}
                            </div>
                        </div>
                    }
                    {activeIndex === 4 &&
                        <div className={styles.wrapper}>
                            <div className={styles.group}>
                                <div className={styles.field}>
                                    <div className={styles.info}>
                                        Max Wallet Balance
                                    </div>
                                    <div className={styles.switch}>
                                        <Switch size='small' value={walletDetails?.isMaxWalletBalance} name='isMaxWalletBalance' onChange={(e) => onChangeBalanceConfig(e, 'balanceControls', walletDetails?.entitySubType, 'maxWalletBalance')}/>
                                    </div>
                                </div>
                                {walletDetails?.isMaxWalletBalance && <TextInput className={styles.field} label='Max Wallet Balance' placeholder='Enter Max Wallet Balance' tooltip={TooltipTitle.RMGMaxBalance} name='maxWalletBalance' value={walletDetails?.maxWalletBalance} onChange={(e) => {onChangeWallet(e, 'integer')}}/>}
                            </div>
                        </div>
                    }
                    {activeIndex === 5 &&
                        <div className={styles.wrapper}>
                            <div className={styles.group}>
                                <div className={styles.field}>
                                    <div className={styles.info}>
                                        {walletDetails?.entitySubType ==='deposit' ? 'Enable Taxation' : 'Apply Tax Deduction'}
                                    </div>
                                    <div className={styles.switch}>
                                        <Switch size='small' value={walletDetails?.isTaxApplicable} onChange={(e) => toggleTax(e, walletDetails?.entitySubType)}/>
                                    </div>
                                </div>
                                <div className={styles.field}>
                                    <div className={styles.info}>
                                        KYC Required
                                    </div>
                                    <div className={styles.switch}>
                                        <Switch size='small' value={walletDetails?.isKycRequired} onChange={(e) => toggleKYC(e, walletDetails?.entitySubType)}/>
                                    </div>
                                </div>
                                
                            </div>
                            {walletDetails.isKycRequired &&
                                <div className={styles.group}>
                                    <TextInput className={styles.field} label='KYC Applicability Threshold for Transactions' placeholder='KYC Applicability Threshold for Transactions' tooltip={TooltipTitle.RMGKYCThresholdForTransaction} name='kycApplicabilityThresholdForTransactions' value={walletDetails?.kycApplicabilityThresholdForTransactions} onChange={(e) => {onChangeWallet(e, 'integer')}}/>
                                    <div className={styles.field}>
                                        <div className={styles.info}>
                                            KYC Applicability Threshold For {walletDetails.walletType === 'deposit' ? 'Total Deposits' : 'Total Winnings'}
                                        </div>
                                        <div className={styles.switch}>
                                            <Switch size='small' value={walletDetails?.isKYCThreshold} name='isKYCThreshold' onChange={(e) => onChangeComplianceConfig(e, 'compliance', walletDetails?.entitySubType)}/>
                                        </div>
                                    </div>
                                    {/* {walletDetails.walletType === 'deposit' && <TextInput className={styles.field} label='KYC Applicability Threshold for Total Deposits' placeholder='Enter KYC Applicability Threshold for Total Deposits' tooltip={TooltipTitle.RMGKYCThresholdForDeposit} name='kycApplicabilityThresholdForTotalDeposits' value={walletDetails?.kycApplicabilityThresholdForTotalDeposits} onChange={(e) => {onChangeWallet(e, 'integer')}}/>}
                                    {walletDetails.walletType === 'winning' && <TextInput className={styles.field} label='KYC Applicability Threshold for Total Winnings' placeholder='Enter KYC Applicability Threshold for Total Winnings' tooltip={TooltipTitle.RMGKYCThresholdForDeposit} name='kycApplicabilityThresholdForTotalWinnings' value={walletDetails?.kycApplicabilityThresholdForTotalWinnings} onChange={(e) => {onChangeWallet(e, 'integer')}}/>} */}
                                </div>
                            }

                            {walletDetails.isKycRequired &&
                                <div className={styles.group}>
                                    {walletDetails.walletType === 'deposit' && walletDetails.isKYCThreshold && <TextInput className={styles.field} label='KYC Applicability Threshold for Total Deposits' placeholder='Enter KYC Applicability Threshold for Total Deposits' tooltip={TooltipTitle.RMGKYCThresholdForDeposit} name='kycApplicabilityThresholdForTotalDeposits' value={walletDetails?.kycApplicabilityThresholdForTotalDeposits} onChange={(e) => {onChangeWallet(e, 'integer')}}/>}
                                    {walletDetails.walletType === 'winning' && walletDetails.isKYCThreshold && <TextInput className={styles.field} label='KYC Applicability Threshold for Total Winnings' placeholder='Enter KYC Applicability Threshold for Total Winnings' tooltip={TooltipTitle.RMGKYCThresholdForDeposit} name='kycApplicabilityThresholdForTotalWinnings' value={walletDetails?.kycApplicabilityThresholdForTotalWinnings} onChange={(e) => {onChangeWallet(e, 'integer')}}/>}
                                </div>
                            }

                            {walletDetails.isTaxApplicable &&
                                <div className={styles.group}>
                                    {walletDetails?.entitySubType !== 'winning' &&
                                        <div className={styles.field}>
                                            <Dropdown label='Tax Type' placeholder='Select Tax Type' options={taxTypeOptions} value={taxType} setValue={setTaxType}/>
                                        </div>
                                    }
                                    <TextInput className={styles.field} label='Tax Rate (%)' placeholder='Enter Tax Rate (%)' tooltip={TooltipTitle.RMGTaxRate} name='taxRate' value={walletDetails?.taxRate} onChange={(e) => {onChangeWallet(e, 'integer')}}/>
                                </div>
                            }

                            {walletDetails.isTaxApplicable &&
                                <div className={styles.group}>
                                    {walletDetails?.entitySubType ==='deposit' &&
                                        <>
                                            <div className={styles.fieldRadio}>
                                                <div className={styles.radioLabel}>
                                                    <span>Tax Inclusion Method<span><Tooltip className={styles.tooltip} title={TooltipTitle.RMGTaxInclusionMethod} icon="info"/></span></span>
                                                </div>
                                                <div className={styles.variants}>
                                                    <Radio className={styles.radio} name="taxInclusive" content="Exclusive" value={!taxInclusionMethod} onChange={() => setTaxInclusionMethod(false)}/>
                                                    <Radio className={styles.radio} name="taxInclusive" content="Inclusive" value={taxInclusionMethod} onChange={() => setTaxInclusionMethod(true)}/>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {walletDetails?.entitySubType ==='winning' &&
                                        <>
                                            <div className={styles.fieldRadio}>
                                                <div className={styles.radioLabel}>
                                                    <span>Tax Deduction Application Basis<span><Tooltip className={styles.tooltip} title={TooltipTitle.RMGTaxDeductionBasic} icon="info"/></span></span>
                                                </div>
                                                <div className={styles.variants}>
                                                    <Radio className={styles.radio} name="taxDeductionBasis" content="On Every Transaction" value={!taxBasis} onChange={() => setTaxBasis(false)}/>
                                                    <Radio className={styles.radio} name="taxDeductionBasis" content="On Net Winnings" value={taxBasis} onChange={() => setTaxBasis(true)} />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <TextInput className={styles.field} label='Tax Applicability Threshold' placeholder='Enter Tax Applicability Threshold' tooltip={TooltipTitle.RMGTaxThreshold} name='applicabilityThreshold' value={walletDetails?.applicabilityThreshold} onChange={(e) => {onChangeWallet(e, 'integer')}}/>
                                </div>
                            }
                            
                            {walletDetails.isTaxApplicable &&
                                <div className={styles.group}>
                                    <div className={styles.field}>
                                        <div className={styles.info}>
                                            Tax-Free Allowance Per Transaction
                                        </div>
                                        <div className={styles.switch}>
                                            <Switch size='small' value={walletDetails?.isTaxFree} name='isTaxFree' onChange={(e) => onChangeTaxConfig(e, 'tax', walletDetails?.entitySubType)}/>
                                        </div>
                                    </div>
                                    {walletDetails?.isTaxFree && <TextInput className={styles.field} label='Tax-Free Allowance Per Transaction' placeholder='Enter Tax-Free Allowance Per Transaction' tooltip={TooltipTitle.RMGTaxFreeAllowance} name='taxFreeAllowancePerTransaction' value={walletDetails?.taxFreeAllowancePerTransaction} onChange={(e) => {onChangeWallet(e, 'integer')}}/>}
                                </div>
                            }
                        </div>
                    }
                </div>
                <div className={styles.modalFooter}>
                    <button className={cn(styles.button, 'button-save-small')} onClick={save}>Save</button>
                    <button className={cn(styles.button, 'button-cancel-small')} onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
        </>
    )
}

export default AddOnModal