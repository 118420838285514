import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Modal from "../../../../../../components/Modals/ModalComponent";
import CreatePayout from '../../../../../../components/EngageComponents/PlayersComponent/Modals/CreatePayout'
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg'
import addIcon from '../../../../../../media/images/icons/light.png'

const Control = ({className, access, type, size, width, activeIndex, act}) => {

  const [visibleModal, setVisibleModal] = useState(false);

  const actions = [
    {
      icon: "add",
      action: () => setVisibleModal(true),
    }
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => (
          <>
            {act === 'Payout' ?
              <div className={styles.createButton} onClick={x.action}>
                <Link className={size === 'large' ? cn("button-small", styles.button) : cn("button-tiny", styles.button)}><img className={styles.add} src={addIcon} action={x.action} alt='gamehub_add_icon'width={16} height={16}/>{size === 'large' ? 'Create Payout' : 'Create'}</Link>
              </div>
            : 
              <div className={styles.controlDiv}>
                <span>Add Payout</span>{!access && <span onClick={x.action} className={styles.addIcon}><AddMetaIcon /></span>}
              </div>
            }
          </>
        ))}
      </div>
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <CreatePayout type={type} act={act} activeIndex={activeIndex} onClose={() => setVisibleModal(false)}/>
      </Modal>
    </>
  );
};

export default Control;
