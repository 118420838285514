import React, { useState, useEffect, useContext } from 'react'
import bcrypt from 'bcryptjs'
import styles from './signup.module.sass'
import TextInput from '../../../components/TextInput'
import Radio from '../../../components/Radio'
import {ReactComponent as GoogleButton} from '../../../media/images/Google_Button.svg'
import Icon from '../../../components/Icon'
import { useDispatch } from 'react-redux'
import { getEmailVerificationAction, signUpUserAction } from '../../../redux/action/authenticate/authenticationAction'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link, useLocation } from 'react-router-dom'
import Spectre from '../../../media/lottieAnimation/Specter_Animation.json'
import {ReactComponent as SpecterLogo} from '../../../media/images/Specter_Logo.svg'
import {ReactComponent as HalfCircle} from '../../../media/images/half_ellipse.svg'
import {ReactComponent as Cross} from '../../../media/images/hollow_crossed.svg'
import {ReactComponent as FullCircle} from '../../../media/images/full_circle.svg'
import {ReactComponent as SmallerFullCircle} from '../../../media/images/full_circle.svg'
import {ReactComponent as FullHollowCircle} from '../../../media/images/full_hollow_circle.svg'
import {ReactComponent as FilledCross} from '../../../media/images/filled_cross.svg'
import FilledCrossed from '../../../media/images/fill_cross.png'
import {ReactComponent as HollowCross} from '../../../media/images/x_fill.svg'
import Lottie from 'lottie-react'
import axios from 'axios'
import { BASE_URL } from '../../../resources/APIEndpoints'
import Toast from '../../../components/Toast'
import GlobalStateContext from '../../../components/Context/GlobalStates/GlobalStateContext'

const MemberInvite = ({setShowLoader,setLoaderType}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const globalStates = useContext(GlobalStateContext)

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [orgId, setOrgId] = useState(null)
  
    useEffect(() => {
      // Access and use the query parameters
      const param1 = queryParams.get('organisationId');
      const emailData = queryParams.get('email');
      setOrgId(param1)
      setEmail(emailData)
        
    }, [location.search]);

    const style = {
        width : 560,
        height : 608,
    }

    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [organizationType, setOrganizationType] = useState('')
    const [organizationName, setOrganizationName] = useState('')
    const [organization, setOrganization] = useState(true)

    const [fullNameErr, setFullNameErr] = useState(false)
    const [emailErr, setEmailErr] = useState(false)
    const [passwordErr, setPasswordErr] = useState(false)
    const [organizationErr, setOrganizationErr] = useState(false)

    const [rules, setRules] = useState({lengthRule: null, capitalRule: null, smallRule: null, numberRule: null, specialRule: null})

    const onFullName = (e) => {
        setFullName(e.target.value)
    }

    // const onEmail = (e) => {
    //     setEmail(e.target.value)
    // }

    const onPassword = (e) => {
        setPassword(e.target.value)
        setRules(validatePassword(e.target.value))
    }

    const onOrganization = (e) => {
        setOrganizationType(parseInt(e.target.value))
    }

    const onOrganizationName = (e) => {
        setOrganizationName(e.target.value)
    }

    const validate = () => {
        if(fullName == ''){
            toast.error('Full name is required')
        }
        if(email == ''){
            toast.error('Email is required')
        }
        if(password == ''){
            toast.error('Password is required')
        }
        if(organization == false && organizationName == ''){
            toast.error('Organization Name is required')
        }
    }

    const validatePassword = (password) => {
        const rules = {
            lengthRule: password.length >= 8,
            capitalRule: /[A-Z]/.test(password),
            smallRule: /[a-z]/.test(password),
            numberRule: /\d/.test(password),
            specialRule: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        }
        return rules
    }

    const signUp = async() => {
        let user = {
            fullName : fullName,
            email : email,
            password : password,
            organisationId : orgId,
            timezone : 'Asia/Kolkata',
            type :"invited_member"
        }
        if(rules.lengthRule && rules.smallRule && rules.capitalRule && rules.numberRule && rules.specialRule){
            let res = await axios.post(`${BASE_URL}/member/configure/password`, user)
            if(res.status == 200){
                localStorage.setItem('token', res.data.data.organisation.memberDetails.authToken)
                localStorage.setItem('orgId', res.data.data.organisation.id)
                localStorage.setItem('darkMode', false)
                if(res.data.data.organisation.typeId == 1){
                    let orgName = res.data.data.organisation.name.split(" ")[0]  
                    localStorage.setItem('orgName', `${orgName}'s Organization`)
                }else{
                    let orgName = res.data.data.organisation.name
                    localStorage.setItem('orgName', orgName)
                }    
                let memberName = ((res.data.data.organisation.memberDetails && res.data.data.organisation.memberDetails.name != null) ? res.data.data.organisation.memberDetails.name : '-')
                localStorage.setItem('userName', JSON.stringify(memberName))
                let apiKeys = res.data.data.organisation.apiKeys
                localStorage.setItem('apiKeys', JSON.stringify(apiKeys))
                let user = res.data.data.organisation.memberDetails
                localStorage.setItem('user', JSON.stringify(user))
                let environment = 'Development'
                localStorage.setItem('environment', environment)
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 1500, })
                navigate('/home')
                setShowLoader(true)
                setLoaderType(1)
                setTimeout(() => {
                    setShowLoader(false)
                    globalStates.setShowQuickGuideModal(true)
                }, 4000)
            }
        }
    }

    return (
    <>
        <ToastContainer />
        <div className={styles.signUp}>
            <div className={styles.signUp_container_outer}>
                <div className={styles.logo}>
                    <SpecterLogo />
                </div>
                <div className={styles.signUp_container_inner}>
                    <div className={styles.Branding}>
                        <HalfCircle className={styles.halfCircle}/>
                        <Cross className={styles.crossFilled}/>
                        <FullCircle className={styles.fullCircle}/>
                        <FullHollowCircle className={styles.hollowCircle}/>
                        <div className={styles.animation}>
                            <Lottie className={styles.lottieAnimation} animationData={Spectre} loop={true} autoPlay={true} style={style} />
                        </div>
                    </div>
                    <div className={styles.form}>
                        <HollowCross className={styles.hollowCross}/>
                        <div className={styles.signUpForm}>
                            <img src={FilledCrossed} className={styles.filledCross} />
                            <SmallerFullCircle className={styles.fullCircle}/>
                            <div className={styles.signUpFormContainer}>
                                <div className={styles.formTitle}>
                                    <span>Sign Up</span>
                                </div>

                                <div className={styles.formFiedls}>
                                    <TextInput className={styles.field} type="text" placeHolder="Full Name" currency={<Icon name="profile-circle" size="20" />} onChange={(e)=>{onFullName(e)}} name='fullName' />
                                </div>

                                <div className={styles.formFiedls}>
                                    <TextInput className={styles.field} disabled value={email} type="email" placeHolder="Email" currency={<Icon name="mail" size="20" />} name='email' />
                                </div>

                                <div className={styles.formFiedls}>
                                    <TextInput className={styles.field} type="password" placeHolder="Password" currency={<Icon name="lock" size="20" />} onChange={(e)=>{onPassword(e)}} name='password' />
                                </div>

                                <div className={styles.rules}>
                                        <div className={styles.passwordRule}>
                                            <div className={styles.heading}>
                                                <span>Password must contain: </span>
                                            </div>
                                            <div className={styles.ruleGroup}>
                                                <div className={styles.rule}>
                                                    {rules.lengthRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                                    {rules.lengthRule != null && !rules.lengthRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                                    {rules.lengthRule != null && rules.lengthRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                                    <span>at least 8 characters</span>
                                                </div>
                                                <div className={styles.rule}>
                                                    {rules.smallRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                                    {rules.smallRule != null && !rules.smallRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                                    {rules.smallRule != null && rules.smallRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                                    <span>at least one lowercase letter (a-z)</span>
                                                </div>
                                                <div className={styles.rule}>
                                                    {rules.capitalRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                                    {rules.capitalRule != null && !rules.capitalRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                                    {rules.capitalRule != null && rules.capitalRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                                    <span>at least one uppercase letter (A-Z)</span>
                                                </div>
                                                <div className={styles.rule}>
                                                    {rules.numberRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                                    {rules.numberRule != null && !rules.numberRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                                    {rules.numberRule != null && rules.numberRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                                    <span>at least one number (0-9)</span>
                                                </div>
                                                <div className={styles.ruleFull}>
                                                    {rules.specialRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                                    {rules.specialRule != null && !rules.specialRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                                    {rules.specialRule != null && rules.specialRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                                    <span>at least one special character (! @ # $ % ^ & )</span>
                                                </div>
                                            </div>
                                        </div>
                                </div>

                                <div className={styles.signUpButton}>
                                    <button className={styles.Save} onClick={signUp}>Continue</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default MemberInvite