import React, { useState, useEffect } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from './Control'
import { Link, useParams } from "react-router-dom";
import cn from "classnames";
import Switch from "../../../../Switch";

const Row = ({ item, access, value, setValue, index, key }) => {

    const {appid} = useParams()

    const toggleWallet = (id) => {
        setValue(wallets => wallets && wallets.map(x => x?.entitySubType === id ? {...x, active: !x.active} : x))
    }

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <div className={styles.item}>{item?.rules?.info?.walletName !== '' ? item?.rules?.info?.walletName : item?.entitySubType.charAt(0).toUpperCase() + item?.entitySubType.slice(1)}</div>
                </div>
                <div className={styles.col}>
                    <div className={styles.item}>{item?.rules?.info?.walletId !== '' ? item?.rules?.info?.walletId : item?.entitySubType.charAt(0).toUpperCase() + item?.entitySubType.slice(1)}</div>
                </div>
                <div className={styles.col}>
                    <div className={cn(styles.pills, {[styles.green] : (item?.entitySubType === 'bonus')}, {[styles.blue] : (item?.entitySubType === 'deposit')}, {[styles.red] : (item?.entitySubType === 'winning')})}>{item?.entitySubType.charAt(0).toUpperCase() + item?.entitySubType.slice(1)}</div>
                </div>
                <div className={styles.col}>
                    <Switch size='small' value={item?.active} onChange={() => toggleWallet(item?.entitySubType)}/>
                </div>
                <div className={styles.col}>
                    {item?.active &&
                        <Control id={item?.entitySubType} item={item} value={value} setValue={setValue} />
                    }
                </div>
            </div>
        </>
    );
};

export default Row;
