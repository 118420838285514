import { GET_ALL_COUNTRY, GET_ALL_COUNTRY_ERROR, GET_ALL_COUNTRY_SUCCESS, GET_ALL_STATES, GET_ALL_STATES_ERROR, GET_ALL_STATES_SUCCESS, GET_CITY, GET_DESCRIPTION, GET_DESCRIPTION_ERROR, GET_DESCRIPTION_SUCCESS, GET_GOALS, GET_META_DATA,GET_PLATFORMS,GET_STATES,GET_TAGS, UPDATE_TAGS} from "../../actionTypes/actionTypes";
import CommonSevices from "../../../services/commonServices/commonSevices";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fa } from "@faker-js/faker";

export const getAllMetaDataAction = () => {
    return async (dispatch) => {
        await CommonSevices.getMetaData()
        .then(res=>{
            dispatch(getMetaData(res.data.data))
        })
    }
}

export const extarctImageFromUrlAction = (url) => {
    return async (dispatch) => {
        try{
            let response = await CommonSevices.getImage(url)
            let imageBlobUrl = await URL.createObjectURL(response.data)
            return imageBlobUrl;
        }catch (error){
            console.error('Error fetching image:', error);
        }
    }
}

export const getDescriptionAction = (body, setLoading) => {
    if(setLoading){
        setLoading(true)
    }
    return async(dispatch) => {
        // dispatch(getDescription(null, GET_DESCRIPTION))
        // await CommonSevices.getDescription(body)
        // .then(res => {
        //     dispatch(getDescription(res.data.data, GET_DESCRIPTION_SUCCESS))
        // }).catch(err => {
        //     dispatch(getDescription(err.response, GET_DESCRIPTION_ERROR))
        // })
        try {
            const res = await CommonSevices.getDescription(body)
            if(setLoading){
                setLoading(false)
            }
            return res.data.data;
        }catch(error) {
            if(setLoading){
                setLoading(false)
            }
            throw error; 
        }
    }
}

export const getAllCountriesForSignUpAction = () => {
    return async(dispatch) => {
        dispatch(getAllCountry(null, GET_ALL_COUNTRY))
        await CommonSevices.getAllCountry()
        .then(res => {
            dispatch(getAllCountry(res.data.data, GET_ALL_COUNTRY_SUCCESS))
        }).catch(err => {
            dispatch(getAllCountry(err, GET_ALL_COUNTRY_ERROR))
        })
    }
}

export const getAllStatesForSignUpAction = (body) => {
    return async(dispatch) => {
        dispatch(getAllStates(null, GET_ALL_STATES))
        await CommonSevices.getAllStates(body)
        .then(res => {
            dispatch(getAllStates(res.data.data, GET_ALL_STATES_SUCCESS))
        }).catch(err => {
            dispatch(getAllStates(err, GET_ALL_STATES_ERROR))
        })
    }
}

export const getRegionsAction = (body) => {
    return async(dispatch) => {
        await CommonSevices.getRegion(body)
        .then(res=>{
            dispatch(getRegion(res.data.data))
        })
    }
}

export const getCitiesAction = (body) => {
    return async(dispatch) => {
        await CommonSevices.getCity(body)
        .then(res=>{
            dispatch(getCity(res.data.data))
        })
    }
}

export const getPlatformAction = (body) => {
    return async (dispatch) => {
        await CommonSevices.getPlatforms(body)
        .then(res=>{
            dispatch(getPlatforms(res.data.data))
        })
    }
}

export const getGoalsAction = (body) => {
    return async (dispatch) => {
        await CommonSevices.getGoals(body)
        .then(res=>{
            dispatch(getGoals(res.data.data))
        })
    }
}


export const getTagsAction = (body) => {
    return async (dispatch) => {
        await CommonSevices.getTags(body)
        .then(res=>{
            dispatch(getTags(res.data.data))
        })
    }
}
export const getTags = (data) => {
    return {
        type : GET_TAGS,
        payload : data
    }
}
export const updateTagsAction = (body) => {
    return async (dispatch) => {
        await CommonSevices.updateTags(body)
        .then(res=>{
            dispatch(updateTags(res.data.data))
        })
    }
}
export const updateTags = (data) => {
    return {
        type : UPDATE_TAGS,
        payload : data
    }
}

export const getMetaData = (data) => {
    return {
        type : GET_META_DATA,
        payload : data
    }
}

export const getDescription = (data, type) => {
    return {
        payload: data,
        type: type
    }
}

export const getRegion = (data) => {
    return{
        type: GET_STATES,
        payload: data
    }
}

export const getCity = (data) => {
    return{
        type: GET_CITY,
        payload: data
    }
}

export const getPlatforms = (data) => {
    return {
        type : GET_PLATFORMS,
        payload : data
    }
}

export const getGoals = (data) => {
    return {
        type : GET_GOALS,
        payload : data
    }
}

export const getAllCountry = (data, type) => {
    return {
        type : type,
        payload : data
    }
}

export const getAllStates = (data, type) => {
    return {
        type : type,
        payload : data
    }
}