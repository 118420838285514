import React, { useState, useRef, useMemo, useContext } from 'react';
import { ToastContainer, toast } from "react-toastify";
import cn from "classnames";
import Card from "../../../../components/Card";
import styles from './documents.module.sass'
import JoditEditor from 'jodit-react';
import 'ace-builds/src-noconflict/theme-idle_fingers';
import 'ace-builds/src-noconflict/mode-html';
import { useNavigate, useParams } from 'react-router-dom';
import TextInput from '../../../../components/TextInput';
import { createDocumentsAction } from '../../../../redux/action/AppSettings/Document/documentAction';
import { useDispatch } from 'react-redux';
import app,{increaseUnreadCountForAllUsers} from "../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";
import GlobalStateContext from '../../../../components/Context/GlobalStates/GlobalStateContext.js';


const CreateDocument = ({className, placeholder }) => {

    const {appid} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const globalStates = useContext(GlobalStateContext)
    const editor = useRef(null);
    const [documentName, setDocumentName] = useState('')
    const [documentId, setDocumentId] = useState('')
	const [content, setContent] = useState('');

	const config = {readonly: false, minHeight: '500px', spellcheck: true, placeholder: placeholder || 'Start typing...', ace: { theme: 'ace/theme/idle_fingers'}}

    const onChangeDocumentID = (e) => {
        setDocumentId(e.target.value)
    }

    const onChangeDocumentName = (e) => {
        setDocumentName(e.target.value)
    }

    const db = getFirestore(app);

    const save = async () => {
        let document = {
            projectId: appid,
            documentId: documentId,
            name: documentName,
            content: content,
        }
        try {

                await dispatch(createDocumentsAction(document, navigate, appid, globalStates.setShowOverlay))
                const user = JSON.parse(localStorage.getItem('user'))
                const memberID = user?.id
                const memberName = user?.name
                const orgId = (localStorage.getItem('orgId'))

                // Log activity in Firestore
                const activityLog = {
                    timestamp: new Date().toISOString(),
                    action_type: "CREATE",
                    app_id:appid,
                    resource: "DOCUMENT",
                    // resource_id: IdFromResponse, // Use the ID from response
                    description: `Document '${documentName}' created successfully`,
                    quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
operation_status: "SUCCESS",
                    activity_feed: true,
                    priority: "LOW",
                    performed_by_id: memberID,
                    organization_id: orgId,
                    performed_by:memberName,
                    request_json:JSON.stringify(document),
                };
                await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
                console.log("Activity logged successfully");
            } catch (error) {
                console.error("Error creating game or logging activity:", error);
            }
    }

    const cancel = () => {
        navigate(`/app-documents/${appid}`)
    }

	return (
        <>
            <ToastContainer />
            <Card className={styles.card} classCardHead={styles.head} title="Information" classTitle={cn("title-yellow", styles.title)} >
                
                <div className={styles.container}>
                    <div className={styles.group}>
                        <TextInput className={styles.field} label="Display Name*" name="name" type="text" required value={documentName} onChange={(e)=>{onChangeDocumentName(e)}}/>
                        <TextInput isId={true} className={styles.field} label="Document ID*" name="documentId" type="text" required value={documentId} onChange={(e)=>{onChangeDocumentID(e)}}/>
                    </div>
                    <div className={styles.content}>
                        <JoditEditor ref={editor} value={content} config={config} tabIndex={1} onBlur={newContent => setContent(newContent)} onChange={newContent => {}} />
                    </div>
                </div>
                
            </Card> 

            <Card className={cn(styles.card, className)}>
                <div className={styles.cardAction}>
                    <div className={styles.save}>
                        <button className={cn(styles.button, 'button-small')} onClick={save}>save</button>
                    </div>
                    <div className={styles.cancel}>
                        <button className={cn(styles.button, 'button-white-small')} onClick={cancel}>Cancel</button>
                    </div>
                </div>
            </Card>
        </>
	);
};


export default CreateDocument;