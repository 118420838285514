import React, {useContext, useEffect, useState} from 'react'
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import cn from 'classnames';
import styles from './progressionMarkerInfo.module.sass'
import Card from '../../../../../../components/Card';
import TextInput from '../../../../../../components/TextInput';
import Dropdown from '../../../../../../components/Dropdown';
import TextArea from '../../../../../../components/TextArea';
import Icon from '../../../../../../components/Icon';
import {ReactComponent as AddButtonIcon} from '../../../../../../media/images/icons/add_button.svg'
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg'
import { useDispatch, useSelector } from 'react-redux';
import { createProgressionMarkerAction } from '../../../../../../redux/action/builds/progression/progressionMarker/progressionMarkerAction';
import { WithContext as ReactTags } from "react-tag-input";
import removeIcon from '../../../../../../media/images/icons/remove.png'
import Tooltip from '../../../../../../components/Tooltip';
import ProgressionMarker from '../../../../../../media/images/placeholders/Progression_Marker.png'
import { useRef } from 'react';
import Toast from '../../../../../../components/Toast';
import TagInputWithDropdown from "../../../../../../components/TagInputWithDropdown";
import FileUploadSidebar from '../../../../../../components/FileUploadSidebar';
import { getAllMediaAction } from '../../../../../../redux/action/AppSettings/Media/mediaAction';
import TooltipTitle from '../../../../../../Tooltip/TooltipTitle';
import JSONMetaDataValueInput from '../../../../../../components/MetaInput';
import { BASE_URL } from '../../../../../../resources/APIEndpoints';
import app,{increaseUnreadCountForAllUsers} from "../../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";
import GlobalStateContext from '../../../../../../components/Context/GlobalStates/GlobalStateContext.js';

const ProgressionMarkerInfo = ({className, onClose,isChanged, setIsChanged}) => {

    const {appid} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ref = useRef()

    const placeholder = ProgressionMarker
    const[{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const globalStates = useContext(GlobalStateContext)
    const [displayName, setDisplayName] = useState('')
    const [markerIcon, setMarkerIcon] = useState(null)
    const [markerId, setMarkerId] = useState('')
    const [markerDescription, setMarkerDescription] = useState()

    const onDisplayName= (e) => {
        if(e.target.value == ''){
            setDisplayName('')
        }else{
            errors.nameError = ''
            setDisplayName(e.target.value)
        }
    }

    const onMarkerId = (e) => {
        if(e.target.value == ''){
            setMarkerId('')
        }else{
            errors.idError = ''
            setMarkerId(e.target.value)
        }

    }

    const onMarkerDescription= (e) => {
        setMarkerDescription(e.target.value)
    }

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setMarkerIcon(res.data.getUrl);
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
 toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        })
        .catch(function (error) {
            //toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        });
    }

    // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
              
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
        
    // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
        const fileReader = new FileReader();
        fileReader.onload = (eve) =>{
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
        })
    }

    const Cancel = () => {
        setMarkerIcon(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        ref.current.value = ''
    }

    const [tags, setTags] = useState([]);

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]);
        }else{
            toast.warning(<Toast type='Warning' messages='Only 10 tags are allowed'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        }
    };
  
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
  
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }

    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const [errors, setErrors] = useState({nameError : '', idError : ''})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if (data.name == '') {
            error.nameError = 'Display Name is required';
            isValid = false;
            const element = document.getElementById('pmName');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if (data.progressionMarkerId == '') {
            error.idError = 'PM ID is required';
            isValid = false;
            if(data.name != ''){
                const element = document.getElementById('pmId');
                const offset = 140;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }
    const db = getFirestore(app);

    const save = async () => {
        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }
        let itemTags = []
        tags && tags.forEach((e)=>{
            itemTags.push(e.text)
        })
        let progressionMaraker = {
            projectId : appid,
            iconUrl : markerIcon,
            name : displayName,
            description : markerDescription,
            progressionMarkerId : markerId,
            meta : finalMetaData,
            tags : itemTags
        }

        if(validation(progressionMaraker)){
            try {
                // Await the response from createGameAction
                const res = await dispatch(createProgressionMarkerAction(progressionMaraker, navigate, appid, globalStates.setShowOverlay))
                // Access the response data (e.g., game ID)
                if (res) {
                    const matchIdFromResponse = res?.data?.data?.progressionMarker?.id;
    
                    setIsChanged(false);
                    const user = JSON.parse(localStorage.getItem('user'))
                    const memberID = user?.id
                    const memberName = user?.name
                    const orgId = (localStorage.getItem('orgId'))
    
                    // Log activity in Firestore
                    const activityLog = {
                        timestamp: new Date().toISOString(),
                        action_type: "CREATE",
                        app_id:appid,
                        resource: "PROGRESSION MARKER",
                        resource_id: matchIdFromResponse, // Use the ID from response
                        description: `Progression Marker '${displayName}' created successfully`,
                        quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
operation_status: "SUCCESS",
                        activity_feed: true,
                        priority: "LOW",
                        performed_by_id: memberID,
                        organization_id: orgId,
                        performed_by:memberName
                    };
                    await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
                    console.log("Activity logged successfully");
                }
            } catch (error) {
                console.error("Error creating game or logging activity:", error);
            }
        }

    }

    const cancel = () => {
        navigate(`/progression-marker/${appid}`)
        setIsChanged(false)
    }

    const onTagInput = () => {
        if(document.getElementById('tagsInput')){
            document.getElementById('tagsInput').focus()
        }
    }

    const [visibleHelp, setVisibleHelp] = useState(false)

    const setchange = () => {
        if (
            (markerIcon==null||markerIcon==undefined)&&
            (displayName===undefined||displayName==''||displayName=="")&&
            (markerId===undefined||markerId==''||markerId=="")&&
            (markerDescription==undefined||markerDescription==''||markerDescription=="")&&
            (tags.length==0)&&
            (metaData.length==1&&metaData[0].key == "" && (metaData[0].value == "" || metaData[0].value == null))
        )
        {
            setIsChanged(false)
        }
        else 
        {
            setIsChanged(true)
        }
    }


    useEffect(()=>{
        setchange()
    },[displayName,markerIcon,markerId,markerDescription,tags,metaData])
    const [media, setMedia] = useState([])

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : 0
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })

    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }

    useEffect(() => {
        getMedia()
    }, [])

    useEffect(() => {
        configureMedia()
    }, [files])

    return (
        <>
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-blue">
                    <div className={styles.description}>
                        <div className={styles.preview}>
                            <div className={styles.previewImage}>
                                <img src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                            </div>
                            <div className={styles.previewInput}> 
                                <input type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={ref} onChange={(e)=>{uploadLogo(e)}}/>
                                <label className={styles.button} onClick={() => setVisibleHelp(true)}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>
                            </div>
                            {markerIcon != null &&
                                <div className={styles.previewCancel}>
                                    <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                                </div>
                            }
                        </div>
                        <FileUploadSidebar media={media} setMedia={setMedia} types='Images' id={`icon`} multiple={false} visible={visibleHelp} setVisible={setVisibleHelp} icon={markerIcon} setIcon={setMarkerIcon} setValue={setLogo} onClose={onClose} />
                        <div className={styles.group}>
                            <TextInput id='pmName' className={styles.field} errorMessage={errors.nameError} tooltip={TooltipTitle.PMName} label="Display Name*" name="DisplayName" type="text" required onChange={(e)=>{onDisplayName(e)}}/>
                            <TextInput isId={true} value={markerId} id='pmId' className={styles.field} errorMessage={errors.idError} tooltip={TooltipTitle.PMID} label="Progression Marker ID*" name="progressionId" type="text" required onChange={(e)=>{onMarkerId(e)}}/>
                        </div>
                        <TextArea type='PROGRESSION_MARKER_DESCRIPTION' value={markerDescription} className={styles.field} rows={5} tooltip={TooltipTitle.PMDescription} label="Progression Marker Description" onChange={(e)=>{onMarkerDescription(e)}}/>
                    </div>
                </Card>

                <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                    <div className={styles.cardDescription}>
                        <TagInputWithDropdown tooltip={TooltipTitle.PMTags} tags={tags} setTags={setTags}/>
                        <div className={styles.createCurrency}>
                            <div className={styles.groupHeading}>
                                <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.PMMetaData} icon="info"/></span><span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>
                            </div>

                            {metaData && metaData.length > 0 &&
                                <div className={styles.groupMeta}>
                                    <div className={styles.field}>Key</div>
                                    <div className={styles.field}>Value</div>
                                </div>
                            }

                            <div className={styles.groupMeta}>
                                {metaData && metaData.map((e,i)=>{
                                        return(
                                            <>
                                                <TextInput className={styles.field} label="" name="key" type="text" required value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''} onChange={(e)=>{onChangeKey(e,i)}}/>
                                                <JSONMetaDataValueInput key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                                {/* <TextInput className={styles.field} label="" name="value" type="text" required value={(metaData[i].value != '' || metaData[i].value != undefined || metaData[i].value != null) ? metaData[i].value : ''} onChange={(e)=>{onChangeValue(e,i)}}/> */}
                                                <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><img className={styles.icon} src={removeIcon} alt="remove" /></span>
                                            </>
                                        )
                                })}
                            </div>
                        </div>
                    </div>
                </Card>

                <Card className={cn(styles.card, className)}>
                    <div className={styles.cardAction}>
                        <div className={styles.cardSave}>
                            <button className={cn(styles.button, 'button-save-small')} onClick={save}>Save</button>
                        </div>
                        <div className={styles.cardCancel}>
                            <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                        </div>
                    </div>
                </Card>
        </>
    )
}

export default ProgressionMarkerInfo