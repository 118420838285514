import React, {useEffect, useRef, useState} from 'react'
import cn from "classnames";
import Card from "../../../../components/Card";
import addIcon from '../../../../media/images/icons/light.png'
import styles from './getMatches.module.sass'
import { LeaderboardResult } from './mockData/leaderboardResult';
import LeaderboardResultTableLayout from '../../../../components/EngageComponents/LederboardComponent/LeaderboardResult';
import LeaderboardResultPDTableLayout from '../../../../components/EngageComponents/LederboardComponent/LeaderboardResultPD';
import {ReactComponent as Previous} from '../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../media/images/icons/next.svg'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getLeadboardResultAction } from '../../../../redux/action/engage/Leaderboard/leaderboardAction';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import EmptyStates from '../../../../components/EmptyStates';
import Calendar from './Calendar';
import moment from 'moment';
import { getLeadboardResultPDAction } from '../../../../redux/action/engage/LiveOps/LiveOpsAction';

const GetLeaderboardResult = ({props}) => {

    const {id} = useParams()
    const {appid} = useParams()
    const location = useLocation()

    const [page, setPage] = useState(1)
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(10)
    const [selectionRange, setSelectionRange] = useState([{
        startDate: moment().toDate(),
        endDate: moment().toDate(),
        key: 'selection',
    }])

    const [leaderboardInstanceId, setLeaderboardInstanceId] = useState(location.state.instanceId)
    const [leaderboardStatus, setLeaderboardStatus] = useState(location.state.status)
    const [pdStatus, setPDStatus] = useState(location.state.PD)
    const [placeHolderDate, setPlaceHolderDate] = useState(true)
    const [infinitySymbol, setInfinitySymbol] = useState('\u221E')
    // const [current, setCurrent] = useState(location.state.interval == 1 ? `${moment().format('MMMM DD, YYYY')}` : location.state.interval == 2 ? `${moment().startOf('week').format('MMMM DD, YYYY')} - ${moment().endOf('week').format('MMMM DD, YYYY')}` : location.state.interval == 3 ? `${moment().startOf('month').format('MMMM DD, YYYY')} - ${moment().endOf('month').format('MMMM DD, YYYY')}` : location.state.interval == 4 ? `${moment().startOf('year').format('MMMM DD, YYYY')} - ${moment().endOf('year').format('MMMM DD, YYYY')}` : '')
    const [current, setCurrent] = useState(location.state.interval === 6 ? `${moment(location.state.scheduleInstance[0].startDate).format('MMMM DD, YYYY')} - ${infinitySymbol}` : location.state.interval === 7 ? `${moment(location.state.scheduleInstance[0].startDate).format('MMMM DD, YYYY hh:mm A')} - ${moment(location.state.scheduleInstance[0].endDate).format('MMMM DD, YYYY hh:mm A')}` : '')
    const dispatch = useDispatch()

    const isInitialMount = useRef(true);

    useEffect(() => {
        if(leaderboardStatus === 'completed' && pdStatus){
            getLeadboardResultPD()
        }else{
            getLeaderboardResult()
        }
    }, [leaderboardInstanceId, page]);

    const getLeaderboardResult = () => {
        let leaderboardResult = {
            leaderboardId : id,
            instanceId : leaderboardInstanceId,
            pagination : {
                offset : ((page-1)*limit),
                limit : limit
            }
        }
        dispatch(getLeadboardResultAction(leaderboardResult))
    }

    const getLeadboardResultPD = () => {
        let leaderboardResultPD = {
            instanceId : leaderboardInstanceId,
            pagination:{
                offset : ((page-1)*limit),
                limit : limit
            }
        }
        dispatch(getLeadboardResultPDAction(leaderboardResultPD))
    }

    let leaderboardResults = useSelector((state) =>{
        return state.leaderBoards.leaderBoardResult
    })

    let leaderboardPD = useSelector((state) =>{
        return state.liveOps.liveOpsPD
    })

    let totalResult = useSelector((state) =>{
        return state.leaderBoards.resultCount
    })

    let totalResultPD = useSelector((state) =>{
        return state.liveOps.resultCount
    })

    const handlePageChange = (event, value)  => {
        setPage(value)
    }

    return(
        <>
            <div className={styles.container}>
                <div className={styles.head}>
                    <div className={styles.title}>
                        <div className={styles.block}></div>
                        <div className={styles.name}>
                            {location.state.name} Result
                            <div className={leaderboardStatus == 'yet to start' ? styles.purple : leaderboardStatus == 'in progress' ? styles.green : leaderboardStatus == 'completed' ? styles.grey : leaderboardStatus == 'stopped' ? styles.orange : leaderboardStatus == 'in review' ? styles.yellow : leaderboardStatus == 'failed' ? styles.red : ''}>{leaderboardStatus == 'yet to start' ? 'Yet-to-Start' : leaderboardStatus == 'in progress' ? 'Live' : leaderboardStatus == 'completed' ? 'Completed' : leaderboardStatus == 'in review' ? 'In-Review' : leaderboardStatus == 'stopped' ? 'Stopped' : leaderboardStatus == 'failed' ? 'Failed' : ''}</div>
                        </div>
                    </div>
                    <div className={styles.calendar}>
                        <Calendar setLeaderboardStatus={setLeaderboardStatus} setPlaceHolderDate={setPlaceHolderDate} current={current} setCurrent={setCurrent} 
                        dates={location.state.scheduleInstance} setLeaderboardInstanceId={setLeaderboardInstanceId}
                        selectionRange={selectionRange} setSelectionRange={setSelectionRange} interval={location.state.interval}/>
                    </div>
                </div>
                <div className={styles.wrapper}>
                {(leaderboardStatus !== 'completed' || (leaderboardStatus === 'completed' && !pdStatus)) && leaderboardResults.length == 0 && 
                            <EmptyStates route={`/leaderboard/${appid}`} type="Leaderboard Result" />
                        }
                        {leaderboardStatus === 'completed' && pdStatus && leaderboardPD.length == 0 && 
                            <EmptyStates route={`/leaderboard/${appid}`} type="Leaderboard Result" />
                        }
                        
                        <>
                            {(leaderboardStatus !== 'completed' || (leaderboardStatus === 'completed' && !pdStatus)) && 
                                <>
                                    {leaderboardResults.length > 0 && <LeaderboardResultTableLayout items={leaderboardResults} title="Last edited" usedFor='leaderboard' />}
                                    <div className={styles.pagination}>
                                        <Stack>
                                            <Pagination renderItem={(item) => (
                                                <PaginationItem
                                                    {...item}
                                                    sx={{
                                                        '&.MuiPaginationItem-root': {
                                                          minWidth: '28px',
                                                          height: '28px',
                                                          fontSize: '12px'
                                                        },
                                                        '&.Mui-selected': {
                                                          color: 'rgb(255, 255, 255)',
                                                          fontWeight : 'bold',
                                                          border : '1px solid rgb(42, 133, 255)',
                                                          background : 'rgba(42, 133, 255)'
                                                        },
                                                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                          color: 'rgb(255, 255, 255)',
                                                          fontWeight : 'bold',
                                                          border : '1px solid rgb(42, 133, 255)',
                                                          background : 'rgba(42, 133, 255)'
                                                        }
                                                    }}
                                                />
                                            )} siblingCount={1} count={Math.ceil(totalResult/limit)} color="primary" onChange={handlePageChange} />
                                        </Stack>
                                    </div>
                                </>
                            }
                        </>
                        
                        <>
                            {(leaderboardStatus === 'completed' && pdStatus) && 
                                <>
                                    {leaderboardPD.length > 0 && <LeaderboardResultPDTableLayout items={ leaderboardPD} title="Last edited" usedFor='leaderboard' />}
                                    <div className={styles.pagination}>
                                        <Stack>
                                            <Pagination renderItem={(item) => (
                                                <PaginationItem
                                                    {...item}
                                                    sx={{
                                                        '&.MuiPaginationItem-root': {
                                                          minWidth: '28px',
                                                          height: '28px',
                                                          fontSize: '12px'
                                                        },
                                                        '&.Mui-selected': {
                                                          color: 'rgb(255, 255, 255)',
                                                          fontWeight : 'bold',
                                                          border : '1px solid rgb(42, 133, 255)',
                                                          background : 'rgba(42, 133, 255)'
                                                        },
                                                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                          color: 'rgb(255, 255, 255)',
                                                          fontWeight : 'bold',
                                                          border : '1px solid rgb(42, 133, 255)',
                                                          background : 'rgba(42, 133, 255)'
                                                        }
                                                    }}
                                                />
                                            )} siblingCount={1} count={Math.ceil(totalResultPD/limit)} color="primary" onChange={handlePageChange} />
                                        </Stack>
                                    </div>
                                </>
                            }
                        </>
                </div>
            </div>
            {/* <div className={styles.leaderboardHeading}>
                <div className={cn("h5", styles.title)}>{location.state.name}</div>
            </div>
            <Card className={styles.card} classCardHead={styles.head} title="Leaderboard Result" classTitle={cn("title-purple", styles.title)}
                head={
                <>
                    <div className={leaderboardStatus == 'yet to start' ? styles.purple : leaderboardStatus == 'in progress' ? styles.green : leaderboardStatus == 'completed' ? styles.grey : leaderboardStatus == 'stopped' ? styles.orange : leaderboardStatus == 'in review' ? styles.yellow : leaderboardStatus == 'failed' ? styles.red : ''}>{leaderboardStatus == 'yet to start' ? 'Yet-to-Start' : leaderboardStatus == 'in progress' ? 'Live' : leaderboardStatus == 'completed' ? 'Completed' : leaderboardStatus == 'in review' ? 'In-Review' : leaderboardStatus == 'stopped' ? 'Stopped' : leaderboardStatus == 'failed' ? 'Failed' : ''}</div>
                    <Calendar setLeaderboardStatus={setLeaderboardStatus} setPlaceHolderDate={setPlaceHolderDate} current={current} setCurrent={setCurrent} 
                    dates={location.state.scheduleInstance} setLeaderboardInstanceId={setLeaderboardInstanceId}
                    selectionRange={selectionRange} setSelectionRange={setSelectionRange} interval={location.state.interval}/>
                </>
                }>
                <div>
                    <div className={styles.wrapper}>
                        {(leaderboardStatus !== 'completed' || (leaderboardStatus === 'completed' && !pdStatus)) && leaderboardResults.length == 0 && 
                            <EmptyStates route={`/leaderboard/${appid}`} type="Leaderboard Result" />
                        }
                        {leaderboardStatus === 'completed' && pdStatus && leaderboardPD.length == 0 && 
                            <EmptyStates route={`/leaderboard/${appid}`} type="Leaderboard Result" />
                        }
                        
                        <>
                            {(leaderboardStatus !== 'completed' || (leaderboardStatus === 'completed' && !pdStatus)) && 
                                <>
                                    {leaderboardResults.length > 0 && <LeaderboardResultTableLayout items={leaderboardResults} title="Last edited" usedFor='leaderboard' />}
                                    <div className={styles.pagination}>
                                        <Stack>
                                            <Pagination renderItem={(item) => (
                                                <PaginationItem
                                                    {...item}
                                                    sx={{
                                                        '&.MuiPaginationItem-root': {
                                                          minWidth: '28px',
                                                          height: '28px',
                                                          fontSize: '12px'
                                                        },
                                                        '&.Mui-selected': {
                                                          color: 'rgb(255, 255, 255)',
                                                          fontWeight : 'bold',
                                                          border : '1px solid rgb(42, 133, 255)',
                                                          background : 'rgba(42, 133, 255)'
                                                        },
                                                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                          color: 'rgb(255, 255, 255)',
                                                          fontWeight : 'bold',
                                                          border : '1px solid rgb(42, 133, 255)',
                                                          background : 'rgba(42, 133, 255)'
                                                        }
                                                    }}
                                                />
                                            )} siblingCount={1} count={Math.ceil(totalResult/limit)} color="primary" onChange={handlePageChange} />
                                        </Stack>
                                    </div>
                                </>
                            }
                        </>
                        
                        <>
                            {(leaderboardStatus === 'completed' && pdStatus) && 
                                <>
                                    {leaderboardPD.length > 0 && <LeaderboardResultPDTableLayout items={ leaderboardPD} title="Last edited" usedFor='leaderboard' />}
                                    <div className={styles.pagination}>
                                        <Stack>
                                            <Pagination renderItem={(item) => (
                                                <PaginationItem
                                                    {...item}
                                                    sx={{
                                                        '&.MuiPaginationItem-root': {
                                                          minWidth: '28px',
                                                          height: '28px',
                                                          fontSize: '12px'
                                                        },
                                                        '&.Mui-selected': {
                                                          color: 'rgb(255, 255, 255)',
                                                          fontWeight : 'bold',
                                                          border : '1px solid rgb(42, 133, 255)',
                                                          background : 'rgba(42, 133, 255)'
                                                        },
                                                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                          color: 'rgb(255, 255, 255)',
                                                          fontWeight : 'bold',
                                                          border : '1px solid rgb(42, 133, 255)',
                                                          background : 'rgba(42, 133, 255)'
                                                        }
                                                    }}
                                                />
                                            )} siblingCount={1} count={Math.ceil(totalResultPD/limit)} color="primary" onChange={handlePageChange} />
                                        </Stack>
                                    </div>
                                </>
                            }
                        </>
                    </div>
                </div>
            </Card> */}
        </>
  )
}

export default GetLeaderboardResult