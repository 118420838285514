import {LOGIN, LOGOUT, LOGIN_FAILED, SIGNUP} from '../../actionTypes/actionTypes'
import AuthService from '../../../services/authServices/authenticationServices';
import Toast from '../../../components/Toast'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CheckMemberOnLogin} from "../../../firebase.js"

export const loginUser = (body, navigate, setShowLoader, setLoaderType, show, setShowQuickGuideModal, setShow, setWorkSpace) => {
    return (dispatch) => {
        AuthService.login(body)
            .then(res => {
                let token = res.data.data.memberDetails.authToken
                localStorage.setItem('token', token)
                let user = res.data.data.memberDetails
                localStorage.setItem('user', JSON.stringify(user))
                let memberName = ((res.data.data.memberDetails && res.data.data.memberDetails.name != null) ? res.data.data.memberDetails.name : '-')
                localStorage.setItem('userName', JSON.stringify(memberName))
                let environment = 'Development'
                localStorage.setItem('environment', environment)
                localStorage.setItem('darkMode', false)
                setWorkSpace(res.data.data.memberDetails.organisations)
                CheckMemberOnLogin(res.data.data.memberDetails.organisations,res.data.data.memberDetails.id)
                if(res.data.data.memberDetails.organisations?.length > 1){
                    dispatch(getLoggedInUser(res.data.data.memberDetails))
                    setWorkSpace(res.data.data.memberDetails.organisations)
                    setShow(true)
                }else{
                    let apiKeys = res.data.data.memberDetails.organisations[0].api_keys
                    localStorage.setItem('apiKeys', JSON.stringify(apiKeys))
                    let customerId = res.data.data.memberDetails.organisations[0].external_customer_id
                    sessionStorage.setItem('customerId', JSON.stringify(customerId))
                    let gstTreatment = res.data.data.memberDetails.organisations[0].gst_treatment
                    sessionStorage.setItem('gstTreatment', JSON.stringify(gstTreatment))
                    let location = res.data.data.memberDetails.organisations[0].location ? res.data.data.memberDetails.organisations[0].location : res.data.data.memberDetails.organisations[0].billing_address?.country ? res.data.data.memberDetails.organisations[0].billing_address?.country : null
                    sessionStorage.setItem('location', JSON.stringify(location)) 
                    let orgId = res.data.data.memberDetails.organisations[0].id
                    localStorage.setItem('orgId', orgId)
                    let orgName = res.data.data.memberDetails.organisations[0].name                
                    localStorage.setItem('orgName', JSON.stringify(orgName)) 
                    dispatch(getLoggedInUser(res.data.data.memberDetails));
                    navigate('/home')
                    setShowLoader(true)
                    setLoaderType(1)
                    setTimeout(() => {
                        setShowLoader(false)
                        if(show){
                            setShowQuickGuideModal(true)
                        }
                    }, 4000)
                }
            })
            .catch(error => {
                error?.response?.data?.errors && error?.response?.data?.errors?.map((err,i)=>{
                    toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                })
            })
    }
}

export const signUpUserAction = (body, Navigate, setShowLoader, setLoaderType, setShowQuickGuideModal, setShowOverlay) => {
    return (dispatch) => {
        if(setShowOverlay){
            setShowOverlay(true)
        }
        AuthService.SignUp(body)
        .then(res=>{
            let authToken = res.data.data.organisation.memberDetails.authToken
            let user = res.data.data.organisation.memberDetails
            CheckMemberOnLogin([{api_keys:res.data.data.organisation.apiKeys,...res.data.data.organisation}],res.data.data.organisation.memberDetails.id)
            if(res.data.data.organisation.typeId == 1){
                let orgName = res.data.data.organisation.name.split(" ")[0]  
                localStorage.setItem('orgName', `${orgName}'s Organization`)
            }else{
                let orgName = res.data.data.organisation.name 
                localStorage.setItem('orgName', orgName)
            }
            localStorage.setItem('token', authToken)
            localStorage.setItem('user', JSON.stringify(user))
            let orgId = res.data.data.organisation.id
            localStorage.setItem('orgId', orgId)
            let apiKeys = res.data.data.organisation.apiKeys
            localStorage.setItem('apiKeys', JSON.stringify(apiKeys))
            let environment = 'Development'
            localStorage.setItem('environment', environment)
            localStorage.removeItem('userEmail')
            localStorage.setItem('darkMode', false)
            localStorage.setItem('showModal', JSON.parse(true))
            sessionStorage.setItem('customerId', JSON.stringify(res.data.data.organisation.externalCustomerId))
            Navigate('/plans')
            setShowLoader(true)
            setShowLoader(false)
            if(setShowOverlay){
                setShowOverlay(false)
            }
            dispatch(getLoggedInUser(res));
        }).catch(error => {

        })
    }
}

export const getEmailVerificationAction = (body, navigate, showModal) => {
    return async (dispatch) => {
        AuthService.getverifyEmail(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            setTimeout(()=>{
                showModal(true)
            }, 1000)
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
        })
    }
}

export const verifyEmailAction = (body, navigate, hideModal, setShowLoader, setLoaderType) => {
    return async (dispatch) => {
        AuthService.sendverifyEmail(body)
        .then(res=>{
            hideModal(false)
            navigate('/user-detail')
            setLoaderType(0)
            setShowLoader(true)
            setTimeout(() => {
                setShowLoader(false)
            }, 2000)
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
        })
    }
}

export const getResetPasswordLinkAction = (body) => {
    return async (dispatch) => {
        AuthService.resetPasswordLink(body)
        .then(res => {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
        })
    }
}

export const ResetPasswordAction = (body, navigate, setShowLoader, setLoaderType) => {
    return async (dispatch) => {
        AuthService.resetPassword(body)
        .then(res => {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            setTimeout(() => {
                navigate('/')
                // dispatch(loginUser({email : body.email, password : body.newPassword}, navigate, setShowLoader, setLoaderType))
            }, 2000)
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
        })
    }
}

export const acceptInvitedMemberAction = (body, navigate) => {
    return async (dispatch) => {
        AuthService.acceptInvitedMember(body)
        .then(res => {
            dispatch(getAllOrganisationAction(body.memberId, body.organisationId, navigate))
        }).catch(err => {
            dispatch(getAllOrganisationAction(body.memberId, body.orgId, navigate))
            console.log(err)
        })
    }
}

export const getAllOrganisationAction = (member, org, navigate) => {
    return async (dispatch) => {
        let payload = {memberId: member}
        AuthService.getAllOrganisation(payload)
        .then(async res => {
            let organisations = res.data.data
            console.log(organisations, 'Response')
            let selectedOrg = organisations && organisations.filter(x => x.id === org)
            let apiKeys = selectedOrg[0].api_keys
            localStorage.setItem('apiKeys', JSON.stringify(apiKeys))
            let orgId = selectedOrg[0].id
            localStorage.setItem('orgId', orgId)
            console.log(selectedOrg)
            let orgName = selectedOrg[0].name
            localStorage.setItem('orgName', JSON.stringify(orgName))
            let location = selectedOrg[0]?.location
            sessionStorage.setItem('location', JSON.stringify(location)) 
            let customerId = selectedOrg[0]?.external_customer_id
            sessionStorage.setItem('customerId', JSON.stringify(customerId)) 
            let gstTreatment = selectedOrg[0]?.gst_treatment
            sessionStorage.setItem('gstTreatment', JSON.stringify(gstTreatment))
            await CheckMemberOnLogin(organisations,member,org)
            navigate('/home')
        }).catch(err => {
            navigate('/')
        })
    }
}

export const getLoggedInUser = (data)=> {
    return{
        type: LOGIN,
        payload: data
    }
}

export const SignUp = (data) => {
    return {
        type : SIGNUP, 
        payload : data
    }
}

export const onLoginFailed = (data)=> {
    return{
        type: LOGIN_FAILED,
        payload: data
    }
}