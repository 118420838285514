import React, {useEffect, useState, useRef, useContext} from 'react'
import cn from 'classnames'
import axios from "axios";
import customAxios from '../../../../../utils/interceptor';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { WithContext as ReactTags } from "react-tag-input";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../../../../../components/Card'
import TextInput from '../../../../../components/TextInput';
import TextArea from '../../../../../components/TextArea';
import Tooltip from '../../../../../components/Tooltip';
import Control from '../Control';
import GenreDropdown from '../../../../../components/MultiselectDropDown/GenreDropdown'
import PlatformDropdown from '../../../../../components/MultiselectDropDown/PlatformDropdown'
import LocationDropdown from '../../../../../components/MultiselectDropDown/LocationDropdown'
import Switch from '../../../../../components/Switch';
import { BASE_URL, GET_APPS, GET_GAMES } from '../../../../../resources/APIEndpoints';
import { editAppAction } from '../../../../../redux/action/apps/appsAction';
import Icon from '../../../../../components/Icon';
import styles from './gamesInformation.module.sass'
import Toast from '../../../../../components/Toast';
import Game from '../../../../../media/images/placeholders/Game.png'
import FileImages from '../../../../../components/File/Images'
import FileVideos from '../../../../../components/File/Videos';
import { ReactComponent as AddButtonIcon } from '../../../../../media/images/icons/add_button.svg'
import { ReactComponent as UploadFile } from '../../../../../media/images/icons/upload_file.svg';
import {ReactComponent as Remove} from '../../../../../media/images/icons/remove.svg'
import {ReactComponent as AddMetaIcon} from '../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as ImagePlaceholder} from '../../../../../media/images/icons/image_placeholder.svg'
import {ReactComponent as VideoPlaceholder} from '../../../../../media/images/icons/video_placeholder.svg'
import { editGameAction } from '../../../../../redux/action/builds/games/gamesAction';
import Radio from "../../../../../components/Radio";
import TagInputWithDropdown from "../../../../../components/TagInputWithDropdown";
import FileUploadSidebar from '../../../../../components/FileUploadSidebar';
import { getAllMediaAction, addMediaAction} from '../../../../../redux/action/AppSettings/Media/mediaAction';
import TooltipTitle from '../../../../../Tooltip/TooltipTitle';
import JSONMetaDataValueInput from '../../../../../components/MetaInput';
import _ from 'lodash'
import app,{increaseUnreadCountForAllUsers} from "../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";
import GlobalStateContext from '../../../../../components/Context/GlobalStates/GlobalStateContext';

const gernePlaceHolder = ["Select Genre"]

const GameInfo = ({ className,isChanged, setIsChanged, onClose}) => {

    const { appid } = useParams();
    const { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const logoRef = useRef()
    const screenshotRef = useRef()
    const videoRef = useRef()
    const tagRef = useRef()

    const placeholder = Game
    const globalStates = useContext(GlobalStateContext)
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const [appIcon, setAppIcon] = useState(null)
    const [appName, setAppName] = useState("");
    const [appGerne, setAppGerne] = useState([])
    const [appDescription, setAppDescription] = useState()
    const [platform, setPlatform] = useState([])
    const [locations, setLocations] = useState([])
    const [appHowTo, setAppHowTo] = useState("")
    const [screenshot, setScreenshot] = useState([])
    const [video, setVideo] = useState([])
    const [isScreenShot, setIsScreenShot] = useState(false);
    const [isVideo, setIsVideo] = useState(false)
    const [tags, setTags] = useState([]);
    const [orientation, setorientation] = useState(true);
    const [genreOption, setGenreOption] = useState([])
    const [platformOption, setPlatformOption] = useState([])
    const [locationOption, setLocationOption] = useState([])
    const [checkObject, setCheckObject] = useState()

    const [catIndex,setCatIndex]= useState(null)

    // const extractImageFromCdn = async(appIcon) => {
    //     if(appIcon !== null){
    //         const token = localStorage.getItem('token')
    //         // const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    //         const headers = {headers : {"Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    //         let res = await axios.get(appIcon, headers)
    //         console.log(res)
    //     }
    // }

    const extractImageFromCdn = async(appIcon) => {
        if(appIcon !== null && appIcon.includes('cdn')){
            console.log(appIcon, 'App Icon')
            try {
                const apiKey =  (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)
                const imageUrl = appIcon;
                const response = await axios.get(appIcon, {
                  headers: {
                    'Api-Key': apiKey, // Custom header for authorization
                  },
                  responseType: 'blob', // Ensure the response is treated as a binary file
                });
                console.log(response, 'Response')
                // Create a URL for the image blob
                const imageBlobUrl = URL.createObjectURL(response.data);
                // Example: Set the image blob URL to an <img> tag
                const imgElement = document.getElementById('image-display');
                if (imgElement) {
                  imgElement.src = imageBlobUrl;
                }
            }catch (error){
                console.error('Error fetching image:', error);
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            extractImageFromCdn(src)
        }, 500)
    }, [src])

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setAppIcon(res.data.getUrl)
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        });
    }



    const onSelectedCategoryLogo = async(e,i,x) =>{
        const tempCategory=[...platform]
        console.log("platformCategory",tempCategory,x,i)
        tempCategory[i].assetBundleUrl=e
        tempCategory[i]['assetName'] = x.name
        setPlatform(tempCategory)
    }

    const progressToastId = useRef(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadingMedia, setUploadingMedia] = useState([]);




    // A) Add item
    const addUploadingItem = (file) => {
        const newItem = { fileName: file.name, progress: 0 };
        setUploadingMedia((prev) => [...prev, newItem]);
        return newItem;
    };
    
    // B) Update item
    const updateUploadingItem = (fileName, progress) => {
        setUploadingMedia((prev) =>
        prev.map((item) =>
            item.fileName === fileName ? { ...item, progress } : item
        )
        );
    };
    
    // C) Remove item
    const removeUploadingItem = (fileName) => {
        setUploadingMedia((prev) =>
        prev.filter((item) => item.fileName !== fileName)
        );
    };


    // Show a toast if one doesn’t already exist
  const startProgressToast = () => {
    // if (!progressToastId.current) {
    //   progressToastId.current = toast(
    //     <Toast type="Info" messages="Starting upload..." />,
    //     {
    //       autoClose: false,
    //       closeOnClick: false,
    //       draggable: false,
    //       progress: 0,
    //     }
    //   );
    // }
  };


  const handleUploadProgress = (progressEvent,name) => {
    // if (!progressToastId.current) return; // no toast to update yet

    const { loaded, total } = progressEvent;
    const percent = Math.floor((loaded * 100) / total);
    updateUploadingItem(name, percent);
    // const fraction = percent / 100; // toastify expects a fraction (0-1)

    // toast.update(progressToastId.current, {
    //   progress: fraction,
    //   render: (
    //     <Toast
    //       type="Info"
    //       messages={`Uploading file... ${percent}%`}
    //     />
    //   ),
    // });
  };

  const dismissProgressToast = () => {
    // if (progressToastId.current) {
    //   toast.dismiss(progressToastId.current);
    //   progressToastId.current = null;
    // }
  };
    

    const uploadCategoryLogo = async(e,i,getMedia) =>{
        var file1 =null
        file1 = e.target.files[0];
        startProgressToast();
        addUploadingItem(file1);
        const file_binary1 = await convertbase64(file1)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file1.type}&projectId=${appid}&category=${`bundles`}&fileName=${file1.name}`)

        const tempCategory=[...platform]
        tempCategory[i].assetBundleUrl=res.data.getUrl;
        tempCategory[i]['assetName'] =  file1.name
        e.target.value=null
        setPlatform(tempCategory);
        var config1 = {
            headers : {'Content-Type' : `${file1.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary1),
            onUploadProgress: ((z)=> handleUploadProgress(z, file1.name)),
        }
        axios(config1)
        .then(function (response) {
            let setSidebar=true
            if(setSidebar){
                let uploadLogo = {
                    projectId : appid,
                    contents : [{
                        contentType : file1.type,
                        fileName : file1.name,
                        category : "bundles",
                        url : res.data.getUrl,
                        fileSize : file1.size/1024
                    }]
                }
                dispatch(addMediaAction(uploadLogo, null, null, appid, 'bundles',false))
                // setSidebar(false)
            }
            removeUploadingItem(file1.name);
            setUploadProgress(0);
            getMedia()
            setTimeout(()=>{
                dismissProgressToast();
            },[1500])
        })
        .catch(function (error) {
            console.log(error)
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        });
    }

    const [visibleCategorySidebar, setVisibleCategorySidebar] = useState(false)

        // To Convert Base64 to Binary
        function convertDataURIToBinary(dataURI) {
            var BASE64_MARKER = ';base64,';
            var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));
              
            for(var i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            return array;
        }
        
        // To Convert file into base64 format
        const convertbase64 = (file) => {
            return new Promise((resolve, err)=>{
            const fileReader = new FileReader();
            fileReader.onload = (eve) =>{
                resolve(fileReader.result)
            }
            fileReader.readAsDataURL(file)
            })
        }

    const Cancel = () => {
        setAppIcon(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        logoRef.current.value = ''
    }

    const onAppName = (e) => {
        if(e.target.value == ''){
            // errors.nameError = 'Display name is required'
            setAppName("")
        }else{
            errors.nameError = ''
            setAppName(e.target.value)
        }
    }

    const onAppGerne = (e) => {
        setAppGerne(e.target.value)
    }

    const onAppDescription = (e) => {
        setAppDescription(e.target.value)        
    }

    const uploadFile = async(e) => {
        try{
            var file = e.target.files[0];   
            const file_binary = await convertbase64(file)
            const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=octet-stream`)
            var config = {
                headers : {'Content-Type' : `${file.type}`},
                method: 'put',
                url: res.data.postUrl,
                data :convertDataURIToBinary(file_binary),
            }
            let response = await axios(config)
            if(response){
                toast.success('File Uploaded Successfully')
                if(response.status == 200){
                    return res.data.getUrl
                }
            }
            return false
        }catch(err){
            return false
        }
    }

    const removeFile = (index) => {
        let platformList = [...platform]
        platformList[index].assetName = ''
        platformList[index].assetBundleUrl = ''
        setPlatform(platformList)
    }

    const onChangePlatform = async(e, index, type, id) => {
        let platformList = [...platform]
        if(type == 'file'){
            let res = await uploadFile(e)
            platformList[index][e.target.name] = res
            platformList[index]['assetName'] = e.target.files[0].name
            setPlatform(platformList)
        }else{
            if(/^\d*\.?\d\d*\.?\d*$/.test(e.target.value)){
                platformList[index][e.target.name] = e.target.value
                setPlatform(platformList)
            }if(e.target.value == ''){
                platformList[index][e.target.name] = ''
                setPlatform(platformList)
            }
        }
    }

    const onRemove = (selectedList, removedItem) => {
        setPlatform(oldValues => {return oldValues.filter(platform => platform.name !== removedItem.name)})
    }

    const onAppHowTo = (e) => {
        setAppHowTo(e.target.value)
    }

    const removeScreenShots = (index) => {
        let screenshotList = [...screenshot]
        let screenshotFields = screenshotList.filter((e,i,c)=>{
            return i != index
        })
        screenshotRef.current.value = ''
        setScreenshot(screenshotFields)
    }

    const removeVideos = (index) => {
        let videoList = [...video]
        let videoFields = videoList.filter((e,i,c)=>{
            return i != index
        })
        videoRef.current.value = ''
        setVideo(videoFields)
    }

    const onTagInput = () => {
        if(document.getElementById('tagsInput')){
            document.getElementById('tagsInput').focus()
        }
    }

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleInputChange = (e) => {

    }

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]);
        }else{
            toast.warning(<Toast type='Warning' messages='Only 10 tags are allowed'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        }
    };
  
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }


    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const getGameDetail = async() => {
        let getGameByID = {
            projectId : appid,
            ids : [id]
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_GAMES, getGameByID, headers)
        if(res){
            let gameDetail = res.data.data.gameDetails[0]
            setCheckObject(gameDetail)
            setorientation(gameDetail.isGameScreenOrientationLandscape)
            setAppName(gameDetail.name)
            setGameId(gameDetail.gameId)
            setLogo({src: (gameDetail.logo != null ? gameDetail.logo : Game), alt: 'Upload an Image'}); 
            setAppIcon(gameDetail.logo)
            setAppDescription(gameDetail.description)
            setAppHowTo(gameDetail.howTo)
            setIsScreenShot(gameDetail.screenShotUrl == null ? false : true)
            setIsVideo(gameDetail.previewVideoUrl == null ? false : true)
            setScreenshot(gameDetail.screenShotUrl != null ? gameDetail.screenShotUrl : [])
            setVideo(gameDetail.previewVideoUrl != null ? gameDetail.previewVideoUrl : [])
            gameDetail.gameGener && gameDetail.gameGener.forEach((e,i) => {
                setAppGerne(prevState => [...prevState, {id : e.gameGenreMaster.id, name : (e.gameGenreMaster.genreName[0].toUpperCase() + e.gameGenreMaster.genreName.slice(1))}])
            })
            console.log(gameDetail.gamePlatforms)
            gameDetail.gamePlatforms && gameDetail.gamePlatforms.forEach((e,i) => {
                setPlatform(prevState => [...prevState, {id: e.id, platformId : e.gamePlatformMasterId, name : ((e.name !== 'ios' && e.name !== 'iOS') ? e.name.charAt(0).toUpperCase() + e.name.slice(1) : (e.name === 'ios' || e.name === 'iOS') ? 'iOS' : '-'), assetName : e.assetName, assetBundleUrl : e.assetBundleUrl, assetBundleVersion : e.assetBundleVersion, minimumGameVersion : e.minimumGameVersion}])
            })

            
            gameDetail.countries && gameDetail.countries.forEach((e,i)=>{
                setLocations(prevState => [...prevState, {id : e.countryId, name : e.countryMaster.name}])
            })

            gameDetail.tagsMapping && gameDetail.tagsMapping.forEach(e => {
                e.tag && setTags(tags => [...tags, {id : e.tag.id, text : e.tag.name}])
            });

            let meta = []
            if(gameDetail.meta == null || JSON.stringify(gameDetail.meta) == '{}'){
                setMetaData([{key : '', value : null}])
            }else{
                gameDetail.meta && Object.keys(gameDetail.meta).forEach(e => {
                    meta.push({key : e, value : gameDetail.meta[e]})
                })
                setMetaData(meta)
            }

        }
    }

    useEffect(() => {
        getOptionalData()
        getGameDetail()
    }, [appid])

    const getOptionalData = async() => {
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await axios.get(`${BASE_URL}/master-data`, headers)
        setLocationOption(res.data.data.countryMaster)

        let Platforms = []
        res.data.data.gamePlatforms && res.data.data.gamePlatforms.map((e, i) =>{
            Platforms.push({platformId : e.id, name : (e.platformName !== 'ios' ? e.platformName.charAt(0).toUpperCase() + e.platformName.slice(1) : e.platformName === 'ios' ? 'iOS' : '-'), assetName : '', assetBundleUrl : '', assetBundleVersion : '', minimumAppVersion : ''})
        })
        setPlatformOption(Platforms)
        let genre = []
        res.data.data.gameGenerMaster && res.data.data.gameGenerMaster.forEach((e,i) => {
            genre.push({id : e.id, name : (e.genreName[0].toUpperCase() + e.genreName.slice(1))})
        })
        setGenreOption(genre)
    }

    const [gameId, setGameId] = useState('')
    const onGameId = (e) => {
        if(e.target.value == ''){
            // errors.idError = 'Game ID is required'
            setGameId("")
        }else{
            errors.idError = ''
            setGameId(e.target.value)
        }
    }

    const [errors, setErrors] = useState({nameError : '', idError : '', gerneError : '', platformError : ''})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if (data.name && data.name == '') {
            error.nameError = 'Display Name is required';
            isValid = false;
            const element = document.getElementById('gameName');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if (data.gameId && data.gameId == '') {
            error.idError = 'Game ID is required';
            isValid = false;
            if(data.name && data.name != '') {
                const element = document.getElementById('gameId');
                const offset = 140;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        if (data.gameGenre && data.gameGenre?.gameGenerId.length == 0) {
            error.gerneError = 'Game Genre is required';
            isValid = false;
            if((data.name && data.name != '') && (data.gameId && data.gameId != '')){
                const element = document.getElementById('gameGenre');
                const offset = 100;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        if (data.gamePlatforms && data.gamePlatforms.length == 0) {
            error.platformError = 'Game Platform is required';
            isValid = false;
            if((data.name && data.name != '') && (data.gameId && data.gameId != '') && (data.gameGenre && data.gameGenre?.gameGenerId.length > 0)){
                const element = document.getElementById('gamePlatform');
                const offset = 100;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }

    const compareMeta = (meta1, meta2) => {
        const keys1 = Object.keys(meta1);
        const keys2 = Object.keys(meta2);
        // If the number of keys or their values differ, return the whole meta2
        if (keys1.length !== keys2.length || !_.isEqual(meta1, meta2)) {
            return meta2; // Return the whole meta2 object
        }
        // Return null if there are no changes
        return null;
    }

    const filterChanged = (obj1, obj2) => {
        return _.reduce(obj2, (result, value, key) => {
            // Always include 'id' and 'uuid' keys
            if (key === 'id' || key === 'projectId') {
              result[key] = value;
            }
            // Special case: compare 'tags' in obj2 with 'tagsMapping' in obj1
            else if (key === 'tags') {
                const tagsFromMapping = obj1['tagsMapping'] && obj1['tagsMapping'].map(mapping => mapping.tag.name);
                if (!_.isEqual(tagsFromMapping, value)) {
                  result[key] = value; // map tags changes to 'tagsMapping'
                }
            }
            // Compare gameGeo
            else if (key === 'gameGeo' || key === 'gameGenre') {
                const obj1Data = key === 'gameGeo' ? obj1.countries && obj1.countries.map(country => country.countryId) : key === 'gameGenre' ? obj1.gameGener && obj1.gameGener.map(genre => genre.gameGenerId) : null
                const obj2Data = key === 'gameGeo' ? value.countryId || [] : key === 'gameGenre' ? value.gameGenerId || [] : null;
                if (!_.isEqual(obj1Data, obj2Data)) {
                    result[key] = value;
                }
            }
            // Compare nested objects (like 'meta')
            else if (key === 'meta' && _.isObject(value) && _.isObject(obj1[key])) {
                const metaChanges = compareMeta(obj1.meta, obj2.meta);
                if (metaChanges) {
                    result.meta = metaChanges; // Include the whole new meta if there are changes
                }
            }
            // Normal comparison: add the key if values differ
            else if (!_.isEqual(obj1[key], value)) {
              result[key] = value;
            }
            return result;
        }, {})
    }

    const db = getFirestore(app);
    
    const saveApp = async() => {
        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let finalTag = []
        tags && tags.forEach((e)=>{finalTag.push(e.text)})

        let countryId = []
        locations && locations.filter((e,i) => countryId.push(e.id))

        let appGerneId = []
        appGerne && appGerne.filter((e,i) => appGerneId.push(e.id))

        let editGameDetails = {
            projectId : appid,
            id : id,
            isDefault : false,
            gameId: gameId,
            name: appName,
            isGameScreenOrientationLandscape:orientation,
            description: appDescription,
            howTo : appHowTo,
            logo: appIcon,
            ...(screenshot.length > 0 && {screenShotUrl : screenshot}),
            ...(video.length > 0 && {previewVideoUrl : video}),
            gameGenre: {gameGenerId: appGerneId},
            gamePlatforms: platform && platform.map((obj) => {return {id : obj.id, name: obj.name, assetBundleUrl: (obj.assetBundleUrl==""?null:obj.assetBundleUrl),assetName:(obj.assetName==""?null:obj.assetName), assetBundleVersion: (obj.assetBundleVersion==""?null:obj.assetBundleVersion), minimumGameVersion: ((obj.minimumGameVersion==undefined||obj.minimumGameVersion==''||obj.minimumGameVersion==null)?null:obj.minimumGameVersion), gamePlatformMasterId: obj.platformId}}),
            gameGeo: {countryId: countryId},
            meta : finalMetaData,
            tags : finalTag
        }
        let games = await filterChanged(checkObject, editGameDetails)
            if(validation(games)){
                dispatch(editGameAction(games, navigate, appid, globalStates.setShowOverlay))
                // Access the response data (e.g., game ID)
                 
                    setIsChanged(false);
                    const user = JSON.parse(localStorage.getItem('user'))
                    const memberID = user?.id
                    const memberName = user?.name
                    const orgId = (localStorage.getItem('orgId'))
    
                    // Log activity in Firestore
                    const activityLog = {
                        timestamp: new Date().toISOString(),
                        action_type: "UPDATE",
                        app_id:appid,
                        resource: "GAME",
                        resource_id: id, // Use the ID from response
                        description: `Game '${appName}' updated successfully`,
                        quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
operation_status: "SUCCESS",
                        activity_feed: true,
                        priority: "LOW",
                        performed_by_id: memberID,
                        organization_id: orgId,
                        performed_by:memberName
                    };
                    await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
                    console.log("Activity logged successfully");
                
            }
    }
    
    const [visibleHelp, setVisibleHelp] = useState(false)
    const [visibleVideo, setVisibleVideo] = useState(false)
    const [visibleImage, setVisibleImage] = useState(false)

    const [media, setMedia] = useState([])
    const [type, setType] = useState(0)

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : type
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })

    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }

    useEffect(() => {
        getMedia()
    }, [type])

    useEffect(() => {
        configureMedia()
    }, [files])

    function compareGenreArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length !== array2?.length) {
            return false
        }
        for (let i = 0; i < array1.length; i++) {
            if (
                (parseInt(array1[i]?.id)===array2[i]?.gameGenreMaster.id|| array2[i]?.gameGenreMaster.id==undefined|| array2[i]?.gameGenreMaster.id==null)
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }

    function compareScreenshotArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length==0 &&(array2==null || array2==undefined)) {
            return true
        }
        if (array1?.length !== array2?.length) {
            return false
        }
        for (let i = 0; i < array1.length; i++) {
            if (
                (array1[i]==array2[i]|| ((array2[i]==undefined|| array2[i]==null)&&array1.length==0))
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }

    function comparePlatformArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length !== array2?.length) {
            return false
        }
        for (let i = 0; i < array1.length; i++) {
            if (
                (parseInt(array1[i]?.id)===array2[i]?.gamePlatformMasterId.id|| array2[i]?.gamePlatformMasterId.id==undefined|| array2[i]?.gamePlatformMasterId.id==null)&&
                (array1[i].assetBundleUrl ==array2[i]?.assetBundleUrl)&&
                (array1[i].assetBundleVersion ==array2[i]?.assetBundleVersion)&&
                (array1[i].minimumGameVersion ==array2[i]?.minimumGameVersion)
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }

    function compareLocationArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length !== array2?.length) {
            return false
        }
        for (let i = 0; i < array1.length; i++) {
            if (
                (parseInt(array1[i]?.id)===array2[i]?.countryMaster.id|| array2[i]?.countryMaster.id==undefined|| array2[i]?.countryMaster.id==null)
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }
    
    const cancel = () => {
        navigate(`/games/${appid}`)
        setIsChanged(false)
    }

    const setchange = () => {

        let metaDataList = [...metaData];
        let finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let itemTags = []
        tags && tags.forEach((e)=>{
            itemTags.push(e.text)
        })

        let checkTags = []
        checkObject?.tagsMapping && checkObject?.tagsMapping.forEach((e)=>{
            if (e.tag!==null&&e.tag!==undefined) {
            checkTags.push(e?.tag?.name)
            }
        })

        let locationcheck = checkObject&& ((checkObject.countries&& compareLocationArrays(locations, checkObject.countries))||!checkObject?.countries)
        let platformcheck = checkObject&& ((checkObject.gamePlatforms&& comparePlatformArrays(platform, checkObject.gamePlatforms))||!checkObject?.gamePlatforms)
        let genrecheck = checkObject&& ((checkObject.gameGener&& compareGenreArrays(appGerne, checkObject.gameGener))||!checkObject?.gameGener)
        let screenshotCheck = checkObject&& (( compareScreenshotArrays(screenshot, checkObject.screenShotUrl)))
        let videoCheck = checkObject&& (( compareScreenshotArrays(video, checkObject.previewVideoUrl)))
    
        
        if (checkObject&&
            (appIcon == checkObject.logo)&&
            (appName == checkObject.name)&&
            (gameId == checkObject.gameId)&&
            (appDescription == checkObject.description||((appDescription===undefined||appDescription==''||appDescription=="")&&((checkObject.description===undefined||checkObject.description==''||checkObject.description==""||checkObject.description==null))))&&
            (screenshotCheck)&&
            (videoCheck)&&
            (genrecheck)&&
            (platformcheck)&&
            (locationcheck)&&
            (orientation==checkObject.isGameScreenOrientationLandscape||checkObject.isGameScreenOrientationLandscape==null||checkObject.isGameScreenOrientationLandscape==undefined)&&
            (appHowTo==checkObject.howTo)&&
            // ((JSON.stringify(finalMetaData) == JSON.stringify(checkObject.meta))||((JSON.stringify(finalMetaData)=='{"":""}' || JSON.stringify(finalMetaData) === JSON.stringify({"" : null}))&&(checkObject.meta==null)))&&
            (JSON.stringify(itemTags) == JSON.stringify(checkTags)|| (checkObject.tagsMapping==null && itemTags.length==0))
        )
        {
            setIsChanged(false)
        }
        else 
        {
            setIsChanged(true)
        }
    }

    useEffect(()=>{
        setchange()
        
    },[appIcon,gameId,appName,appGerne,appDescription,platform,locations,appHowTo,screenshot,video,isScreenShot,isScreenShot,isVideo,tags,orientation,metaData])

    function getFileNameFromUrl(url) {
        // Split the URL by '/' and take the last segment
        const segments = url.split('/');
        return segments[segments.length - 1];
      }

    return (
        <>
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-purple">
            <div className={styles.description}>
                    <div className={styles.preview}>
                        <div className={styles.previewImage}>
                            <img id="image-display" alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                        </div>
                        <div className={styles.previewInput}> 
                            <input type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={logoRef} onChange={(e)=>{uploadLogo(e)}}/>
                            <label className={styles.button} onClick={() => {setVisibleHelp(true); setType(0)}}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>
                        </div>
                        {appIcon != null ?
                            <div className={styles.previewCancel}>
                                <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                            </div>
                        : ''}
                    </div>
                    <FileUploadSidebar media={media} setMedia={setMedia} types='Images' id={`icon`} multiple={false} visible={visibleHelp} setVisible={setVisibleHelp} icon={appIcon} setIcon={setAppIcon} setValue={setLogo} onClose={onClose} />
                    <div className={styles.group}>
                        <TextInput id='gameName' className={styles.field} tooltip={TooltipTitle.GameName} errorMessage={errors.nameError} label="Game Name*" name="gameName" type="text" required value={appName} onChange={(e)=>{onAppName(e)}}/>
                        <TextInput isId={true} id='gameId' className={styles.field} tooltip={TooltipTitle.GameId} errorMessage={errors.idError}  label="Game ID*" name="gameID" type="text" required value={gameId} onChange={(e)=>{onGameId(e)}}/>
                    </div>
                    <div className={styles.createItem}>   
                    <div className={styles.groupItem}>
                        <div className={styles.lockGroup}>
                            <GenreDropdown id='gameGenre' className={styles.selexctField} errorMessage={errors.gerneError} label="Select Genre*" tooltip={TooltipTitle.GameGenre} placeholder="Select Genre" options={genreOption} value={appGerne} setValue={setAppGerne}/>
                        </div>                
                        <div className={styles.RGroup}>
                                <div className={styles.radioLabel}>
                                    <span>Screen Orientation</span>
                                </div>
                                <div className={styles.variants}>
                                    <Radio className={styles.radio} name="orientation" value={orientation} onChange={() => setorientation(true)} content="Landscape" />
                                    <Radio className={styles.radio} name="orientation" value={!orientation} onChange={() => setorientation(false)} content="Portrait" />
                                </div>
                        </div>
                    </div>
                </div>
                    <div className={styles.textGroup}>
                        <TextArea type='GAME_DESCRIPTION' tooltip={TooltipTitle.GameDescription} rows={5} label="Description" value={appDescription != null ? appDescription : ''} onChange={(e)=>{onAppDescription(e)}}/>
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Technical Details" classTitle="title-red">
                <div className={styles.cardDescription}>   
                    <div className={styles.appInformation}>
                        <div className={styles.appInformation}>
                            <PlatformDropdown tooltip={TooltipTitle.GamePlatform} id='gamePlatform' errorMessage={errors.platformError} label="Select Platform*" placeholder="Choose Game Platform" options={platformOption} value={platform} setValue={setPlatform} />
                        </div>
                    </div>
                    <FileUploadSidebar type={"store"} catIndex={catIndex} folderName='bundles' customUploadFunction={uploadCategoryLogo} customUploadingMedia={uploadingMedia} customSelectMediaFunction={onSelectedCategoryLogo} media={media} setMedia={setMedia} types='Images' id={`asset${'-'}`} multiple={false} visible={visibleCategorySidebar} setVisible={setVisibleCategorySidebar} onClose={onClose} />
                    <div className={styles.appInformation}>
                        {platform && platform.map((e,i)=>{
                            return(
                                <>
                                    <div key={e.name} className={styles.platformContainer}>
                                        <div className={styles.platformHeading}>
                                            <span>{e.name !== 'ios' ? e.name : "iOS"}</span>
                                        </div>
                                        <div className={styles.groupByThree}>
                                            <div className={styles.field}>
                                                <div className={styles.bundleLabel}><span>Asset Bundle</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.GameAssetBundle} icon="info"/></span></div>
                                                {(e.assetBundleUrl == '' ||e.assetBundleUrl == null)&&
                                                    <>
                                                        {/* <input type="file" id={`files-${i}`} className='file-input' name='assetBundleUrl' onChange={(evt)=>{onChangePlatform(evt, i, 'file', e.platformId)}}/>
                                                        <label for={`files-${i}`} className={styles.uploadBundleButton}><span className={styles.label}>Upload Bundle</span><span className={styles.icon}><UploadFile /></span></label> */}
                                                        <label for={`files-${i}`} className={styles.uploadBundleButton} onClick={() => {setCatIndex(i);setVisibleCategorySidebar(true)}}><span className={styles.label}>Upload Bundle</span><span className={styles.icon}><UploadFile /></span></label>                                                        
                                                    </>
                                                }
                                                {e.assetBundleUrl != ''&&e.assetBundleUrl != null &&
                                                    <>
                                                        <label for={`files-${i}`} className={styles.uploadedFile}>
                                                            {/* <input type="file" id={`files-${i}`} name='assetBundleUrl' className='file-input' onChange={(evt)=>{onChangePlatform(evt, i, 'file', e.platformId)}}/> */}
                                                            <label for={`files-${i}`} className={styles.fileInput}><span className={styles.label} title={e.assetName} onClick={() => {setCatIndex(i);setVisibleCategorySidebar(true)}}>{e.assetName}</span></label>
                                                            <label className={styles.fileRemove}><span className={styles.icon} onClick={()=>{removeFile(i)}}><Icon className={styles.close} name='close-circle' size='24'/></span></label>
                                                        </label>
                                                    </>
                                                }
                                                </div>
                                                <TextInput tooltip={TooltipTitle.GameAssetBundleVerion} key={i} className={styles.field} label="Asset Bundle Version" name="assetBundleVersion" type="text" value={e.assetBundleVersion} onChange={(evt)=>{onChangePlatform(evt,i)}}/>
                                                <TextInput tooltip={TooltipTitle.GameMinimumVersion} key={i} className={styles.field} label="Minimum App Version" name="minimumGameVersion" type="text" value={e.minimumGameVersion}  onChange={(evt)=>{onChangePlatform(evt,i)}} />
                                                <span className={styles.remove} onClick={()=>{onRemove(platform, e)}}><Remove className={styles.icon}/></span>
                                            </div>
                                            {e.assetBundleUrl != '' ? 
                                                <div className={styles.platformUrl}>
                                                    <a className={styles.bundleUrl} href={e.assetBundleUrl}>Download file</a>
                                                </div>
                                            : ''}
                                        </div>
                                    </>
                                )
                            })}
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Location" classTitle="title-blue">
                <div className={styles.cardDescription}>
                    <div className={styles.appInformation}>
                        <div className={styles.appInformation}>
                            <LocationDropdown tooltip={TooltipTitle.GameLocation} label="Choose Location" placeholder="Choose Location" options={locationOption} value={locations} setValue={setLocations} />
                        </div>
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Extra" classTitle="title-red">
                <div className={styles.cardDescription}>
                    <div className={styles.appInformation}>
                        <div className={styles.screenshot}>
                            <div className={styles.line}>
                                <div className={styles.info}>
                                    Add Screenshots<span><Tooltip className={styles.tooltip} title={TooltipTitle.GameScreenshot} icon="info"/></span>
                                </div>
                                <Switch className={styles.switch} value={isScreenShot} onChange={() => setIsScreenShot(!isScreenShot)} />
                            </div>
                            {isScreenShot ?
                                <div onClick={() => {setVisibleImage(true); setType(0)}}>
                                    <FileImages type="image" className={styles.field} title="Click or drop image" label="Cover images" setValue={setScreenshot} value={screenshot} data={screenshotRef}/>
                                </div>
                            : ''} 
                            <FileUploadSidebar media={media} setMedia={setMedia} types={type} id={`screenshot`} multiple={true} visible={visibleImage} setVisible={setVisibleImage} icon={screenshot} setIcon={setScreenshot} value={screenshot} setValue={setScreenshot} onClose={onClose} />
                            {isScreenShot && screenshot &&
                                <>
                                    <div className={styles.screenshotPreview}>
                                        <div className={styles.previewRow}>
                                            {screenshot && screenshot.map((e,i)=>{
                                                return(
                                                    <>
                                                        <div className={styles.previewCol}>
                                                            <div className={styles.previewContainer}>
                                                                <div className={styles.imgPreview}>
                                                                    <ImagePlaceholder />
                                                                </div>
                                                                <div className={styles.previewContent}>
                                                                    <div className={styles.screenshotTitle}>
                                                                        {/* {`screenshot - ${i+1}`} */}
                                                                        {getFileNameFromUrl(e)}
                                                                    </div>
                                                                    <div className={styles.precentageContainer}>
                                                                        <div id={`percentage${i+1}`} className={styles.percentage}>
                                                                            <div id ={`successfull${i+1}`} className={styles.successfull}></div>
                                                                        </div>
                                                                        <div className='progress-bar-images'></div>
                                                                        <div id={`view${i+1}`} className={styles.view}><Control type='Images' src={e} /></div>
                                                                    </div>
                                                                </div>
                                                                <div className={styles.close} onClick={(e)=>{removeScreenShots(i)}}>
                                                                    <Icon name="close" size="14"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </>
                            }
                        </div> 

                        <div className={styles.screenshot}>
                            <div className={styles.line}>
                                <div className={styles.info}>
                                    Add Video<span><Tooltip className={styles.tooltip} title={TooltipTitle.GameVideos} icon="info"/></span>
                                </div>
                                <Switch className={styles.switch} value={isVideo} onChange={() => setIsVideo(!isVideo)} />
                            </div>
                            {isVideo ?
                                <div onClick={() => {setVisibleVideo(true); setType(1)}}>
                                    <FileVideos type="video" className={styles.field} title="Click or drop videos" label="Cover videos" setValues={setVideo} value={video} data={videoRef}/>
                                </div>
                            : ''} 
                            <FileUploadSidebar media={media} setMedia={setMedia} types={type} id={`videos`} multiple={true} visible={visibleVideo} setVisible={setVisibleVideo} icon={video} setIcon={setVideo} value={video} setValue={setVideo} onClose={onClose} folderName='videos'/>
                            {isVideo && video &&
                                <>
                                    <div className={styles.screenshotPreview}>
                                        <div className={styles.previewRow}>
                                            {video && video.map((e,i)=>{
                                                return(
                                                    <>
                                                        <div className={styles.previewCol}>
                                                            <div className={styles.previewContainer}>
                                                                <div className={styles.imgPreview}>
                                                                    <VideoPlaceholder />
                                                                </div>
                                                                <div className={styles.previewContent}>
                                                                    <div className={styles.screenshotTitle}>
                                                                        {/* {`video - ${i+1}`} */}
                                                                        {getFileNameFromUrl(e)}
                                                                    </div>
                                                                    <div className={styles.precentageContainer}>
                                                                        <div id={`percentage_video${i+1}`} className={styles.percentage}>
                                                                            <div id = {`successfull_video${i+1}`} className={styles.successfull}></div>
                                                                        </div>
                                                                        <div className='progress-bar-videos'></div>
                                                                        <div id={`view_video${i+1}`} className={styles.view}><Control type='Videos' src={e} /></div>
                                                                    </div>
                                                                </div>
                                                                <div className={styles.close} onClick={(e)=>{removeVideos(i)}}>
                                                                    <Icon name="close" size="14" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </>
                            }
                        </div> 

                        <div className={className.video}>
                            <TextArea type='GAME_HOW_TO' tooltip={TooltipTitle.GameHowTo} rows={5} label="How-to" value={appHowTo} onChange={(e)=>{onAppHowTo(e)}} />
                        </div>      
                    </div>
                </div>
            </Card>
            
            <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                <div className={styles.cardDescription}>
                    
                <TagInputWithDropdown tooltip={TooltipTitle.GameTags} tags={tags} setTags={setTags}/>

                    <div className={styles.appInformation}>
                        <div className={styles.groupHeading}>
                            <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.GameMetaData} icon="info"/></span><span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>
                        </div>

                        {metaData && metaData.length > 0 &&
                            <div className={styles.groupMeta}>
                                <div className={styles.field}>Key</div>
                                <div className={styles.field}>Value</div>
                            </div>
                        }
                        
                        <div className={styles.groupMeta}>
                            {metaData && metaData.map((e,i)=>{
                                return(
                                    <>
                                        <TextInput className={styles.field} label="" name="key" type="text" required value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''} onChange={(e)=>{onChangeKey(e,i)}}/>
                                        <JSONMetaDataValueInput key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                        {/* <TextInput className={styles.field} label="" name="value" type="text" value={(metaData[i].value != '' || metaData[i].value != undefined || metaData[i].value != null) ? metaData[i].value : ''} required onChange={(e)=>{onChangeValue(e,i)}}/> */}
                                        <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><Remove className={styles.icon}/></span>
                                    </>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)}>
                <div className={styles.cardAction}>
                    <div className={styles.cardSave}>
                        <button className={cn(styles.button, 'button-save-small')} onClick={saveApp}>Save</button>
                    </div>
                    <div className={styles.cardCancel}>
                        <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default GameInfo