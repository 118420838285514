import React, { useEffect, useState } from "react";
import styles from './addOns.module.sass'
import classNames from "classnames";
import cn from 'classnames'
import Icon from "../../../../components/Icon";
import Checkbox from "../../../../components/Checkbox";
import { buyAddOnsAction, buyNewPlanAction, getAddOnsAction, getPlansAction, getPriceBookAction, verifyBillingAction } from "../../../../redux/action/organisation/Billing/billingAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../../components/Loaders/LoaderProgress";
import Loaders from "../../../../components/Loader";
import BillingHeader from "../BillingHeader";

const AddOn = ({className, plans, priceBook, currencyType, selected, showAddOn, addOnType, showOverlay, setShowOverlay}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const Location = useLocation()
    const [addOn, setAddOn] = useState([])
    const [currency, setCurrency] = useState(currencyType || null)
    const [priceId, setPriceId] = useState(selected || null)

    const {id} = useParams()

    const onAddOns = (id) => {
        setAddOn(addOn => addOn && addOn.map(e => e.addonCode === id ? {...e, active: !e.active, quantity: 1} : {...e}))
    }

    const onAdd = (id) => {
        setAddOn(addOn => addOn && addOn.map(e => e.addonCode === id ? {...e, quantity: e.quantity + 1} : {...e, quantity: e.quantity}))
    }

    const onRemove = (id) => {
        setAddOn(addOn => addOn && addOn.map(e => e.addonCode === id ? {...e, quantity: (e.quantity !== 1 ? e.quantity - 1 : 1)} : {...e, quantity: e.quantity}))
    }

    const savePlan = () => {
        let customerId = JSON.parse(sessionStorage.getItem('customerId'))
        let gstTreatment = JSON.parse(sessionStorage.getItem('gstTreatment'))
        let isGST = JSON.parse(sessionStorage.getItem('isGST'))
        const plan = {
            gstTreatment: gstTreatment,
            customerId: customerId,
            planDetails : plans && plans.filter(e => e.selected).map(item => ({ planCode: item.planCode, quantity: 1 }))[0],
            ...(showAddOn && {type: addOnType}),
            ...(showAddOn && {addonsDetails : addOn && addOn.filter(e => e.active).map(item => ({ addonCode: item.addonCode, quantity: item.quantity, price: item.price }))})
        }
        dispatch(verifyBillingAction(plan, navigate, isGST, setShowOverlay))
    }

    const getAllAddOns = () => {
        dispatch(getAddOnsAction())
    }

    const configureAddOns = () => {
        let updatedAddOns = addOns && addOns.map(x => {
            const matchObj = priceBook?.pricebook_items && priceBook?.pricebook_items.find(y => x.addonCode === y.product_code)
            return{
                ...x,
                price: matchObj ? matchObj.pricebook_rate : x.priceBrackets[0]?.price
            }
        }).filter(x => x.type === (addOnType === 'one-time' ? 'one_time' : addOnType))
        setAddOn(updatedAddOns && updatedAddOns.map(e => ({...e, quantity: 0, active: false})))
    }

    let {addOns, isLoadingAddOns, price} = useSelector(state =>  state.billing)

    let {orgProfile} = useSelector(state => state.organisation)

    const getAllPriceBook = () => {
        let priceBook = {}
        dispatch(getPriceBookAction(priceBook))
    }

    useEffect(() => {
        getAllAddOns()
    }, [])

    useEffect(() => {
        configureAddOns()
    }, [addOns, price, priceBook, priceId, addOnType])

    if(isLoadingAddOns){
        return(
            <>
                <div className={styles.loader}>
                    <Loader />
                </div>
            </>
        )
    }

    console.log(addOnType, 'Add On Type')

    return(
        <>
            <div className={cn(styles.container)}>
                <div className={styles.billings}>
                    <div className={styles.addOns}>
                        {showAddOn &&
                            <>
                                <div className={styles.title}>{addOnType === 'one-time' ? 'Top-ups' : 'Add-ons'}</div>

                                <div className={styles.addOnsList}>
                                    {addOn && addOn.map(e => (
                                        <div className={cn(styles.addOn)}>
                                            <div className={styles.addOnDetail}>
                                                <div className={styles.detail}>
                                                    <div className={styles.name}>{e.name}</div>
                                                    <div className={styles.description}>{e.description}</div>
                                                </div>
                                                <div className={styles.checkbox}>
                                                    <Checkbox size='small' value={e.active} onChange={() => {onAddOns(e.addonCode)}}/>
                                                </div>
                                            </div>
                                            <div className={styles.addOnPurchase}>
                                                <div className={styles.price}>{currency === 'Rupee' ? <>&#8377;</> : <>&#36;</>}{e.price}</div>
                                                {e.active &&
                                                    <div className={styles.quantity}>
                                                        <div className={styles.label}>Qty</div>
                                                        <div className={styles.amount}>{e.quantity}</div>
                                                        <div className={styles.operation}>
                                                            <div className={styles.add} onClick={() => onAdd(e.addonCode)}><Icon name='arrow-next' size='12'/></div>
                                                            <div className={styles.remove} onClick={() => onRemove(e.addonCode)}><Icon name='arrow-next' size='12'/></div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        }
                        <div className={styles.procced}>
                            <button className={cn(styles.button, 'button-save-small')} onClick={savePlan}>   
                                {showOverlay && 
                                    <>
                                    <div className={styles.buyAddOn}>
                                        <div className={styles.loader}><Loaders white={false} /></div>
                                    </div>
                                    </>
                                }
                                {!showOverlay&& <span>Proceed</span>}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddOn