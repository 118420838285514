import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./getRMG.module.sass";
import Icon from "../../../../../../components/Icon";
import Card from "../../../../../../components/Card";
import CurrencyTable from "../../../../../../components/Table";
import { getAllCurrencyAction, addCurrecyAction, filterCurrencyAction } from "../../../../../../redux/action/builds/economy/currency/currencyAction";
import Loader from "../../../../../../components/Loaders/LoaderProgress";
import EmptyState from '../../../../../../components/EmptyStates'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import { Skeleton } from '@mui/material';
import SkeletonTable from '../../../../../../components/Skeleton/TableSkeleton'
import SkeletonEmptyState from "../../../../../../components/Skeleton/EmptyStateSkeleton";
import GlobalStateContext from "../../../../../../components/Context/GlobalStates/GlobalStateContext";
import Control from './Filters/Control'
import { ToastContainer } from "react-toastify";
import Navigation from '../../../../../../components/Header/SecondaryNavigation'

const RMG = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams()
  // Pagination
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  // Filter
  const [currencyType, setCurrencyType] = useState([])
  const filters = {currencyType, setCurrencyType}
  // Search
  const [search, setSearch] = useState(null)
  // Archive
  const [showArchive, setShowArchive] = useState(false)
  // Sort
  const sortList = [{id: 1, name: 'Currency Name', value: 'name'}, {id: 2, name: 'Created At', value: 'created_at'}, {id: 3, name: 'Updated At', value: 'updated_at'}]
  const [sortValue, setSortValue] = useState({name: 'updated_at', value: 0})
  const [selectedSort, setSelectedSort] = useState('updated_at')
  const [sort, setSort] = useState([{id: 1, name: 'Ascending'}, {id : 2, name : 'Descending'}])
  const [sortIndex, setSortIndex] = useState(0)
  // User Access
  const [userAccess, setUserAccess] = useState(false)
  const globalStates = useContext(GlobalStateContext)
  // Skeleton
  const [showSkeleton, setShowSkeleton] = useState(true)
  
  const getUserAccess = () => {
    if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
      let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
      if(appPermission?.length > 0){
        setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
      }
    }
  }
  
  useEffect(() => {
    getUserAccess()
  }, [])

  const getCurrecy = async(search) => {
    const getCurrencyData = {
      projectId : appid,
      offset: ((page-1)*limit),
      limit: limit,
      // Search
      ...(search && search !== null && search !== '' && {search : search}),
      // Archive
      showArchived: showArchive,
      // Filter
      types: ['real'],
      //Sort
      sortOrder : (sortIndex == 0 ? 'asc' : 'desc'),
      sortField : selectedSort
    }
    await dispatch(filterCurrencyAction(getCurrencyData, configureSkeleton, !isFiltered))
    // let keysToCheck = ['currencyType']
    // let isFiltered = await keysToCheck.every(key => filters[key]?.length === 0)
    // if(!search && isFiltered){
    //   await dispatch(getAllCurrencyAction(getCurrencyData, configureSkeleton))
    // }else{
    //   await dispatch(filterCurrencyAction(getCurrencyData, configureSkeleton, !isFiltered))
    // }
  }

  let currencies = useSelector((state)=>{ 
    return state.currencies.currency
  })

  let isLoading = useSelector((state) => {
    return state.currencies.isLoading
  })

  let totalCurrency = useSelector((state)=>{
    return state.currencies.totalCount
  })

  let isFiltered = useSelector((state)=>{
    return state.currencies.isFiltered
  })

  const handlePageChange = (event, value)  => {
    setPage(value)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const configureSkeleton = () => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
  }

  useEffect(()=>{
    if(selectedSort !== null){
      getCurrecy(search)
    }
  }, [page, selectedSort, showArchive])


  const onSearch = (search) => {
    if(search == ''){
      setSearch(null)
      getCurrecy(null)
    }else{
      setSearch(search)
      getCurrecy(search)
    }
  }

  // Loader
  if(isLoading){
    return(
      <>
        <div className={styles.loader}>
          <Loader />
        </div>
      </>
    )
  }

  return(
      <>
        <ToastContainer />
        <Navigation type='RMG' filterAction={getCurrecy} filters={filters} search={search} setSearch={onSearch} archive={showArchive} setArchive={setShowArchive} data={sortList} setPage={setPage} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selectedSort} setSelected={setSelectedSort} sort={sort} setSort={setSort} value={sortValue} setValue={setSortValue} />
        <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} >
      
              <div className={styles.wrapper}>
                {/* Currency Table View */}
                {showSkeleton && currencies?.length === 0 && 
                  <SkeletonEmptyState />
                }
                {showSkeleton && currencies?.length > 0 && 
                  <SkeletonTable rows={currencies.length} column={6} controls={2} type={['recatangle', 'recatangle', 'recatangle', 'reactangle', 'reactangle', 'circular']} colWidth={[36, 110, 110, 110, 110, 36]} colHeight={[36, 15, 25, 25, 25, 36]} />
                }
                {!showSkeleton && (isFiltered || !isFiltered) && currencies?.length === 0 &&
                  <EmptyState access={userAccess} route={`/create-RMG/${appid}`} type="RMG" />
                }
                {!showSkeleton && !isFiltered && currencies?.length > 0 &&
                  <CurrencyTable access={userAccess} items={currencies} title="Last edited" type='real currency' />
                }
                {!showSkeleton && (isFiltered || !isFiltered) && currencies?.length > 0 &&
                  <CurrencyTable access={userAccess} items={currencies} title="Last edited" type='real currency' />
                }
              </div>

              {!isLoading && currencies.length > 0 &&
                <div className={styles.pagination}>
                  <Stack spacing={2}>
                    <Pagination page={page} renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        sx={{
                          '&.MuiPaginationItem-root': {
                            minWidth: '28px',
                            height: '28px',
                            fontSize: '12px'
                          },
                          '&.Mui-selected': {
                            color: 'rgb(255, 255, 255)',
                            fontWeight : 'bold',
                            border : '1px solid rgb(42, 133, 255)',
                            background : 'rgba(42, 133, 255)'
                          },
                          '&.MuiPaginationItem-page.Mui-selected:hover' : {
                            color: 'rgb(255, 255, 255)',
                            fontWeight : 'bold',
                            border : '1px solid rgb(42, 133, 255)',
                            background : 'rgba(42, 133, 255)'
                          }
                        }}
                      />
                    )} siblingCount={1} count={Math.ceil(totalCurrency/limit)} color="primary" onChange={handlePageChange} />
                  </Stack>
                </div>
              }
              
          </Card>
          <div className="divider"></div>
          <div className={styles.msg}>
            <p className={styles.alert}>Learn more about currencies <a className={styles.infoLink} href="https://manual.specterapp.xyz/specter-user-manual/build/economy/currencies" target="_blank">Click Here</a></p>
          </div>
      </>
  )

};

export default RMG;