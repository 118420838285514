import React, { useEffect, useState } from "react";
import GlobalStateContext from "./GlobalStateContext";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const GlobalStates = (props) => {

    const [showOverlay, setShowOverlay] = useState(false)
    const [showQuickGuideModal, setShowQuickGuideModal] = useState(false)
    const [access, setAccess] = useState(true)
    const [bulkUploadProcess, setBulkUploadProcess] = useState(false)
    const [showAnimation, setShowAnimation] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [loaderType, setLoaderType] = useState(0)

    let user = useSelector((state) => {
        return state.auth.user
    })

    const globalState = {showOverlay, setShowOverlay, showQuickGuideModal,setShowQuickGuideModal,showAnimation,setShowAnimation,access,setAccess,user,bulkUploadProcess,setBulkUploadProcess, showLoader, setShowLoader, loaderType, setLoaderType}

    return(
        <>
            <GlobalStateContext.Provider value={globalState}>{props.children}</GlobalStateContext.Provider>
        </>
    )

}

export default GlobalStates