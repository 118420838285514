import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import styles from './bonusWallet.module.sass'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Card from '../../../../Card'
import TextInput from '../../../../TextInput'
import Switch from '../../../../Switch'
import TooltipTitle from '../../../../../Tooltip/TooltipTitle'
import { toast } from 'react-toastify'
import Toast from '../../../../Toast'


const BonusModal = ({className, visible, value, setValue, item, onClose}) => {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [toggle, setToggle] = useState([{id: 1, name: 'Wallet Info'}])
  const [activeIndex, setActiveIndex] = useState(0)
  // Wallte Info
  const [walletDetails, setWalletDetails] = useState(
    {
        entitySubType: item?.entitySubType,
        walletId: item?.rules?.info?.walletId, walletName: item?.rules?.info?.walletName, walletType: item?.rules?.info?.walletType,
        isExpiry:  item?.rules?.expiryAndClearance?.all?.find(x => x.fact === 'bonusExpiryPeriodInDays'),
        bonusExpiryPeriodInDays:  item?.rules?.expiryAndClearance?.all?.find(x => x.fact === 'bonusExpiryPeriodInDays')?.value,
    }
  )

  const onChangeWallet = (e, type) => {
    const {name, value} = e.target
    setWalletDetails(walletDetails => ({...walletDetails, [name]: (type === 'integer' ? ((value !== '' && value !== null) ? parseInt(value) : '') : value)}))
  }

  const onChangeWalletExpiry = (e, key, id, fact) => {
    const {name, value} = e.target
    setWalletDetails(walletDetails => ({...walletDetails, [name]: e?.target?.checked}))
    setValue(wallets => wallets && wallets.map(x => x?.entitySubType === id ? 
        {
            ...x,
            rules: {
                ...x?.rules,
                [key] : (e?.target?.checked ?
                    {all : [...(x?.rules?.all || []), {fact: fact, operator: 'lessThanInclusive', value: ''}]}
                :
                    {all : (x?.rules?.all || []).filter((item) => item.fact !== fact)}
                )
            }
        }
    :
        x
    ))
  }

  const validation = (obj) => {

    const generalKeys = ["walletId", "walletName", "walletType"]

    if(obj.isExpiry) generalKeys.push('bonusExpiryPeriodInDays')
    const isGeneralValid = generalKeys.every((key) => obj[key] !== null && obj[key] !== "" && obj[key] !== undefined)
    if (!isGeneralValid){
        toast.error(<Toast type='Error' messages='All the fields in wallet info are required.' />, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        return false;
    }

    return true
}


  const save = () => {
    if(validation(walletDetails)){
        setValue(wallets => wallets.map(x => x?.entitySubType === walletDetails?.entitySubType ?
            {
                ...x,
                rules: {
                    info: {walletName: walletDetails?.walletName, walletId: walletDetails?.walletId, walletType: walletDetails?.walletType},
                    ...(walletDetails?.isExpiry ?
                        {
                            expiryAndClearance: {
                                all: [
                                    {fact: 'bonusExpiryPeriodInDays', operator: 'lessThanInclusive', value: walletDetails?.bonusExpiryPeriodInDays},
                                ]
                            }
                        }
                    : {expiryAndClearance: null}),       
                    tax: null,
                    compliance: null
                }
            }
                :
                x
            )
        )
        onClose()
    }
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.deposit}>
            <div className={styles.modalHeading}>
                <div className={styles.block}></div><span>Edit {walletDetails?.walletType.charAt(0).toUpperCase() + walletDetails?.walletType?.slice(1)} Wallet</span>
            </div>
            <div className={styles.modalBody}>
                <div className={styles.toggle}>
                    {toggle && toggle.map((x, index) => (
                        <button className={cn(styles.link, {[styles.active]: index === activeIndex}, {[styles.grid]: item?.entitySubType === 'winning'}, {[styles.disabled] : (x?.name === 'Withdrawal Controls' && !walletDetails?.isWithdrawal) ? true : false})} onClick={() => setActiveIndex(index)} key={index}>
                            <p>{x?.name}</p>
                        </button>
                    ))}
                </div>
                {activeIndex === 0 &&
                    <div className={styles.wrapper}>
                        <div className={styles.group}>
                            <TextInput name='walletName' className={styles.field} label='Wallet Name*' placeholder='Enter Wallet Name' tooltip={TooltipTitle.RMGWalletName} value={walletDetails?.walletName} onChange={(e) => {onChangeWallet(e, 'text')}} />
                            <TextInput name='walletId' className={styles.field} label='Wallet Id*' placeholder='Enter Wallet Id' tooltip={TooltipTitle.RMGWalletID} value={walletDetails?.walletId} onChange={(e) => {onChangeWallet(e, 'text')}} />
                        </div>
                        <div className={styles.group}>
                            <TextInput disabled className={styles.field} label='Wallet Type*' placeholder='Enter Wallet Type' tooltip={TooltipTitle.RMGWalletType} value={walletDetails?.walletType.charAt(0).toUpperCase() + walletDetails?.walletType?.slice(1)} />
                            <div className={styles.field}>
                                <div className={styles.info}>
                                    Bonus Wallet Expiry Info
                                </div>
                                <div className={styles.switch}>
                                    <Switch size='small' value={walletDetails?.isExpiry} name='isExpiry' onChange={(e) => {onChangeWalletExpiry(e, 'expiryAndClearance', walletDetails?.entitySubType, 'bonusExpiryPeriodInDays')}}/>
                                </div>
                            </div>
                        </div>
                        <div className={styles.group}>
                            {walletDetails?.isExpiry && <TextInput className={styles.field} label='Bonus Expiry Period (days)' placeholder='Enter Bonus Expiry Period (days)' name='bonusExpiryPeriodInDays' value={walletDetails?.bonusExpiryPeriodInDays} onChange={(e) => {onChangeWallet(e, 'integer')}}/>}
                        </div>
                    </div>
                }
                {/* {activeIndex === 1 &&
                    <div className={styles.wrapper}>
                        <div className={styles.group}>
                            <TextInput className={styles.field} label='Bonus Expiry Period (days)*' placeholder='Enter Bonus Expiry Period (days)' name='bonusExpiryPeriodInDays' value={walletDetails?.bonusExpiryPeriodInDays} onChange={(e) => {onChangeWallet(e, 'integer')}}/>
                        </div>
                    </div>
                }
                {activeIndex === 2 &&
                    <div className={styles.wrapper}>
                        <div className={styles.group}>
                            <TextInput className={styles.field} label='Max Contribution Per Transaction*' placeholder='Enter Max Contribution Per Transaction' name='maxContributionPerTransaction' value={walletDetails?.maxContributionPerTransaction} onChange={(e) => {onChangeWallet(e, 'integer')}}/>
                            <TextInput className={styles.field} label='Daily Contribution Limit*' placeholder='Enter Daily Contribution Limit' name='dailyContributionLimit' value={walletDetails?.dailyContributionLimit} onChange={(e) => {onChangeWallet(e, 'integer')}}/>
                        </div>
                    </div>
                } */}  
            </div>
            <div className={styles.modalFooter}>
                <button className={cn(styles.button, 'button-save-small')} onClick={save}>Save</button>
                <button className={cn(styles.button, 'button-cancel-small')} onClick={onClose}>Cancel</button>
            </div>
        </div>
      </div>
    </>
  )
}

export default BonusModal