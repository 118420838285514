import React, { useEffect, useState } from "react";
import styles from "./row.module.sass";
import Control from "./Control/index";
import { Link, useParams } from "react-router-dom";
import ProgressionMarker from '../../../../../../media/images/placeholders/Progression_Marker.png'
import TooltipGlodal from "../../../../../TooltipGlodal";
import cn from "classnames";
import { extarctImageFromUrlAction } from "../../../../../../redux/action/common/commonAction";
import { useDispatch } from "react-redux";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({ item, className, access, value, onChange }) => {
    
    const {appid} = useParams()
    const dispatch = useDispatch()

    const extractImageFromCdn = async(url, id) => {
        if(url !== null && url !== undefined && url.includes('cdn')){
            try {
                const response = await dispatch(extarctImageFromUrlAction(url))
                const logo = document.getElementById(`pmIcon_${id}`)
                if(logo){
                    logo.src = response
                }
            }catch (error){
                console.error('Error fetching image:', error);
            }
        }else{
            const logo = document.getElementById(`pmIcon_${id}`)
            if(logo){
                logo.src = ProgressionMarker
            }
        }
    }

    useEffect(() => {
        extractImageFromCdn(item.iconUrl, item.id)
    }, [item])

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <div className={styles.item}>
                        {item.iconUrl !== undefined ?
                            <div className={cn(styles.preview, className, {[styles.bg] : true})}>
                                <img id={`pmIcon_${item.id}`} alt="PM" />
                            </div>
                            :
                            <div className={styles.preview}></div>
                        }
                        <div className={styles.details}>
                            <div>{item.name}</div>
                            <div>{item.progressionMarkerId}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <Link to={`/view-progression-marker/${appid}/${item.id}`}>
                        <TooltipGlodal width={200} itemId={`PM${item.id}`} title={item.name}><div className={styles.itemName}><span id={`PM${item.id}`}>{item.name}</span></div></TooltipGlodal>
                        <div className={styles.itemId}>{item.progressionMarkerId}</div>
                    </Link>
                </div>
                <div className={styles.col}><div className={item.archive === false ? styles.price : item.archive === true ? styles.archive : null}>{item.archive === false ? 'Active' : item.archive === true ? 'Archive' : null}</div></div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 &&
                        <Control access={access} className={styles.control} id={item.id} appid={appid} name={item.name} status={item.archive} type='pm' archiveStatus={item.archive} deleteStatus={item.active} markerName={item.name}/>
                    }
                </div>
            </div>
        </>
    );
};

export default Row;
