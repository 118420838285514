import React, { useState, useEffect } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from './Control'
import { Link, useParams } from "react-router-dom";
import cn from "classnames";
import Switch from "../../../../Switch";
import moment from "moment";

const Row = ({ item, className, access, value, onChange, index, type, setValue }) => {

    const {appid} = useParams()

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 && <div className={styles.item}>{moment(item?.createdAt).format('DD-MM-YYYY')}</div>}
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 && <div className={styles.item}>{moment(item?.createdAt).format('hh:mm A')}</div>}
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 && <>₹ {item?.amount}</>}
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 && <div className={cn(styles.pills, {[styles.green] : (item?.transactionType === 'debit')}, {[styles.red] : (item?.transactionType === 'credit')})}>{item?.transactionType ? item?.transactionType.charAt(0).toUpperCase() + item?.transactionType.slice(1) : '-'}</div>}
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 && <>{(item?.walletTransactionPurpose && item?.walletTransactionPurpose?.name) ? item?.walletTransactionPurpose?.name.charAt(0).toUpperCase() + item?.walletTransactionPurpose?.name.slice(1) : '-'}</>}
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 && <div className={cn(styles.pills, {[styles.green] : (item?.status === 'processed')}, {[styles.red] : (item?.status === 'In-Progress')})}>{item?.status ? item?.status.charAt(0).toUpperCase() + item?.status.slice(1) : '-'}</div>}
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 && <>{item?.mode ? item?.mode.charAt(0).toUpperCase() + item?.mode.slice(1) : '-'}</>}
                </div>
            </div>
        </>
    );
};

export default Row;
