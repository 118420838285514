import React, { useState, useEffect } from 'react'
import cn from 'classnames';
import axios from "axios";
import { toast } from 'react-toastify';
import Toast from '../../components/Toast';
import { Link, useParams } from "react-router-dom";
import styles from './fileUploadSidebar.module.sass'
import Icon from '../Icon';
import Checkbox from '../Checkbox';
import FileUpload from '../File/FileUpload';
import ImageUpload from '../File/Images';
import VideoUpload from '../File/Videos';
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { getAllMediaAction } from '../../redux/action/AppSettings/Media/mediaAction';
import { BASE_URL, GET_APPS, GET_MEDIA } from '../../resources/APIEndpoints';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../Form';
import Dropdown from '../Dropdown';
import {ReactComponent as BundleFolder} from "../../media/images/icons/bundleFolder.svg"


const FileUploadSidebar = ({ className, media, setMedia, types, id, multiple, icon, setIcon,folderName, value, setValue, visible, setVisible, onClose, level, type,catIndex,customUploadFunction,customUploadingMedia,customSelectMediaFunction, setPSLevels }) => {

    const {appid} = useParams()
    const dispatch = useDispatch()
    const [sidebarMedia, setsidebarMedia] = useState([])
    const [uploadingMedia, setUploadingMedia] = useState([]);
    const onSelectLogo = (selectedFile, index) => {
        if(multiple){
            if(selectedFile.status == false){
                let fileData = [...sidebarMedia]
                fileData[index].status = true
                setMedia(fileData)
                setIcon(icon => [...icon, selectedFile.url])
            }else{
                let fileData = [...sidebarMedia]
                fileData[index].status = false
                setMedia(fileData)
                setIcon(icon.filter((x, i) => x !== selectedFile.url));
            }
        }
        else if (type=="store") {
            customSelectMediaFunction(selectedFile.url,catIndex,selectedFile)
            setVisible(false)
        }else{
            setIcon(selectedFile.url)
            setValue({src: selectedFile.url, alt: selectedFile.url})
            setVisible(false)
        }
    }

    const save = () => {
        setVisible(false)
    }

    const Folders =[
        {name:"Icons",id:"icons"},
        // {name:"Assets",id:"assets"},
        {name:"Videos",id:"videos"},
        {name:"Remote Assets",id:"bundles"},
        {name:"Misc",id:"misc"}
    ]
    
    const [selectedFolderID,setSelectedFolderID]= useState(folderName ? folderName : Folders[0].id)
    const [selectedFolder,setSelectedFolder]= useState(folderName?(Folders.find(x=>{return folderName==x.id}).name):Folders[0].name)

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            filters:{
                categories : selectedFolderID
            }
        }
        try {
            const token = localStorage.getItem('token');
            const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}    
            let res = await axios.post(BASE_URL + GET_MEDIA, getMediaData,headers);
            if (res && res.data && res.data.data && res.data.data.mediaDetails) {
                setsidebarMedia(res.data.data.mediaDetails)
            }
        } catch (error) {
            toast.error(<Toast type='Error' messages={`Failed to fetch media: ${error.message}`} />, {
                position: 'top-center',
                icon: false,
                hideProgressBar: true,
                autoClose: 2000,
                
            });
            return;
        }
    }

    const getSearchMedia = async(search) => {
        let getMediaData = {
            search : search,
            projectId : appid,
            filters:{
                categories : selectedFolderID
            }
        }
        try {
            const token = localStorage.getItem('token');
            const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}    
            let res = await axios.post(BASE_URL + GET_MEDIA, getMediaData,headers);
            if (res && res.data && res.data.data && res.data.data.mediaDetails) {
                setsidebarMedia(res.data.data.mediaDetails)
            }
        } catch (error) {
            toast.error(<Toast type='Error' messages={`Failed to fetch media: ${error.message}`} />, {
                position: 'top-center',
                icon: false,
                hideProgressBar: true,
                autoClose: 2000,
                
            });
            return;
        }
    }

    const onSearch = (search) => {
        if(search == ''){
            getMedia()
        }else{
            getSearchMedia(search)
        }
      }

    useEffect(()=>{
        if(visible){
            getMedia()
        }
    },[selectedFolder, selectedFolderID, visible])

    useEffect(() => {
        if (visible) {
          const target = document.querySelector("#sideModal");
          disableBodyScroll(target);
        } else {
          clearAllBodyScrollLocks();
        }
    }, [visible]);

    return(
        <>
            <div id="sideModal" className={cn(styles.help, className, {[styles.active]: visible})}>
                <div className={styles.helpContainer}>
                    <div className={styles.head}>
                        <div className={styles.header}>
                            <div className={styles.headingDash}></div>
                            <div className={styles.heading}>Media</div>
                        </div>                
                        <button className={styles.close} onClick={() => setVisible(false)}>
                            <Icon name="close" size="24" />
                        </button>
                    </div> 

                    <div className={styles.body}>
                        <div className={styles.bodyContainer}>
                            <div className={styles.preview}>

                                <div style={{marginTop:"24px"}}>
                                    <Dropdown disabled={true} label={"Folder"} options={Folders} value={selectedFolder} setValue={setSelectedFolder} setId={setSelectedFolderID}/>
                                </div>

                                <div className={styles.fileUpload}>
                                    <FileUpload getMedia={getMedia} setUploadingMedia={setUploadingMedia} type={type} catIndex={catIndex} customUploadFunction={customUploadFunction} id={id} usedFor={multiple ? 'multiUpload' : 'logo'} icon={icon} setIcon={setIcon} folderName={selectedFolderID} setFile={setValue} value={value} setValue={setValue} setSidebar={setVisible} title={folderName === 'icons' ? "click or drop image" : folderName === 'videos'? "click or drop video" : folderName=='bundles'?"click or drop files":'click or drop image or video'} />
                                </div>
                                        
                                <div>
                                    <Form className={styles.form} placeholder="Search Images" type="text" name="search" icon="search" onChange={(e) => {onSearch(e.target.value)}}/>
                                </div>

                                <div className={styles.previewContainer}>
                                {((type=="store"&&customUploadingMedia)?customUploadingMedia:uploadingMedia).map((item, index) => (
                                        <div key={`uploading-${index}`} style={{ height: "fit-content" }}>
                                            <div className={styles.previewBox}>
                                            {/* A container for the progress bar */}
                                            <div style={{ position: "relative", width: "80px", height: "80px", backgroundColor: "#f2f2f2"}}>
                                                {/* progress bar element - style as you wish */}
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        bottom: 0,
                                                        left: 0,
                                                        height: "8px",
                                                        borderRadius: "8px",
                                                        background: "#2A85FF",
                                                        margin:"0 8px",
                                                        width: `${item.progress}%`,
                                                        transition: "width 0.4s ease"
                                                    }}
                                                />
                                                </div>
                                            </div>

                                            <div
                                                style={{
                                                    maxWidth: "112px",
                                                    fontSize: "12px",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    marginTop: "4px"
                                                }}
                                                title={item.fileName}
                                            >
                                            {item.fileName} 
                                            {item.progress < 100 ? " (uploading...)" : " (done)"}
                                            </div>
                                        </div>
                                    ))}
                                    {sidebarMedia && sidebarMedia.map((e, index) => {
                                        return(
                                            <>  <div style={{height:"fit-content"}} onClick={()=>{onSelectLogo(e, index)}}>
                                                    <div className={styles.previewBox} style={selectedFolderID=="bundles"?{backgroundColor:"#fff"}:{}} >
                                                        {e.status == true && <span><Icon name='check' size='12' className={styles.checkbox}/></span>}
                                                        {selectedFolderID=='bundles'?
                                                            <BundleFolder style={{height:"inherit", width:"inherit"}}/>
                                                            :
                                                            (folderName === 'videos' ?  <video src={e.url != null ? e.url : ''} /> : <img src={e.url} />)
                                                        }
                                                    </div>
                                                    <div style={{maxWidth:"112px",fontSize:"12px",whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis",marginTop:"4px"}} title={e?.name ? e.name : ""}>
                                                        {e?.name?e.name:null}
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                                {multiple && sidebarMedia && sidebarMedia.length > 0 &&
                                    <div className={styles.actions}>
                                        <div className={styles.buttons}>
                                            <button onClick={save} className={styles.save}>Save</button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className={cn(styles.overlay, { [styles.active]: visible })} onClick={() => setVisible(false)}></div>
    </>
    )
}

export default FileUploadSidebar