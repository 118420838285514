import React, {useContext, useEffect, useRef, useState} from 'react'
import cn from "classnames";
import styles from './PlayerDetails.module.sass'
import Card from "../../../../components/Card";
import Form from '../../../../components/Form';
import Profile from './Profile'
import PlayerData from './PlayerData'
import Inventory from './Inventory'
import Matches from './Matches'
import Progression from './Progression'
import Rewards from './Rewards'
import Wallets from './Wallets'
import Task from './Tasks';
import { useParams } from 'react-router-dom';
import { editPlayerProfileAction, getPlayerDetailAction } from '../../../../redux/action/engage/Players/PlayersAction';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer , toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Icon from '../../../../components/Icon';
import TextInput from '../../../../components/TextInput';
import Toast from '../../../../components/Toast';
import {ReactComponent as UserProfileIcon} from '../../../../media/images/icons/Player_Icon.svg'
import FileUploadSidebar from '../../../../components/FileUploadSidebar';
import axios from 'axios';
import { getAllMediaAction } from '../../../../redux/action/AppSettings/Media/mediaAction';
import App from '../../../../media/images/placeholders/App.png'
import GlobalStateContext from '../../../../components/Context/GlobalStates/GlobalStateContext';
import { BASE_URL } from '../../../../resources/APIEndpoints';
import app,{increaseUnreadCountForAllUsers} from "../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const tabs = ["Profile", "Player Data", "Inventory", "Wallets", "Progression", "Achievements", "Rewards", "Matches"]

const PlayerDetail = ({className, isChanged, setIsChanged, onClose}) => {

    const {appid} = useParams()
    const {id} = useParams()
    const dispatch = useDispatch()
    const [activeIndex, setActiveIndex] = useState(0);

    const [userAccess, setUserAccess] = useState(false)
    const globalStates = useContext(GlobalStateContext)

    const getUserAccess = () => {
      if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
        let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
        if(appPermission?.length > 0){
          setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
        }
      }
    }
  
    useEffect(() => {
      getUserAccess()
    }, [])

    const db = getFirestore(app);
    const onEditPlayerActivity = async(req)=>{
            const user = JSON.parse(localStorage.getItem('user'))
            const memberID = user?.id
            const memberName = user?.name
            const orgId = (localStorage.getItem('orgId'))
            // Log activity in Firestore
            const activityLog = {
                timestamp: new Date().toISOString(),
                action_type: "UPDATE",
                app_id:appid,
                resource: "PLAYER",
                resource_id: id, // Use the ID from response
                description: `Player '${playerProfile?.[0]?.username??playerProfile?.[0]?.email??playerProfile?.[0]?.linkedAccounts[0]?.userId??playerProfile?.[0]?.linkedAccounts[0]?.phoneNumber??playerProfile?.[0]?.customId}' updated successfully`,
                quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
                operation_status: "SUCCESS",
                activity_feed: true,
                priority: "LOW",
                performed_by_id: memberID,
                organization_id: orgId,
                performed_by:memberName,
                request_json:JSON.stringify(req),
            };
            await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
            console.log("Activity logged successfully");
    }
    
    const getPlayerDetails = async() => {
        let playerDetail = {
            entities : {
                value : 'profile',
                offset: 0,
                limit: 100
            },
            projectId : appid,
            ids : [id]
        }
        await dispatch(getPlayerDetailAction(playerDetail, activeIndex))
    }

    useEffect(()=>{
        if(activeIndex == 0 || activeIndex == 1){
            getPlayerDetails()
        }
    }, [activeIndex])

    const playerProfile = useSelector((state) => {
        return state.players.profile
    })

    const playerUserData = useSelector((state) => {
        return state.players.playerData
    })

    const [UID, setUID] = useState('')
    const [UUID, setUUID] = useState('')

    const configure = () => {
        if(playerProfile && playerProfile.length > 0){
            setUserProfile(playerProfile[0].thumbUrl)
            setLogo({src: (playerProfile[0].thumbUrl != null ? playerProfile[0].thumbUrl : null), alt: 'Upload an Image'}); 
            setUserName(playerProfile[0].displayName)
            setUID(playerProfile[0].ouid != null ? playerProfile[0].ouid : playerProfile[0].ouid)
            setUUID(playerProfile[0].id != null ? playerProfile[0].id : playerProfile[0].id)
        }
    }

    useEffect(() => {
        configure()
    }, [playerProfile])

    const [onEditProfileName, setOnEditProfileName] = useState(false)
    const [userName, setUserName] = useState('')
    const [userProfile, setUserProfile] = useState(null)

    const onClickUserName = () => {
        setOnEditProfileName(true)
    }

    const onUserNameChange = (e) => {
        setUserName(e.target.value)
    }

    const submit = () => {
        let userNameData = {
            projectId : appid,
            userId : id,
            username : userName
        }
        dispatch(editPlayerProfileAction(userNameData, appid, id, setOnEditProfileName, activeIndex))
        setIsChanged(false)
    }

    const copyUIDClipboard = () => {
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = UID;
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand("copy");
        document.body.removeChild(tempTextArea);
         toast.success(<Toast type='Success' messages='copied to clipboard'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
    };

    const copyUUIDClipboard = () => {
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = UUID;
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand("copy");
        document.body.removeChild(tempTextArea);
        toast.success(<Toast type='Success' messages='copied to clipboard'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
    };

    const [visibleHelp, setVisibleHelp] = useState(false)

    const [media, setMedia] = useState([])
    const [type, setType] = useState(0)

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : type
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })

    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }

    useEffect(() => {
        getMedia()
    }, [type])

    useEffect(() => {
        configureMedia()
    }, [files])
    

    const logoRef = useRef()
    const placeholder = App
    const [{alt, src}, setLogo] = useState({src: null , alt: 'Upload an Image'}); 

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}&projectId=${appid}&category=${`icons`}&fileName=${file.fileName}`)
        setUserProfile(res.data.getUrl)
        //setAppIcon(res.data.getUrl)
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            let userNameData = {
                projectId : appid,
                userId : id,
                thumbUrl : res.data.getUrl
            }
            dispatch(editPlayerProfileAction(userNameData, appid, id, setOnEditProfileName, activeIndex))
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        });
    }

        // To Convert Base64 to Binary
        function convertDataURIToBinary(dataURI) {
            var BASE64_MARKER = ';base64,';
            var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));
              
            for(var i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            return array;
        }
        
        // To Convert file into base64 format
        const convertbase64 = (file) => {
            return new Promise((resolve, err)=>{
            const fileReader = new FileReader();
            fileReader.onload = (eve) =>{
                resolve(fileReader.result)
            }
            fileReader.readAsDataURL(file)
            })
        }

    const Cancel = () => {
        setUserProfile(null)
        setLogo({src: null , alt: 'Upload an Image'})
        logoRef.current.value = ''
    }

    return (
        <>
            <ToastContainer />
            {/* <Form as='players' className={styles.form} placeholder="Search or type a Player" type="text" name="search" icon="search" /> */}
            <Card className={cn(styles.card, className)}>
                <div className={styles.group}>
                    <div className={styles.playerInformation}>
                        <div className={styles.preview}>
                            <div className={styles.primaryInfo}>
                                <div className={styles.profile}>
                                    <div className={styles.preview}>
                                        <div className={styles.previewImage}>
                                            {userProfile != null &&  
                                                <img lassName={styles.profileImage} src={src} alt={`user`} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                                            }
                                            {userProfile == null &&  
                                                <UserProfileIcon className={styles.profileImage}/>
                                            }
                                        </div>
                                        <div className={styles.previewInput}> 
                                            <input disabled={userAccess} id='profile' type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={logoRef} onChange={(e)=>{uploadLogo(e)}}/>
                                            <label for='profile' className={styles.button} onClick={() => {setVisibleHelp(true); setType(0)}}><Icon name='add' size='16'/></label>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.otherInfo}>
                                    <div className={styles.playerName}>
                                        <div className={styles.userName}>{(playerProfile && playerProfile.length > 0 && playerProfile[0].displayName != null && playerProfile[0].displayName != '') ? playerProfile[0].displayName : (playerProfile && playerProfile.length > 0 && playerProfile[0].username != null && playerProfile[0].username != '') ? playerProfile[0].username : (playerProfile && playerProfile.length > 0 && playerProfile[0].firstName != null && playerProfile[0].firstName != '') ? `${playerProfile[0].firstName} ${playerProfile[0].lastName}`: (playerProfile && playerProfile.length > 0 && playerProfile[0].linkedAccounts != null && playerProfile[0].linkedAccounts.length > 0) ? playerProfile[0].linkedAccounts[0].userId : (playerProfile && playerProfile.length > 0 && playerProfile[0].email != null && playerProfile[0].email != '') ? playerProfile[0].email : '-'}</div>
                                    </div>
                                    <div className={styles.secondaryInfo}>
                                        <div className={styles.playerId}>
                                            <div className={styles.ids}>
                                                <span className={styles.label}>UID:&nbsp;</span><span className={styles.UIDNO}>{UID != null ? UID : '-'}<span className={styles.copyToClipboard} onClick={copyUIDClipboard}><Icon name='copy' size='12' /></span></span>
                                            </div>
                                            <div className={styles.ids}>
                                                <span className={styles.label}>ID:&nbsp;</span><span className={styles.UUIDNO}>{UUID != null ? UUID : '-'}<span className={styles.copyToClipboard} onClick={copyUUIDClipboard}><Icon name='copy' size='12' /></span></span>
                                            </div>
                                        </div>
                                        <div className={styles.playerLocation}>
                                            <div className={styles.ids}>
                                                <span className={styles.label}>Location:&nbsp;</span><span className={styles.UUIDNO}>Mumbai, India</span>
                                            </div>
                                            <div className={styles.ids}>
                                                <span className={styles.label}>Created At:&nbsp;</span><span className={styles.UUIDNO}>{playerProfile.length > 0 ? moment(playerProfile[0].createdAt).format('DD-MM-YYYY hh:mm A') : '-'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.group}>
                    <div className={styles.toggle}>
                        {tabs && tabs.map((x, index) => (
                            <button className={cn(styles.link, { [styles.active]: index === activeIndex,})} onClick={() => setActiveIndex(index)} key={index}>
                                <p>{x}</p>
                            </button>
                        ))}
                    </div>
                </div>
            </Card>

            {activeIndex == 0 &&
                <>
                    <Profile editActivity={onEditPlayerActivity} access={userAccess} isChanged={isChanged} setIsChanged={setIsChanged} data={playerProfile} activeIndex={activeIndex}/>
                </>
            }

            {activeIndex == 1 &&
                <>
                    <PlayerData editActivity={onEditPlayerActivity} access={userAccess} isChanged={isChanged} setIsChanged={setIsChanged} data={playerUserData} activeIndex={activeIndex}/>
                </>
            }

            {activeIndex == 2 &&
                <>
                    <Inventory editActivity={onEditPlayerActivity} access={userAccess} activeIndex={activeIndex}/>
                </>
            }

            {activeIndex == 3 &&
                <>
                    <Wallets editActivity={onEditPlayerActivity} access={userAccess} activeIndex={activeIndex}/>
                </>
            }

            {activeIndex == 4 &&
                <>
                    <Progression editActivity={onEditPlayerActivity} access={userAccess} activeIndex={activeIndex}/>
                </>
            }

            {activeIndex == 5 &&
                <>
                    <Task editActivity={onEditPlayerActivity} access={userAccess} activeIndex={activeIndex}/>
                </>
            }

            {activeIndex == 6 &&
                <>
                    <Rewards editActivity={onEditPlayerActivity} access={userAccess} activeIndex={activeIndex}/>
                </>
            }

            {activeIndex == 7 &&
                <>
                    <Matches editActivity={onEditPlayerActivity} activeIndex={activeIndex}/>
                </>
            }

        </>
    )
}

export default PlayerDetail