export const routes = {
    AppOverview : {path : '/app-overview/:appid'},
    AppAcceptedMembers : {path : '/app-accepted-members/:appid'},
    AppInvitedMembers : {path : '/app-invited-members/:appid'},
    API : {path : '/app-api/:appid'},
    MemberInviteSetPassword : {path : '/members/set-password'},
    Media : {path : '/app-media/:appid'},
    Documents : {path : '/app-documents/:appid'},
    CreateDocuments : {path : '/create-document/:appid'},
    EditDocuments : {path : '/edit-document/:appid/:id'},
    MediaFolders : {path : '/media/folders/:folderName/:appid'},
    
    AppSettingsInfo : {path : '/app-information/:appid'},
    ActivityFeed : {path : '/activityFeed/:appid'},
    AppInfo : {path : '/env-information/:appid'},
    Terminal : {path : '/terminal/:appid'},
    PlansNBilling : {path : '/billing-overview'},
    Invoices : {path : '/invoices'},
    BillingNotification : {path : '/billing-notification'},
    BillingSettings : {path : '/billing-settings'},
    plans : {path: '/plans'},
    addOns : {path: '/add-on/:id'},
    billingDetails: {path: '/billing-details'},
    upgradeAddOn: {path: '/add-on'},
    upgradeTopUp: {path: '/top-up'},

    Games : {path : '/games/:appid'},
    ViewGame : {path : '/view-game/:appid/:id'},
    CreateGame : {path : '/create-game/:appid'},
    EditGames : {path : '/edit-game/:appid/:id'},

    Matches : {path : '/matches/:appid'},
    ViewMatch : {path : '/view-match/:appid/:id'},
    CreateMatch : {path : '/create-match/:appid'},
    EditMatch : {path : '/edit-match/:appid/:id'},

    Currency : {path : '/currency/:appid'},
    ViewCurrency : {path : '/view-currency/:appid/:id'},
    CreateCurrency : {path : '/create-currency/:appid'},
    EditCurrency : {path : '/edit-currency/:appid/:id'},

    Items : {path : '/item/:appid'},
    ViewItem : {path : '/view-item/:appid/:id'},
    CreateItems : {path : '/create-item/:appid'},
    EditItems : {path : '/edit-item/:appid/:id'},

    Bundles : {path : '/bundle/:appid'},
    ViewBundle : {path : '/view-bundle/:appid/:id'},
    CreateBundles : {path : '/create-bundle/:appid'},
    EditBundles : {path : '/edit-bundle/:appid/:id'},

    Stores : {path : '/store/:appid'},
    ViewStore : {path : '/view-store/:appid/:id'},
    CreateStore : {path :'create-store/:appid'},
    ViewStores: {path : '/view-store/:appid/:id'},
    EditStores: {path : '/edit-store/:appid/:id'},

    GetProgressionMarker : {path : '/progression-marker/:appid'},
    ViewProgressionMarker : {path : '/view-progression-marker/:appid/:id'},
    CreateProgressionMarker : {path : '/create-progression-marker/:appid'},
    EditProgressionMarker : {path : '/edit-progression-marker/:appid/:id'},

    GetProgressionSystem : {path : '/progression-system/:appid'},
    ViewProgressionSystem : {path : '/view-progression-system/:appid/:id'},
    CreateProgressionSystem : {path : '/create-progression-system/:appid'},
    EditProgressionSystem : {path : '/edit-progression-system/:appid/:id'},

    Events : {path : '/events/:appid'},
    CustomEvent : {path : '/custom-events/:appid'},
    DefaultEvent : {path : '/default-events/:appid'},
    CreateEvents : {path : '/create-events/:appid'},
    ViewCustomEvents : {path: '/view-custom-events/:appid/:id'},
    EditCustomEvents : {path: '/edit-custom-events/:appid/:id'},
    ViewDefaultEvents : {path : '/view-default-events/:appid/:id'},
    EditDefaultEvents : {path : '/edit-default-events/:appid/:id'},

    RMG : {path: '/RMG/:appid'},
    CreateRMG : {path: '/create-RMG/:appid'},
    EditRMG : {path: '/edit-RMG/:appid/:id'},
    SpecterBank : {path: '/specter-bank/:appid'},
    Payout : {path: '/payout/:appid'},
    RMGTransactions : {path: '/specter-bank/transactions/:appid/:id'},

    Task : {path : '/task/:appid'},
    ViewTask : {path : '/view-task/:appid/:id'},
    CreateTask : {path : '/create-task/:appid'},
    EditTask : {path : '/edit-task/:appid/:id'},

    StepSeries : {path : '/step-series/:appid'},
    ViewStepSeries : {path : '/view-step-series/:appid/:id'},
    CreateStepSeries : {path : '/create-step-series/:appid'},
    EditStepSeries : {path : '/edit-step-series/:appid/:id'},

    Missions : {path : '/mission/:appid'},
    ViewMission : {path : '/view-mission/:appid/:id'},
    CreateMission : {path : '/create-mission/:appid'},
    EditMission : {path : '/edit-mission/:appid/:id'},

    GetPlayers : {path : '/players/:appid'},
    PlayerDetails : {path : '/player-details/:appid/:id'},

    Leaderboard : {path : '/leaderboard/:appid'},
    ViewLeaderboard : {path : '/view-leaderboard/:appid/:id'},
    CreateLeaderboard : {path : '/create-leaderboard/:appid'},
    EditLeaderboard : {path : '/edit-leaderboard/:appid/:id'},
    LeaderboardResult : {path : '/leaderboard-result/:appid/:id'},
    CompetitionResult : {path : '/tournament-result/:appid/:id'},
    CompetitionInstantBattleResult : {path : '/instant-battle-result/:appid/:id'},
    Calendar : {path : '/calendar/:appid'},
    CompetitionSchedule : {path : '/competition-schedule/:appid'},
    ViewCompetitionSchedule : {path : '/view-competition-schedule/:appid/:id'},
    CreateCompetitionSchedule : {path : '/create-competition-schedule/:appid'},
    EditCompetitionSchedule : {path : '/edit-competition-schedule/:appid/:id'},

    CompetitionTournament : {path : '/competition-tournament/:appid'},
    ViewCompetitionTournament : {path: '/view-competition-tournament/:appid/:id'},
    CreateCompetitionTournament : {path: '/create-competition-tournament/:appid'},
    EditCompetitionTournament : {path: '/edit-competition-tournament/:appid/:id'},

    CompetitionInstantBattle : {path : '/competition-instant-battle/:appid'},
    ViewCompetitionInstantBattle : {path: '/view-competition-instant-battle/:appid/:id'},
    CreateCompetitionInstantBattle : {path: '/create-competition-instant-battle/:appid'},
    EditCompetitionInstantBattle : {path: '/edit-competition-instant-battle/:appid/:id'},

    Community : {path : 'community/:appid'},

    NotificationFeed : {path : '/notificationFeed'},
    Dashboard : {path : '/home'},
    UsageAnalytics : {path : '/usage-analytics'},
    Apps : {path : '/apps'},
    OrganisationProfile : {path : '/settings-Organization-profile'},
    UserProfile : {path : '/settings-member-profile'},
    OrganisationAcceptedMembers : {path : '/access-manager-accepted'},
    OrganisationInvitedMembers : {path : '/access-manager-invited'},
    
    Login : {path : '/'},
    SignUp : {path : '/sign-up'},
    Forbidden : {path : '/forbidden'},
    EmailValidate : {path : '/email-verification'},
    ResetPassword : {path : '/reset-password'},
    ForgotPassword : {path : '/forgot-password'},
    MemberInviteSetPassword : {path : '/members/set-password'},
    userDetail: {path: '/user-detail'},
    AcceptInvite: {path: '/accept-invite'}
}