import React, { useEffect, useState, useRef, useContext } from "react";
import cn from "classnames";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from "./currencyInformation.module.sass";
import Card from "../../../../../../../components/Card";
import Icon from "../../../../../../../components/Icon";
import TextInput from "../../../../../../../components/TextInput";
import TextArea from "../../../../../../../components/TextArea";
import Dropdown from "../../../../../../../components/Dropdown/DaysDropdown";
import {ReactComponent as AddButtonIcon} from '../../../../../../../media/images/icons/add_button.svg'
import {ReactComponent as AddMetaIcon} from '../../../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as Remove} from '../../../../../../../media/images/icons/remove.svg'
import TagInputWithDropdown from "../../../../../../../components/TagInputWithDropdown";
import { WithContext as ReactTags } from "react-tag-input";
import removeIcon from '../../../../../../../media/images/icons/remove.png'
import Tooltip from "../../../../../../../components/Tooltip";
import Toast from '../../../../../../../components/Toast'
import Currency from '../../../../../../../media/images/placeholders/Currency.png'
import FileUploadSidebar from "../../../../../../../components/FileUploadSidebar";
import { getAllMediaAction } from "../../../../../../../redux/action/AppSettings/Media/mediaAction";
import TooltipTitle from "../../../../../../../Tooltip/TooltipTitle";
import JSONMetaDataValueInput from "../../../../../../../components/MetaInput";
import GlobalStateContext from "../../../../../../../components/Context/GlobalStates/GlobalStateContext";
import { BASE_URL } from "../../../../../../../resources/APIEndpoints";
import WalletTable from '../../../../../../../components/BuildComponents/RMC/WalletTable'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { addCurrecyAction, addPolicyAction } from "../../../../../../../redux/action/builds/economy/currency/currencyAction";
import ProgressTooltip from "../../../../../../../components/CustomTooltips/ProgressTooltip";
import Radio from "../../../../../../../components/Radio";
import { getAllMetaDataAction } from "../../../../../../../redux/action/common/commonAction";

const CurrencyInformation = ({ className, save, isChanged,setIsChanged, onClose}) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const ref = useRef()
    const {appid} = useParams()
    const globalStates = useContext(GlobalStateContext)
    const placeholder = Currency
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const [currencyIcon, setCurrencyIcon] = useState(null)
    const [displayName, setDisplayName] = useState('');
    const [currencyId, setCurrencyId] = useState('')
    const [currencyCode, setCurrencyCode] = useState();
    const [currencyType, setCurrencyType] = useState('');
    const [currencyDescription, setCurrencyDescription] = useState()
    const [isDisabled, setIsDisabled] = useState(false)

    const configureAccess = () => {
        if(globalStates.user.memberAccessConfig?.length > 0){
            let access = globalStates.user.memberAccessConfig && globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
            setIsDisabled(access[0]?.permissions.id == 1 ? true : false)
        }
    }

    useEffect(() => {
        configureAccess()
    }, [])

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setCurrencyIcon(res.data.getUrl);
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        });
    }

        // To Convert Base64 to Binary
        function convertDataURIToBinary(dataURI) {
            var BASE64_MARKER = ';base64,';
            var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));
              
            for(var i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            return array;
        }
        
        // To Convert file into base64 format
        const convertbase64 = (file) => {
            return new Promise((resolve, err)=>{
            const fileReader = new FileReader();
            fileReader.onload = (eve) =>{
                resolve(fileReader.result)
            }
            fileReader.readAsDataURL(file)
            })
        }

    const Cancel = () => {
        setCurrencyIcon(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        ref.current.value = ''
    }

    const onDisplayName = (e) => {
        if(e.target.value == ''){
            setDisplayName('')
        }else{
            setDisplayName(e.target.value)
        }
    }

    const onCurrencyCode = (e) => {
        setCurrencyCode(e.target.value)
    }

    const onCurrencyType = (e) => {
        setCurrencyType(e.target.value)
    }

    const onCurrencyId = (e) => {
        if(e.target.value == ''){
            setCurrencyId('')
        }else{
            setCurrencyId(e.target.value)
        }
    }

    const onCurrecyDescription = (e) => {
        setCurrencyDescription(e.target.value)
    }

    const [tags, setTags] = useState([]);

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]);
        }else{
            toast.warning(<Toast type='Warning' messages='Only 10 tags are allowed'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        }
    };
  
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
  
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }

    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const [id, setId] = useState(null)

    const saveCurrency = async () => {
        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }
        let currencyTags = []
        tags && tags.forEach((e)=>{
            currencyTags.push(e.text)
        })
        const currency = {
            projectId : appid,
            iconUrl : currencyIcon,
            name : displayName,
            currencyId : currencyId,
            code : currencyCode,
            type : 'real',
            description : currencyDescription,
            meta : finalMetaData,
            tags : currencyTags,
            organisationId: localStorage.getItem('orgId')
        }
        dispatch(addCurrecyAction(currency, null, appid, null, setId, setCurrentStep))
    }

    const savePolicy = () => {
        let policy = {
            currencyId: id,
            projectId: appid,
            policyDetails: wallets && wallets.filter(x => x.active)
        }
        console.log(policy, 'Policy Configuration')
        dispatch(addPolicyAction(policy, null, null, appid, setCurrentStep))
    }

    const [taxType, setTaxType] = useState('')
    const [taxRate, setTaxRate] = useState('')
    const [taxApplicability, setTaxApplicability] = useState('')
    const [taxDeduction, setTaxDeduction] = useState('')
    const [platformStructure, setPlatformStructure] = useState('')
    const [platformApplicability, setPlatformApplicability] = useState('')
    const [platformFee, setPlatformFee] = useState('')

    const onChangeTaxRate = (e) => {
        setTaxRate(parseInt(e?.target?.value))
    }

    const onChangePlatformFee = (e) => {
        setPlatformFee(parseInt(e?.target?.value))
    }

    const saveWalletPolicy = () => {
        let policy = {
            currencyId: id,
            projectId: appid,
            
            policyDetails: [
                {
                    entity: 'wallet',
                    entitySubType: 'currency_debit',
                    rules: {
                        currencyDebit: {
                            transactionPriority: characters && characters.map(x => x?.name),
                            ...(isTax  ? 
                                {
                                    taxSettings: {
                                        tax_type: taxType,
                                        percentage: taxRate,
                                        applicableTo: taxApplicability,
                                        deductionRule: taxDeduction,
                                        taxInclusionMethod : (taxInclusive ? 'Inclusive' : 'Exclusive')
                                    }
                                }
                            : {taxSettings : null}),
                            platformFee: {
                                platformStructure: platformStructure,
                                applicableTo: platformApplicability,
                                percentage: ((platformStructure !== 'none' && platformStructure !== 'None') ? platformFee : null)
                            }
                        }
                    }
                }
            ]
        }
        console.log(policy, 'Policy Wallet')
        dispatch(addPolicyAction(policy, navigate, null, appid, setCurrentStep))
    }

    const cancel = () => {
        setIsChanged(false)
        navigate(`/currency/${appid}`)
    }

    const onTagInput = () => {
        if(document.getElementById('currencyTagInput')){
            document.getElementById('currencyTagInput').focus()
        }
    }

    const walletList = [
        {
            id: 1, 
            active: true, 
            entity: "wallet", 
            entitySubType: "deposit", 
            rules: {
                enableWithdrawal: false, 
                isTaxApplicable: false, 
                isKycRequired: false,
                info: {walletName: '', walletId: '', walletType: 'deposit'}, 
                depositControls: {
                    all: [
                        {fact: 'maxDepositPerTransaction', operator: 'lessThanInclusive', value: null},
                        {fact: 'minDepositPerTransaction', operator: 'greaterThanInclusive', value: null},
                        // {fact: 'dailyDepositLimit', operator: 'lessThanInclusive', value: null},
                        // {fact: 'weeklyDepositLimit', operator: 'lessThanInclusive', value: null},
                        // {fact: 'monthlyDepositLimit', operator: 'lessThanInclusive', value: null},
                    ]
                },
                balanceControls: null,
                // balanceControls: {
                //     all: [
                //         {fact: 'maxWalletBalance', operator: 'lessThanInclusive', value: null},
                //         {fact: 'minWalletBalanceForWithdrawal', operator: 'greaterThanInclusive', value: null},
                //     ]
                // },
                withdrawalControls : null,
                tax: null,
                compliance: null
            }
        },
        {
            id: 2, 
            active: true, 
            entity: "wallet", 
            entitySubType: "bonus", 
            rules: {
                enableWithdrawal: false, 
                isTaxApplicable: false, 
                isKycRequired: false,
                info: {walletName: '', walletId: '', walletType: 'bonus'},
                expiryAndClearance: null, 
                // expiryAndClearance: {
                //     all: [
                //         {fact: 'bonusExpiryPeriodInDays', operator: 'lessThanInclusive', value: null},
                //     ]
                // },
                tax: null,
                compliance: null
            }
        },
        {
            id: 3, 
            active: true, 
            entity: "wallet", 
            entitySubType: "winning", 
            rules: {
                enableWithdrawal: true, 
                isTaxApplicable: false, 
                isKycRequired: false,
                info: {walletName: '', walletId: '', walletType: 'winning'}, 
                depositControls: null,
                balanceControls: null,
                // balanceControls: {
                //     all: [
                //         {fact: 'maxWalletBalance', operator: 'lessThanInclusive', value: null},
                //         {fact: 'minWalletBalanceForWithdrawal', operator: 'greaterThanInclusive', value: null},
                //     ]
                // },
                withdrawalControls : {
                    all: [
                        {fact: "maxWithdrawalPerTransaction", operator: "lessThanInclusive", "value": null},
                        {fact: "minWithdrawalPerTransaction", operator: "greaterThanInclusive", "value": null},
                        // {fact: "dailyWithdrawalLimit", operator: "lessThanInclusive", "value": null},
                        // {fact: "weeklyWithdrawalLimit", operator: "lessThanInclusive", "value": null},
                        // {fact: "monthlyWithdrawalLimit", operator: "lessThanInclusive", "value": null},
                        // {fact: "withdrawalLockPeriodInDays", operator: "greaterThanInclusive", "value": null},
                    ]
                },
                tax: null,
                compliance: null
            }
        }
    ]

    const list = [{id: 'Deposit', name: 'Deposit'}, {id: 'Withdrawal', name: 'Withdrawal'}]

    const [wallets, setWallets] = useState(walletList)
    const [characters, updateCharacters] = useState(list)

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const itemsData = Array.from(characters);
        const [reorderedItem] = itemsData.splice(result.source.index, 1);
        itemsData.splice(result.destination.index, 0, reorderedItem);
        updateCharacters(itemsData);
    }
    
    const configureSteps = () => {
        updateCharacters(list)
    }

    const getAllMetaDataOptions = () => {
        dispatch(getAllMetaDataAction())
    }

    const [taxTypeOptions, setTaxTypeOptions] = useState([])
    const [taxApplicabilityOptions, setTaxApplicabilityOptions] = useState([])
    const [taxDeductionOptions, setTaxDeductionOptions] = useState([])
    const [platformStructureOptions, setPlatformStructureOptions] = useState([])
    const [platformApplicabilityOptions, setPlatformApplicabilityOptions] = useState([])

    const configureMetaData = () => {
        if(allMetaData && allMetaData?.tax){
            let meta = allMetaData.tax
            setTaxTypeOptions(Object.values(meta?.taxTypes))
            setTaxApplicabilityOptions(Object.values(meta?.taxApplicability))
            setTaxDeductionOptions(Object.values(meta?.taxDeductionPoint))
            setPlatformStructureOptions(Object.values(meta?.platformFeeStructure))
            setPlatformApplicabilityOptions(Object.values(meta?.platformFeeApplicability))
        }
    }

    let allMetaData = useSelector((state) => {
      return state.common.metaData
    })

    useEffect(()=>{ 
      configureMetaData()
    }, [allMetaData])

    useEffect(() => {
        configureSteps()
        getAllMetaDataOptions()
    }, [])

    const [currentStep, setCurrentStep] = useState(0)
    const totalSteps = 3

    const [isTax, setIsTax] = useState(true)
    const [taxInclusive, setTaxInclusion] = useState(false)

    const [visibleHelp, setVisibleHelp] = useState(false)
    const [media, setMedia] = useState([])
    
    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : 0
        }
        await dispatch(getAllMediaAction(getMediaData))
    }
    
    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })
    
    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }
    
    useEffect(() => {
        getMedia()
    }, [])
    
    useEffect(() => {
        configureMedia()
    }, [files])

    const resetTaxDeduction = () => {
        if((platformStructure === 'None' || platformStructure === 'none') && taxDeduction === 'Only Platform Fee'){
            setTaxDeduction('')
        }
    }

    useEffect(() => {
        resetTaxDeduction()
    }, [platformStructure])

    return (
        <>
            <ToastContainer />
            <ProgressTooltip>
                <div className={styles.progressBar}>
                    <div className={styles.progress}>
                        <div className={styles.counts}>
                            {Array.from({ length: totalSteps }, (_, index) => (
                                <>
                                    <div className={styles.stepsWrapper}>
                                        <div className={styles.steps}>
                                            <div className={cn(styles.step, {[styles.active] : currentStep === index}, {[styles.disabled]: currentStep > index})}><div className={styles.number}>{index+1}</div></div>
                                            <div className={cn(styles.check, {[styles.active] : currentStep > index})}><Icon name='check-circle' size='20' /></div>
                                            {/* <div onClick={()=> onNext(index)} className={cn(styles.step, {[styles.active] : currentStep === index}, {[styles.disabled] : currentStep > index})}><div className={styles.number}>{index+1}</div></div> */}
                                            <div className={styles.stepDetails}>{index === 0 ? 'RMG Currency Information' : index === 1 ? 'RMG Wallet Configuration' : index === 2 ? 'Transaction Settings' : ''}</div>
                                            {(index + 1 !== totalSteps) && <div className={cn(styles.line, {[styles.active] : currentStep > index})}></div>}
                                        </div>
                                    </div>
                                </>
                                // <div className={cn(styles.count, {[styles.three] : totalSteps === 3}, {[styles.completed]: currentStep >= index+1}, {[styles.active] : currentStep === index})}>
                                //     <div className={cn(styles.steps)}><div className={styles.step}>Step {index+1}</div></div>
                                // </div>
                            ))}
                        </div>
                    </div>
                </div>
            </ProgressTooltip>
            <div className={styles.wrapper}>
                <div className={styles.formContainer}>
                    <div className={styles.steps}>
                        {currentStep === 0 &&
                            <div className={styles.step}>
                                <Card className={cn(styles.card, className)} title="RMG Currency Information" classTitle="title-green">
                                    <div className={styles.description}>
                                        <div className={styles.preview}>
                                            <div className={styles.previewImage}>
                                                <img src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                                            </div>
                                            {!isDisabled &&
                                            <>
                                                <div className={styles.previewInput}> 
                                                    <input type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={ref} onChange={(e)=>{uploadLogo(e)}}/>
                                                    <label className={styles.button} onClick={() => setVisibleHelp(true)}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>
                                                </div>
                                                <div className={styles.previewCancel}>
                                                    {currencyIcon != null &&
                                                        <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                                                    }
                                                </div>
                                            </>
                                            }
                                        </div>
                                        <FileUploadSidebar media={media} setMedia={setMedia} types='Images' id={`icon`} multiple={false} visible={visibleHelp} setVisible={setVisibleHelp} icon={currencyIcon} setIcon={setCurrencyIcon} setValue={setLogo} onClose={onClose} />
                                        <div className={styles.group}>
                                            <TextInput disabled={isDisabled} className={styles.field} id='currencyName' tooltip={TooltipTitle.RMGName} label="Display Name*" name="DisplayName" type="text" required onChange={(e)=>{onDisplayName(e)}}/>
                                            <TextInput disabled={isDisabled} value={currencyId} isId={true} className={styles.field} id='currencyId' tooltip={TooltipTitle.RMGID} label="Currency ID*" name="currencyId" type="text" required onChange={(e)=>{onCurrencyId(e)}}/>
                                        </div>
                                        <div className={styles.group}>
                                            <TextInput disabled={isDisabled} className={styles.field} tooltip={TooltipTitle.RMGCode} label="Currency Code" name="CurrencyCode" type="text" required onChange={(e)=>{onCurrencyCode(e)}} />
                                            <TextInput disabled={true} className={styles.field} tooltip={TooltipTitle.RMGType} label="Currency Type*" name="currencyType" type="text" value='RMG' required />
                                        </div>
                                        <TextArea type='CREATE_CURRENCY' disabled={isDisabled} className={styles.field} rows={5} value={currencyDescription} tooltip={TooltipTitle.RMGDescription} label="Currency Description" onChange={(e)=>{onCurrecyDescription(e)}}/>
                                    </div>
                                </Card>

                                <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                                    <div className={styles.cardDescription}>
                                        
                                    <TagInputWithDropdown disabled={isDisabled} tooltip={TooltipTitle.RMGTags} tags={tags} setTags={setTags}/>

                                        <div className={styles.createCurrency}>
                                            <div className={styles.groupHeading}>
                                                <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.RMGMetaData} icon="info"/></span><span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>
                                            </div>
                            
                                            {metaData && metaData.length > 0 &&
                                                <div className={styles.groupMeta}>
                                                    <div className={styles.field}>Key</div>
                                                    <div className={styles.field}>Value</div>
                                                </div>
                                            }

                                            <div className={styles.groupMeta}>
                                                {metaData && metaData.map((e,i)=>{
                                                        return(
                                                            <>
                                                                <TextInput key={i} disabled={isDisabled} className={styles.field} label="" name="key" type="text" required value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''}   onChange={(e)=>{onChangeKey(e,i)}}/>
                                                                <JSONMetaDataValueInput key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                                                {/* <TextInput className={styles.field} label="" name="value" type="text" required value={(metaData[i].value != '' || metaData[i].value != undefined || metaData[i].value != null) ? metaData[i].value : ''} onChange={(e)=>{onChangeValue(e,i)}}/> */}
                                                                <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><Remove className={styles.icon} /></span>
                                                            </>
                                                        )
                                                })}
                                            </div>

                                        </div>
                                    </div>
                                </Card>

                                <Card className={cn(styles.card, className, {[styles.active] : true})}>
                                    <div className={styles.cardAction}>
                                        <div className={styles.cardSave}>
                                            <button className={cn(styles.button, 'button-save-small')} onClick={saveCurrency}>Next</button>
                                        </div>
                                        <div className={styles.cardCancel}>
                                            <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        }

                        {currentStep === 1 &&
                            <div className={styles.step}>
                                <Card className={cn(styles.card, className)} title="RMG Wallet Configuration" classTitle="title-purple">
                                    <div id='rmg_config' className={styles.wallet}>
                                        <div className={styles.configuration}>
                                            <WalletTable items={wallets} setValue={setWallets}/>
                                        </div>
                                    </div>
                                </Card>

                                <Card className={cn(styles.card, className)}>
                                    <div className={styles.cardAction}>
                                        <div className={styles.cardSave}>
                                            <button className={cn(styles.button, 'button-save-small')} onClick={savePolicy}>Next</button>
                                        </div>
                                        <div className={styles.cardCancel}>
                                            <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        }

                        {(currentStep === 2 || currentStep === 3) &&
                            <div className={styles.step}>
                                <Card className={cn(styles.card, className)} title="Internal Transaction Settings" classTitle="title-blue">
                                    <div id='rmg_compliance' className={styles.description}>

                                        <div className={styles.group}>
                                            <div className={styles.fieldRadio}>
                                                <div className={styles.radioLabel}>
                                                    <span>Is Tax required*<span><Tooltip className={styles.tooltip} icon="info"/></span></span>
                                                </div>
                                                <div className={styles.variants}>
                                                    <Radio className={styles.radio} name="isTax" value={isTax} onChange={() => setIsTax(true)} content="Yes" />
                                                    <Radio className={styles.radio} name="isTax" value={!isTax} onChange={() => setIsTax(false)} content="No" />
                                                </div>
                                            </div>
                                           {isTax &&
                                                <div className={styles.fieldRadio}>
                                                    <div className={styles.radioLabel}>
                                                        <span>Tax Inclusion Method* <span><Tooltip className={styles.tooltip} title={TooltipTitle.RMGTaxInclusionMethod} icon="info"/></span></span>
                                                    </div>
                                                    <div className={styles.variants}>
                                                        <Radio className={styles.radio} name="taxInclusive" value={!taxInclusive} onChange={(e) => setTaxInclusion(false)} content="Exclusive" />
                                                        <Radio className={styles.radio} name="taxInclusive" value={taxInclusive} onChange={(e) => setTaxInclusion(true)} content="Inclusive" />
                                                    </div>
                                                </div>
                                           }
                                        </div>


                                        {isTax &&
                                            <>
                                                <div className={styles.group}>
                                                    <div className={styles.field}>
                                                        <Dropdown label='Tax Type*' placeholder='Select Tax Type' options={taxTypeOptions} value={taxType} setValue={setTaxType}/>
                                                    </div>
                                                    <TextInput className={styles.field} id='taxRate' tooltip={TooltipTitle.RMGTaxRate} label={`${taxType} Rate (%)*`} name="taxRate" type="text" required  onChange={(e) => {onChangeTaxRate(e)}}/>
                                                </div>

                                                <div className={styles.group}>
                                                    <div className={styles.field}>
                                                        <Dropdown label='Tax Applicability*' placeholder='Select Tax Applicability' options={taxApplicabilityOptions} value={taxApplicability} setValue={setTaxApplicability}/>
                                                    </div>
                                                    <div className={styles.field}>
                                                        <Dropdown label='Tax Deduction Point*' placeholder='Select Tax Deduction Point' options={taxDeductionOptions} value={taxDeduction} setValue={setTaxDeduction} disabledOption={(platformStructure !=='' && platformStructure !== 'None' && platformStructure !== 'none') ? '' : 'Only Platform Fee'}/>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        <div className={styles.group}>
                                            <div className={styles.field}>
                                                <Dropdown label='Platform Fee Structure*' placeholder='Select Platform Fee Structure' options={platformStructureOptions} value={platformStructure} setValue={setPlatformStructure}/>
                                            </div>
                                            {(platformStructure !=='' && platformStructure !== 'None' && platformStructure !== 'none') &&
                                                <div className={styles.field}>
                                                    <Dropdown label='Platform Fee Applicability*' placeholder='Select Platform Fee Applicability' options={platformApplicabilityOptions} value={platformApplicability} setValue={setPlatformApplicability}/>
                                                </div>
                                            }
                                        </div>

                                        {(platformStructure !=='' && platformStructure !== 'None' && platformStructure !== 'none') &&
                                            <div className={styles.group}>
                                                <TextInput disabled={isDisabled} className={styles.field} tooltip={TooltipTitle.RMGFixedPlatformFee} label="Platform Fee" name="platformFee" type="text" required value={platformFee} onChange={(e) => {onChangePlatformFee(e)}}/>
                                            </div>
                                        }
                                    </div>

                                    <div className={styles.priority}>
                                        <div className={styles.title}>
                                            Wallet Priority <span><Tooltip className={styles.tooltip} title={TooltipTitle.RMGWalletPriority} icon="info"/></span>
                                        </div>
                                        <div className={styles.table}>
                                            <div className={styles.container} style={{cursor: 'not-allowed'}}>
                                                <div className={styles.cardContainer}>
                                                    <div className={styles.name}>Bonus</div>
                                                </div>
                                            </div>
                                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                                <Droppable droppableId="characters">
                                                    {(provided) => (
                                                        <div className={styles.container} {...provided.droppableProps} ref={provided.innerRef}>
                                                        {characters && characters.map((x, index) => {
                                                            return(
                                                            <Draggable key={x.id} draggableId={x.id} index={index}>
                                                                {(provided) => (
                                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <div className={styles.cardContainer}>
                                                                        <div className={styles.name}>{x?.name}</div>
                                                                    </div>
                                                                </div>
                                                                )}
                                                            </Draggable>
                                                            )
                                                        })}
                                                        {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </div>
                                    </div>
                                </Card>

                                <Card className={cn(styles.card, className)}>
                                    <div className={styles.cardAction}>
                                        <div className={styles.cardSave}>
                                            <button className={cn(styles.button, 'button-save-small')} onClick={saveWalletPolicy}>Save</button>
                                        </div>
                                        <div className={styles.cardCancel}>
                                            <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        }
                    </div>
                </div>

            </div>
        </>
    );
};

export default CurrencyInformation;