import React, { useEffect, useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control";
import Schedule from "./Schedule";
import { Link, useParams } from "react-router-dom";
import Task from '../../../../../media/images/placeholders/TAsk.png'
import Tooltip from '../../../../CustomTooltips/ReusableTooltip'
import DateTooltip from '../../../../CustomTooltips/DateTooltip'
import TooltipGlodal from "../../../../TooltipGlodal";
import moment from "moment";
import 'moment-timezone';
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { extarctImageFromUrlAction } from "../../../../../redux/action/common/commonAction";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({ item, className, isLast, access, value, onChange }) => {
    
    const {appid} = useParams()
    const dispatch = useDispatch()

    const extractImageFromCdn = async(url, id) => {
        if(url !== null && url !== undefined && url.includes('cdn')){
            try {
                const response = await dispatch(extarctImageFromUrlAction(url))
                const logo = document.getElementById(`taskIcon_${item.id}`)
                if(logo){
                    logo.src = response
                }
            }catch (error){
                console.error('Error fetching image:', error);
            }
        }else{
            const logo = document.getElementById(`taskIcon_${item.id}`)
            if(logo){
                logo.src = Task
            }
        }
    }

    useEffect(() => {
        extractImageFromCdn(item.iconUrl, item.id)
    }, [item])

    let userTimeZone = useSelector((state) => {
        return state.auth.user.timezone        
    })

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    {item.iconUrl !== undefined ?
                        <div className={cn(styles.preview, className, {[styles.bg] : true})}>
                            <img id={`taskIcon_${item.id}`} alt="Task" />
                        </div>
                        :
                        <div className={styles.preview}></div>
                    }
                </div>
                <div className={styles.col}>
                    <Link to={`/view-task/${appid}/${item.id}`}>
                        <TooltipGlodal width={180} itemId={`taskName${item.id}`} title={item.name}><div className={styles.itemName}><span id={`taskName${item.id}`}>{item.name}</span></div></TooltipGlodal>
                        <div className={styles.itemId}>{item.taskId}</div>
                    </Link>
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 && 
                        <>
                            {[...item.currencies, ...item.items, ...item.bundles, ...item.progressionMarkers] && [...item.currencies, ...item.items, ...item.bundles, ...item.progressionMarkers].slice(0,1).map((e,i)=>{
                                return(
                                    <>
                                        <div className={styles.prices}>
                                            <div className={styles.rewards}>{e.name}</div><span>({e.quantity})</span>
                                            {([...item.currencies, ...item.items, ...item.bundles, ...item.progressionMarkers] && [...item.currencies, ...item.items, ...item.bundles, ...item.progressionMarkers].length > 1) ? 
                                                <Tooltip type='Task' text='Rewards' data={[...item.currencies, ...item.items, ...item.bundles, ...item.progressionMarkers]}><span className={styles.count}>+ {[...item.currencies, ...item.items, ...item.bundles, ...item.progressionMarkers].length - 1}</span></Tooltip>  
                                            : ''}
                                        </div>
                                    </>   
                                ) 
                            })}
                        </>
                    }
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 && 
                        <>
                            {item.startDate == null && item.endDate == null && <>-</>}
                            {(item.startDate != null || item.endDate != null) &&
                                <>
                                    <DateTooltip userTimeZone={userTimeZone} isLast={isLast} timeZone={item.timezone} startDate={item.startDate} endDate={item.endDate}>
                                        <>
                                            {item.startDate != null ? moment(item.startDate).tz(userTimeZone).format('MMM DD') : ''}  {item.endDate != null ? <> &#8208; {moment(item.endDate).tz(userTimeZone).format('MMM DD')} </> : <><span>&#45;</span> <span className={styles.infinity}><span className={styles.icon}>&#8734;</span></span></>}
                                        </>
                                    </DateTooltip>
                                    {(item.startDate != null && item.endDate != null && item.recurrenceCount != null && item.recurrenceCountLeft != null) && 
                                        <div className={styles.instance}>
                                            {(item.recurrenceCount != null && item.recurrenceCountLeft != null ? (item.recurrenceCount - item.recurrenceCountLeft) : '')}/{(item.recurrenceCount != null ? item.recurrenceCount : '')}&nbsp; ({item.recurrenceFrequency != null ? item.recurrenceFrequency : ''} {item.intervalUnit != null ? item.intervalUnit.intervalName : ''})
                                        </div>
                                    }
                                </>
                            }
                        </>
                    }
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 && 
                        <Schedule userTimeZone={userTimeZone} usedFor='instance' as='schedule' type='Task' id={item.id} name={item.name} status={item.status} instanceStatus={item.instanceStatus} instanceStart={item.currentStartDate != null ? item.currentStartDate : null} instanceEnd={item.currentEndDate != null ? item.currentEndDate : null} timeZone={item.timezone} />
                    }         
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 && 
                        <Schedule userTimeZone={userTimeZone} usedFor='status' as='schedule' type='Task' id={item.id} name={item.name} status={item.status} instanceStatus={item.instanceStatus} instanceStart={item.currentStartDate != null ? item.currentStartDate : null} instanceEnd={item.currentEndDate != null ? item.currentEndDate : null} timeZone={item.timezone} />
                    }
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 && 
                        <Control access={access} className={styles.control} appid={appid} id={item.id} name={item.name} status={item.archive} type='task' />
                    }
                </div>
            </div>
        </>
    );
};

export default Row;
