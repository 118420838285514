import React, {useState, useEffect, useContext} from 'react'
import cn from 'classnames'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './apps.module.sass'
import Card from '../../../../components/Card'
import Icon from '../../../../components/Icon'
import addIcon from '../../../../media/images/icons/light.png'
import Form from '../../../../components/Form'
import { ToastContainer } from 'react-toastify';
import AppTableLayout from '../../../../components/Organisation/AppSetting/AppTableLayout'
import { getAcceptedMembersAction, getInvitedMembersAction } from '../../../../redux/action/AppSettings/Members/MembersAction'
import { filterAppsAction, getListOfAppsAction } from '../../../../redux/action/apps/appsAction'
import Control from '../../../Organization/Apps/GetApps/Control'
import Filters from './Filters/Control'
import Loader from '../../../../components/Loaders/LoaderProgress'
import EmptyStates from '../../../../components/EmptyStates'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {ReactComponent as ArchiveSvg} from '../../../../media/images/icons/archive.svg'
import { Skeleton } from '@mui/material'
import SkeletonTable from '../../../../components/Skeleton/TableSkeleton'
import GlobalStateContext from '../../../../components/Context/GlobalStates/GlobalStateContext'
import SkeletonEmptyState from '../../../../components/Skeleton/EmptyStateSkeleton'
import OrganizationHeader from '../../../../components/Header/OrganizationHeader'

const Apps = ({className, setAppName, setShowLoader, setLoaderType}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const globalStates = useContext(GlobalStateContext)
  // Skeleton
  const [showSkeleton, setShowSkeleton] = useState(true)
  const [page, setPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  // Search
  const [search, setSearch] = useState(null)
  // Sort
  const sortList = [{id: 1, name: 'App Name', value: 'name'}, {id: 2, name: 'Created At', value: 'created_at'}, {id: 3, name: 'Updated At', value: 'updated_at'}]
  const [sortValue, setSortValue] = useState({name: 'updated_at', value: 0})
  const [selectedSort, setSelectedSort] = useState('updated_at')
  const [sort, setSort] = useState([{id: 1, name: 'Ascending'}, {id : 2, name : 'Descending'}])
  const [sortIndex, setSortIndex] = useState(0)

  const getAllApps = async(search) => {
    let allApps = {
      // Search
      ...(search && search !== null && search !== '' && {search : search}),
      //Sort
      sortOrder : (sortIndex == 0 ? 'asc' : 'desc'),
      sortField : selectedSort,
      offset: ((page-1)*limit),
      limit: limit,
      organisationId: localStorage.getItem('orgId')
    }
    if(!search){
      await dispatch(getListOfAppsAction(allApps, configureSkeleton))
    }else{
      await dispatch(filterAppsAction(allApps, configureSkeleton))
    }
  }

  const configureSkeleton = () => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
  }

  useEffect(() =>{
    if(selectedSort !== null){
      getAllApps(search)
    }
  }, [page, selectedSort])

  let apps = useSelector((state) => {
    return state.apps.appList
  })

  let isLoading = useSelector((state) => {
    return state.apps.isLoading
  })

  let isFiltered = useSelector((state) => {
    return state.apps.isFiltered
  })

  let totalApps = useSelector((state) => {
    return state.apps.totalAppCount
  })
  
  const handlePageChange = (event, value)  => {
    setPage(value)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const onSearch = (search) => {
    if(search == ''){
      setSearch(null)
      getAllApps(null)
    }else{
      setSearch(search)
      getAllApps(search)
    }
  }

  // Loader
  if(isLoading){
    return(
      <>
        <div className={styles.loading}>
          <Loader />
        </div>
      </>
    )
  }

  return(
    <>
      <ToastContainer />
      <OrganizationHeader title='Apps' search={search} setSearch={onSearch} data={sortList} setPage={setPage} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selectedSort} setSelected={setSelectedSort} sort={sort} setSort={setSort} value={sortValue} setValue={setSortValue} setAppName={setAppName} setShowLoader={setShowLoader} setLoaderType={setLoaderType}/>
      <Card layOut={`Get`} className={styles.card} classCardHead={styles.head}> 

        <div className={styles.app}>

        <div className={styles.wrapper}>

          {/* Currency Table View */}
          {showSkeleton && apps?.length === 0 && 
            <SkeletonEmptyState />
          }
          {showSkeleton && apps?.length > 0 && 
            <SkeletonTable rows={apps.length} column={6} controls={2} type={['recatangle', 'recatangle', 'recatangle', 'reactangle', 'reactangle', 'circular']} colWidth={[36, 110, 110, 110, 110, 36]} colHeight={[36, 15, 25, 25, 25, 36]} />
          }
          {!showSkeleton && !isFiltered && apps?.length === 0 &&
            <EmptyStates type='Apps' />
          }
          {!showSkeleton && !isFiltered && apps?.length > 0 &&
            <AppTableLayout to='accepted' setAppName={setAppName} setLoaderType={setLoaderType} setShowLoader={setShowLoader} items={apps} userType={globalStates?.user?.type} title="Last edited" />
          }
          {!showSkeleton && isFiltered &&
            (apps?.length > 0 ?
              <AppTableLayout to='accepted' setAppName={setAppName} setLoaderType={setLoaderType} setShowLoader={setShowLoader} items={apps} userType={globalStates?.user?.type} title="Last edited" />
            :
              <div className={styles.message}>No Result Found</div>
            )
          }
          </div>

          {!isLoading && apps.length > 0 &&
          <div className={styles.pagination}>
            <Stack spacing={2}>
              <Pagination page={page} renderItem={(item) => (
                <PaginationItem
                  {...item}
                  sx={{
                    '&.MuiPaginationItem-root': {
                      minWidth: '28px',
                      height: '28px',
                      fontSize: '12px'
                    },
                    '&.Mui-selected': {
                      color: 'rgb(255, 255, 255)',
                      fontWeight : 'bold',
                      border : '1px solid rgb(42, 133, 255)',
                      background : 'rgba(42, 133, 255)'
                    },
                    '&.MuiPaginationItem-page.Mui-selected:hover' : {
                      color: 'rgb(255, 255, 255)',
                      fontWeight : 'bold',
                      border : '1px solid rgb(42, 133, 255)',
                      background : 'rgba(42, 133, 255)'
                    }
                  }}
                />
              )} siblingCount={1} count={Math.ceil(totalApps/limit)} color="primary" onChange={handlePageChange} />
            </Stack>
          </div>
          }

        </div>
          

      </Card>
    </>
  )

}

export default Apps