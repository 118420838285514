import React from "react";
import { Link } from "react-router-dom";
import UnAuthorizedImage from "../../media/images/401-error-wordpress-featured-image__1_-ai-brush-removebg-rbqwpyoc.png";
import cn from "classnames";
import styles from "./pageNotFound.module.sass";
import { ReactComponent as SpecterLogo } from "../../media/images/Specter_Logo.svg";
import { ReactComponent as HalfCircle } from "../../media/images/half_ellipse.svg";
import { ReactComponent as Cross } from "../../media/images/hollow_crossed.svg";
import { ReactComponent as FullCircle } from "../../media/images/full_circle.svg";
import { ReactComponent as SmallerFullCircle } from "../../media/images/full_circle.svg";
import { ReactComponent as FullHollowCircle } from "../../media/images/full_hollow_circle.svg";
import { ReactComponent as HollowCross } from "../../media/images/x_fill.svg";

function PageNotFound() {
  return (
    <div className={styles.container}>
      {/* Branding Elements */}
      <div className={styles.branding}>
        <HalfCircle className={styles.halfCircle} />
        <Cross className={styles.crossFilled} />
        <FullCircle className={styles.fullCircle} />
        <FullHollowCircle className={styles.hollowCircle} />
        <HollowCross className={styles.hollowCross} />
        <SmallerFullCircle className={styles.smallerFullCircle} />
      </div>

      {/* Main Content */}
      <div className={styles.content}>
        <h1 className={styles.title}>404 - Not Found</h1>
        <p className={styles.subtitle}>
            We couldn’t find the page you’re looking for.
        </p>
        <img
          className={styles.unauthorizedImage}
          src={UnAuthorizedImage}
          alt="Unauthorized"
        />
        <Link className={styles.goHomeButton} to="/home">
          Go Home
        </Link>
      </div>
    </div>
  );
}

export default PageNotFound