import React, {useContext, useState} from 'react'
import cn from "classnames";
import styles from "./getPlayer.module.sass";
import Form from '../../../../components/Form';
import Icon from "../../../../components/Icon";
import Card from "../../../../components/Card";
import PlayersTableLayout from '../../../../components/EngageComponents/PlayersComponent/PlayersTableComponent'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { filterPlayerAction, getAllPlayersAction } from '../../../../redux/action/engage/Players/PlayersAction';
import { Link } from 'react-router-dom';
import addIcon from '../../../../media/images/icons/light.png'
import Control from './Control';
import { ToastContainer } from 'react-toastify';
import {ReactComponent as Previous} from '../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../media/images/icons/next.svg'
import EmptyStates from '../../../../components/EmptyStates';
import Loader from '../../../../components/Loader';
import Loaders from '../../../../components/Loaders/LoaderProgress';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {ReactComponent as ArchiveSvg} from '../../../../media/images/icons/archive.svg'
import { Skeleton } from '@mui/material';
import SkeletonTable from '../../../../components/Skeleton/TableSkeleton';
import SkeletonEmptyState from '../../../../components/Skeleton/EmptyStateSkeleton';
import GlobalStateContext from '../../../../components/Context/GlobalStates/GlobalStateContext';
import Navigation from '../../../../components/Header/SecondaryNavigation'
const sorting = ["list", "grid"];

const GetPlayers = () => {

    const {appid} = useParams()
    const dispatch = useDispatch()
    const [activeIndex, setActiveIndex] = useState(0);
    // Search
    const [search, setSearch] = useState(null)
    const [page, setPage] = useState(1)
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(10)

    const [userAccess, setUserAccess] = useState(false)
    const globalStates = useContext(GlobalStateContext)

    const getUserAccess = () => {
      if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
        let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
        if(appPermission?.length > 0){
          setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
        }
      }
    }
  
    useEffect(() => {
      getUserAccess()
    }, [])

    const getAllPlayers = async(search) => {
      let playersData = {
        offset: ((page-1)*limit),
        limit: limit,
        ...(search && search !== null && search !== '' && {search : search}),
        projectId : appid
      }
      if(!search){
        await dispatch(getAllPlayersAction(playersData, configureSkeleton))
      }else{
        await dispatch(filterPlayerAction(playersData, configureSkeleton))
      }
    }

    let allPlayers = useSelector((state) => {
      return state.players.players
    })

    let totalPlayers = useSelector((state) => {
      return state.players.totalCount
    })

    let isLoading = useSelector((state) => {
      return state.players.isLoading
    })

    let isFiltered = useSelector((state)=>{
      return state.currencies.isFiltered
    })

    const handlePageChange = (event, value)  => {
      setPage(value)
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const [showSkeleton, setShowSkeleton] = useState(true)

    const configureSkeleton = () => {
      setShowSkeleton(true)
      setTimeout(() => {
        setShowSkeleton(false)
      }, 1000)
    }
  
    useEffect(()=>{
      getAllPlayers(search)
    }, [page])

    const onSearch = (search) => {
      if(search == ''){
        setSearch(null)
        getAllPlayers(null)
      }else{
        setSearch(search)
        getAllPlayers(search)
      }
    }

    // Loader
    if(isLoading){
      return(
        <>
          <div className={styles.loader}>
            <Loaders />
          </div>
        </>
      )
    }
      
    return (
        <>
            <Navigation type='Players' search={search} setSearch={onSearch} />
            <ToastContainer />
            <Card layOut={`Get`} className={styles.card} classCardHead={styles.head}>
            <div className={styles.wrapper}>
                {/* Currency Table View */}
                {showSkeleton && allPlayers?.length === 0 && 
                  <SkeletonEmptyState />
                }
                {showSkeleton && allPlayers?.length > 0 && 
                  <SkeletonTable usedFor='Players' rows={allPlayers.length} column={6} controls={1} type={['circular', 'recatangle', 'recatangle', 'recatangle', 'reactangle', 'circular']} colWidth={[36, 100, 80, 80, 80, 36]} colHeight={[36, 15, 20, 20, 20, 36]} />
                }
                {!showSkeleton && !isFiltered && allPlayers?.length === 0 &&
                  <EmptyStates  type="Player" action={`Create Player`}/>
                }
                {!showSkeleton && !isFiltered && allPlayers?.length > 0 &&
                  <PlayersTableLayout items={allPlayers} title="Last edited" /> 
                }
                {!showSkeleton && isFiltered &&
                  (allPlayers?.length > 0 ?
                    <PlayersTableLayout items={allPlayers} title="Last edited" /> 
                  :
                    <div className={styles.message}>No Result Found</div>
                  )
                }
            
              {!isLoading && allPlayers.length > 0 &&
                <div className={styles.pagination}>
                  <Stack spacing={2}>
                    <Pagination  page={page} renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        sx={{
                          '&.MuiPaginationItem-root': {
                            minWidth: '28px',
                            height: '28px',
                            fontSize: '12px'
                          },
                          '&.Mui-selected': {
                            color: 'rgb(255, 255, 255)',
                            fontWeight : 'bold',
                            border : '1px solid rgb(42, 133, 255)',
                            background : 'rgba(42, 133, 255)'
                          },
                          '&.MuiPaginationItem-page.Mui-selected:hover' : {
                            color: 'rgb(255, 255, 255)',
                            fontWeight : 'bold',
                            border : '1px solid rgb(42, 133, 255)',
                            background : 'rgba(42, 133, 255)'
                          }
                        }}
                      />
                    )} siblingCount={1} count={Math.ceil(totalPlayers/limit)} color="primary" onChange={handlePageChange} />
                  </Stack>
                </div>
              }
            </div>

            </Card>
          <div className="divider"></div>
          <div className={styles.msg}>
            <p className={styles.alert}>Learn more about Players <a className={styles.infoLink} href="https://manual.specterapp.xyz/specter-user-manual/engage/players" target="_blank">Click Here</a></p>
          </div>
        </>
    )
}

export default GetPlayers