import React, {useState, useEffect} from 'react'
import Card from '../../../../../components/Card'
import TextInput from '../../../../../components/TextInput'
import Radio from '../../../../../components/Radio'
import styles from './wallets.module.sass'
import OverviewTableLayout from '../../../../../components/EngageComponents/PlayersComponent/walletOverviewTable'
import TransactionHistoryTableLayout from '../../../../../components/EngageComponents/PlayersComponent/transactionHistory'
import Control from './Control'
import { getPlayerDetailAction } from '../../../../../redux/action/engage/Players/PlayersAction'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {ReactComponent as Previous} from '../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import Dropdown from '../../../../../components/Dropdown/CommonDropdown'

const Wallets = ({activeIndex, access}) => {

  const {appid} = useParams()
  const {id} = useParams()
  const dispatch = useDispatch()

  const typesOption = [{id: 'virtual', name: 'Virtual'}, {id: 'real', name: 'RMG'}]
  const [page, setPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [overviewPage, setOverviewPage] = useState(1)
  const [type, setType] = useState('Virtual')
  const [walletOverview, setWalletOverview] = useState([])

  const getPlayerDetails = async() => {
    let playerDetail = {
      entities : {value : 'wallets', offset: ((page-1)*limit), limit: limit},
      projectId : appid,
      ids : [id]
    }
    await dispatch(getPlayerDetailAction(playerDetail, activeIndex))
  }

  const onOverviewPageChange = (event, value) => {
    setOverviewPage(value)
    configureOverviewWallet(value)
  }

  const playerWalletOverview = useSelector((state) => {
    return state.players.walletOverview
  })

  const playerWalletHistory = useSelector((state) => {
    return state.players.walletHistory
  })

  const rmgWalletHistory = useSelector((state) => {
    return state.players.rmgWalletHistory
  })

  let totalCount = useSelector((state) => {
    return state.players.totalWalletCount
  })

  let totalRMGWalletCount = useSelector((state) => {
    return state.players.totalRMGWalletCount
  })

  const handlePageChange = (event, value)  => {
    setPage(value)
  }

  const configureOverviewWallet = (overviewPage) => {
    if(playerWalletOverview && playerWalletOverview?.length > 0){
      console.log(playerWalletOverview, 'wallet')
      setWalletOverview(playerWalletOverview.slice(((overviewPage-1)* 10), (overviewPage * 10)))
    }
  }

  useEffect(() => {
    configureOverviewWallet(overviewPage)
  }, [playerWalletOverview])

  useEffect(()=>{
    getPlayerDetails()
  }, [activeIndex, page])


  return (
    <>
      <Card title="Overview" classTitle="title-purple">
        {playerWalletOverview && playerWalletOverview.length > 0 ?
          <>
            <OverviewTableLayout items={walletOverview} /> 
            <div className={styles.pagination}>
              <Stack spacing={2}>
                <Pagination renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    sx={{
                      '&.MuiPaginationItem-root': {
                        minWidth: '28px',
                        height: '28px',
                        fontSize: '12px'
                      },
                      '&.Mui-selected': {
                        color: 'rgb(255, 255, 255)',
                        fontWeight : 'bold',
                        border : '1px solid rgb(42, 133, 255)',
                        background : 'rgba(42, 133, 255)'
                      },
                      '&.MuiPaginationItem-page.Mui-selected:hover' : {
                        color: 'rgb(255, 255, 255)',
                        fontWeight : 'bold',
                        border : '1px solid rgb(42, 133, 255)',
                        background : 'rgba(42, 133, 255)'
                      }
                    }}
                  />
                )} siblingCount={1} count={Math.ceil(playerWalletOverview?.length/limit)} color="primary" onChange={onOverviewPageChange} />
              </Stack>
            </div>
          </>
          : 
          <div className={styles.warning}>No Data Available</div>
        }
      </Card>
      <Card title="Transaction History" classTitle="title-purple" 
        head={
          <>
            <div className={styles.head}>
              <Dropdown options={typesOption} value={type} setValue={setType} />
            </div>
          </>
        }>
        <div>
          <Control type={type} access={access} activeIndex={activeIndex}/>
        </div>  
        <div className={styles.tableWrapper}>
          <div className={styles.group}>
            {((type === 'Virtual' && playerWalletHistory && playerWalletHistory.length > 0) || (type === 'RMG' && rmgWalletHistory && rmgWalletHistory?.length > 0)) ?
              <>
                <TransactionHistoryTableLayout items={type === 'Virtual' ? playerWalletHistory : rmgWalletHistory}/>
                <div className={styles.pagination}>
                  <Stack spacing={2}>
                    <Pagination renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        sx={{
                          '&.MuiPaginationItem-root': {
                            minWidth: '28px',
                            height: '28px',
                            fontSize: '12px'
                          },
                          '&.Mui-selected': {
                            color: 'rgb(255, 255, 255)',
                            fontWeight : 'bold',
                            border : '1px solid rgb(42, 133, 255)',
                            background : 'rgba(42, 133, 255)'
                          },
                          '&.MuiPaginationItem-page.Mui-selected:hover' : {
                            color: 'rgb(255, 255, 255)',
                            fontWeight : 'bold',
                            border : '1px solid rgb(42, 133, 255)',
                            background : 'rgba(42, 133, 255)'
                          }
                      }}
                    />
                  )} siblingCount={1} count={Math.ceil(type === 'Virtual' ? Math.ceil(totalCount/limit) : Math.ceil(totalRMGWalletCount/limit))} color="primary" onChange={handlePageChange} />
                </Stack>
                </div>
                </>
              : <div className={styles.warning}>No Data Available</div>}
            </div>
          </div>
        </Card> 
    </>
  )
}

export default Wallets