import React, {useContext, useEffect, useState} from 'react'
import cn from "classnames";
import { Link, useNavigate, useParams } from "react-router-dom";
import CompetitionTournamentTableComponent from '../../../../../components/EngageComponents/CompetitionComponent/CompetitionTournamentTableComponent'
import { useDispatch, useSelector } from 'react-redux';
import Form from '../../../../../components/Form';
import Icon from "../../../../../components/Icon";
import Card from "../../../../../components/Card";
import addIcon from '../../../../../media/images/icons/light.png'
import styles from './competition.module.sass'
import Loader from '../../../../../components/Loaders/LoaderProgress';
import { filterCompetitionAction, getCompetitionTournamentAction } from '../../../../../redux/action/engage/Competitions/competitionAction';
import EmptyState from '../../../../../components/EmptyStates'
import {ReactComponent as Previous} from '../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {ReactComponent as ArchiveSvg} from '../../../../../media/images/icons/archive.svg'
import { Skeleton } from '@mui/material';
import SkeletonTable from '../../../../../components/Skeleton/TableSkeleton';
import SkeletonEmptyState from '../../../../../components/Skeleton/EmptyStateSkeleton';
import GlobalStateContext from '../../../../../components/Context/GlobalStates/GlobalStateContext';
import { ToastContainer } from 'react-toastify';
import Calendar from '../../../LiveOps/Calendar';
import Control from "./Filters/Control";
import moment from 'moment';
import Navigation from '../../../../../components/Header/SecondaryNavigation';
const sorting = ["list", "grid"];

const GetCompetitionTournament = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {appid} = useParams()
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeViewIndex, setActiveViewIndex] = React.useState(0);
    const modes = [{value:"list",icon:"list"}, {value:"calendar",icon:"calendar"}];
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    // Search
    const [search, setSearch] = useState(null)
    // Sort
    const sortList = [{id: 1, name: 'Tournament Name', value: 'name'}, {id: 2, name: 'Created At', value: 'created_at'}, {id: 3, name: 'Updated At', value: 'updated_at'}]
    const [sortValue, setSortValue] = useState({name: 'updated_at', value: 0})
    const [selectedSort, setSelectedSort] = useState('updated_at')
    const [sort, setSort] = useState([{id: 1, name: 'Ascending'}, {id : 2, name : 'Descending'}])
    const [sortIndex, setSortIndex] = useState(0)
    // Filters
    const [source, setSource] = useState([])
    const [minPlayer, setMinPlayer] = useState([0,0])
    const [maxPlayer, setMaxPlayer] = useState([0,0])
    const [isEntryPriced, setIsEntryPriced] = useState(null)
    const [priceType, setPriceType] = useState([])
    const [maxEntryAllowed, setMaxEntryAllowed] = useState(null)
    const [maxAttemptAllowed, setMaxAttemptAllowed] = useState(null)
    const [isPrizeConfigured, setIsPrizeConfigured] = useState(null)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [recurrance, setRecurrance] = useState(null)
    const [recurranceInterval, setRecurranceInterval] = useState(null)
    const [recurranceIntervalId, setRecurranceIntervalId] = useState(null)
    const [status, setStatus] = useState([])
    const [instanceStatus, setInstanceStatus] = useState([])
    const filters = {source, setSource, minPlayer, setMinPlayer, maxPlayer, setMaxPlayer, isEntryPriced, setIsEntryPriced, priceType, setPriceType, maxEntryAllowed, setMaxEntryAllowed, maxAttemptAllowed, setMaxAttemptAllowed, isPrizeConfigured, setIsPrizeConfigured, startDate, setStartDate, endDate, setEndDate, recurrance, setRecurrance, recurranceInterval, setRecurranceInterval, recurranceIntervalId, setRecurranceIntervalId, status, setStatus, instanceStatus, setInstanceStatus}
    // User Access
    const [userAccess, setUserAccess] = useState(false)
    const globalStates = useContext(GlobalStateContext)
  
    const getUserAccess = () => {
      if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
        let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
        if(appPermission?.length > 0){
          setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
        }
      }
    }
    
    useEffect(() => {
      getUserAccess()
    }, [])
  
    const getCompetitionTournament = async(search) => {
      let getTournaments = {
        projectId : appid,
        competitionFormatTypeMasterId : 2,
        // Search
        ...(search && search !== null && search !== '' && {search : search}),
        //Filters
        ...(source?.length > 0 && {sourceTypeId: source}),
        ...(minPlayer[1] > 0 && {minPlayerStart: minPlayer[0]}),
        ...(minPlayer[1] > 0 && {minPlayerEnd: minPlayer[1]}),
        ...(maxPlayer[1] > 0 && {maxPlayerStart: maxPlayer[0]}),
        ...(maxPlayer[1] > 0 && {maxPlayerEnd: maxPlayer[1]}),
        ...(isEntryPriced != null && {isEntryPriced: isEntryPriced}),
        ...(priceType?.length > 0 && {priceType: priceType}),
        ...((maxEntryAllowed != null && maxEntryAllowed !== '') && {maxEntryAllowed: maxEntryAllowed}),
        ...((maxAttemptAllowed != null && maxAttemptAllowed !== '') && {maxAttemptAllowed: maxAttemptAllowed}),
        ...(isPrizeConfigured  != null && {isPrizeConfigured: isPrizeConfigured}),
        ...((recurrance !== null && recurrance !== '') && {recurrenceFrequency: recurrance}),
        ...((recurranceIntervalId !== null && recurrance !== '') && {intervalUnitId: recurranceIntervalId}),
        ...(startDate !== '' && {startDate : (moment(startDate).format('YYYY-MM-DD')).concat(' 00:00:00+00:00')}),
        ...(endDate !== '' && {endDate : (moment(endDate).format('YYYY-MM-DD')).concat(' 00:00:00+00:00')}),
        ...(status?.length > 0 && {status : status}),
        ...(instanceStatus?.length > 0 && {instanceStatus : instanceStatus}),
        //Sort
        sortOrder : (sortIndex == 0 ? 'asc' : 'desc'),
        sortField : selectedSort,
        offset: ((page-1)*limit),
        limit: limit
      }
      let keysToCheck = ['source', 'priceType', 'status', 'instanceStatus']
      let isFiltered = await keysToCheck.every(key => filters[key]?.length === 0)
      let isChecked = await (filters.hasOwnProperty('isPrizeConfigured') && filters.isPrizeConfigured === null && filters.isPrizeConfigured !== '') && (filters.hasOwnProperty('isEntryPriced') && filters.isEntryPriced === null && filters.isEntryPriced !== '') && (filters.hasOwnProperty('maxEntryAllowed') && filters.maxEntryAllowed === null && filters.maxEntryAllowed !== '') && (filters.hasOwnProperty('maxAttemptAllowed') && filters.maxAttemptAllowed === null && filters.maxAttemptAllowed !== '') && (filters.hasOwnProperty('startDate') && filters.startDate === '') && (filters.hasOwnProperty('endDate') && filters.endDate === '') && (filters.hasOwnProperty('recurrance') && filters.recurrance === null) && (filters.hasOwnProperty('recurranceIntervalId') && filters.recurranceIntervalId === null) 
      if(!search && isFiltered && isChecked){
        dispatch(getCompetitionTournamentAction(getTournaments, configureSkeleton))
      }else{
        dispatch(filterCompetitionAction(getTournaments, configureSkeleton, (!isFiltered || !isChecked)))
      }
    }

    let tournaments = useSelector((state) => {
      return state.competition.tournaments
    })

    let isLoading = useSelector((state) => {
      return state.competition.isLoading
    })

    let tournamentCount = useSelector((state) => {
      return state.competition.tournamentCount
    })

    let isFiltered = useSelector((state) => {
      return state.competition.isFiltered
    })

    const [showSkeleton, setShowSkeleton] = useState(true)

    const configureSkeleton = () => {
      setShowSkeleton(true)
      setTimeout(() => {
        setShowSkeleton(false)
      }, 1000)
    }

    useEffect(() => {
      if(selectedSort !== null){
        getCompetitionTournament(search)
      }
  }, [page, selectedSort])

    const handlePageChange = (event, value)  => {
      setPage(value)
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const onSearch = (search) => {
      if(search === ''){
        setSearch(null)
        getCompetitionTournament()
      }else{
        setSearch(search)
        getCompetitionTournament(search)
      }
    }


  // Loader
  if(isLoading){
    return(
      <>
        <div className={styles.loader}>
          <Loader />
        </div>
      </>
    )
  }

  return(
    <>
      <ToastContainer />
      <Navigation type='Tournament' modes={modes} view={activeViewIndex} setView={setActiveViewIndex} filterAction={getCompetitionTournament} filters={filters} search={search} setSearch={onSearch} data={sortList} setPage={setPage} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selectedSort} setSelected={setSelectedSort} sort={sort} setSort={setSort} value={sortValue} setValue={setSortValue} />
      <Card layOut={`Get`} className={styles.card} classCardHead={styles.head}>

        {activeViewIndex === 0 &&
          <div className={styles.wrapper}>
            {/* Competition Tournament Table View */}
            {showSkeleton && tournaments?.length === 0 && 
              <SkeletonEmptyState />
            }
            {showSkeleton && tournaments?.length > 0 && 
              <SkeletonTable from={3} usedFor='Engage' rows={tournaments.length} column={8} controls={1} type={['recatangle', 'reactangle', 'recatangle', 'recatangle', 'reactangle', 'reactangle', 'reactangle', 'circular']} colWidth={[80, 80, 80, 80, 80, 80, 80, 36]} colHeight={[25, 15, 25, 16, 16, 16, 16, 36]} />
            }
            {!showSkeleton && !isFiltered && tournaments?.length === 0 &&
              <EmptyState access={userAccess} route={`/create-competition-tournament/${appid}`} type="Tournaments" />
            }
            {!showSkeleton && !isFiltered && tournaments?.length > 0 &&
              <CompetitionTournamentTableComponent access={userAccess} items={tournaments} title="Last edited" /> 
            }
            {!showSkeleton && isFiltered &&
              (tournaments?.length > 0 ?
                <CompetitionTournamentTableComponent access={userAccess} items={tournaments} title="Last edited" /> 
              :
                <div className={styles.message}>No Result Found</div>
              )
            }

            {!isLoading && tournaments.length > 0 &&
              <div className={styles.pagination}>
                <Stack spacing={2}>
                  <Pagination page={page} renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      sx={{
                        '&.MuiPaginationItem-root': {
                          minWidth: '28px',
                          height: '28px',
                          fontSize: '12px'
                        },
                        '&.Mui-selected': {
                          color: 'rgb(255, 255, 255)',
                          fontWeight : 'bold',
                          border : '1px solid rgb(42, 133, 255)',
                          background : 'rgba(42, 133, 255)'
                        },
                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                          color: 'rgb(255, 255, 255)',
                          fontWeight : 'bold',
                          border : '1px solid rgb(42, 133, 255)',
                          background : 'rgba(42, 133, 255)'
                        }
                      }}
                    />
                  )} siblingCount={1} count={Math.ceil(tournamentCount/limit)} color="primary" onChange={handlePageChange} />
                </Stack>
              </div>
            }
          </div>
        }
        {activeViewIndex === 1 && <div><Calendar/></div>}
      </Card>
      <div className="divider"></div>
      <div className={styles.msg}>
        <p className={styles.alert}>Learn more about tournaments <a className={styles.infoLink} href="https://manual.specterapp.xyz/specter-user-manual/engage/competitions/tournaments" target="_blank">Click Here</a></p>
      </div>
    </>
  )
}

export default GetCompetitionTournament