const TooltipTitle = {
    Appname : "Modify the app name as needed to reflect its current branding or purpose.",
    AppGenre : "Choose up to five genres that best describe your app's theme and content.",
    AppCategory : "Select the most appropriate category for your app from the provided list.",
    AppDescription : "Summarize the main features and appeal of your app in a concise manner.",
    AppPlatform : "Select the platforms (e.g., iOS, Android, Steam) where your app will be available.",
    AppAssetBundle : "Upload the asset bundle files necessary for your app's functionality.",
    AppAssetBundleVerion : "Define the version number of the asset bundle you have uploaded.",
    AppMinimumVersion : "State the lowest version of your app that supports the current asset bundle.",
    AppLocation : "Choose the geographical regions where your app will be accessible.",
    AppScreenshot : "Upload screenshots to visually display your app's features and interface.",
    AppVideos : "Provide video clips to offer a dynamic preview of your app in action.",
    AppHowTo : "Share instructions or guides to assist users in navigating or utilizing your app.",
    AppTags : "Utilize tags (limit to 10) to improve your app's searchability and organization.",
    AppMetaData : "Enter additional app details in key-value pair format for more in-depth information.",

    GameName : "Enter the official name of your game.",
    GameId : "Create a unique code to distinguish your game.",
    GameGenre : "Choose from the list to identify up to five genres that accurately represent your game's style and gameplay.",
    GameDescription : "Offer a concise overview highlighting the key features and premise of your game.",
    GamePlatform : "Select the platforms (e.g., iOS, Android, Steam) where your game will be launched.",
    GameAssetBundle : "Upload the necessary asset bundle files for your game's operation.",
    GameAssetBundleVerion : "Define the version number of the asset bundle being uploaded.",
    GameMinimumVersion : "Specify the lowest version of your game that is compatible with the asset bundle.",
    GameLocation : "Choose where in the world your game will be made available.",
    GameScreenshot : "Provide screenshots to visually demonstrate your game's features or gameplay.",
    GameVideos : "Share video content to give a dynamic preview of what your game offers.",
    GameHowTo : "Include tutorials or guidance to help players understand or get the most out of your game.",
    GameTags : "Use up to ten tags to help categorize and enhance the discoverability of your game.",
    GameMetaData : "Input additional game details in the form of key-value pairs for more comprehensive information.",

    MatchName : "Name your match to identify it among others.",
    MatchId : "Create a unique code to distinguish your match.",
    MatchDescription : "Summarize the main aspects and purpose of your match.",
    MatchGame : "Pick the game this match is associated with from the list, with your app highlighted at the top.",
    MatchFormat : "Choose the match's format: single player, multiplayer, or multiplayer team.",
    MatchPlayer : "(For multiplayer formats) Determine the minimum and maximum player count.",
    MatchPosition : "Determine the number of position.",
    MatchTeam : "(For multiplayer team format) Set the minimum and maximum team size.",
    MatchResult : "Select how the match's results are determined, such as by Score, Time, Position, Earnings, or Win/Loss/Draw.",
    MatchLeaderboard : "Give your leaderboard a name to identify it.",
    MatchLeaderboardId : "Assign a unique identifier to your leaderboard.",
    MatchLeaderboardDescription : "Offer a brief description of what your leaderboard tracks and represents.",
    MatchLeaderboardRanking : "Pick the method for ranking players, such as High Score, Cumulative Score, Time Trial, etc.",
    MatchLeaderboardSource : "Match' is the default source for this leaderboard.",
    MatchFrequency : "Choose how often the leaderboard refreshes: daily, weekly, monthly, yearly, all time, or custom. This sets the pace for updating player rankings.",
    MatchStartDate : "(For Custom Frequency) Select the date and time when the leaderboard will begin tracking results.",
    MatchEndDate : "(For Custom Frequency) Choose the date and time when the leaderboard will stop updating with new results.",
    MatchTags : "Use tags (up to 10) to categorize and improve the searchability of your match.",
    MatchMetaData : "Provide additional details about your match using key-value pairs for deeper insight.",

    CurrencyName : "The name of the currency visible to players in your app.",
    CurrencyId : "A unique identifier for the currency.",
    CurrencyCode : "A short identifier for the currency.",
    CurrencyType : "Specifies if the currency is 'Real' (actual money), 'Virtual' (in-game currency), or 'IAP' (purchased with real money for in-app transactions).",
    CurrencyDescription : "Brief explanation of the currency's purpose and use within your app.",
    CurrencyTags : "Keywords to help categorize and manage your currencies.",
    CurrencyMetaData : "Optional additional attributes for the currency, defined as key-value pairs.",

    RMGName : "The name displayed for the currency, e.g. 'Gold'.",
    RMGID : "A unique identifier for the currency, e.g. 'gold_001'.",
    RMGCode : "Set a Currency Code for your currency, e.g. 'GLD'.",
    RMGDescription : "An optional description providing more details about the currency.",
    RMGType : "Select the type of currency: 'Virtual' or 'RMG (Real Money Gaming)'.",
    RMGTags : "Tags associated with the currency for categorization or metadata purposes.",
    RMGMetaData : "Additional metadata for the currency in key-value format.",
    RMGWalletType : "The inherent type of the wallet, such as 'Deposit', 'Bonus', or 'Winnings'.",
    RMGWalletName : "The name of the wallet, e.g., 'Deposit Wallet'.",
    RMGWalletID : "A unique identifier for the wallet, e.g., 'deposit_wallet_001'.",
    RMGIsWithdrwal : "Toggle to enable or disable withdrawal functionalities for this wallet.",
    RMGMaxDeposit : "The maximum amount allowed per single deposit transaction.",
    RMGMinDeposit : "The minimum amount required per single deposit transaction.",
    RMGDailyDepositLimit : "The total maximum amount that can be deposited in a single day.",
    RMGWeeklyDepositLimit : "The total maximum amount that can be deposited in a single week.",
    RMGMonthlyDepositLimit : "The total maximum amount that can be deposited in a single month.",
    RMGMaxWithdrawl : "The maximum amount allowed per single withdrawal transaction.",
    RMGMinWithdrawl : "The minimum amount required per single withdrawal transaction.",
    RMGDailyWithdrawalLimit : "The total maximum amount that can be withdrawn in a single day.",
    RMGWeeklyWithdrawalLimit : "The total maximum amount that can be withdrawn in a single week.",
    RMGMonthlyWithdrawalLimit : "The total maximum amount that can be withdrawn in a single month.",
    RMGWithdrawalLockPeriod : "The number of days a withdrawal is locked after a transaction is initiated.",
    RMGMaxBalance : "The maximum balance allowed in the wallet.",
    RMGMinBalance : "The minimum balance required in the wallet to allow withdrawals.",
    RMGIsTax : "Toggle to apply tax deductions on transactions.",
    RMGTaxRate : "The percentage rate at which tax is applied to transactions.",
    RMGTaxThreshold : "The minimum transaction amount at which tax becomes applicable.",
    RMGTaxFreeAllowance : "The amount of transaction that is tax-free per transaction.",
    RMGIsKYC : "Toggle to require KYC verification for transactions.",
    RMGKYCThresholdForTransaction : "KYC is required for transactions above this amount.",
    RMGKYCThresholdForDeposit : "KYC is required based on this total threshold.",
    RMGWalletPriority : "Set the priority order of wallets for transactions.",
    RMGTaxType : "Select the type of tax applicable (e.g., VAT, GST).",
    RMGTaxRate : "Define the tax rate applicable to transactions.",
    RMGTaxApplicability : "Select which transactions the tax applies to.",
    RMGTaxDeduction : "Determine whether taxes are applied to the full transaction amount or only to the platform fee.",
    RMGPlatformFeeStructure : "Define the structure of Platform fees (fixed or percentage-based).",
    RMGPlatformFeeApplicability : "Select which transactions the platform fee applies to.",
    RMGWalletPriority : "Set the priority order of wallets for transactions.",
    RMGTaxDeductionBasic : "Choose whether TDS should be applied to each transaction or only on net winnings. On Every Transaction : Deduct TDS on each individual winning transaction as it occurs. On Net Winnings : Deduct TDS on the cumulative net winnings after adjusting for losses.",
    RMGTaxInclusionMethod : "Select whether tax is included in the transaction amount or added to it. Inclusive : The tax is part of the displayed price. Exclusive : The tax will be added on top of the price.",

    ItemName : "The name of the item as it will appear to players in your app.",
    ItemId : "A unique identifier for the item.",
    ItemDescription : "Brief explanation of the item's purpose and use.",
    ItemPricing : "Select the method by which the item will be priced: Real Money Gaming (RMG), Virtual currency, or In-App Purchase (IAP).",
    ItemLock : "Select whether the item access is restricted by an item/bundle or a progression system.",
    ItemPS: "If the item is locked by a progression system, select the specific system from the dropdown menu.",
    ItemBundle : "If the item is locked by another item/bundle, select the specific item/bundle from the dropdown menu.",
    ItemType : "Choose whether the item is 'durable' (permanently available) or 'consumable' (used up over time or with use).",
    ItemBy : "If the item is consumable, indicate whether it is used up by count (number of uses) or by time (duration).",
    ItemSubType : "Indicate whether the item is stackable, equippable, rentable, tradeable, or time stackable (if consumable by time).",
    ItemMaxStack : "If the item is stackable, define the maximum number of items that can be stacked together.",
    ItemQuantity : "Choose if the item is available in a limited quantity. If 'Limited' is selected, specify the quantity.",
    ItemTags : "Keywords to help categorize and manage your items.",
    ItemMetaData : "Optional additional attributes for the item, defined as key-value pairs.",

    BundleName : "The name of the bundle as it will appear to players in your game or app.",
    BundleId : "A unique identifier for the bundle.",
    BundleDescription : "Brief explanation of the bundle's contents and use.",
    BundlePricing : "Select the method by which the bundle will be priced: Real Money Gaming (RMG), Virtual currency, or In-App Purchase (IAP).",
    BundleLock : "Select whether the bundle access is restricted by an item/bundle or a progression system.",
    BundlePS: "If the bundle is locked by a progression system, select the specific system from the dropdown menu.",
    BundleItem : "If the bundle is locked by another item/bundle, select the specific item/bundle from the dropdown menu.",
    BundleType : "Choose whether the bundle is 'durable' (permanently available) or 'consumable' (used up over time or with use).",
    BundleBy : "If the bundle is consumable, indicate whether it is used up by count (number of uses) or by time (duration).",
    BundleSubType : "Indicate whether the bundle is stackable, equippable, rentable, tradeable, or time stackable (if consumable by time).",
    BundleMaxStack : "If the bundle is stackable, define the maximum number of bundles that can be stacked together.",
    BundleQuantity : "Choose if the bundle is available in a limited quantity. If 'Limited' is selected, specify the quantity.",
    BundleTags : "Keywords to help categorize and manage your bundles.",
    BundleMetaData : "Optional additional attributes for the bundle, defined as key-value pairs.",

    StoreName : "This is the name of the store as it will appear to players in your app.",
    StoreId : "A unique identifier for the store.",
    StoreDescription : "Brief explanation of the store's purpose and function.",
    StoreCategory : "Specify the number of distinct categories for items in the store.",
    StoreCategoryName : "This is the name of the category as it will appear to players in your game or app.",
    StoreCategoryId : "A unique identifier for the category.",
    StoreLock : "Choose whether access to the store should be restricted by player level in a progression system",
    StoreItemBundle : "If the store is locked by another item/bundle, select the specific item/bundle from the dropdown menu.",
    StorePS : "If the store is locked by a progression system, select the specific system from the dropdown menu.",
    StoreTags : "Keywords to help categorize and manage your store.",
    StoreMetaData : "Optional additional attributes for the store, defined as key-value pairs.",
    
    PMName : "The name of the progression marker visible to players in your app.",
    PMID : "A unique identifier for the progression marker.",
    PMDescription : "Brief explanation of the progression marker's purpose and use.",
    PMTags : "Keywords to help categorize and manage your progression markers.",
    PMMetaData : "Optional additional attributes for the progression marker, defined as key-value pairs.",

    PSName : "The name of the progression system visible to players in your app.",
    PSID : "A unique identifier for the progression system.",
    PSDescription : "Brief explanation of the progression system's purpose and use.",
    PSParameter : "Choose whether the progression system is based on a 'Progression Marker' or an 'Event'.",
    PSPM : "Select the specific progression marker from the dropdown menu.",
    PSType : "Choose 'Linear' for a straightforward, step-by-step progression, or 'Non-linear' for a progression with multiple branching paths.",
    PSSchedule : "Choose when rewards are given: 'On completion' (when a level/stage is completed) or 'Custom' (at a specific time of your choosing).",
    PSTime : "Specify the custom timing for reward distribution",
    PSTags : "Keywords to help categorize and manage your progression markers.",
    PSMetaData : "Optional additional attributes for the progression marker, defined as key-value pairs.",

    EventName : "The name of the event as it will appear in your Specter dashboard.",
    EventId : "A unique identifier for the event.",
    EventDescription : "Brief explanation of the event's purpose and use.",
    EventParameter : "Choose if the event is based on a 'State' (a specific scenario in your game or app) or a 'Statistic' (measurable quantity like score, enemies defeated, etc.).",
    EventTags : "Keywords to help categorize and manage your events.", 
    EventMetaData : "Optional additional attributes for the event, defined as key-value pairs.",

    TaskName : "Specify the name of your task as it will be shown to players.",
    TaskId : "Enter a unique identifier for the task to avoid conflicts.",
    TaskDescription : "Provide a brief overview of what the task involves and its importance.",
    TaskReward : "Click the '+' icon to add rewards, including items, currencies, bundles, or progression markers, and configure third-party rewards.",
    TaskType : "Select the task's frequency: Static (one-off/recurrent), Daily, or Weekly.",
    TaskGrant : "Choose how rewards are claimed: Automatically upon completion or manually by the player.",
    TaskEvent : "Select or create an event that triggers the task.",
    TaskEventId : "This auto-populates from the selected event or can be manually set for new events.",
    TaskEventRuleEngine : "Set up the criteria for task completion, including parameters, operators, and values.",
    TaskParameter : "Choose a parameter that defines the conditions for task completion.",
    TaskOperator : "Select the appropriate operator for the task parameter.",
    TaskParameterValue : "Specify the value for the chosen task parameter.",
    TaskIncrementalType : "Indicate whether the parameter value is 'one shot' or 'cumulative'.",
    TaskTimePeriod : "Choose 'yes' or 'no' to include data from all time for the task.",
    TaskRecords : "Specify the number of records if not using the 'all time' option.",
    TaskLock : "Set if task access is restricted by player level.",
    TaskPS : "Select the progression system that determines task availability.",
    TaskPSLevel : "Specify the level within the progression system required to unlock the task.",
    TaskTags : "Use tags for easier task categorization and searchability.",
    TaskMetaData : "Enter additional attributes for the task as key-value pairs for deeper customization.",

    SSName : "Enter the name of the series as it will be displayed to players.",
    SSID : "Create a unique code for the series to ensure uniqueness.",
    SSDescription : "Summarize the series' goals and the player's progression path.",
    SSSteps : "Add tasks to the series as steps, each required to unlock the next.",
    SSAddTask : "Pick from previously defined tasks to include as a step, automatically using its details.",
    SSTask : "Create a new task within the step if an appropriate one doesn't exist, including setting task details, logic, and rewards. Refer to task configuration for instructions.",
    SSConfigure : "Arrange the steps in the desired sequence to guide players through the series, ensuring tasks are completed in order.",
    SSLock : "Determine access to the series based on player level requirements.",
    SSPS : "Link series availability to a progression system, selecting from available options.",
    SSPSLevel : "Indicate the level requirement within the selected progression system to access the series.",
    SSTags : "Apply tags for simplified organization and locating of the series.",
    SSMetaData : "Provide extra details for the series through key-value pairs for enhanced configuration.",

    MissionName : "Enter the mission's name as it will appear to players.",
    MissionId : "Assign a unique code to the mission to prevent overlap.",
    MissionDescription : "Summarize the mission's goals and why it matters.",
    MissionType : "Select the mission's recurrence type: daily, weekly, or static.",
    MissionCycle : "Specify the total number of missions for each cycle.",
    MissionGrant : "Choose how rewards are given: automatically by the server or upon player claim.",
    MissionTask : "Add existing tasks to the mission or create new ones to define the mission content.",
    MissionAddTask : "Pick pre-defined tasks to include in the mission, with an option to adjust the task ID.",
    MissionReward : "Assign rewards for completing all missions in a group, selecting from items, currencies, etc., and set the reward quantity.",
    MissionLock : "Restrict mission group access based on player level requirements.",
    MissionPS : "Tie mission availability to a progression system, selected from a list.",
    MissionPSLevel : "Indicate the level required within the progression system to access the mission group.",
    MissionTags : "Apply tags for easier organization and finding of missions.",
    MissionMetaData : "Provide extra mission details via key-value pairs for comprehensive configuration.",

    TournamentName : "Enter a name that reflects the essence and goal of the tournament, encouraging player participation.",
    TournamentID : "Assign a unique code to the tournament for tracking and management purposes.",
    TournamentDescription : "Offer a comprehensive overview of the tournament, including its objectives, rules, and special attributes.",
    TournamentMin : "Set the minimum player count required for the tournament to proceed, ensuring a competitive environment.",
    TournamentMax : "Define the cap on tournament entries to control its size and manageability.",
    TournamentSource : "Choose the scoring basis: 'Matches' for game scores or 'Custom' for API-sent scores",
    TournamentMatch : "Specify Match if the source is 'Matches'.",
    TournamentEntry : "Specify the entry limit per player, allowing for either multiple or single submissions.",
    TournamentAttempt : "Determine how many attempts a player gets within each entry, structuring competition depth.",
    TournamentPricing : "Decide on the entry fee type and amount, with options for virtual or real money currency, including any discounts.",
    TournamentHosting : "Select the hosting fee format (percentage or flat rate) and specify the amount.",
    TournamentPD : "Outline prize distribution rules, differentiating between RMG and non-RMG tournaments, including prize pool calculation and allocation.",
    TournamentLock : "Select whether the tournament access is restricted by an item/bundle or a progression system.",
    TournamentPS : "Limit participation to those who've reached a particular level in a chosen system, adding exclusivity.",
    TournamentItemBundle : "Restrict access to the tournament to players owning specific items or bundles.",
    TournamentTags : "Use tags to facilitate tournament categorization and discovery, improving organization.",
    TournamentMetaData : "Input extra details or tournament-specific rules as key-value pairs for comprehensive insight.",

    IBName : "Choose a name that reflects the Instant Battle's spirit, encouraging participation.",
    IBID : "Create a unique code for the battle for tracking and management.",
    IBDescription : "Give a comprehensive overview of the battle, including objectives, rules, and special features.",
    IBMin : "Set the minimum player count needed to start the battle, ensuring it's competitive.",
    IBMax : "Limit the number of participants to maintain balance and manageability.",
    IBSource : "Choose the scoring basis: 'Matches' for game scores or 'Custom' for API scores.",
    IBMatch : "Specify Match if the source is 'Matches'.",
    IBEntry : "Indicate entry limits per player, allowing for multiple or single attempts.",
    IBAttempt : "State how many tries a player has within each entry, shaping the competitive structure.",
    IBSlots : "Define participation as 'Unlimited' or 'Custom' to set concurrent match instances.",
    IBPricing : "Set the entry fee and currency type, including options for virtual or RMG currency, with discount possibilities.",
    IBHosting : "Decide on hosting fees as a percentage or flat rate, specifying the amount.",
    IBPD : "Outline prize rules for RMG and non-RMG battles, detailing pool calculations and distribution methods.",
    IBLock : "Select whether the Instant Battle access is restricted by an item/bundle or a progression system.",
    IBPS : "Limit participation to those who've reached a particular level in a chosen system, adding exclusivity.",
    IBItemBundle : "Restrict access to the Instant Battle to players owning specific items or bundles.",
    IBTags : "Use tags for easy categorization and discovery of your Instant Battle.",
    IBMetaData : "Add extra battle details or rules as key-value pairs for deeper insight.",

    LeaderboardName : "The name that identifies your leaderboard.",
    LeaderboardId : "A unique code to distinguish your leaderboard for integration and reference.",
    LeaderboardDescription : "A short overview of the leaderboard's purpose and the achievements it tracks.",
    LeaderboardSource : "Select the source of data for the leaderboard: 'Match', 'Statistic', or 'Custom'.",
    LeaderboardRanking : "Choose how players are ranked: by High Score, Time Trial, Position Weighting, Cumulative Score, or Win/Loss/Draw Points.",
    LeaderboardRecurring : "Indicate if the leaderboard will be a one-time or recurring event, to maintain player interest.",
    LeaderboardPD : "Decide if you want to award prizes to top players, which can boost participation.",
    LeaderboardRanks : "Define which ranks will receive prizes, clarifying prize eligibility.",
    LeaderboardTags : "Keywords for better organization and discovery of your leaderboards.",
    LeaderboardMetaData : "Additional details for the leaderboard, provided as key-value pairs.",

    CompetitionName : "Pick the competition to schedule from a list of available options.",
    CompetitionId : "Automatically filled once a competition is selected, serving as its unique tracker.",
    CompetitionSchedule : "Set start and end dates/times for a single event.",
    CompetitionType : "Choose 'Normal' for one-time events or 'Recurring' for ongoing competitions.",
    CompetitionRecurring : "For continuous competitions, select how often they occur (daily, weekly, etc.).",
    CompetitionStartDate : "Define the competition's start. For recurring types, this is the first occurrence.",
    CompetitionEndDate : "For normal schedules, indicate when the competition finishes. Not used for setting up recurring competitions initially.",
    CompetitionOffeset : "Decide on prize timing: immediate ('On End') or delayed by hours/minutes.",
    CompetitionTags : "Apply tags for easier organization and finding of scheduled competition.",
    CompetitionMetaData : "Provide extra scheduled competition details via key-value pairs for comprehensive configuration.",

}


export default TooltipTitle