import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StoreServices from "../../../../../services/buildServices/economyServices/storeServices/storeServices";
import { GET_STORES, ADD_STORES, EDIT_STORES, GET_STORES_BY_ID, FILTER_STORES_SUCCESS, FILTER_STORES_ERROR, GET_STORES_SUCCESS, GET_STORES_ERROR} from "../../../../actionTypes/actionTypes";
import Toast from '../../../../../components/Toast';
import 'react-toastify/dist/ReactToastify.css';

export const createStoreAction = (body, navigate, appid, setDisable) => {
    return async (dispatch) => {
        if(setDisable){
            setDisable(true)
        }
        await StoreServices.addStore(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            setTimeout(()=>{
                if(setDisable){
                    setDisable(false)
                }
                if(navigate){
                    navigate(`/store/${appid}`)
                }
            }, 2000)
            return res;
        }).catch(error => {
            if(setDisable){
                setDisable(false)
            }
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}

export const getCurrencyByIdAction = (body) => {
    return async (dispatch) => {
        await StoreServices.getStoresById(body)
        .then(res=>{
            dispatch(getStoresById(res.data.data))
        }).catch(err=>{
            console.log(err)
        })
    }
}


export const getAllStoresAction = (body, showSkeleton) => {
    return async (dispatch) => {
        dispatch(getAllStores(null, null, GET_STORES))
        await StoreServices.getAllStores(body)
        .then(res=>{
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(getAllStores(res.data.data.stores, (body?.showArchived ? res.data.data.archiveCount : res.data.data.totalCount), (body?.showArchived ? FILTER_STORES_SUCCESS : GET_STORES_SUCCESS)))
        }).catch(error => {
            if(showSkeleton){
                showSkeleton()
            }
            if(error.response?.status == 400){
                error.response.data.message && error.response.data.message.map((err,i)=>{
                    toast.error(<Toast type='Error' messages={err}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                })
            }
            if(error.response?.status == 409){
                error.response.data.message && toast.error(<Toast type='Error' messages={error.response.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FF6A55'}})
            }
            dispatch(getAllStores(error.response, null,  (body?.showArchived ? FILTER_STORES_ERROR : GET_STORES_ERROR)))
        })
    }
}

export const filterStoresAction = (body, showSkeleton) => {
    return async (dispatch) => {
        await StoreServices.getAllStores(body)
        .then(res => {
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(filterStores(res.data.data.stores, ((body?.search && body?.search !== '') ? res.data.data.searchCount : res.data.data.totalCount), FILTER_STORES_SUCCESS))
        }).catch(err=>{
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(filterStores(err.response, null, FILTER_STORES_ERROR))
        })
    }
}


export const editStoreAction = (body, navigate, appid, setDisable) => {
    return async (dispatch) => {
        if(setDisable){
            setDisable(true)
        }
        await StoreServices.editStores(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            setTimeout(()=>{
                if(setDisable){
                    setDisable(false)
                }
                if(navigate){
                    navigate(`/store/${appid}`)
                }
            }, 2000)
            return res;
        }).catch(error => {
            if(setDisable){
                setDisable(false)
            }
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}

export const archiveStoreAction = (body, appid, onClose, status) => {
    return async(dispatch) => {
        try {
            const res = await StoreServices.archiveStore(body)
            if(res){
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                let data = {projectId: appid, showArchived: status, sortField: "updated_at", sortOrder: "asc", offset: 0, limit: 8}
                dispatch(getAllStoresAction(data))
                if(onClose){
                    onClose()
                }
            }
            return res;
        }catch(error) {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error; 
        }
    }
}

export const getAllStores = (data, count, type) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}

export const addStore = (data) => {
    return {
        type : ADD_STORES,
        payload : data
    }
}

export const getStoresById = (data) => {
    return {
        type : GET_STORES_BY_ID,
        payload : data
    }
}

export const editStores = (data) => {
    return {
        type : EDIT_STORES,
        payload : data
    }
}

export const filterStores = (data, count, type) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}
