import React from 'react'
import styles from './editRMG.module.sass'
import CurrencyInfo from './CurrencyInformation'
import { ToastContainer } from 'react-toastify'

const EditRMGCurrency = ({isChanged, setIsChanged}) => {
  
  return (
    <>
      <ToastContainer />
      <div className={styles.row}>
        <div className={styles.col}>
          <CurrencyInfo isChanged={isChanged} setIsChanged={setIsChanged} className={styles.card}/>
        </div>
      </div>
    </>
  )
}

export default EditRMGCurrency