import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getFirestore,
} from "firebase/firestore"; // Import Firestore functions
import app from "../../firebase"; // Import your Firestore instance
import Card from "../../components/Card";
import cn from "classnames";
import styles from "./activity.module.sass";
import Icon from "../../components/Icon";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import moment from "moment";
import EmptyStates from "../../components/EmptyStates";
import { ReactComponent as  ServerIcon} from "../../media/images/icons/server_icon.svg";

const ActivityFeed = () => {
  
  const [activities, setActivities] = useState([]);
  const [lastVisible, setLastVisible] = useState(null); // Stores the last document for pagination
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10; // Number of items per page
  const db = getFirestore(app);
  const { appid } = useParams();

  // Fetch activities initially and when page changes
  useEffect(() => {
    fetchActivities(currentPage);
  }, [currentPage]);

  // Function to fetch activities based on page number
  const fetchActivities = async (pageNumber) => {
    setLoading(true);
    try {
      let q = query(
        collection(db, "activityFeed"),
        where("activity_feed", "==", true),
        where("app_id", "==", appid),
        orderBy("timestamp", "desc"),
        limit(itemsPerPage)
      );

      if (pageNumber > 1) {
        // Get lastVisible document from the previous page to handle pagination
        const lastSnapshot = await getLastDocumentForPage(pageNumber - 1);
        if (lastSnapshot) {
          q = query(
            collection(db, "activityFeed"),
            where("activity_feed", "==", true),
            where("app_id", "==", appid),
            orderBy("timestamp", "desc"),
            startAfter(lastSnapshot),
            limit(itemsPerPage)
          );
        }
      }

      const querySnapshot = await getDocs(q);
      const newActivities = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setActivities(newActivities);

      // Calculate total pages (assuming we do not have a count function available from Firestore)
      if (pageNumber === 1 && querySnapshot.docs.length > 0) {
        const allQuery = await getDocs(
          query(
            collection(db, "activityFeed"),
            where("activity_feed", "==", true),
            where("app_id", "==", appid)
          )
        );
        setTotalPages(Math.ceil(allQuery.docs.length / itemsPerPage));
      }

      if (querySnapshot.docs.length > 0) {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
    } catch (error) {
      console.error("Error fetching activities: ", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to get the last visible document for pagination
  const getLastDocumentForPage = async (page) => {
    const q = query(
      collection(db, "activityFeed"),
      where("activity_feed", "==", true),
      where("app_id", "==", appid),
      orderBy("timestamp", "desc"),
      limit(page * itemsPerPage)
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs[querySnapshot.docs.length - 1];
    
  };

  // Handle page change event
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  }

  return (
    <>
      <Card className={cn(styles.card)}>
        <div className="activity-table">
          <div className={styles.wrapper}>
          {activities?.length === 0 &&
            <>
              <EmptyStates type="Activity" access={true}/>
            </>
          }
          {activities?.length > 0 &&
            <>
              <div className={styles.activity}>
                <div className={styles.icon}>Icon</div>
                <div className={styles.action}>Details</div>
                <div className={styles.performedBy}>User</div>
                <div className={styles.time}>Time</div>
              </div>
              {activities && activities.map((activity, index) => (
                <>
                  <div className={styles.activity}>
                    <div className={styles.icon}>
                      {/* <Icon name={activity?.action_type === "UPDATE" ? "edit" : activity?.action_type === "ARCHIVE" ? "trash" : activity?.action_type === "CREATE" ? "file-add" : "activity"} size="20" /> */}
                      <div onClick={activity?.environment === 'Development' ? () =>{} : null} style={{marginLeft:"auto"}}>
                              <div className={cn(styles.itemContainer, {[styles.active]: true}, {[styles.disabled] : (activity?.environment !== 'Development' ? true : false)})}>
                                  <div className={activity?.environment == 'Production' ? styles.production : activity?.environment == 'Quality Assurance' ? styles.staging :activity?.environment == 'Development' ? styles.develop:styles.global} title={activity?.environment??"Global"}>
                                      <ServerIcon className={styles.logo} />
                                  </div>
                                  {/* <div className={styles.appName}>
                                      <p className={styles.env}>{activity?.environment??"Global"}</p>
                                  </div> */}
                              </div> 
                      </div>
                    </div>
                    <div className={styles.action}>{activity.description}</div>
                    <div className={styles.performedBy}>{activity.performed_by}</div>
                    <div className={styles.time}>{activity?.timestamp ? moment(activity?.timestamp).format('DD/MM/YYYY hh:mm A') : '-'}</div>
                </div>
                </>
              ))}
            </>
          }
          </div>
        </div>
        {activities?.length > 0 &&
        <div className={styles.pagination} style={{ marginTop: "20px", justifyItems:"center"}}>
          <Stack spacing={2}>
            <Pagination
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  sx={{
                    "&.Mui-selected": {
                      color: "rgb(255, 255, 255)",
                      fontWeight: "bold",
                      border: "1px solid rgb(42, 133, 255)",
                      background: "rgba(42, 133, 255)",
                    },
                    "&.MuiPaginationItem-page.Mui-selected:hover": {
                      color: "rgb(255, 255, 255)",
                      fontWeight: "bold",
                      border: "1px solid rgb(42, 133, 255)",
                      background: "rgba(42, 133, 255)",
                    },
                  }}
                />
              )}
              siblingCount={1}
              count={totalPages}
              color="primary"
              page={currentPage}
              onChange={handlePageChange}
            />
          </Stack>
        </div>
        }
      </Card>
      {/* {loading && <p>Loading more activities...</p>} */}
    </>
  );
};

export default ActivityFeed;
