import {GET_CURRENCY, ADD_CURRENCY, EDIT_CURRENCY, GET_CURRENCY_BY_ID, FILTER_CURRENCY_SUCCESS, FILTER_CURRENCY_ERROR, GET_CURRENCY_ERROR, GET_CURRENCY_SUCCESS, GET_RMG_TRANSACTION, GET_RMG_TRANSACTION_SUCCESS, GET_RMG_TRANSACTION_ERROR} from "../../../../actionTypes/actionTypes";
import currencyService from "../../../../../services/buildServices/economyServices/currencyServices/currencyService";
import { toast, Slide } from 'react-toastify';
import Toast from "../../../../../components/Toast";
import 'react-toastify/dist/ReactToastify.css';
import { fa } from "@faker-js/faker";

export const getAllCurrencyAction = (body, configureSkeleton) => {
    return async (dispatch) => {
        dispatch(getAllCurrency(null, null, GET_CURRENCY))
        await currencyService.getAllCurrency(body)
        .then(res=>{
            if(configureSkeleton){
                configureSkeleton()
            }
            dispatch(getAllCurrency(res.data.data.currenciesDetails, (body?.showArchived ? res.data.data.archiveCount : res.data.data.totalCount), (body?.showArchived ? FILTER_CURRENCY_SUCCESS : GET_CURRENCY_SUCCESS)))
        }).catch(err=>{
            dispatch(getAllCurrency(err.response, null,  (body?.showArchived ? FILTER_CURRENCY_ERROR : GET_CURRENCY_ERROR)))
        })
    }
}

export const filterCurrencyAction = (body, configureSkeleton, isFiltered) => {
    return async (dispatch) => {
        await currencyService.getAllCurrency(body)
        .then(res=>{
            if(configureSkeleton){
                configureSkeleton()
            }
            console.log(isFiltered)
           dispatch(filterCurrency(res.data.data.currenciesDetails, res.data.data.filterCount, FILTER_CURRENCY_SUCCESS))
        }).catch(err=>{
            dispatch(filterCurrency(err, null, FILTER_CURRENCY_ERROR))
        })
    }
}

export const getCurrencyByIdAction = (body) => {
    return async (dispatch) => {
        await currencyService.getCurrencyById(body)
        .then(res=>{
            dispatch(getCurrencyById(res.data.data.currenciesDetails))
        }).catch(err=>{
            console.log(err, "Error")
        })
    }
}

export const addCurrecyAction = (body, navigate, appid, setDisable, setId, setStep) => {
    return async (dispatch) => {
        if(setDisable){
            setDisable(true)
        }
        await currencyService.addCurrency(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            setStep && setStep(currentStep => {
                if (currentStep < 3) {
                    return currentStep + 1;
                }
                return currentStep
            })
            if(setId){
                setId(res.data.data.currenciesDetails?.id)
            }
            setTimeout(()=>{
                if(setDisable){
                    setDisable(false)
                }
                if(navigate){
                    navigate(`/currency/${appid}`)
                }
            }, 2000)
            return res;
        }).catch(error => {
            if(setDisable){
                setDisable(false)
            }
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}

export const editCurrecyAction = (body, navigate, appid, setDisable, redirect, setStep) => {
    return async (dispatch) => {
        if(setDisable){
            setDisable(true)
        }
        await currencyService.editCurrency(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            setStep && setStep(currentStep => {
                if (currentStep < 3) {
                    return currentStep + 1;
                }
                return currentStep
            })
            if(navigate){
                setTimeout(()=>{
                    if(setDisable){
                        setDisable(false)
                    }
                    if(redirect === 'RMG'){
                        navigate(`/RMG/${appid}`)
                    }else{
                        navigate(`/currency/${appid}`)
                    }
                }, 2000)
            }
            return res;
        }).catch(error => {
            if(setDisable){
                setDisable(false)
            }
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}

export const archiveCurrencyAction = (body, appid, onClose, status) => {
    return async(dispatch) => {
        try {
            const res = await currencyService.archiveCurrency(body)
            if(res){
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                let data = {projectId: appid, showArchived: status, sortField: "updated_at", sortOrder: "asc", offset: 0, limit: 8}
                dispatch(getAllCurrencyAction(data))
                if(onClose){
                    onClose()
                }
            }
            return res;
        }catch (error) {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error; 
        }
    }
}

export const addPolicyAction = (body, navigate, setDisable, appid, setStep) => {
    if(setDisable){
        setDisable(true)
    }
    return async(dispatch) => {
        try {
            const res = await currencyService.addPolicyCurrency(body)
            if(res){
                setStep && setStep(currentStep => {
                    if (currentStep < 3) {
                        return currentStep + 1;
                    }
                    return currentStep
                })
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                if(setDisable){
                    setDisable(false)
                }
                if(navigate){
                    setTimeout(() => {
                        setStep(1)
                        navigate(`/RMG/${appid}`)
                    }, 3000)
                }
                return res
            }
        }catch (error) {
            if(setDisable){
                setDisable(false)
            }
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error; 
        }
    }
}

export const editPolicyAction = (body, navigate, setDisable, appid, setStep) => {
    if(setDisable){
        setDisable(true)
    }
    return async(dispatch) => {
        try {
            const res = await currencyService.editPolicyCurrency(body)
            if(res){
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                setStep && setStep(currentStep => {
                    if (currentStep < 3) {
                        return currentStep + 1;
                    }
                    return currentStep
                })
                if(setDisable){
                    setDisable(false)
                }
                if(navigate){
                    setTimeout(() => {
                        setStep(1)
                        navigate(`/RMG/${appid}`)
                    }, 3000)
                }
                return res
            }
        }catch (error) {
            if(setDisable){
                setDisable(false)
            }
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error; 
        }
    }
}

export const getRMGTransactionAction = (body) => {
    return async(dispatch) => {
        dispatch(getRMGTransaction([], GET_RMG_TRANSACTION, null))
        try{
            const res = await currencyService.getRMGTransaction(body)
            console.log(res.data.data)
            dispatch(getRMGTransaction(res.data.data.walletTransactions, GET_RMG_TRANSACTION_SUCCESS, res.data.data.totalWalletTransactions))
        }catch(err){
            dispatch(getRMGTransaction(err, GET_RMG_TRANSACTION_ERROR, null))
        }
    }
}

export const getRMGTransaction = (data, type, count) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}

export const getAllCurrency = (data, count, type) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}

export const getCurrencyById = (data) => {
    return {
        type : GET_CURRENCY_BY_ID,
        payload : data
    }
}


export const addCurrency = (data) => {
    return {
        type : ADD_CURRENCY,
        payload : data
    }
}

export const editCurrency = (data) => {
    return {
        type : EDIT_CURRENCY,
        payload : data
    }
}

export const filterCurrency = (data, count, type) => {
    return {
        type : type,
        totalCount : count,
        payload : data
    }
}