import React, { useState } from "react";
import styles from "./table.module.sass";
import cn from "classnames";
import Checkbox from "../../../Checkbox";
import Row from "./Row";

const Table = ({ items, title }) => {

  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}></div>
          <div className={styles.col}>Name</div>
          <div className={styles.col}>ID</div>
          <div className={styles.col}>Currency Code</div>
          <div className={styles.col}>Currency Type</div>
          <div className={styles.col}>Current Balance</div>
          <div className={styles.col}>Last Updated</div>
        </div>
        {items && items.map((x, index) => (
          <Row item={x} key={index} index={index}  />))}
      </div>
    </div>
  );
};

export default Table;
