import React, { useState, useEffect, useRef } from 'react'
import styles from './tooltip.module.sass'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { ReactComponent as  ServerIcon} from "../../../media/images/icons/server_icon.svg";
import OutsideClickHandler from 'react-outside-click-handler';
import Icon from '../../Icon';
import { toast } from 'react-toastify';
import Toast from '../../Toast';
import { getDescriptionAction } from '../../../redux/action/common/commonAction';
import { useDispatch, useSelector } from 'react-redux';

const PromptTooltip = ({className, type, props, children}) => {

    const { onChange } = props
    const dispatch = useDispatch()

    const [description, setDescription] = useState('')
    const [visible, setVisible] = useState(false);
    const [showCopy, setShowCopy] = useState(false)
    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState("");

    const handleKeyDown = (e) => {
        // Check if the Enter key (key code 13) is pressed
        if (e.keyCode === 13) {
          // Call the onChange function
          if(description !== ''){
            setResult("")
            generateDescription()
          }
        }
    }

    const generateDescription = async() => {
        if(description !== ''){
            let prompt = {
                prompt: description,
                fieldType: type,
                unit: 'characters',
                limit: 3000
            }
            let res = await dispatch(getDescriptionAction(prompt, setLoading))
            startTypingEffect(res)
        }
    }

    const startTypingEffect = (text) => {
        setResult("")
        let currentCharIndex = 0;
        const typingInterval = setInterval(() => {
            if (currentCharIndex < text.length) {
                setShowCopy(false)
                setResult((prev) => {
                    const current = prev + text[currentCharIndex]
                    currentCharIndex++;
                    return current
                });
            } else {
                setShowCopy(true)
                clearInterval(typingInterval); // Stop the interval when done
            }
        }, 15); // Adjust typing speed here
    }

    const copyToClipBoard = () => {
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = result;
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand("copy");
        document.body.removeChild(tempTextArea);
        toast.success(<Toast type='Success' messages='copied to clipboard'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        setTimeout(() => {
            setVisible(false)
        }, 1000)
    }

    const save = () => {
        setVisible(false)
        onChange({ target: { value: result} })
    }

    const reset = () => {
        setDescription('')
        setResult('')
        setLoading(false)
        setShowCopy(false)
    }

    useEffect(() => {
        if(!visible){
            reset()
        }
    }, [visible])

    return (
       <>
            <OutsideClickHandler  onOutsideClick={() => setVisible(false)}>
                <div className={cn(styles.custom, className, {[styles.active] : visible})}>
                    <div className={styles.children} onClick={() => setVisible(!visible)}>{children}</div>
                    <div className={styles.tooltip}>
                        <div className={styles.prompt}>
                            <div className={styles.title}>
                                <p>Hello! Let's craft compelling content for your game or app.</p>
                            </div>
                            <div className={styles.promptInput}>
                                <input className={styles.field} placeholder='What do you need today?' value={description} onChange={(e) => setDescription(e.target.value)} onKeyDown={handleKeyDown}/>
                                <div className={cn(styles.icon, {[styles.active] : description !== ''})} onClick={() => generateDescription()}>
                                    <Icon name='arrow-top' size='16' />
                                </div>
                            </div>
                        </div>
                        {loading &&
                            <>
                                <div className={styles.result}>
                                    <div className={styles.content}>Generating description. Please Wait...!</div>
                                </div>
                            </>
                        }
                        {!loading && result !== '' &&
                            <div className={styles.result}>
                                <div className={styles.content}>
                                    <p>Here's what I found: </p>
                                </div>
                                <div className={styles.answer}>
                                    {result}
                                    {showCopy &&
                                        <>
                                            <span className={styles.copy} onClick={() => copyToClipBoard()}>
                                                <Icon name='copy' size='16' />
                                            </span>
                                            <div className={styles.action}>
                                                <button className={styles.cancel} onClick={save}>Use</button>
                                                <button className={styles.cancel} onClick={generateDescription}>Regenrate</button>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </OutsideClickHandler>
       </>
    )
}

export default PromptTooltip