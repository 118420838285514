import React, {useState, useEffect, useContext} from 'react'
import cn from 'classnames'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './event.module.sass'
import Card from '../../../../../components/Card'
import Icon from '../../../../../components/Icon'
import addIcon from '../../../../../media/images/icons/light.png'
import Form from '../../../../../components/Form'
import EmptyState from '../../../../../components/EmptyStates'
import { ToastContainer } from 'react-toastify';
import CustomEventTable from '../../../../../components/BuildComponents/EventComponent/CustomEventTable'
import SpectreEventTable from '../../../../../components/BuildComponents/EventComponent/SpectreEventTable'
import { getDefaultEventAction, getCustomEventAction, filterEventsAction, filterCustomEventsAction, filterDefaultEventsAction } from '../../../../../redux/action/builds/events/eventActions'
import {ReactComponent as Previous} from '../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {ReactComponent as ArchiveSvg} from '../../../../../media/images/icons/archive.svg'
import { Skeleton } from '@mui/material'
import SkeletonTable from '../../../../../components/Skeleton/TableSkeleton'
import GlobalStateContext from '../../../../../components/Context/GlobalStates/GlobalStateContext'
import Navigation from '../../../../../components/Header/SecondaryNavigation';
import Loader from "../../../../../components/Loaders/LoaderProgress";
import SkeletonEmptyState from '../../../../../components/Skeleton/EmptyStateSkeleton'

const Tabs = ["Specter", "Custom"];

const Events = ({className}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams()

  // Search
  const [search, setSearch] = useState(null)
  // Sort
  const sortList = [{id: 1, name: 'Event Name', value: 'name'}, {id: 2, name: 'Created At', value: 'created_at'}, {id: 3, name: 'Updated At', value: 'updated_at'}]
  const [sortValue, setSortValue] = useState({name: 'updated_at', value: 0})
  const [selectedSort, setSelectedSort] = useState('updated_at')
  const [sort, setSort] = useState([{id: 1, name: 'Ascending'}, {id : 2, name : 'Descending'}])
  const [sortIndex, setSortIndex] = useState(0)

  const [customPage, setCustomPage] = useState(1)
  const [customLimit, setCustomLimit] = useState(10)
  const [customCount, setCustomCount] = useState()

  const [userAccess, setUserAccess] = useState(false)
  const globalStates = useContext(GlobalStateContext)

  const getUserAccess = () => {
    if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
      let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
      if(appPermission?.length > 0){
        setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
      }
    }
  }
  
  useEffect(() => {
    getUserAccess()
  }, [])

  let getCustomEvent = async(search) => {
    let customEvent = {
      projectId : appid,
      offset: ((customPage-1)*customLimit),
      limit: customLimit,
      // Search
      ...(search && search !== null && search !== '' && {search : search}),
    }
    if(!search){
      await dispatch(getCustomEventAction(customEvent, configureSkeleton))
    }else{
      await dispatch(filterDefaultEventsAction(customEvent, configureSkeleton))
    }
  }

  let customeEvents = useSelector((state) => {
    return state.event.customEvents
  })

  let totalCustomvents = useSelector((state)=>{
    return state.event.totalCustomEventCount
  })

  let isLoading = useSelector((state)=>{
    return state.event.isLoading
  })

  let isFiltered = useSelector((state) => {
    return state.event.isFiltered
  })

  const [showSkeleton, setShowSkeleton] = useState(true)

  const configureSkeleton = () => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
  }

  const handleCustomPageChange = (event, value)  => {
    setCustomPage(value)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(()=>{
    if(selectedSort !== null){
      getCustomEvent(search)
    }
  }, [customPage, selectedSort])

  const onSearch = (search) => {
    if(search == ''){
      setSearch(null)
      getCustomEvent()
    }else{
      setSearch(search)
      getCustomEvent(search)
    }
  }

  if(isLoading){
    return(
      <>
        <div className={styles.loader}>
          <Loader />
        </div>
      </>
    )
  }

  return(
    <>
        <Navigation type='Custom Events' search={search} setSearch={onSearch} data={sortList} setPage={setCustomPage} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selectedSort} setSelected={setSelectedSort} sort={sort} setSort={setSort} value={sortValue} setValue={setSortValue}/>
        <Card layOut={`Get`}  className={styles.card} classCardHead={styles.head}>
        <div className={styles.wrapper}>
          {/* PS Table View */}
          {showSkeleton && customeEvents?.length === 0 && 
            <SkeletonEmptyState />
          }
          {showSkeleton && customeEvents?.length > 0 && 
            <SkeletonTable usedFor='Events' rows={customeEvents.length} column={5} controls={1} type={['recatangle', 'recatangle', 'recatangle', 'recatangle', 'circular']} colWidth={[100, 100, 100, 100, 36]} colHeight={[25, 20, 25, 25, 36]}/>
          }
          {!showSkeleton && !isFiltered && customeEvents?.length === 0 &&
            <EmptyState access={userAccess} route={`/create-events/${appid}`} type="Event"/>
          }
          {!showSkeleton && !isFiltered && customeEvents?.length > 0 &&
            <CustomEventTable access={userAccess} items={customeEvents} title="Last edited" />
          }
          {!showSkeleton && isFiltered &&
            (customeEvents?.length > 0 ?
              <CustomEventTable access={userAccess} items={customeEvents} title="Last edited" />
            :
              <div className={styles.message}>No Result Found</div>
            )
          }
        </div>

        {!isLoading && customeEvents.length > 0 &&
          <div className={styles.pagination}>
            <Stack spacing={2}>
              <Pagination page={customPage} renderItem={(item) => (
                <PaginationItem
                  {...item}
                  sx={{
                    '&.MuiPaginationItem-root': {
                      minWidth: '28px',
                      height: '28px',
                      fontSize: '12px'
                    },
                    '&.Mui-selected': {
                      color: 'rgb(255, 255, 255)',
                      fontWeight : 'bold',
                      border : '1px solid rgb(42, 133, 255)',
                      background : 'rgba(42, 133, 255)'
                    },
                    '&.MuiPaginationItem-page.Mui-selected:hover' : {
                      color: 'rgb(255, 255, 255)',
                      fontWeight : 'bold',
                      border : '1px solid rgb(42, 133, 255)',
                      background : 'rgba(42, 133, 255)'
                    }
                  }}
                />
              )} siblingCount={1} count={Math.ceil(totalCustomvents/customLimit)} color="primary" onChange={handleCustomPageChange} />
            </Stack>
          </div>
        }
        </Card>
        <div className="divider"></div>
          <div className={styles.msg}>
            <p className={styles.alert}>Learn more about Events <a className={styles.infoLink} href="https://manual.specterapp.xyz/specter-user-manual/build/events" target="_blank">Click Here</a></p>
        </div>
    </>
  )

}

export default Events