import React from "react";
import cn from "classnames";
import styles from "./card.module.sass";

const Card = ({className, layOut, title, classTitle, classCardHead, head, children, disabled}) => {

  return (
    <div className={layOut == 'Get' ? cn(styles.getCard, className, {[styles.disabled] : disabled}) : cn(styles.card, className, {[styles.disabled] : disabled})}>
      {title && (
        <div className={cn(styles.head, classCardHead)}>
          <div className={cn(classTitle, styles.title)}>{title}</div>
          {head && head}
        </div>
      )}
      {children}
    </div>
  );

};

export default Card;
