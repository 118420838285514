import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './toast.module.sass'
import {ReactComponent as SuccessToast} from '../../media/images/icons/success_toast_icon.svg'
import {ReactComponent as ErrorToast} from '../../media/images/icons/error_toast_icon.svg'
import {ReactComponent as WarningToast} from '../../media/images/icons/warning_toast_icon.svg'
import {ReactComponent as InfoToast} from '../../media/images/icons/info_toast_icon.svg'


const Toast = ({type, messages}) => {

    return (
        <>
            <div className={styles.toast}>
                <div className={styles.toastSuccess}>
                    <div className={styles.successIcon}>
                        {type == 'Success' && <SuccessToast />}
                        {type == 'Error' && <ErrorToast />}
                        {type == 'Warning' && <WarningToast />}
                        {type == 'Info' && <InfoToast />}
                    </div>
                    <div className={styles.successContainer}>
                        <div className={type == 'Success' ? styles.successMessage : type == 'Error' ? styles.errorMessage : type == 'Warning' ? styles.warningMessage : type == 'Info' ? styles.infoMessage : ''}>
                            <span>{messages}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Toast