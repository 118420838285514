import { BASE_URL, GET_CURRENCY, ADD_CURRENCY, EDIT_CURRENCY, DELETE_CURRENCY, CREATE_POLICY, EDIT_POLICY, GET_RMG_TRANSACTION } from "../../../../resources/APIEndpoints";
import axios from "../../../../utils/interceptor";


function CurrencyService(){
    this.getAllCurrency = (body) => axios.post(BASE_URL + GET_CURRENCY, body)
    this.getCurrencyById = (body) => axios.post(BASE_URL + GET_CURRENCY, body)
    this.getSearchedCurrency = (body) => axios.post(BASE_URL + GET_CURRENCY, body)
    this.addCurrency = (body) => axios.post(BASE_URL + ADD_CURRENCY, body)
    this.editCurrency = (body) => axios.post(BASE_URL + EDIT_CURRENCY, body)
    this.archiveCurrency = (body) => axios.post(BASE_URL + EDIT_CURRENCY, body)
    this.addPolicyCurrency = (body) => axios.post(BASE_URL + CREATE_POLICY, body)
    this.editPolicyCurrency = (body) => axios.post(BASE_URL + EDIT_POLICY, body)
    this.getRMGTransaction = (body) => axios.post(BASE_URL + GET_RMG_TRANSACTION, body)
}

export default new CurrencyService();