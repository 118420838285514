import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import styles from './getApp.module.sass'
import AppsCardLayout from '../../../../components/Organisation/Apps/AppsCardLayout'
// import {apps} from '../mock/apps'
import Control from './Control'
import addIcon from '../../../../media/images/icons/light.png'
import { ToastContainer } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { getallAppAction } from '../../../../redux/action/apps/appsAction'
import {ReactComponent as EmptyState} from '../../../../media/images/Apps_Initial_State.svg'
import Profile from '../../../../media/images/icons/profile_One.png'
import Card from '../../../../components/Card'
import {ReactComponent as Cross} from '../../../../media/images/fill_cross.svg'
import {ReactComponent as Circle} from '../../../../media/images/full_circle.svg'
import {ReactComponent as HalfCircle} from '../../../../media/images/full_hollow_circle.svg'
import HalfCross from '../../../../media/images/half_cross.png'
import Loader from '../../../../components/Loader'
import { extarctImageFromUrlAction } from '../../../../redux/action/common/commonAction'

const GetApps = ({setShowLoader, setAppName, setLoaderType, users}) => {

  const dispatch = useDispatch()
  const [userName, setUserName] = useState('')

  const getUserName = () => {
    let name = JSON.parse(localStorage.getItem('userName'))
    setUserName(name)
  }

  useEffect(()=>{
    getUserName()
  }, [])

  let apps = useSelector((state)=>{
    return state.apps.app
  })

  let isLoading = useSelector((state)=>{
    return state.apps.isFetched
  })

  const [user, setUser] = useState(null)
  const [isProfile, setIsProfile] = useState(null)

  let UserProfile = useSelector((state) => {
    return state.organisation.userProfile
  })

  const getUser = () => {
    let users = localStorage.getItem('userName')
    setUser(JSON.parse(users).split(" ").map(word => word.charAt(0).toUpperCase()).join(''))
  }

  const getUserProfileImage = async() => {
    if(UserProfile.profile_url == null || UserProfile.profile_url == ''){
      getUser()
      setIsProfile(false)
    }else{
      const url = await extractImageFromCdn(UserProfile.profile_url)
      setUser(url)
      setIsProfile(true)
    } 
  }

  const extractImageFromCdn = async(url) => {
    if(url !== null && url !== undefined && url.includes('cdn')){
      try {
          const response = await dispatch(extarctImageFromUrlAction(url))
          return response
        }catch (error){
          console.error('Error fetching image:', error);
        }
    }
  }

  useEffect(() => {
    getUserProfileImage()
  }, [UserProfile])

  return (
    <>
      <ToastContainer />
      <div className={styles.getAppWrapper}>
          <Card className={styles.profileWrapperOuter}>
            <div className={styles.profileWrapper}>
              <Cross className={styles.cross} />
              <Circle className={styles.circle} />
              <HalfCircle className={styles.circleHalf} />
              <img src={HalfCross} className={styles.crossHalf} />
              <div className={styles.profileOverview}>
                <div className={styles.profile}>
                  <div className={!isProfile ? styles.profileIcon : styles.profileImage}>
                  {isProfile && <img src={user} alt='user-profile' width={48} height={48}/>}
                  {!isProfile && <div className={styles.user}>{user}</div>}
                  </div>
                  <div className={styles.userName}>
                    <p className={styles.greeting}>Hello,</p>
                    <p className={styles.name}>{userName}</p>
                  </div>
                </div>
                <div className={styles.manual}>
                  {users === 'admin' && <Control type='small' setLoaderType={setLoaderType} setAppName={setAppName} setShowLoader={setShowLoader}/>}
                </div>
              </div>
            </div>
          </Card>
        <div className={styles.gap}></div>
        {isLoading && 
          <>
            <div className={styles.wrapper}>
                <div className={styles.loading}>
                  <Loader className={styles.loader}/>
                </div>
              </div>
          </>
        }
        {(isLoading == false && apps && apps.length === 0 )&&
          <Card>
            <div className={styles.emptyState}>
              <div className={styles.emptyStateSVG}>
                <EmptyState />
              </div>
              <div className={styles.emptyStateContent}>
                <span>Feels a little empty over here!</span>
              </div>
              <div className={styles.emptyStateButton}>
                {users === 'admin' && <Control type='small' setLoaderType={setLoaderType} setAppName={setAppName} setShowLoader={setShowLoader}/>}
              </div>
            </div>
          </Card>
        }
        {(isLoading == false && apps && apps.length > 0) &&
          <Card title="Recent Apps" classTitle={cn("title-purple", styles.title)}>
            <div className={styles.listContainer}>
                <div className={styles.list}>
                  {apps && apps.length > 0 ?
                    (apps && apps.slice(0,6).map((x, index) => (
                      <AppsCardLayout setLoaderType={setLoaderType} setAppName={setAppName} setShowLoader={setShowLoader} className={styles.product} item={x} key={index} appid={apps.id} released />
                    )))
                  : <div className={styles.message}>No Apps Available</div>}
                </div>

                <div className={styles.viewAll}>
                  <Link to='/apps' >View All</Link>
                </div>
            </div>
          </Card>
        }
      </div>   
    </>
  )
}

export default GetApps