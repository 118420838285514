import { GET_ITEM, ADD_ITEM, EDIT_ITEM, FILTER_ITEMS_SUCCESS, FILTER_ITEMS_ERROR, GET_ITEM_SUCCESS, GET_ITEM_ERROR } from "../../../../actionTypes/actionTypes";
import itemServices from "../../../../../services/buildServices/economyServices/itemServices/itemServices";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from "../../../../../components/Toast";

export const getAllItemAction = (body, showSkeleton) => {
    return async(dispatch) => {
        dispatch(getAllItems(null, null, GET_ITEM))
        await itemServices.getAllItems(body)
        .then(res=>{
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(getAllItems(res.data.data.items, (body?.showArchived ? res.data.data.archiveCount : res.data.data.totalCount), (body?.showArchived ? FILTER_ITEMS_SUCCESS : GET_ITEM_SUCCESS)))
        }).catch(err => {
            dispatch(getAllItems(err.response, null,  (body?.showArchived ? FILTER_ITEMS_ERROR : GET_ITEM_ERROR)))
        })
    }
}

export const filterItemAction = (body, showSkeleton, isFiltered) => {
    return async (dispatch) => {
        await itemServices.getAllItems(body)
        .then(res => {
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(filterItems(res.data.data.items, ((body?.search && body?.search !== '') ? res.data.data.searchCount : (body?.search === undefined && isFiltered) ? res.data.data.filterCount : res.data.data.totalCount), FILTER_ITEMS_SUCCESS))
        }).catch(err=>{
            dispatch(filterItems(err.response, null, FILTER_ITEMS_ERROR))
        })
    }
}

export const addItemAction = (body, navigate, appid, setDisable) => {
    return async(dispatch) => {
        if(setDisable){
            setDisable(true)
        }
        await itemServices.addItem(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            setTimeout(()=>{
                sessionStorage.removeItem('contents')
                if(setDisable){
                    setDisable(false)
                }
                if(navigate){
                    navigate(`/item/${appid}`)
                }
            }, 2000)
            return res;
        }).catch(error => {
            if(setDisable){
                setDisable(false)
            }
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error
        })
    }
}

export const editItemAction = (body, navigate, appid, setDisable) => {
    return async(dispatch) => {
        if(setDisable){
            setDisable(true)
        }
        await itemServices.editItem(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            setTimeout(()=>{
                if(setDisable){
                    setDisable(false)
                }
                if(navigate){
                    navigate(`/item/${appid}`)
                }
            }, 2000)
            return res;
        }).catch(error => {
            if(setDisable){
                setDisable(false)
            }
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}

export const archiveItemAction = (body, appid, onClose, status) => {
    return async(dispatch) => {
        try {
            const res = await itemServices.archiveItem(body)
            if(res){
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                let data = {projectId: appid, showArchived: status, sortField: "updated_at", sortOrder: "asc", offset: 0, limit: 8}
                dispatch(getAllItemAction(data))
                if(onClose){
                    onClose()
                }
            }
            return res;
        }catch(error) {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            console.log(error)
            throw error; 
        }
    }
}

export const getAllItems = (data, count, type) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}

export const addItem = (data) => {
    return {
        type : ADD_ITEM,
        payload : data
    }
}

export const editItem = (data) => {
    return {
        type : EDIT_ITEM,
        payload : data
    }
}

export const filterItems = (data, count, type) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}