import {GET_META_DATA, GET_TAGS, UPDATE_TAGS, GET_PLATFORMS, GET_GOALS, GET_STATES, GET_CITY, GET_ALL_COUNTRY_SUCCESS, GET_ALL_STATES, GET_ALL_STATES_SUCCESS, GET_DESCRIPTION, GET_DESCRIPTION_SUCCESS, GET_DESCRIPTION_ERROR} from '../../actionTypes/actionTypes'

const initialState = {
    description: null,
    metaData : [],
    tags:[],
    goals : [],
    platforms : [],
    region: [],
    cities: [],
    countries: [],
    states: [],
    isLoading : false
};

export const commonReducer =( state=initialState, action )=>{
    switch(action.type){
        case GET_META_DATA : {
            return {
                ...state,
                metaData: action.payload,
            }
        }

        case GET_STATES : {
            return {
                ...state,
                region: action.payload
            }
        }

        case GET_CITY : {
            return {
                ...state,
                cities: action.payload
            }
        }

        case GET_TAGS : {
            return {
                ...state,
                tags: action.payload,
            }
        }

        case GET_GOALS : {
            return {
                ...state,
                goals: action.payload,
            }
        }

        case GET_PLATFORMS : {
            return {
                ...state,
                platforms: action.payload,
            }
        }

        case UPDATE_TAGS : {
            return {
                ...state,
                tags: action.payload,
            }
        }

        case GET_ALL_COUNTRY_SUCCESS : {
            return {
                ...state,
                countries: action.payload
            }
        }

        case GET_ALL_STATES_SUCCESS : {
            return {
                ...state,
                states: action.payload
            }
        }

        case GET_DESCRIPTION : {
            return {
                ...state,
                isLoading: true,
                description: null
            }
        }

        case GET_DESCRIPTION_SUCCESS : {
            return {
                ...state,
                isLoading: false,
                description: action.payload
            }
        }

        case GET_DESCRIPTION_ERROR : {
            return {
                ...state,
                isLoading: false,
                description: null
            }
        }

        default: {
            return {
                ... state
            }
        }
    }    
}   