import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames'
import styles from "./dropdown.module.sass"
import Tooltip from "../../Tooltip";

const Dropdown = ({className, disabled, groupBy, placeholder, classLabel, classDropdownHead, classDropdownLabel, value, setValue, setId, options, label, small, upBody, tooltip, disabledOption}) => {

    const [visible, setVisible] = useState(false);

    const handleClick = (value) => {
        setValue(value)
        setVisible(false);
    };

      
    return (
        <div className={groupBy == 1 ? styles.groupDropdown : groupBy == 2 ? styles.groupByTwoDropdown : groupBy == 3 ? styles.groupByThreeDropdown : groupBy == 4 ? styles.groupByFourDropdown : 'dropdown'}>
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            {label && (
                <div className={cn(classLabel, styles.label)}>
                {label}{" "}
                {tooltip && (
                    <Tooltip
                    className={styles.tooltip}
                    title={tooltip}
                    icon="info"
                    place="right"
                    />
                )}
                </div>
            )}
            <div className={cn(styles.dropdown, className, { [styles.small]: small },{[styles.active]: visible},{[styles.disabled] : disabled})}>
                <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                <div className={styles.selection}>{(value !== null && value !== '') ? value : placeholder}</div>
                </div>
                <div className={cn(styles.body, { [styles.bodyUp]: upBody }, {[styles.disabled] : disabled})}>
                    {options && options.map((x, index) => {
                        return(
                            <div onClick={() => handleClick(x, index)} className={cn(styles.option, {[styles.selectioned]: x === value}, {[styles.disabled] : x === disabledOption})} key={index}>
                                {x} {disabledOption && x === 'Only Platform Fee' ? ('(Enables if platform fee structure is precentage)') : null}
                            </div>
                        )
                    })}
                </div>
            </div>
            </OutsideClickHandler>
        </div>
    )
}

export default Dropdown