import React, { useState } from "react";
import styles from './cardLayout.module.sass'
import cn from "classnames";
import Icon from "../../Icon";
import { useParams } from "react-router-dom";
import Mission from '../../../media/images/placeholders/Mission.png'
import Control from "../../../screens/AppInformation/AppInfo/Control";
import { deleteMediaAction } from "../../../redux/action/AppSettings/Media/mediaAction";
import { useDispatch } from "react-redux";
import Toast from "../../Toast";
import { ToastContainer, toast } from 'react-toastify';
import Modal from "../../Modals/ModalComponent";
import DeleteMediaModal from "./DeleteMediaModal"
import yellowFolder from "../../../media/images/icons/yellowFolder.svg"

const MediaCardLayout = ({className, access, item,value, type, onChange,released,withoutСheckbox,limit,uploadFileProgress}) => {
    
    const [visible, setVisible] = useState(false);
    const {appid} = useParams()
    const {folderName} = useParams()
    const dispatch = useDispatch()
    const [visibleModal, setVisibleModal] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const handleClick = () => {
        onChange();
        setVisible(!visible);
    }

    const deleteMedia = (id) => {
        let deleteMediaData = {
            projectId : appid,
            ids : [id]
        }
        dispatch(deleteMediaAction(deleteMediaData, appid,folderName,true, limit))
    }
    const copyToClipboard = (value) => {
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = (value != null || value == '') ? value : '-';
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand("copy");
        document.body.removeChild(tempTextArea);
        toast.success(<Toast type='Success' messages='copied to clipboard'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
    }

    // const downloadMedia = (url, name) => {
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.download = name || 'download';
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };

    // const downloadMedia = (url, name) => {
    //     fetch(url)
    //         .then(response => response.blob())
    //         .then(blob => {
    //             const link = document.createElement('a');
    //             link.href = window.URL.createObjectURL(blob);
    //             link.download = name || 'download';
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);
    //         })
    //         .catch(console.error);
    // };
    
    const downloadMedia = async (url,name) => {
        await fetch(url, {
        method: 'GET',
        responseType: 'arraybuffer',
        headers: {
            'Cache-Control': 'no-cache'
          },
          cache: 'no-store'
      })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          name,
        );
    
        // Append to html link element page
        document.body.appendChild(link);
    
        // Start download
        link.click();
    
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
      };

    return (
        <div className={cn(styles.product, className, {[styles.active]: visible,})}>
            <div className={styles.card}>
                <div className={styles.cardWrapper}>
                    <div className={styles.cardHeader}>
                            {(uploadFileProgress>0)&&
                            <div style={{marginLeft:"2%"}}>
                            <div className={styles.progressBar}>
                                <div className={styles.progress} style={{ width: `${uploadFileProgress}%` }}></div>
                            </div>
                            </div>}
                        <div style={{position:"relative"}}>
                            <div className={styles.cardImage} style={uploadFileProgress>0?{opacity:"0.5"}:{}}>
                                {type == 'Images' &&  <img src={item.url != null ? item.url : ''} alt={`${item.name}`}/>}
                                {type == 'Videos' &&  <video src={item.url != null ? item.url : ''} />}
                                {type == 'others' &&  <img src={yellowFolder} alt={`folder`}/>}
                            </div>
                            {!access &&
                                <>
                                    <div className={styles.overlay}>
                                        <div className={styles.icon} onClick={()=>{setVisibleModal(true)}} >
                                            <Icon fill="#6F767E" name='trash' size='12' />
                                        </div>
                                        <div className={styles.icon} onClick={() => { downloadMedia(item.url, item.name) }} >
                                            <Icon fill="#6F767E" name='download' size='12' />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>

                    <div className={styles.cardBody}>
                        <div className={styles.content}>
                            <div className={styles.label} title={(item.name && item.name != null && item.name != '') ? item.name : '-'}>{(item.name && item.name != null && item.name != '') ? item.name : '-'}</div>
                        </div>
                        <div className={styles.fileContent}>
                            <div className={styles.preview}><Control usedFor='Media' type={type} src={item.url} /></div>
                            <div className={styles.fileSize}>{(item.size && item.size != null && item.size != '') ? <>{(item.size /1024).toFixed(2)} Mb</> : ''}</div>
                            <div className={styles.copy}>{!access && <span onClick={()=>{copyToClipboard(item.url != null ? item.url : '');setIsCopied(true);setTimeout(() => {setIsCopied(false)}, 2000);}}>{isCopied?"Copied!":"Copy URL"}<Icon name='copy' size='16' /></span>}</div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal visible={visibleModal} onClose={() => {setVisibleModal(false)}}>
                <DeleteMediaModal onProceed={()=>{deleteMedia(item.id)}} onClose={() => {setVisibleModal(false)}}/>
            </Modal>
        </div>
    );
};

export default MediaCardLayout;
