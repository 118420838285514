import {GET_LIVE_OPS_CATEGORY, SCHEDULE_COMPETITION, GET_SCHEDULE_COMPETITION,CREATE_LIVE_OPS_CATEGORY,EDIT_SCHEDULE_COMPETITION, FILTER_SCHEDULE_COMPETITION_SUCCESS, LIVE_OPS_RESULT_ERROR, LIVE_OPS_RESULT_SUCCESS,GET_CALENDAR,LIVE_OPS_RESULT} from "../../../actionTypes/actionTypes";
import LiveOpsServices from '../../../../services/engageServices/Live-Ops/LiveOpsServices'
import Toast from '../../../../components/Toast';
import { toast } from 'react-toastify';
import { getAllLeadboardAction } from "../Leaderboard/leaderboardAction";
import { getCompetitionInstantBattleAction, getCompetitionTournamentAction } from "../Competitions/competitionAction";
import { getAllTaskAction } from "../Achievement/Task/TaskAction";
import { getAllStepSeriesAction } from "../Achievement/StepSeries/StepSeriesAction";

export const getLiveOpsCategoryAction = (body) => {
    return async (dispatch) => {
        await LiveOpsServices.getLiveOpsCategory(body)
        .then(res=>{
            dispatch(getLiveOpsCategory(res.data.data))
        }).catch(err=>{
            console.log(err)
        })
    }
}

export const createLiveOpsCategoryAction = (body, navigate, appid) => {
    return async (dispatch) => {
        await LiveOpsServices.createLiveOpsCategory(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            setTimeout(()=>{
                window.location.reload()
            }, 2000)
        return res;
        }).catch(error=>{
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}

export const createScheduleTaskAction = (body, type, as, appid, onClose, navigate, setDisable) => {
    return async (dispatch) => {
        await LiveOpsServices.scheduleTask(body)
        .then(res=>{
            onClose()
            if(as == 'saveNschedule'){
                setDisable(false)
                navigate(`/task/${appid}`)
            }else{
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                let task = {
                    projectId : appid,
                    offset: 0,
                    limit: 8
                }
                dispatch(getAllTaskAction(task))
            }
            return res;
        }).catch(error=>{
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}

export const createScheduleTaskGroupAction = (body, type, as, appid, onClose, navigate, setDisable) => {
    return async (dispatch) => {
        await LiveOpsServices.scheduleTaskGroup(body)
        .then(res=>{
            onClose()
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            if(as == 'saveNschedule'){
                if(type == 'Mission'){
                    setTimeout(() => {
                        setDisable(false)
                        navigate(`/mission/${appid}`)
                    }, 1000)
                }else{
                    setTimeout(() => {
                        setDisable(false)
                        navigate(`/step-series/${appid}`)
                    }, 1000)
                }       
            }else{
                let taskGroup = {
                    ids: [],
                    projectId : appid,
                    offset: 0,
                    limit: 8,
                    typeId : (type == 'Mission' ? 1 : 2),
                }
                dispatch(getAllStepSeriesAction(taskGroup))
            }
            return res;
        }).catch(error=>{
            // error.response.data.errors && error.response.data.errors.map((err,i)=>{
            //     toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            // })
            throw error;
        })
    }
}

export const createScheduleLiveOpsAction = (body, type, as, appid, onClose, navigate, setDisable) => {
    return async (dispatch) => {
        await LiveOpsServices.scheduleLiveOps(body)
        .then(res=>{ 
            onClose()       
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            if(type == 'Leaderboard'){
                if(as == 'saveNschedule'){
                    setTimeout(() => {
                        setDisable(false)
                        navigate(`/leaderboard/${appid}`)
                    }, 1000)
                }else{
                    let leaderboard = {
                        projectId : appid,
                        offset : 0,
                        limit: 8
                    }
                    setTimeout(() => {
                        dispatch(getAllLeadboardAction(leaderboard))
                    }, 400)
                }
            }else if(type == 'Tournament'){
                if(as == 'saveNschedule'){
                    setTimeout(() => {
                        setDisable(false)
                        navigate(`/competition-tournament/${appid}`)
                    }, 1000)
                }else{
                    let tournament = {
                        projectId : appid,
                        competitionFormatTypeMasterId : 2,
                        offset : 0,
                        limit: 8
                    }
                    setTimeout(() => {
                        dispatch(getCompetitionTournamentAction(tournament))
                    }, 400)
                }
            }else if(type == 'Instant Battle'){
                if(as == 'saveNschedule'){
                    setTimeout(() => {
                        setDisable(false)
                        navigate(`/competition-instant-battle/${appid}`)
                    }, 1000)
                }else{
                    let tournament = {
                        projectId : appid,
                        competitionFormatTypeMasterId : 3,
                        offset : 0,
                        limit: 8
                    }
                    setTimeout(() => {
                        dispatch(getCompetitionInstantBattleAction(tournament))
                    }, 400)
                }
            }
            return res;
        }).catch(error=>{
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}

export const stopLiveOpsAction = (body, type, as, appid, onClose, navigate, id) => {
    return async (dispatch) => {
        await LiveOpsServices.stopLiveOps(body)
        .then(res => {
            onClose()       
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            if(type == 'Leaderboard'){
                if(as == 'saveNschedule'){
                    navigate(`/leaderboard/${appid}`)
                }else{
                    let leaderboard = {
                        projectId : appid,
                        offset : 0,
                        limit: 8
                    }
                    setTimeout(() => {
                        dispatch(getAllLeadboardAction(leaderboard))
                    }, 400)
                }
            }else if(type == 'Tournament'){
                if(as == 'saveNschedule'){
                    navigate(`/competition-tournament/${appid}`)
                }else{
                    let tournament = {
                        projectId : appid,
                        competitionFormatTypeMasterId : 2,
                        offset : 0,
                        limit: 8
                    }
                    setTimeout(() => {
                        dispatch(getCompetitionTournamentAction(tournament))
                    }, 400)
                }
            }else if(type == 'Instant Battle'){
                if(as == 'saveNschedule'){
                    navigate(`/competition-instant-battle/${appid}`)
                }else{
                    let tournament = {
                        projectId : appid,
                        competitionFormatTypeMasterId : 3,
                        offset : 0,
                        limit: 8
                    }
                    setTimeout(() => {
                        dispatch(getCompetitionInstantBattleAction(tournament))
                    }, 400)
                }
            }
            return res;
        }).catch(error=>{
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}

export const stopTaskAction = (body, onClose, type, appid) => {
    return async(dispatch) => {
        await LiveOpsServices.stopTask(body)
        .then(res => {
            onClose() 
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            setTimeout(() => {
                let tasks = {
                    projectId : appid,
                    offset: 0,
                    limit: 10
                }
                dispatch(getAllTaskAction(tasks))
            }, 500)
            return res;
        }).catch(err => {
            throw err;
        })
    }
}

export const stopTaskGroupAction = (body, onClose,type, appid) => {
    return async(dispatch) => {
        await LiveOpsServices.stopTaskGroup(body)
        .then(res => {
            onClose() 
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            setTimeout(() => {
                let tasks = {
                    ids: [],
                    projectId : appid,
                    offset: 0,
                    limit: 8,
                    typeId : (type == 'Mission' ? 1 : 2),
                }
                dispatch(getAllStepSeriesAction(tasks))
            }, 500)
            return res;
        }).catch(err => {
            throw err;
        })
    }
}

export const pausenplayLiveOpsAction = (body, type, as, appid, onClose, navigate, id) => {
    return async (dispatch) => {
        await LiveOpsServices.pausenplayLiveOps(body)
        .then(res => {
            onClose() 
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            if(type == 'Leaderboard'){
                if(as == 'saveNschedule'){
                    navigate(`/leaderboard/${appid}`)
                }else{
                    let leaderboard = {
                        projectId : appid,
                        offset : 0,
                        limit: 8
                    }
                    setTimeout(() => {
                        dispatch(getAllLeadboardAction(leaderboard))
                    }, 400)
                }
            }else if(type == 'Tournament'){
                if(as == 'saveNschedule'){
                    navigate(`/competition-tournament/${appid}`)
                }else{
                    let tournament = {
                        projectId : appid,
                        competitionFormatTypeMasterId : 2,
                        offset : 0,
                        limit: 8
                    }
                    setTimeout(() => {
                        dispatch(getCompetitionTournamentAction(tournament))
                    }, 400)
                }
            }else if(type == 'Instant Battle'){
                if(as == 'saveNschedule'){
                    navigate(`/competition-instant-battle/${appid}`)
                }else{
                    let tournament = {
                        projectId : appid,
                        competitionFormatTypeMasterId : 3,
                        offset : 0,
                        limit: 8
                    }
                    setTimeout(() => {
                        dispatch(getCompetitionInstantBattleAction(tournament))
                    }, 400)
                }
            }
            return res;
        }).catch(error=>{
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}

export const rescheduleLiveOpsAction = (body, type, as, appid, onClose, navigate, setDisable) => {
    return async (dispatch) => {
        await LiveOpsServices.rescheduleLiveOps(body)
        .then(res=>{ 
            onClose()       
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            if(type == 'Leaderboard'){
                if(as == 'saveNschedule'){
                    setTimeout(() => {
                        setDisable(false)
                        navigate(`/leaderboard/${appid}`)
                    }, 1000)   
                }else{
                    let leaderboard = {
                        projectId : appid,
                        offset : 0,
                        limit: 8
                    }
                    setTimeout(() => {
                        dispatch(getAllLeadboardAction(leaderboard))
                    }, 400)
                }
            }else if(type == 'Tournament'){
                if(as == 'saveNschedule'){
                    setTimeout(() => {
                        setDisable(false)
                        navigate(`/competition-tournament/${appid}`)
                    }, 1000)
                }else{
                    let tournament = {
                        projectId : appid,
                        competitionFormatTypeMasterId : 2,
                        offset : 0,
                        limit: 8
                    }
                    setTimeout(() => {
                        dispatch(getCompetitionTournamentAction(tournament))
                    }, 400)
                }
            }else if(type == 'Instant Battle'){
                if(as == 'saveNschedule'){
                    setTimeout(() => {
                        setDisable(false)
                        navigate(`/competition-instant-battle/${appid}`)
                    }, 1000)
                }else{
                    let tournament = {
                        projectId : appid,
                        competitionFormatTypeMasterId : 3,
                        offset : 0,
                        limit: 8
                    }
                    setTimeout(() => {
                        dispatch(getCompetitionInstantBattleAction(tournament))
                    }, 400)
                }
            }
            return res;
        }).catch(error=>{
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}

export const editScheduleLiveOpsAction = (body) => {
    return async (dispatch) => {
        await LiveOpsServices.editscheduleCompetition(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            return res;
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error
        })
    }
}


export const getAllScheduleCompetitionAction = (body) => {
    return async (dispatch) => {
        await LiveOpsServices.getAllScheduleCompetition(body)
        .then(res=>{
            dispatch(getAllScheduleCompetition(res.data))
        }).catch(error => {
            if(error.response.status == 400){
                error.response.data.message && error.response.data.message.map((err,i)=>{
                    toast.error(<Toast type='Error' messages={err}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                })
            }
            if(error.response.status == 409){
                error.response.data.message && toast.error(<Toast type='Error' messages={error.response.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FF6A55'}})
            }
        })
    }
}

export const filterScheduleCompetitionAction = (body) => {
    return async (dispatch) => {
        await LiveOpsServices.getAllScheduleCompetition(body)
        .then(res=>{
            dispatch(filterScheduleCompetition(res.data.data.liveOps, FILTER_SCHEDULE_COMPETITION_SUCCESS ,res.data.data.totalCount))
        }).catch(error => {
           
        })
    }
}

export const getCalendarAction = (body) => {
    return async (dispatch) => {
        dispatch(getCalendar([]))
        await LiveOpsServices.getCalendar(body)
        .then(res=>{
            dispatch(getCalendar(res.data))
        }).catch(error => {
            if(error.response.status == 400){
                error.response.data.message && error.response.data.message.map((err,i)=>{
                    toast.error(<Toast type='Error' messages={err}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                })
            }
            if(error.response.status == 409){
                error.response.data.message && toast.error(<Toast type='Error' messages={error.response.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FF6A55'}})
            }
        })
    }
}

export const getLeadboardResultPDAction = (body) => {
    return async(dispatch) => {
        // dispatch(getLiveOpsResult([], LIVE_OPS_RESULT, null))
        await LiveOpsServices.getLiveOpsResult(body)
        .then(res => {
            dispatch(getLiveOpsResult(res.data.data.result, LIVE_OPS_RESULT_SUCCESS, res.data.data.totalCount))
        }).catch(err => {
            dispatch(getLiveOpsResult(err, LIVE_OPS_RESULT_ERROR, null))
        })
    }
}

export const getLiveOpsResult = (data, type, count) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}

export const getLiveOpsCategory = (data) => {
    return {
        type : GET_LIVE_OPS_CATEGORY,
        payload : data
    }
}

export const scheduleCompetition = (data) => {
    return {
        type : SCHEDULE_COMPETITION,
        payload : data
    }
}

export const getAllScheduleCompetition = (data, count) => {
    return {
        type : GET_SCHEDULE_COMPETITION,
        payload : data,
        totalCount : count
    }
}

export const createLiveOpsCategory = (data) => {
    return {
        type : CREATE_LIVE_OPS_CATEGORY,
        payload : data
    }
}

export const editscheduleCompetition = (data) => {
    return {
        type : EDIT_SCHEDULE_COMPETITION,
        payload : data
    }
}

export const filterScheduleCompetition = (data, type, count) => {
    return {
        type : type,
        payload : data
    }
}

export const getCalendar = (data) => {
    return {
        type : GET_CALENDAR,
        payload : data
    }
}