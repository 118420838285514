import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames'
import styles from "./dropdown.module.sass"
import Tooltip from "../../../../../../../components/Tooltip";

const Dropdown = ({className, placeholder, disabled, groupBy , error, classLabel, classDropdownHead, classDropdownLabel, value, setValue,unlockItemId, setUnlockItemId, options, label, small, upBody, tooltip, setType}) => {

    const [visible, setVisible] = useState(false);
    const [filterText, setFilterText] = useState('');

    const itemName = () => {
        const hasActiveItem = value.find(el => !el.archive);
        if (!hasActiveItem) {
            return <span className={styles.placeholder}>Select Item/Bundle</span>;
        } else {
            return hasActiveItem.name
        }
    };

    const handleClick = (x) => {
        const val=[...value]
        val.map((e)=>(e.archive=true))
        val.push({name:x.name , unlockItemId:x.id, lockByOptionType: x.lockByOptionType})
        setUnlockItemId(x.id)
        setValue(val)
        setVisible(false);
        if(setType){
            setType(x.lockByOptionType)
        }
        if(error){
            error(false)
        }
    }

    const filteredOptions = options && options.filter(option =>
        option.name.toLowerCase().includes(filterText.toLowerCase())
    )

    const inputStyle = {
        background: "#FCFCFC",
        color: "#6F767E",
        border: "none",
        height: "30px",
        fontSize: "12px",
        fontWeight: "inherit",
        width: "100%",
    }
      
    return (
        <div className={groupBy == 1 ? styles.groupDropdown : groupBy == 2 ? styles.groupByTwoDropdown : groupBy == 3 ? styles.groupByThreeDropdown : groupBy == 4 ? styles.groupByFourDropdown : 'dropdown'}>
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            {label && (
                <div className={cn(classLabel, styles.label)}>
                {label}{" "}
                {tooltip && (<Tooltip className={styles.tooltip} title={tooltip} icon="info" place="right" />)}
                </div>
            )}
            <div className={cn(styles.dropdown, className, { [styles.small]: small },{[styles.active]: visible},{[styles.disabled] : disabled})}>
                <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                    <div className={styles.selection}>
                        {/* {itemName()} */}
                        <input
                            type="text"
                            style={inputStyle}
                            placeholder={placeholder}
                            value={filterText !== '' ? filterText : itemName()}
                            onChange={(e) => { setFilterText(e.target.value); setVisible(true); }}
                            disabled={disabled}
                        />
                    </div>
                </div>
                <div className={cn(styles.body, {[styles.bodyUp]: upBody}, {[styles.disabled] : disabled})}>
                {filteredOptions && filteredOptions.map((x, index) => (
                    <div onClick={() => handleClick(x, index)} className={cn(styles.option, {[styles.selectioned]: x === value,})} key={x.id}>
                        {x.name}
                    </div>
                ))}
                </div>
            </div>
            </OutsideClickHandler>
        </div>
    )
}

export default Dropdown