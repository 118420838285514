import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./currency.module.sass";
import Form from '../../../../../components/Form';
import Icon from "../../../../../components/Icon";
import Card from "../../../../../components/Card";
import CurrencyTable from "../../../../../components/Table";
import CurrencyCardLayout from "../../../../../components/CurrencyLayout/CardLayout";
import addIcon from '../../../../../media/images/icons/light.png'
import { getAllCurrencyAction, addCurrecyAction, filterCurrencyAction } from "../../../../../redux/action/builds/economy/currency/currencyAction";
import Loader from "../../../../../components/Loaders/LoaderProgress";
import EmptyState from '../../../../../components/EmptyStates'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import { Skeleton } from '@mui/material';
import SkeletonTable from '../../../../../components/Skeleton/TableSkeleton'
import {ReactComponent as ArchiveSvg} from '../../../../../media/images/icons/archive.svg'
import {ReactComponent as Check} from '../../../../../media/images/icons/Check_Svg.svg'
import SkeletonEmptyState from "../../../../../components/Skeleton/EmptyStateSkeleton";
import GlobalStateContext from "../../../../../components/Context/GlobalStates/GlobalStateContext";
import XLSX from 'xlsx';
import Control from './Filters/Control'
import { BASE_URL, GET_CURRENCY } from "../../../../../resources/APIEndpoints";
import customAxios from "../../../../../utils/interceptor";
import ExportTooltip from '../../../../../components/CustomTooltips/ExportTooltip'
import ControlFileUpload from './Control'
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { startApiCalls } from "../../../../../sagas/actions/currency/bulkFileUploadAction";
import Navigation from '../../../../../components/Header/SecondaryNavigation'

const sorting = ["list", "grid"];

const Currency = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams()
  // Pagination
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  // Filter
  const [currencyType, setCurrencyType] = useState([])
  const filters = {currencyType, setCurrencyType}
  // Search
  const [search, setSearch] = useState(null)
  // Archive
  const [showArchive, setShowArchive] = useState(false)
  // Sort
  const sortList = [{id: 1, name: 'Currency Name', value: 'name'}, {id: 2, name: 'Created At', value: 'created_at'}, {id: 3, name: 'Updated At', value: 'updated_at'}]
  const [sortValue, setSortValue] = useState({name: 'updated_at', value: 0})
  const [selectedSort, setSelectedSort] = useState('updated_at')
  const [sort, setSort] = useState([{id: 1, name: 'Ascending'}, {id : 2, name : 'Descending'}])
  const [sortIndex, setSortIndex] = useState(0)
  // User Access
  const [userAccess, setUserAccess] = useState(false)
  const globalStates = useContext(GlobalStateContext)
  // Skeleton
  const [showSkeleton, setShowSkeleton] = useState(true)
  // Import
  const [currencyFile, setCurrencyFile] = useState(null)
  const [currencyFileErrors, setCurrencyFileErrors] = useState(null)
  const [currencyData, setCurrencyData] = useState([])

  const getUserAccess = () => {
    if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
      let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
      if(appPermission?.length > 0){
        setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
      }
    }
  }
  
  useEffect(() => {
    getUserAccess()
  }, [])

  const getCurrecy = async(search) => {
    const getCurrencyData = {
      projectId : appid,
      offset: ((page-1)*limit),
      limit: limit,
      // Search
      ...(search && search !== null && search !== '' && {search : search}),
      // Archive
      showArchived: showArchive,
      // Filter
      types: ['virtual'],
      //Sort
      sortOrder : (sortIndex == 0 ? 'asc' : 'desc'),
      sortField : selectedSort
    }
    await dispatch(filterCurrencyAction(getCurrencyData, configureSkeleton, !isFiltered))
    // let keysToCheck = ['currencyType']
    // let isFiltered = await keysToCheck.every(key => filters[key]?.length === 0)
    // if(!search && isFiltered){
    //   console.log('all')
    //   await dispatch(getAllCurrencyAction(getCurrencyData, configureSkeleton))
    // }else{
    //   console.log('filter')
    //   await dispatch(filterCurrencyAction(getCurrencyData, configureSkeleton, !isFiltered))
    // }
  }

  let currencies = useSelector((state)=>{ 
    return state.currencies.currency
  })

  let isLoading = useSelector((state) => {
    return state.currencies.isLoading
  })

  let totalCurrency = useSelector((state)=>{
    return state.currencies.totalCount
  })

  let isFiltered = useSelector((state)=>{
    return state.currencies.isFiltered
  })

  const handlePageChange = (event, value)  => {
    setPage(value)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const configureSkeleton = () => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
  }

  useEffect(()=>{
    if(selectedSort !== null){
      getCurrecy(search)
    }
  }, [page, selectedSort, showArchive])


  const onSearch = (search) => {
    if(search == ''){
      setSearch(null)
      getCurrecy(null)
    }else{
      setSearch(search)
      getCurrecy(search)
    }
  }

  const onCSVCurrency = (e) => {
    setCurrencyFile(e)
  }

  // Converting CSV to JSON and Validation

  const validateCurrencyExistance = async(rewards, data) => {
    const token = localStorage.getItem('token')
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    let res = await axios.post(`${BASE_URL}/rewards-details/validate`, rewards, headers)
    if(res.data.data){
      let content = res.data.data
      let existingData = content.currenciesDetails.existingCurrencies
      if(existingData?.length > 0){
        const updatedData = data && data.map((e) => {
          let matchObj = existingData && existingData.find(currency => currency.currencyId === e.currencyId)
          if(matchObj) {
            return {
              ...e,
              errors: {
                ...e.errors,
                idErr: [
                  ...(e.errors && e.errors.idErr ? e.errors.idErr : []), ...(matchObj.archive ? [`Currency Id with ${e.currencyId} archived and already exist.`] : [`Currency Id with ${e.currencyId} already exists.`])
                ]
              }
            };
          }else {
            return {
              ...e,
              errors: {
                ...e.errors,
                idErr: e.errors && e.errors.idErr ? [...e.errors.idErr] : [] 
              }
            };
          }
        })
        return updatedData
      }else{
        return data
      }
    }
  }

  const checkCurrencyId = async(data) => {
    const rewards = {
      projectId : appid,
      rewardDetails : {
        items : {ids:[]},
        currencies : {ids:[]},
        bundles : {ids:[]},
        progressionMarkers : {ids:[]},
        progressionSystem: { ids: [] },
        stores : {ids : []}
      }
    }

    data && data.forEach((e, index) => {
      if(e.currencyId !== null && !rewards.rewardDetails.currencies.ids.includes(e.currencyId)){
        rewards.rewardDetails.currencies.ids.push((e.currencyId).toString());
      }
    })
    let validateCurrencyData = await validateCurrencyExistance(rewards, data)
    return validateCurrencyData

  }

  const checkErrors = (header) => {
    let newErrors = []
    let isError = true;
    if (header[0] !== 'Currency Icon') {
      newErrors.push(`Please change 1st Column name from ${header[0]} to Currency Icon.`)
      isError = false
    }
    if (header[1] !== 'Display Name') {
      newErrors.push(`Please change 2nd Column name from ${header[1]} to Display Name.`)
      isError = false
    }
    if (header[2] !== 'Currency ID') {
      newErrors.push(`Please change 3rd Column name from ${header[2]} to Currency ID.`)
      isError = false
    }
    if (header[3] !== 'Currency Code') {
      newErrors.push(`Please change 4th Column name from ${header[3]} to Currency Code.`)
      isError = false
    }
    if (header[4] !== 'Currency Type') {
      newErrors.push(`Please change 5th Column name from ${header[4]} to Currency Type.`)
      isError = false
    }
    if (header[5] !== 'Currency Description') {
      newErrors.push(`Please change 5th Column name from ${header[5]} to Currency Description.`)
      isError = false
    }
    if (header[6] !== 'Tags') {
      newErrors.push(`Please change 17th Column name from ${header[6]} to Tags.`)
      isError = false
    }
    if (header[7] !== 'Meta Data') {
      newErrors.push(`Please change 18th Column name from ${header[7]} to Meta Data.`)
      isError = false
    }
    setCurrencyFileErrors(newErrors);
    if(isError){
      return true
    }else{
      return false
    }
  }

  const checkCurrecyFileDataType = (obj) => {
    let errors = {iconErr: [], nameErr: [], idErr: [], descriptionErr: [], typeErr: [], codeErr: []}
    if(obj.iconUrl !== null && typeof obj.iconUrl !== 'string'){
      errors.iconErr.push(`Currency Icon should be a valid string.`);
    }
    if(typeof obj.name !== 'string'){
      errors.nameErr.push(`Display Name should be a valid string.`);
    }
    if(typeof obj.currencyId !== 'string'){
      errors.idErr.push(`Currency ID should be a valid string.`);
    }
    if(obj.description !== null &&  typeof obj.description !== 'string'){
      errors.descriptionErr.push(`Currency Description should be a valid string.`);
    }
    if(obj.type !== null &&  typeof obj.type !== 'string'){
      errors.typeErr.push(`Currency Type should be a valid string.`);
    }
    if(obj.type !== null && typeof obj.type === 'string' && obj.type !== "virtual" && obj.type !== "real"){
      errors.typeErr.push(`Currency Type should be either Vitual Or Real Money Gaming Currency.`);
    }
    if(obj.code !== null &&  typeof obj.code !== 'string'){
      errors.codeErr.push(`Currency Code should be a valid string.`);
    }
    return errors
  }

  const checkDataType = (obj) => {
    return obj && obj.map((e, i) => ({
      ...e,
      errors: checkCurrecyFileDataType(e)
    }))
  }

  const transformCurrencyData = (currencies) => {
    return currencies && currencies.map((entry, index) => ({
      projectId : appid,
      iconUrl : ((entry["Currency Icon"] !== null && entry["Currency Icon"] !== '' && entry["Currency Icon"] !== 'N/A') ? entry["Currency Icon"] : null),
      name : ((entry["Display Name"] !== null && entry["Display Name"] !== '' && entry["Display Name"] !== 'N/A') ? entry["Display Name"] : null),
      currencyId : ((entry["Currency ID"] !== null && entry["Currency ID"] !== '' && entry["Currency ID"] !== 'N/A') ? entry["Currency ID"] : null),
      description : ((entry["Currency Description"] !== null && entry["Currency Description"] !== '' && entry["Currency Description"] !== 'N/A') ? entry["Currency Description"] : null),
      code: ((entry["Currency Code"] !== null && entry["Currency Code"] !== '' && entry["Currency Code"] !== 'N/A') ? entry["Currency Code"] : null),
      type: ((entry["Currency Type"] !== null && entry["Currency Type"] !== '' && entry["Currency Type"] !== 'N/A') ? (entry["Currency Type"] === 'Virtual' ? 'virtual' : entry["Currency Type"] === 'Real Money Gaming Currency' ? 'real' : entry["Currency Type"]) : null),
      meta : ((entry["Meta Data"] && entry["Meta Data"] !== null && entry["Meta Data"] !== '' && entry["Meta Data"] !== 'N/A') ? JSON.parse(entry["Meta Data"]) : null),
      tags : ((entry["Tags"] && entry["Tags"] !== null && entry["Tags"] !== 'N/A'  && entry["Tags"] !== '') ? entry["Tags"].split(", ") : null),
    }))
  }

  const convertCurrencyToJson = async(data, headers) => {
    if(data.length > 1000){
      setCurrencyFileErrors([`Total number of rows should be less than 1000.`])
    }else{
      const dataRows = data && data.map(row => {
        // Create an object with the headers and corresponding row values
        const rowData = {};
        headers.forEach((header, index) => {
          rowData[header] = row[header] !== undefined ? row[header] : null; // Handle blank cells
        });
        return rowData;
      })
      return transformCurrencyData(dataRows)
    }
  }

  const validateCurrency = () => {
    return new Promise((resolve, reject) => {
      if(currencyFile && (currencyFile.size/1024).toFixed(2) < 10000){
        const fileExtension = currencyFile.name.split('.').pop().toLowerCase();
        const reader = new FileReader();
        reader.onload = async(e) => {
          if(fileExtension === 'csv'){
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'string', raw: true });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1, defval: ''});
            const headers = columnsArray[0];
            if(checkErrors(headers)){
              const json = XLSX.utils.sheet_to_json(worksheet);
              let currencyJSON = await convertCurrencyToJson(json, headers)
              let currencyInfo = await checkDataType(currencyJSON)
              let checkCurrency = await checkCurrencyId(currencyInfo)
              if(checkCurrency){
                setCurrencyData(checkCurrency)
                resolve(true)
              }else{
                resolve(false)
              }
            }
          }else{
            const arrayBuffer = e.target.result;
            const data = new Uint8Array(arrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1, defval: ''});
            const headers = columnsArray[0];
            if(checkErrors(headers)){
              const json = XLSX.utils.sheet_to_json(worksheet);
              let currencyJSON = await convertCurrencyToJson(json, headers)
              let currencyInfo = await checkDataType(currencyJSON)
              let checkCurrency = await checkCurrencyId(currencyInfo)
              if(checkCurrency){
                setCurrencyData(checkCurrency)
                resolve(true)
              }else{
                resolve(false)
              }
            }
          }
        };  
        if(fileExtension === 'csv'){
          reader.readAsText(currencyFile);
        }else{
          reader.readAsArrayBuffer(currencyFile);
        }
      }else{
        setCurrencyFileErrors([`File Size Should be less than 10Mb`])
        resolve(false)
      }
    })
  }

  // Re-Validate

  const revalidate = async(data) => {
    let currencyInfo = await checkDataType(data)
    let checkCurrency = await checkCurrencyId(currencyInfo)
    if(checkCurrency){
      setCurrencyData(checkCurrency)
    }
  }

  // Export

  const [exportOptions, setExportOptions] = useState([{id:1, name: 'Import'}, {id: 2, name: 'Export', options: [{id: 1, name: 'As XLSX'}, {id: 2, name: 'As CSV'}]}])

  const transformCurrencyKeys = (data) => {
    return data && data.map(item => ({
      "Currency Icon": ((item.iconUrl !== null && item.iconUrl !== '') ? item.iconUrl : 'N/A'),
      "Display Name": item.name,
      "Currency ID": item.currencyId,
      "Currency Code": ((item.code !== null && item.code !== '') ? item.code : 'N/A'),
      "Currency Type": ((item.type !== null && item.type !== '' && item.type === 'virtual') ? 'Virtual' : (item.type !== null && item.type !== '' && item.type === 'real') ? 'Real Money Gaming Currency' : 'N/A'),
      "Currency Description": ((item.description !== null && item.description !== '') ? item.description : 'N/A'),
      "Tags": ((item.tagsMapping !== null && item.tagsMapping?.length > 0) ? item.tagsMapping && item.tagsMapping.map(mapping => mapping?.tags?.name).join(', ') : 'N/A'),
      "Meta Data": JSON.stringify(item.meta),
      // Add more transformations if needed
    }));
  }

  const getCurrencyDetails = async() => {
    let exportedData = []
    let ids;
    let count = Math.ceil(totalCurrency/100)
    for(var i = 0; i < count ; i++){
      let items = {
        projectId : appid,
        offset : (i === 0 ? 0 : (i*100)),
        limit : 100
      }
      const token = localStorage.getItem('token')
      const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
      let res = await customAxios.post(BASE_URL + GET_CURRENCY, items, headers)
      if(res){
        let itemInstance = res.data.data.currenciesDetails
        ids = itemInstance && itemInstance.map(e => e.id)
        exportedData.push(...itemInstance)
      }
    }
    if(exportedData){
      return {data : exportedData, ids: ids}
    }
  }
  
  const exportData = async(type) => {
    let currenciesData = await getCurrencyDetails()
    let convertedCurrencies = await transformCurrencyKeys(currenciesData.data)
    // Items
    const worksheetItems = XLSX.utils.json_to_sheet(convertedCurrencies);
    const workbookItems = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbookItems, worksheetItems, "Sheet1");
    if(type === 'As XLSX'){
      XLSX.writeFile(workbookItems, "Currencies.xlsx");
    }else{
      XLSX.writeFile(workbookItems, "Currencies.csv");
    }
  }

  // Upload

  const uploadFunctions = {onCSVCurrency: onCSVCurrency}
  const files = {itemFile: currencyFile, setItemFile: setCurrencyFile}
  const fileErrors = {itemFileError: currencyFileErrors, setItemFileErrors: setCurrencyFileErrors}
  
  const [bulkUpload, setBulkUpload] = useState(false)
  
  const removeErrorKeyObject = (data) => {
    let updatedData = data && data.map(item => {
      const { errors, ...rest } = item;
      return item
    })
    return updatedData
  }
  
  const currencyBulkUpload = () => {
    let currency = currencyData.slice(0, 1)
    let filterCurrency = removeErrorKeyObject(currency)
    dispatch(addCurrecyAction(filterCurrency[0], null, appid, null))
    .then(res => {
      // Changes
      if(concluded < 10){
        setConcluded(concluded => concluded + 1)
      }
      setCurrencyData(currencyData.slice(1))
    }).catch(err => {
      console.log(err)
    })
  }
  
  const clearAllData = () => {
    setCurrencyFile(null)
    setCurrencyFileErrors(null)
  }

  // Import Progress

  const {loading, completedCalls, failedCalls, totalCalls} = useSelector((state) => state.economyCurrency)

  const [progress, setProgress] = useState(false)
  const [total, setTotal] = useState(0)
  const [concluded, setConcluded] = useState(0)

  const radius = 150; // Radius of the circle
  const stroke = 20; // Stroke width
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const progressOffset = circumference - (completedCalls / totalCalls) * circumference;
  const [action, setActions] = useState([{id: 1, name: 'Upload File', status: 'completed'}, {id: 2, name: 'Validate File', status: 'completed'}, {id: 3, name: 'Repair', status: 'completed'}, {id: 4, name: 'Import', status: 'completed'}])

  const uploadBulkCurrency = () => {
    // Dispatch the action to start API calls
    let currency = currencyData
    let filterCurrency = removeErrorKeyObject(currency)
    dispatch(startApiCalls(filterCurrency, getCurrecy))
  };

  useEffect(() => {
    if(bulkUpload){
      uploadBulkCurrency()
    }
  }, [bulkUpload])


  // Loader
  if(isLoading){
    return(
      <>
        <div className={styles.loader}>
          <Loader />
        </div>
      </>
    )
  }

  // Bulk Upload Loader
  if(loading){
    return(
      <>
        <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} title={showSkeleton ? <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={110} height={32}/> : 'Currencies'} classTitle={cn("title-purple", styles.title)}>
          <div className={styles.wrapper}>
          <div className={styles.progressContainer}>
            <div className={styles.progress}>
              {action && action.map((e, i) => (
                <>
                  <div className={styles.progressContent}>
                    {e.status === 'remaining' && <div className={styles.progressStatus}></div>}
                    {e.status === 'completed' && <Check />}
                    <div className={styles.content}>{e.name}</div>
                    <div className={styles.icon}><Icon name='arrow-next' size='16' /></div>
                  </div>
                </>
              ))}
            </div>
            <div className={styles.svgContainer}>
              <div className={styles.progressEvents}>
                <div className={styles.title}>Currency Uploaded</div>
                <div className={styles.events}>
                  <div className={styles.currencies}></div>
                  <div className={styles.eventTitle}>{completedCalls}/{totalCalls}</div>
                </div>
              </div>
              <svg height={radius * 2} width={radius * 2} className={styles.circleSvg}>
                  <circle
                      stroke="#EFEFEF"
                      fill="transparent"
                      strokeWidth={stroke}
                      r={normalizedRadius}
                      cx={radius}
                      cy={radius}
                  />
                  <circle
                      stroke="#2A85FF"
                      fill="transparent"
                      strokeWidth={stroke}
                      strokeDasharray={circumference + ' ' + circumference}
                      style={{borderRadius: '20px', strokeDashoffset: progressOffset, transition: 'stroke-dashoffset 1s ease-in-out' }}
                      r={normalizedRadius}
                      cx={radius}
                      cy={radius}
                      strokeLinecap="round"
                  />
              </svg>
            </div>
          </div>
          </div>
        </Card>
      </>
    )
  }
  return(
      <>
        <ToastContainer />
        <Navigation type='Currency' filterAction={getCurrecy} filters={filters} exportAction={exportData} exportOption={exportOptions} search={search} setSearch={onSearch} archive={showArchive} setArchive={setShowArchive} data={sortList} setPage={setPage} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selectedSort} setSelected={setSelectedSort} sort={sort} setSort={setSort} value={sortValue} setValue={setSortValue} />
        <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} >
      
              <div className={styles.wrapper}>
                {/* Currency Table View */}
                {showSkeleton && currencies?.length === 0 && 
                  <SkeletonEmptyState />
                }
                {showSkeleton && currencies?.length > 0 && 
                  <SkeletonTable rows={currencies.length} column={6} controls={2} type={['recatangle', 'recatangle', 'recatangle', 'reactangle', 'reactangle', 'circular']} colWidth={[36, 110, 110, 110, 110, 36]} colHeight={[36, 15, 25, 25, 25, 36]} />
                }
                {!showSkeleton && (isFiltered || !isFiltered) && currencies?.length === 0 &&
                  <EmptyState access={userAccess} route={`/create-currency/${appid}`} type="Currency" exportOption={<><ControlFileUpload size='large' type='Currency' data={currencyData} setData={setCurrencyData} revalidate={revalidate} files={files} fileErrors={fileErrors} onFileChange={uploadFunctions} onValidation={validateCurrency} setBulkUpload={setBulkUpload} setTotal={setTotal} /></>} />
                }
                {!showSkeleton && !isFiltered && currencies?.length > 0 &&
                  <CurrencyTable access={userAccess} items={currencies} title="Last edited" type='virtual currency' />
                }
                {!showSkeleton && (isFiltered || !isFiltered) &&
                  (currencies?.length > 0 ?
                    <CurrencyTable access={userAccess} items={currencies} title="Last edited" type='virtual currency' />
                  :
                    <div className={styles.message}>No Result Found</div>
                  )
                }
              </div>

              {!isLoading && currencies.length > 0 &&
                <div className={styles.pagination}>
                  <Stack spacing={2}>
                    <Pagination page={page} renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        sx={{
                          '&.MuiPaginationItem-root': {
                            minWidth: '28px',
                            height: '28px',
                            fontSize: '12px'
                          },
                          '&.Mui-selected': {
                            color: 'rgb(255, 255, 255)',
                            fontWeight : 'bold',
                            border : '1px solid rgb(42, 133, 255)',
                            background : 'rgba(42, 133, 255)'
                          },
                          '&.MuiPaginationItem-page.Mui-selected:hover' : {
                            color: 'rgb(255, 255, 255)',
                            fontWeight : 'bold',
                            border : '1px solid rgb(42, 133, 255)',
                            background : 'rgba(42, 133, 255)'
                          }
                        }}
                      />
                    )} siblingCount={1} count={Math.ceil(totalCurrency/limit)} color="primary" onChange={handlePageChange} />
                  </Stack>
                </div>
              }
              
          </Card>
          <div className="divider"></div>
          <div className={styles.msg}>
            <p className={styles.alert}>Learn more about currencies <a className={styles.infoLink} href="https://manual.specterapp.xyz/specter-user-manual/build/economy/currencies" target="_blank">Click Here</a></p>
          </div>
      </>
  )

};

export default Currency;