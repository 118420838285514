import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileAction } from "../../../redux/action/organisation/ProfileAction/profileAction";
import GlobalStateContext from "../../Context/GlobalStates/GlobalStateContext";
import axios from "axios";
import { extarctImageFromUrlAction } from "../../../redux/action/common/commonAction";

const items = [
    {
        menu: [
            {
                title: "Profile",
                url: "/settings-member-profile",
            },
            {
                title: "Logout",
            },
        ]
    }
];

const help = [
    {
        menu: [
            // {
            //     title: "Explore Demo",
            //     url: "/home",
            // },
            {
                title: "Quick Guide",
            },
            {
                title: "Specter Manual",
                url: "https://dirtcube-interactive.gitbook.io/specter-user-manual/getting-started/introducing-specter",
            },
            {
                title: "API Documentation",
                url: "https://doc.specterapp.xyz/",
            },
            {
                title: "Blogs",
                url: "https://medium.com/specter-chronicles",
            },
            {
                title: "Specter Privacy Policy",
                url: "https://www.specterapp.xyz/privacyPolicy",
            },
            {
                title: "Specter Terms of Service",
                url: "https://www.specterapp.xyz/termsOfService",
            },
        ]
    },
    {
        menu: [
            {
                title: "Contact Us",
                url: "mailto:hello@specterapp.xyz",
            },
            {
                title: "Join Discord Community",
                url: "https://discord.gg/BZSF94yg2K",
            },
            {
                title: "Submit Feedback",
                url: "https://specter.canny.io/feedback",
            },

        ],
    }
]

const User = ({ className }) => {

    const [visible, setVisible] = useState(false);
    const [visibleHelp, setVisibleHelp] = useState(false);

    const { pathname } = useLocation();
    const dispatch = useDispatch()

    const [user, setUser] = useState(null)
    const [isProfile, setIsProfile] = useState(null)

    const getUserProfile = () => {
        dispatch(getUserProfileAction({}))
    }

    let UserProfile = useSelector((state) => {
        return state.organisation.userProfile
    })

    const getUser = () => {
        let users = localStorage.getItem('userName')
        setUser(JSON.parse(users).split(" ").map(word => word.charAt(0).toUpperCase()).join(''))
    }

    const getUserProfileImage = async() => {
        if(UserProfile.profile_url == null || UserProfile.profile_url == ''){
            getUser()
            setIsProfile(false)
        }else{
            const url = await extractImageFromCdn(UserProfile.profile_url)
            setUser(url)
            setIsProfile(true)
        } 
    }

    useEffect(() => {
        getUserProfileImage()
    }, [UserProfile])

    useEffect(() => {
        getUserProfile()
    }, [])

    const logout = () => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload()
    }

    const showHelp = (title) => {
        setVisibleHelp(false)
        if(title == 'Quick Guide'){
            globalStates.setShowQuickGuideModal(true)
        }
    }

    const globalStates = useContext(GlobalStateContext)

    const extractImageFromCdn = async(url) => {
        if(url !== null && url !== undefined && url.includes('cdn')){
          try {
            const response = await dispatch(extarctImageFromUrlAction(url))
            return response
          }catch (error){
            console.error('Error fetching image:', error);
          }
        }
    }

    return (
        <>
        <OutsideClickHandler onOutsideClick={() => setVisibleHelp(false)}>
            <div className={cn(styles.user, className, {[styles.active]: visibleHelp})}>
                <button className={styles.headHelp} onClick={() => setVisibleHelp(!visibleHelp)}>
                    ?
                </button>
                <div className={cn(styles.body, {[styles.wide] : true})}>
                    {help.map((item, index) => (
                        <div className={styles.menu} key={index}>
                            {item.menu.map((x, index) =>
                                (
                                    <NavLink target={x.title == 'Quick Guide' ? '' : '"_blank"'} className={cn(styles.item)}
                                        to={x.url} onClick={()=>{showHelp(x.title)}} key={index}>
                                        {x.icon && (
                                            <Icon name={x.icon} size="24" />
                                        )}
                                        {x.title}
                                    </NavLink>
                                ) 
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </OutsideClickHandler>
        <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div className={cn(styles.user, className, {[styles.active]: visible})}>
                {isProfile && 
                    <button className={styles.head} onClick={() => setVisible(!visible)}>
                        <img src={user} alt='user-profile' width={48} height={48} />
                    </button>
                }
                {!isProfile &&
                    <button className={styles.head} onClick={() => setVisible(!visible)}>
                        <div>{user}</div>
                    </button>
                }
                <div className={styles.body}>
                    {items.map((item, index) => (
                        <div className={styles.menu} key={index}>
                            {item.menu.map((x, index) =>
                                x.url ? (
                                    <NavLink className={cn(styles.item, {[styles.color]: x.color,[styles.active]: pathname === x.url})}
                                        to={x.url} onClick={() => setVisible(false)} key={index}>
                                        {x.icon && (
                                            <Icon name={x.icon} size="24" />
                                        )}
                                        {x.title}
                                    </NavLink>
                                ) : (
                                    <button
                                        className={styles.item}
                                        onClick={logout}
                                        key={index}
                                    >
                                        {x.title}
                                    </button>
                                )
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </OutsideClickHandler>
        </>
    );
};

export default User;
