import React, { useState } from 'react'
import styles from './tooltip.module.sass'
import cn from 'classnames'

const ProgressTooltip = ({className, type, text, data, isLast, children }) => {
    
    const [showTooltip, setShowTooltip] = useState(true)
    
    return (
        <div className={cn(styles.custom, className, {[styles.active] : showTooltip})}>
            {showTooltip && 
                <div className={styles.tooltipContainer}>
                    {children}
                </div>
            }
        </div>
    )
}

export default ProgressTooltip