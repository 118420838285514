import logo from './logo.svg';
import './styles/app.sass'
import {BrowserRouter as Router, Routes, Route, useParams } from "react-router-dom";
import {getLoggedInUser} from './redux/action/authenticate/authenticationAction'
import PrivateRoute from './reusable/PrivateRoute';
import { Provider, useSelector } from 'react-redux';
import React, { useEffect, Suspense, useState, useContext } from 'react';
import { routes } from './routes/routes';

import Overview from './screens/Overview'
import Sidebar from './components/Sidebar';
import Test from './components/Organisation/Sidebar'
import Page from './components/Page';
import { store, persistor } from './redux/store/store';

import Currency from './screens/Builds/Economy/Currency/GetCurrency/currency'
import ViewCurrency from './screens/Builds/Economy/Currency/ViewCurrency';
import CreateCurrency from './screens/Builds/Economy/Currency/CreateCurrency';
import EditCurrency from './screens/Builds/Economy/Currency/EditCurrency';

import Items from './screens/Builds/Economy/Items/GetItems'
import ViewItem from './screens/Builds/Economy/Items/ViewItems'
import CreateItems from './screens/Builds/Economy/Items/CreateItems';
import EditItems from './screens/Builds/Economy/Items/EditItems'

import Bundles from './screens/Builds/Economy/Bundles/GetBundles'
import CreateBundle from './screens/Builds/Economy/Bundles/CreateBundles';
import EditBundle from './screens/Builds/Economy/Bundles/EditBundles'

import SignUp from './screens/Authentication/Register/Email';
import UserDetail from './screens/Authentication/Register/UserDetail'
import Login from './screens/Authentication/SignIn'
import EmailValidate from './screens/Authentication/SignUp/EmailValidation'

import Apps from './screens/Organization/Apps';
import PageLayout from './components/Organisation/Page';
import AppInformation from './screens/AppInformation';
import AppSettingsInfo from './screens/AppSettingsInfo';

import GetProgressionMarker from './screens/Builds/Progression/ProgressionMarker/GetProgressionMarker';
import ViewProgressionMarker from './screens/Builds/Progression/ProgressionMarker/ViewProgressionMarker';
import GetProgressionSystem from './screens/Builds/Progression/ProgressionSystem/GetProgressionSystem';
import ViewProgressionSystem from './screens/Builds/Progression/ProgressionSystem/ViewProgressionSystem';
import CreateProgressionMarker from './screens/Builds/Progression/ProgressionMarker/CreateProgressionMarker';
import CreateProgressionSystem from './screens/Builds/Progression/ProgressionSystem/CreateProgressionSystem'
import EditProgressionMarker from './screens/Builds/Progression/ProgressionMarker/EditProgressionMarker';
import { ForgotPassword } from './screens/Authentication/ForgotPassword';
import EditProgressionSystem from './screens/Builds/Progression/ProgressionSystem/EditPrgoressionSystem';
import GetDefaultEvents from './screens/Builds/Events/GetEvent/GetDefaultEvents';
import GetCustomEvents from './screens/Builds/Events/GetEvent/GetCustomEvents';
import CreateEvents from './screens/Builds/Events/CreateEvent';
import ViewCustomEvent from './screens/Builds/Events/ViewEvent/ViewCustomEvent'
import ViewDefaultEvent from './screens/Builds/Events/ViewEvent/ViewDefaultEvent'
import EditCustomeEvents from './screens/Builds/Events/EditEvent/EditCustomEvents'
import EditDefaultEvents from './screens/Builds/Events/EditEvent/EditDefaultEvents'
import Task from './screens/Engage/Achievement/Tasks/GetTask';
import CreateTask from './screens/Engage/Achievement/Tasks/CreateTask';
import ViewTask from './screens/Engage/Achievement/Tasks/ViewTask';
import EditTask from './screens/Engage/Achievement/Tasks/EditTask';
import StepSeries from './screens/Engage/Achievement/StepSeries/GetStepSeries';
import ViewStepSeries from './screens/Engage/Achievement/StepSeries/ViewStepSeries';
import CreateStepSeries from './screens/Engage/Achievement/StepSeries/CreateStepSeries';
import EditStepSeries from './screens/Engage/Achievement/StepSeries/EditStepSeries';
import GetMission from './screens/Engage/Achievement/Missions/GetMission';
import ViewMission from './screens/Engage/Achievement/Missions/ViewMission';
import CreateMission from './screens/Engage/Achievement/Missions/CreateMission';
import EditMission from './screens/Engage/Achievement/Missions/EditMission';
import GetPlayers from './screens/Engage/Players/GetPlayers';
import PlayerDetail from './screens/Engage/Players/PlayerDetails';

import Games from './screens/Builds/Games/GetGames'
import ViewGame from './screens/Builds/Games/ViewGames'
import CreateGame from './screens/Builds/Games/CreateGames';
import EditGame from './screens/Builds/Games/EditGames';

import Matches from './screens/Builds/Matches/GetMatches'
import ViewMatch from './screens/Builds/Matches/ViewMatches'
import CreateMatch from './screens/Builds/Matches/CreateMatches'
import EditMatch from './screens/Builds/Matches/EditMatches'
import CreateStore from './screens/Builds/Economy/Store/CreateStore';
import ViewStore from './screens/Builds/Economy/Store/ViewStore';
import Stores from './screens/Builds/Economy/Store/GetStores/index';
import EditStores from './screens/Builds/Economy/Store/EditStores';

import Leaderboard from './screens/Engage/Leaderboard/GetLeaderboard'
import ViewLeaderboard from './screens/Engage/Leaderboard/ViewLeaderboard'
import LeaderboardResult from './screens/Engage/Leaderboard/LeaderboardResult'
import CreateLeaderboard from './screens/Engage/Leaderboard/CreateLeaderboard'
import EditLeaderboard from './screens/Engage/Leaderboard/EditLeaderboard'
import GetCommunity from './screens/Community/GetCommunity';

import GetCompetitionTournament from './screens/Engage/Competitions/GetCompetition/GetCompetitionTournament';
import GetCompetitionInstantBattle from './screens/Engage/Competitions/GetCompetition/GetCompetitionInstantBattle';
import ViewCompetitionTournament from './screens/Engage/Competitions/ViewCompetition/ViewTournament';
import ViewCompetitionInstantBattle from './screens/Engage/Competitions/ViewCompetition/ViewInstantBattle';
import CreateCompetitionTournament from './screens/Engage/Competitions/CreateCompetition/Tournament';
import CreateCompetitionInstantBattle from './screens/Engage/Competitions/CreateCompetition/InstantBattle';
import EditCompetitionTournament from './screens/Engage/Competitions/EditCompetition/Tournament';
import EditCompetitionInstantBattle from './screens/Engage/Competitions/EditCompetition/InstantBattle';
import GetCompetitionSchedule from './screens/Engage/CompetitionSchedule/GetCompetitionSchedule'
import CreateCompetitionSchedule from './screens/Engage/CompetitionSchedule/CreateCompetitionSchedule'
import ViewCompetitionSchedule from './screens/Engage/CompetitionSchedule/ViewCompetitionSchedule'
import EditCompetitionSchedule from './screens/Engage/CompetitionSchedule/EditCompetitionSchedule'
import InstantBattleResult from './screens/Engage/Competitions/CompetitionResult/IBResult'
import TournamentResult from './screens/Engage/Competitions/CompetitionResult/TournamentResult'

import AcceptedMembers from './screens/AppSettings/Members/GetMembers';
import InvitedMembers from './screens/AppSettings/Members/GetInvitedMembers';
import MemberInvite from './screens/Authentication/MemberInviteSetPassword'
import GetAPI from './screens/AppSettings/API/GetAPI'

import OrgAcceptedMembers from './screens/Organization/Settings/Members'
import OrgInvitedMembers from './screens/Organization/Settings/Members/InvitedMembers'
import OrgApps from './screens/Organization/Settings/Apps'
import OrgProfile from './screens/Organization/Settings/Profile/Organisation'
import Media from './screens/AppSettings/Media';
import GetDocuments from './screens/AppSettings/Documents/GetDocument';
import CreateDocuments from './screens/AppSettings/Documents/CreateDocument';
import MediaFolders from './screens/AppSettings/MediaFolders';
import UserProfile from './screens/Organization/Settings/Profile/User';
import ResetPassword from './screens/Authentication/ResetPassword';
import GlobalStates from './components/Context/GlobalStates/GlobalStates';
import Modal from './components/Modals/ModalComponent';
import IntroductionModal from './components/Modals/IntroductionModal';
import GlobalStateContext from './components/Context/GlobalStates/GlobalStateContext';
import Calendar from './screens/Engage/LiveOps/Calendar';
import ViewBundle from './screens/Builds/Economy/Bundles/ViewBundle';
import Terminal from './screens/Terminal'
import EditDocument from './screens/AppSettings/Documents/EditDocument';
import BillingOverview from './screens/AppSettings/Plans&Billing/BillingOverview';
import Plans from './screens/AppSettings/Plans&Billing/Billings';
import AddOns from './screens/AppSettings/Plans&Billing/AddOns';
import BillingDetails from './screens/AppSettings/Plans&Billing/BillingDetails';
import ActivityFeed from "./screens/ActivityFeed";
import NotificationFeed from './screens/NotificationFeed';
import PageNotFound from './screens/PageNotFound';
import BillingHistory from './screens/AppSettings/Plans&Billing/Invoices';
import BillingNotification from './screens/AppSettings/Plans&Billing/BillingNotifications';
import BillingSettings from './screens/AppSettings/Plans&Billing/BillingSettings';
import UsageAnalytics from './screens/Organization/UsageAnalytics'
import AcceptInvite from './screens/Authentication/AcceptInvite';
import AddOn from './screens/AppSettings/Plans&Billing/BillingOverview/Upgrade/AddOns';
import TopUp from './screens/AppSettings/Plans&Billing/BillingOverview/Upgrade/TopUps';

import RMG from './screens/Builds/Economy/RMG/RMG/GetRMG';
import CreateRMG from './screens/Builds/Economy/RMG/RMG/CreateRMG';
import EditRMG from './screens/Builds/Economy/RMG/RMG/EditRMG';
import SpecterBank from './screens/Builds/Economy/RMG/SpecterBank/GetSpecterBanks';
import Payout from './screens/Builds/Economy/RMG/Payout/GetPayout';
import Forbidden from './components/ForbiddenPage'
import RMGTransactions from './screens/Builds/Economy/RMG/SpecterBank/RMGTransaction';

function App() {

  const {appid} = useParams()

  const token = localStorage.getItem('token')
  const user = JSON.parse(localStorage.getItem('user'))
  
  if(token){
    store.dispatch(getLoggedInUser(user))
  }

  const [showLoader, setShowLoader] = useState(false)
  const [loaderType, setLoaderType] = useState(null)
  const globalStates = useContext(GlobalStateContext)

  return (
    <>
      <GlobalStates>
        <div className='App'>
          <Router>
            <Routes>
              <Route path='/'>
                  <Route path={routes.AppOverview.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'  title='App Overview' background={true}><Overview /></Page></PrivateRoute>} />
                  <Route path={routes.AppSettingsInfo.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='AppSettings' title='Global App Information' background={true}><AppSettingsInfo /></Page></PrivateRoute>} />
                  <Route path={routes.AppInfo.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title={localStorage.getItem('environment')+' App Info'} background={true}><AppInformation /></Page></PrivateRoute>} />
                  <Route path={routes.Games.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' view={true} background={true}><Games /></Page></PrivateRoute>} />
                  <Route path={routes.ViewGame.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='View Game' background={true}><ViewGame /></Page></PrivateRoute>} />
                  <Route path={routes.CreateGame.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Create Game' background={true}><CreateGame /></Page></PrivateRoute>} />
                  <Route path={routes.EditGames.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Edit Game' background={true}><EditGame /></Page></PrivateRoute>} />
                  <Route path={routes.Matches.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'  view={true} background={true}><Matches /></Page></PrivateRoute>} />
                  <Route path={routes.ViewMatch.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='View Match' background={true}><ViewMatch /></Page></PrivateRoute>} />
                  <Route path={routes.CreateMatch.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Create Match' background={true}><CreateMatch /></Page></PrivateRoute>} />
                  <Route path={routes.EditMatch.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Edit Match' background={true}><EditMatch /></Page></PrivateRoute>} />
                  <Route path={routes.Currency.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' view={true} background={true}><Currency /></Page></PrivateRoute>} />
                  <Route path={routes.ViewCurrency.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Currency" background={true}><ViewCurrency /></Page></PrivateRoute>} />                  
                  <Route path={routes.CreateCurrency.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create Currency" background={true}><CreateCurrency /></Page></PrivateRoute>} />
                  <Route path={routes.EditCurrency.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Currency" background={true}><EditCurrency /></Page></PrivateRoute>} />
                  <Route path={routes.Items.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'  view={true} background={true}><Items /></Page></PrivateRoute>} />
                  <Route path={routes.ViewItem.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Item" background={true}><ViewItem /></Page></PrivateRoute>} /> 
                  <Route path={routes.CreateItems.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create Item" background={true}><CreateItems /></Page></PrivateRoute>} />
                  <Route path={routes.EditItems.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Item" background={true}><EditItems /></Page></PrivateRoute>} />
                  <Route path={routes.Bundles.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'  view={true} background={true}><Bundles /></Page></PrivateRoute>} />
                  <Route path={routes.ViewBundle.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Bundle" background={true}><ViewBundle /></Page></PrivateRoute>} />    
                  <Route path={routes.CreateBundles.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create Bundle" background={true}><CreateBundle /></Page></PrivateRoute>} />
                  <Route path={routes.EditBundles.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Bundle" background={true}><EditBundle /></Page></PrivateRoute>} />
                  <Route path={routes.CreateStore.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create Store" background={true}><CreateStore /></Page></PrivateRoute>} />
                  <Route path={routes.Stores.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'  view={true} background={true}><Stores /></Page></PrivateRoute>} />
                  <Route path={routes.ViewStore.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Stores" background={true}><ViewStore /></Page></PrivateRoute>} />
                  <Route path={routes.EditStores.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Stores" background={true}><EditStores /></Page></PrivateRoute>} />
                  <Route path={routes.GetProgressionMarker.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'  view={true} background={true}><GetProgressionMarker /></Page></PrivateRoute>} />
                  <Route path={routes.ViewProgressionMarker.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Progression Marker" background={true}><ViewProgressionMarker /></Page></PrivateRoute>} />
                  <Route path={routes.CreateProgressionMarker.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create Progression Marker" background={true}><CreateProgressionMarker /></Page></PrivateRoute>} />
                  <Route path={routes.EditProgressionMarker.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Progression Marker" background={true}><EditProgressionMarker /></Page></PrivateRoute>} />
                  <Route path={routes.GetProgressionSystem.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'  view={true} background={true}><GetProgressionSystem /></Page></PrivateRoute>} />
                  <Route path={routes.ViewProgressionSystem.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Progression System" background={true}><ViewProgressionSystem /></Page></PrivateRoute>} />
                  <Route path={routes.CreateProgressionSystem.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create Progression System" background={true}><CreateProgressionSystem /></Page></PrivateRoute>} />
                  <Route path={routes.EditProgressionSystem.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Progression System" background={true}><EditProgressionSystem /></Page></PrivateRoute>} />
                  <Route path={routes.DefaultEvent.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'  view={true} background={true}><GetDefaultEvents /></Page></PrivateRoute>} />
                  <Route path={routes.CustomEvent.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'  view={true} background={true}><GetCustomEvents /></Page></PrivateRoute>} />
                  <Route path={routes.CreateEvents.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create Custom Event" background={true}><CreateEvents /></Page></PrivateRoute>} />
                  <Route path={routes.ViewCustomEvents.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Custom Event" background={true}><ViewCustomEvent /></Page></PrivateRoute>} />
                  <Route path={routes.ViewDefaultEvents.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Default Event" background={true}><ViewDefaultEvent /></Page></PrivateRoute>} />
                  <Route path={routes.EditCustomEvents.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Custom Event" background={true}><EditCustomeEvents /></Page></PrivateRoute>} />
                  <Route path={routes.EditDefaultEvents.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Default Event" background={true}><EditDefaultEvents /></Page></PrivateRoute>} />
                  <Route path={routes.RMG.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'  view={true} background={true}><RMG /></Page></PrivateRoute>} />
                  <Route path={routes.CreateRMG.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create RMG Currency" background={true}><CreateRMG /></Page></PrivateRoute>} />
                  <Route path={routes.EditRMG.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit RMG Currency" background={true}><EditRMG /></Page></PrivateRoute>} />
                  <Route path={routes.SpecterBank.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'  view={true} background={true}><SpecterBank /></Page></PrivateRoute>} />
                  <Route path={routes.Payout.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'  view={true} background={true}><Payout /></Page></PrivateRoute>} />
                  <Route path={routes.RMGTransactions.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' view={true} background={true} title='RMG Transactions'><RMGTransactions /></Page></PrivateRoute>} />
                  <Route path={routes.Task.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'  view={true} background={true}><Task /></Page></PrivateRoute>} />
                  <Route path={routes.CreateTask.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create Task" background={true}><CreateTask /></Page></PrivateRoute>} />
                  <Route path={routes.ViewTask.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Task" background={true}><ViewTask /></Page></PrivateRoute>} />
                  <Route path={routes.EditTask.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Task" background={true}><EditTask /></Page></PrivateRoute>} />
                  <Route path={routes.StepSeries.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'  view={true} background={true}><StepSeries /></Page></PrivateRoute>} />
                  <Route path={routes.CreateStepSeries.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create Step Series" background={true}><CreateStepSeries /></Page></PrivateRoute>} />
                  <Route path={routes.ViewStepSeries.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Step Series" background={true}><ViewStepSeries /></Page></PrivateRoute>} />
                  <Route path={routes.EditStepSeries.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Step Series" background={true}><EditStepSeries /></Page></PrivateRoute>} />
                  <Route path={routes.Missions.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'  view={true} background={true}><GetMission /></Page></PrivateRoute>} />
                  <Route path={routes.ViewMission.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Mission" background={true}><ViewMission /></Page></PrivateRoute>} />
                  <Route path={routes.CreateMission.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create Mission" background={true}><CreateMission /></Page></PrivateRoute>} />
                  <Route path={routes.EditMission.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Mission" background={true}><EditMission /></Page></PrivateRoute>} />
                  <Route path={routes.GetPlayers.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'  view={true} background={true}><GetPlayers /></Page></PrivateRoute>} />
                  <Route path={routes.PlayerDetails.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Player Details' background={true}><PlayerDetail /></Page></PrivateRoute>} />
                  <Route path={routes.Leaderboard.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'  view={true} background={true}><Leaderboard /></Page></PrivateRoute>} />
                  <Route path={routes.ViewLeaderboard.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='View Leaderboard' background={true}><ViewLeaderboard /></Page></PrivateRoute>} />
                  <Route path={routes.CreateLeaderboard.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Create Leaderboard' background={true}><CreateLeaderboard /></Page></PrivateRoute>} />
                  <Route path={routes.EditLeaderboard.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Edit Leaderboard' background={true}><EditLeaderboard /></Page></PrivateRoute>} />
                  <Route path={routes.LeaderboardResult.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true} view={true} title='Leaderboard Result'><LeaderboardResult /></Page></PrivateRoute>} />
                  <Route path={routes.CompetitionResult.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' view={true} background={true} title='Tournament Result'><TournamentResult /></Page></PrivateRoute>} />
                  <Route path={routes.CompetitionInstantBattleResult.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' view={true} background={true} title='Instant Battle Result'><InstantBattleResult /></Page></PrivateRoute>} />
                  <Route path={routes.Calendar.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><Calendar /></Page></PrivateRoute>} />
                  <Route path={routes.CompetitionSchedule.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><GetCompetitionSchedule /></Page></PrivateRoute>} />
                  <Route path={routes.ViewCompetitionSchedule.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Competition" background={true}><ViewCompetitionSchedule /></Page></PrivateRoute>} />
                  <Route path={routes.CreateCompetitionSchedule.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Schedule Competition" background={true}><CreateCompetitionSchedule /></Page></PrivateRoute>} />
                  <Route path={routes.EditCompetitionSchedule.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Schedule Competition" background={true}><EditCompetitionSchedule /></Page></PrivateRoute>} />
                  <Route path={routes.Community.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><GetCommunity /></Page></PrivateRoute>} />
                  <Route path={routes.CompetitionTournament.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'  view={true} background={true}><GetCompetitionTournament /></Page></PrivateRoute>} />
                  <Route path={routes.CompetitionInstantBattle.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'  view={true} background={true}><GetCompetitionInstantBattle /></Page></PrivateRoute>} />
                  <Route path={routes.ViewCompetitionTournament.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='View Tournament' background={true}><ViewCompetitionTournament /></Page></PrivateRoute>} />
                  <Route path={routes.ViewCompetitionInstantBattle.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='View Instant Battle' background={true}><ViewCompetitionInstantBattle /></Page></PrivateRoute>} />
                  <Route path={routes.CreateCompetitionTournament.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Create Tournament' background={true}><CreateCompetitionTournament /></Page></PrivateRoute>} />
                  <Route path={routes.CreateCompetitionInstantBattle.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Create Instant Battle' background={true}><CreateCompetitionInstantBattle /></Page></PrivateRoute>} />
                  <Route path={routes.EditCompetitionTournament.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Edit Tournament' background={true}><EditCompetitionTournament /></Page></PrivateRoute>} />
                  <Route path={routes.EditCompetitionInstantBattle.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Edit Instant Battle' background={true}><EditCompetitionInstantBattle /></Page></PrivateRoute>} />
                  <Route path={routes.AppAcceptedMembers.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='AppSettings'  title='Accepted Members' view={true} background={true}><AcceptedMembers /></Page></PrivateRoute>} />
                  <Route path={routes.AppInvitedMembers.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='AppSettings'  title='Invited Members' view={true} background={true}><InvitedMembers /></Page></PrivateRoute>} />
                  <Route path={routes.API.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='AppSettings'  title='API' background={true}><GetAPI /></Page></PrivateRoute>} />
                  <Route path={routes.Media.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='AppSettings'  title='Media' background={true}><Media /></Page></PrivateRoute>} />
                  <Route path={routes.MediaFolders.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='AppSettings'  title='Media' background={true}><MediaFolders /></Page></PrivateRoute>} />
                  <Route path={routes.Documents.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='AppSettings'  title='Documents' view={true} background={true}><GetDocuments /></Page></PrivateRoute>} />
                  <Route path={routes.CreateDocuments.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='AppSettings' title='Create Document' background={true}><CreateDocuments /></Page></PrivateRoute>} />
                  <Route path={routes.EditDocuments.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='AppSettings' title='Edit Document' background={true}><EditDocument /></Page></PrivateRoute>} />
                  <Route path={routes.Terminal.path} element={<PrivateRoute><Page wide={false} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' view={true} title='Terminal' background={true}><Terminal /></Page></PrivateRoute>} />
                  <Route path={routes.PlansNBilling.path} element={<PrivateRoute><Page wide={true} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='Organization' background={true}><BillingOverview /></Page></PrivateRoute>} />
                  <Route path={routes.Invoices.path} element={<PrivateRoute><Page wide={true} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='Organization' background={true}><BillingHistory /></Page></PrivateRoute>} />
                  <Route path={routes.BillingNotification.path} element={<PrivateRoute><Page wide={true} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='Organization' background={true}><BillingNotification /></Page></PrivateRoute>} />
                  <Route path={routes.BillingSettings.path} element={<PrivateRoute><Page wide={true} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='Organization' background={true}><BillingSettings /></Page></PrivateRoute>} />
                  <Route path={routes.plans.path} element={<PrivateRoute><Page wide={true} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='Organization' background={true}><Plans /></Page></PrivateRoute>} />
                  <Route path={routes.addOns.path} element={<PrivateRoute><Page wide={true} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='Organization'><AddOns active={true}/></Page></PrivateRoute>} />
                  <Route path={routes.billingDetails.path} element={<PrivateRoute><Page wide={true} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='Organization'><BillingDetails/></Page></PrivateRoute>} />
                  <Route path={routes.ActivityFeed.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='AppSettings' title='Activity' background={true}><ActivityFeed /></Page></PrivateRoute>} />
                  <Route path={routes.NotificationFeed.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='Organization' wide={true} background={true}><NotificationFeed /></Page></PrivateRoute>} />
                  <Route path={routes.upgradeAddOn.path} element={<PrivateRoute><Page wide={true} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='Organization'><AddOn /></Page></PrivateRoute>} />
                  <Route path={routes.upgradeTopUp.path} element={<PrivateRoute><Page wide={true} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='Organization'><TopUp /></Page></PrivateRoute>} />

                  {/* ORGANISATION PATH */}
                  <Route path={routes.Dashboard.path} element={<PrivateRoute><Page wide={true} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='Organization' title='Apps' background={true}><Apps /></Page></PrivateRoute>} />
                  <Route path={routes.Apps.path} element={<PrivateRoute><Page wide={true} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType}  type='Organization'  title='Apps' background={true}><OrgApps /></Page></PrivateRoute>} />
                  <Route path={routes.OrganisationAcceptedMembers.path} element={<PrivateRoute><Page wide={true} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='Organization' background={true}><OrgAcceptedMembers /></Page></PrivateRoute>} />
                  <Route path={routes.OrganisationInvitedMembers.path} element={<PrivateRoute><Page wide={true} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='Organization' background={true}><OrgInvitedMembers /></Page></PrivateRoute>} />
                  <Route path={routes.OrganisationProfile.path} element={<PrivateRoute><Page wide={true} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='Organization' background={true}><OrgProfile /></Page></PrivateRoute>} />
                  <Route path={routes.UserProfile.path} element={<PrivateRoute><Page wide={true} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='Organization' background={true}><UserProfile /></Page></PrivateRoute>} />
                  <Route path={routes.UsageAnalytics.path} element={<PrivateRoute><Page wide={true} showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='Organization' background={true}><UsageAnalytics /></Page></PrivateRoute>} />
                  {/* LOGIN AND SIGN UP */}
                  <Route path={routes.EmailValidate.path} element={<EmailValidate />} />
                  <Route index element={<Login showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType}  />} />
                  <Route path={routes.SignUp.path} element={<SignUp />} />
                  <Route path={routes.Forbidden.path} element={<Forbidden />} />
                  <Route path={routes.userDetail.path} element={<UserDetail showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType}/>} />
                  <Route path={routes.ForgotPassword.path} element={<ForgotPassword showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} />} />
                  <Route path={routes.ResetPassword.path} element={<ResetPassword showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType}/>} />
                  <Route path={routes.MemberInviteSetPassword.path} element={<MemberInvite showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType}/>} />
                  <Route path={routes.AcceptInvite.path} element={<AcceptInvite />} />
                  <Route path="*" element={<PageNotFound />} />
              </Route>
            </Routes>
          </Router>
        </div>
      </GlobalStates>
    </>
  );
}
export default App;