import cn from "classnames";
import styles from "./notification.module.sass";
import { Link } from "react-router-dom";
import Icon from "../../../../components/Icon";
import { useRef } from "react";
import moment from "moment";
import Dropdown from "../../../../components/Dropdown/CommonDropdown"

const NotificationDrawer = ({ className, notifications,activityListRef, onScroll, unread, visible,showOptions,showUnread,setShowUnread, setVisible}) => {

    // const activityListRef = useRef(null);

    const handleClose = () => {
        setVisible(false);
    };

    const boldText = (text) => {
        return text.replace(/'(.*?)'/g, (match, p1) => {
          return `<span style="color: #2A85FF">${p1}</span>`;
        });
    };

    return (
        <>
            <div className={cn(styles.notification, className, {[styles.active]: visible})}>
                <div className={styles.container}>
                    <div className={styles.body}>
                        <div className={styles.activityTable}>
                            <div className={styles.head}>
                                <div className={styles.title}>Notifications</div>
                                <div style={{width:"fit-content",marginLeft:"8px"}}><Dropdown options={showOptions} height={28} value={showUnread} setValue={setShowUnread}/></div>
                                <div className={styles.close} onClick={() => setVisible(false)}><Icon name='close' size='20' /></div>
                            </div>
                            <ul className={styles.activityList} ref={activityListRef} onScroll={onScroll}>
                                {(notifications?.length > 0) ? (
                                    notifications && notifications.map((activity) => (
                                    <li key={activity.id} className={styles.activityItem}>
                                        <span className={styles.iconWrapper}>
                                            <Icon className={cn(styles.activityIcon, {[styles.create]: activity?.action_type === "CREATE", [styles.edit]: activity?.action_type === "UPDATE", [styles.delete]: activity?.action_type === "ARCHIVE"})}
                                            name={activity?.action_type === "UPDATE" ? "edit" : activity?.action_type === "ARCHIVE" ? "trash" : activity?.action_type === "CREATE" ? "file-add" : "activity"} size="20" />
                                        </span>
                                        <div className={styles.activityContent}>
                                            <div className={styles.performedBy}>
                                            <div className={styles.activity}>
                                                <div
                                                title={activity.description}
                                                className={styles.description}
                                                dangerouslySetInnerHTML={{ __html: boldText(activity.description) }}
                                                ></div>
                                                <div className={styles.userdetail}>
                                                <div className={styles.name}>by {activity.performed_by}</div>
                                                <div className={styles.timeStamp}>
                                                    <div className={styles.date}>
                                                    <div>
                                                        {activity?.timestamp &&
                                                        moment(activity?.timestamp).format('DD/MM/YYYY hh:mm A')}
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </li>
                                ))
                                ) : (
                                <p className={styles.warning}>No Notification Available.</p>
                                )}
                            </ul>
                        
                        </div>
                    </div>
                </div>
            </div>
            <div className={cn(styles.notificationOverlay, { [styles.active]: visible })} onClick={() => setVisible(false)}></div>
        </>
    );
};

export default NotificationDrawer;