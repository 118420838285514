import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Modal from "../../../../../components/Modals/ModalComponent";
import AddAppsModal from "../../../../../components/Organisation/Apps/Modals/AddAppModal";
import Icon from "../../../../../components/Icon";
import addIcon from '../../../../../media/images/icons/light.png'
import {ReactComponent as AddButtonIcon} from '../../../../../media/images/icons/add_button.svg'

const Control = ({ className, type, width, setAppName, setShowLoader, setLoaderType }) => {

  const [visibleModal, setVisibleModal] = useState(false);

  const actions = [
    {
      icon: "add",
      action: () => setVisibleModal(true),
    }
  ];

  return (
    <>
      <div className={styles.addApps}>
        {actions.map((x, index) => (
            <Link key={index} onClick={x.action} className={cn(styles.control, {[styles.tiny] : type === 'tiny'}, {[styles.small] : type === 'small'})}><AddButtonIcon className={styles.addIcon}/>Create</Link>
        ))}
      </div>
      <Modal width='small' visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <AddAppsModal setLoaderType={setLoaderType} setApp={setAppName} setShowLoader={setShowLoader} onClose={() => setVisibleModal(false)}/>
      </Modal>
    </>
  );
};

export default Control;
