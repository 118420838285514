import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import styles from './topUpModal.module.sass'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '../../../Checkbox'
import Card from '../../../Card'
import Icon from '../../../Icon'
import { buyAddOnsAction, getAddOnsAction, getPriceBookAction } from '../../../../redux/action/organisation/Billing/billingAction'
import Loader from '../../../Loader'

const TopUpModal = ({className, subscriptionId, planCode, onClose}) => {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [showOverlay, setShowOverlay] = useState(false)
  const [addOn, setAddOn] = useState([])
  const [currency, setCurrency] = useState(null)
  const [priceId, setPriceId] = useState(null)

  let {addOns, price, isLoadingAddOns} = useSelector((state) => state.billing)
  let {orgProfile} = useSelector(state => state.organisation)

  const getAllAddOn = () => {
    dispatch(getAddOnsAction())
  }

  const getAllPriceBook = () => {
    let priceBook = {}
    dispatch(getPriceBookAction(priceBook))
  }

  const getSelectedPriceBook = () => {
    let priceBook = {
      id: priceId
    }
    dispatch(getPriceBookAction(priceBook))
  }

  const configureAddOns = () => {
      let updatedAddOns = addOns && addOns.map(x => {
        const matchObj = price?.pricebook_items && price?.pricebook_items.find(y => x.addonCode === y.product_code)
        return{
            ...x,
            price: matchObj ? matchObj.pricebook_rate : x.priceBrackets[0]?.price
        }
      })
      setAddOn(updatedAddOns && updatedAddOns.map(e => ({...e, quantity: 0, active: false, disabled: (e.name === 'TaskFlow Engine Automation Overage' ? true : false)})))
  }

  const findSelectedPriceBook = () => {
    if(price && price !== null && Array.isArray(price)){
      if(orgProfile && orgProfile.location && orgProfile.location !== null){
        if(orgProfile.location === 'India'){
          let priceBookId = price && price.filter(x => x.name === 'India').map(y => y.pricebook_id)[0]
          setPriceId(priceBookId)
          setCurrency('Rupee')
        }else{
          let priceBookId = price && price.filter(x => x.name === 'Outside India').map(y => y.pricebook_id)[0]
          setPriceId(priceBookId)
          setCurrency('Dollar')
        }
      }
    }
  }

  const onAddOns = (id) => {
    setAddOn(addOn => addOn && addOn.map(e => e.addonCode === id ? {...e, active: !e.active, quantity: 1} : {...e}))
  }

  const onAdd = (id) => {
    setAddOn(addOn => addOn && addOn.map(e => e.addonCode === id ? {...e, quantity: e.quantity + 1} : {...e, quantity: e.quantity}))
  }

  const onRemove = (id) => {
    setAddOn(addOn => addOn && addOn.map(e => e.addonCode === id ? {...e, quantity: (e.quantity !== 1 ? e.quantity - 1 : 1)} : {...e, quantity: e.quantity}))
  }

  useEffect(() => {
    getAllAddOn()
    getAllPriceBook()
  }, [])

  useEffect(() => {
    configureAddOns()
  }, [addOns, priceId])

  useEffect(() => {
    findSelectedPriceBook()
  }, [price])

  useEffect(() => {
    if(priceId !== null){
      getSelectedPriceBook()
    }
  }, [priceId])

  const [toggle, setToggle] = useState(false)

  const toggleAddon = (id) => {
    setAddOn(addOn && addOn.map(x => x.addonCode === id?.addonCode ? {...x, quantity: 1, active: !x.active} : {...x, disabled: (x.name !== 'TaskFlow Engine Automation Overage' ? false : toggle)}))
    setToggle(!toggle)
  }

  const buyAddOn = () => {
    let addonsDetails = {
      subscriptionId: subscriptionId,
      addonsDetails: addOn && addOn.filter(x => x.active).map(y => ({addonCode: y.addonCode, type: (y.type ? 'one-time' : 'one-time'), quantity: y.quantity, price: y.priceBrackets[0]?.price, addonDescription: 'add on'}))
    }
    dispatch(buyAddOnsAction(addonsDetails, setShowOverlay))
  }

  return (
    <>
      <div className={styles.container}>
        <Card className={styles.card} classCardHead={styles.head} classTitle={cn("title-purple", styles.title)}>
          <div className={styles.wrapper}>
            <div className={styles.cardHead}>
              <p className={styles.title}>One-Time Add-Ons</p>
              <p className={styles.info}>Add one time top up you need to manage and grow your business.</p>
            </div>
                        
            <div className={styles.table}>
              <div className={styles.addOnsList}>
                {addOn && addOn.filter(x => x.type === 'one_time').map(e => (
                  <div className={cn(styles.addOn, {[styles.disabled]: e.disabled})}>
                    <div className={styles.addOnDetail}>
                      <div className={styles.detail}>
                        <div className={styles.name}>{e.name}</div>
                        <div className={styles.description}>{e.description}</div>
                      </div>
                      <div className={styles.checkbox}>
                        <Checkbox size='small' value={e.active} onChange={() => {onAddOns(e.addonCode)}}/>
                      </div>
                    </div>
                    <div className={styles.addOnPurchase}>
                      <div className={styles.price}>{currency === 'Rupee' ? <>&#8377;</> : <>&#36;</>}{e.price}</div>
                        {e.active &&
                          <div className={styles.quantity}>
                            <div className={styles.label}>Qty</div>
                            <div className={styles.amount}>{e.quantity}</div>
                            <div className={styles.operation}>
                              <div className={styles.add} onClick={() => onAdd(e.addonCode)}><Icon name='arrow-next' size='12'/></div>
                              <div className={styles.remove} onClick={() => onRemove(e.addonCode)}><Icon name='arrow-next' size='12'/></div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                ))}
              </div>
              <div className={styles.buy}>
                <button disabled={addOn && addOn.filter(x => x.active)?.length === 0} className={cn(styles.button, 'button-save-small')} onClick={buyAddOn}>
                  {showOverlay && 
                    <>
                      <div className={styles.buyAddOn}>
                        <div className={styles.loader}><Loader white={false} /></div>
                      </div>
                    </>
                  }
                  {!showOverlay&& <span>Buy</span>}
                </button>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className={cn(styles.overlay, {[styles.active] : showOverlay})}></div>
    </>
  )
}

export default TopUpModal