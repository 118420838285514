import axios from "axios";
import customAxios from "../../utils/interceptor";
import { BASE_URL, GET_META_DATA, GET_STATES, UPDATE_TAGS, GET_TAGS, GET_PLATFORMS, GET_GOALS, GET_CITY, GET_ALL_COUNTRY, GET_ALL_STATES, GET_DESCRIPTION } from "../../resources/APIEndpoints";

function CommonServices(){
    this.getMetaData = (body) => axios.get(BASE_URL + GET_META_DATA)
    this.getAllCountry = () => axios.post(BASE_URL + GET_ALL_COUNTRY)
    this.getAllStates = (body) => axios.post(BASE_URL + GET_ALL_STATES, body)
    this.getRegion = (body) => axios.post(BASE_URL + GET_STATES, body)
    this.getCity = (body) => axios.post(BASE_URL + GET_CITY, body)
    this.getPlatforms = (body) => axios.post(BASE_URL + GET_PLATFORMS, body)
    this.getGoals = (body) => axios.post(BASE_URL + GET_GOALS, body)
    this.getTags = (body) => customAxios.post(BASE_URL+GET_TAGS,body)
    this.updateTags = (body) => customAxios.post(BASE_URL+UPDATE_TAGS,body)
    this.getDescription = (body) => customAxios.post(BASE_URL + GET_DESCRIPTION, body)
    this.getImage = (url) => customAxios.get(url, { responseType: 'blob' })
}

export default new CommonServices();