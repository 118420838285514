import { GET_CURRENCY, GET_CURRENCY_BY_ID, ADD_CURRENCY, EDIT_CURRENCY, ADD_CURRENCY_SUCCESS, ADD_CURRENCY_ERROR, FILTER_CURRENCY_SUCCESS, FILTER_CURRENCY_ERROR, GET_CURRENCY_SUCCESS, GET_CURRENCY_ERROR, GET_RMG_TRANSACTION, GET_RMG_TRANSACTION_SUCCESS, GET_RMG_TRANSACTION_ERROR } from "../../../../actionTypes/actionTypes";

const initialState = {
    allCurrency : [],
    currency : [],
    currencyDetails : [],
    rmgTransaction : [],
    totalCount : null,
    isLoading : false,
    isFiltered: false,
    errors: null
}

export const currencyReducer = (state = initialState, action) => {

    switch(action.type){

        case GET_CURRENCY : {
            return {
                ...state,
                isLoading: true
            }
        }

        case GET_CURRENCY_SUCCESS : {
            return {
               ...state,
               isLoading : false,
               isFiltered: false,
               currency: action.payload,
               totalCount : action.totalCount,
               errors: null
            }
        }

        case GET_CURRENCY_ERROR : {
            return {
                ...state,
                isLoading : false,
                errors: action.payload
            }
        }

        case GET_RMG_TRANSACTION : {
            return {
                ...state,
                isLoading: true
            }
        }

        case GET_RMG_TRANSACTION_SUCCESS : {
            return {
               ...state,
               isLoading : false,
               isFiltered: false,
               rmgTransaction: action.payload,
               totalCount : action.totalCount,
               errors: null
            }
        }

        case GET_RMG_TRANSACTION_ERROR : {
            return {
                ...state,
                isLoading : false,
                rmgTransaction: [],
                errors: action.payload
            }
        }

        case FILTER_CURRENCY_SUCCESS : {
            return {
                ...state,
                currency : action.payload,
                totalCount: action.totalCount,
                isLoading: false,
                isFiltered: true
            }
        }

        case FILTER_CURRENCY_ERROR : {
            return {
                ...state,
                isLoading: false,
                errors: action.payload
            }
        }

        case GET_CURRENCY_BY_ID : {
            return {
                ...state,
                currencyDetails: action.payload,
                isLoading : false,
                isFiltered: false
             }
        }

        case ADD_CURRENCY : {
            return {
                ...state,
                currency: action.payload,
                messages : action.payload,
                isFiltered: false
            }
        }

        case EDIT_CURRENCY : {
            return {
                ...state,
                currency: action.payload,
                isFiltered: false
            }
        }

        default : {
            return {
                ...state
            }
        }

    }

}
