import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./getSpecter.module.sass";
import Icon from "../../../../../../components/Icon";
import Card from "../../../../../../components/Card";
import CurrencyTable from "../../../../../../components/Table";
import { getAllCurrencyAction, addCurrecyAction, filterCurrencyAction, getRMGTransactionAction } from "../../../../../../redux/action/builds/economy/currency/currencyAction";
import Loader from "../../../../../../components/Loaders/LoaderProgress";
import EmptyState from '../../../../../../components/EmptyStates'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import { Skeleton } from '@mui/material';
import SkeletonTable from '../../../../../../components/Skeleton/TableSkeleton'
import SkeletonEmptyState from "../../../../../../components/Skeleton/EmptyStateSkeleton";
import GlobalStateContext from "../../../../../../components/Context/GlobalStates/GlobalStateContext";
import { ToastContainer } from "react-toastify";
import Navigation from '../../../../../../components/Header/SecondaryNavigation'
import TransactionTable from '../../../../../../components/BuildComponents/RMC/TransactionTable'
import EmptyStates from "../../../../../../components/EmptyStates";

const RMGTransactions = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {id, appid} = useParams()

  // Pagination
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  
  const getTransaction = () => {
    let transaction = {
      projectId: appid,
      currencyId: parseInt(id),
      // Pagination
      offset: ((page-1)*limit),
      limit: limit
    }
    dispatch(getRMGTransactionAction(transaction))
  }

  let rmgTransaction = useSelector((state)=>{ 
    return state.currencies.rmgTransaction
  })
  
  let isLoading = useSelector((state) => {
    return state.currencies.isLoading
  })
  
  let totalCount = useSelector((state)=>{
    return state.currencies.totalCount
  })

  useEffect(() => {
    getTransaction()
  }, [page])

  const handlePageChange = (event, value)  => {
    setPage(value)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return(
      <>
        <ToastContainer />
        <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} >
          <div className={styles.wrapper}>
            {!isLoading && rmgTransaction?.length === 0 &&
              <EmptyStates type="Transaction" />
            }
            {!isLoading && rmgTransaction?.length > 0 &&
              <TransactionTable items={rmgTransaction}/>
            }
          </div> 
          {rmgTransaction.length > 0 &&
            <div className={styles.pagination}>
              <Stack spacing={2}>
                <Pagination page={page} renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    sx={{
                      '&.MuiPaginationItem-root': {
                        minWidth: '28px',
                        height: '28px',
                        fontSize: '12px'
                      },
                      '&.Mui-selected': {
                        color: 'rgb(255, 255, 255)',
                        fontWeight : 'bold',
                        border : '1px solid rgb(42, 133, 255)',
                        background : 'rgba(42, 133, 255)'
                      },
                      '&.MuiPaginationItem-page.Mui-selected:hover' : {
                        color: 'rgb(255, 255, 255)',
                        fontWeight : 'bold',
                        border : '1px solid rgb(42, 133, 255)',
                        background : 'rgba(42, 133, 255)'
                      }
                    }}/>
                )} siblingCount={1} count={Math.ceil(totalCount/limit)} color="primary" onChange={handlePageChange} />
              </Stack>
            </div>
          }
        </Card>
        <div className="divider"></div>
      </>
  )

};

export default RMGTransactions;