import { GET_PROGRESSION_SYSTEM, ADD_PROGRESSION_SYSTEM, EDIT_PROGRESSION_SYSTEM, FILTER_PROGRESSION_SYSTEM_SUCCESS, GET_PROGRESSION_SYSTEM_SUCCESS, FILTER_PROGRESSION_SYSTEM_ERROR, GET_PROGRESSION_SYSTEM_ERROR } from "../../../../actionTypes/actionTypes";
import progressionSystemServices from '../../../../../services/buildServices/progressionServices/progressionSystemServices/progressionSystemServices'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from "../../../../../components/Toast";

export const getAllProgressionSystemAction = (body, showSkeleton) => {
    return async(dispatch) => {
        dispatch(getAllProgressionSystem(null, null, GET_PROGRESSION_SYSTEM))
        await progressionSystemServices.getAllProgressionSystem(body)
        .then(res=>{
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(getAllProgressionSystem(res.data.data.levelDetails, (body?.showArchived ? res.data.data.archiveCount : res.data.data.totalCount), (body?.showArchived ? FILTER_PROGRESSION_SYSTEM_SUCCESS : GET_PROGRESSION_SYSTEM_SUCCESS)))
        }).catch(err=>{
            dispatch(getAllProgressionSystem(err.response, null, (body?.showArchived ? FILTER_PROGRESSION_SYSTEM_ERROR : GET_PROGRESSION_SYSTEM_ERROR)))
        })
    }
}

export const filterPSAction = (body, showSkeleton, isFiltered) => {
    return async (dispatch) => {
        await progressionSystemServices.getAllProgressionSystem(body)
        .then(res => {
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(filterPS(res.data.data.levelDetails, ((body?.search && body?.search !== '') ? res.data.data.searchCount : (body?.search === undefined && isFiltered) ? res.data.data.filterCount : res.data.data.totalCount), FILTER_PROGRESSION_SYSTEM_SUCCESS))
        }).catch(err=>{
            dispatch(filterPS(err.response, null, FILTER_PROGRESSION_SYSTEM_ERROR))
        })
    }
}

// export const createProgressionSystemAction = (body, navigate, appid, setDisable) => {
//     return async(dispatch) => {
//         setDisable(true)
//         await progressionSystemServices.createProgressionSystem(body)
//         .then(res=>{
//             toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
//             setTimeout(()=>{
//                 localStorage.removeItem('Level')
//                 setDisable(false)
//                 navigate(`/progression-system/${appid}`)  
//             }, 2000)
//         }).catch(error => {
//             setDisable(false)
//             error.response.data.errors && error.response.data.errors.map((err,i)=>{
//                     toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
//                 })
//         })
//     }
// }

export const createProgressionSystemAction = (body, navigate, appid, setDisable) => {
    return async (dispatch) => {
        if(setDisable){
            setDisable(true);
        }
        try {
            // Send request to create a progression system
            const res = await progressionSystemServices.createProgressionSystem(body); // Assuming this returns a promise
            // Dispatching success action (if needed, add this functionality)
            // dispatch(addProgressionSystem(res.data.data, ADD_PROGRESSION_SYSTEM_SUCCESS));
            // Show success message
            toast.success(
                <Toast type="Success" messages={res.data.message} />,
                {
                    position: 'top-center',
                    icon: false,
                    hideProgressBar: true,
                    autoClose: 2000,
                }
            );
            // Navigate after a short delay
            setTimeout(() => {
                localStorage.removeItem('Level');
                if(setDisable){
                    setDisable(false);
                }
                navigate(`/progression-system/${appid}`);
            }, 2000);

            return res; // Return the response to be used by the caller

        } catch (error) {
            if(setDisable){
                setDisable(false);
            }
            // Display error messages, if any
            if (error.response?.data?.errors) {
                error.response.data.errors.forEach((err) => {
                    toast.error(
                        <Toast type="Error" messages={err.message} />,
                        {
                            position: 'top-center',
                            icon: false,
                            hideProgressBar: true,
                            autoClose: 2000
                        }
                    );
                });
            }

            throw error; // Re-throw the error to be caught by the caller
        }
    };
};

export const editProgressionSystemAction = (body, navigate, appid, setDisable) => {
    return async(dispatch) => {
        if(setDisable){
            setDisable(true);
        }
        await progressionSystemServices.editProgressionSystem(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            setTimeout(()=>{
                localStorage.removeItem('Level')
                if(setDisable){
                    setDisable(false);
                }
                navigate(`/progression-system/${appid}`)  
            }, 2000)
            return res;
        }).catch(error=>{
            if(setDisable){
                setDisable(false);
            }
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                    toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                })
                throw error;
        })
    }
}

export const archivePSAction = (body, appid, onClose, status) => {
    return async(dispatch) => {
        try {
            const res = await progressionSystemServices.archivePS(body)
            if(res){
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                let data = {projectId : appid, showArchived: status, offset: 0, limit: 8}
                dispatch(getAllProgressionSystemAction(data))
                if(onClose){
                    onClose()
                }
            }
            return res;
        }catch(error) {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            console.log(error)
            throw error; 
        }
    }
}

export const getAllProgressionSystem = (data, count, type) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}

export const createProgressionSystem = (data) => {
    return {
        type : ADD_PROGRESSION_SYSTEM,
        payload : data
    }
}

export const editProgressionSystem = (data) => {
    return {
        type : EDIT_PROGRESSION_SYSTEM,
        payload : data
    }
}

export const filterPS = (data, count, type) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}