import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Icon from "../../../../../Icon";
import Modal from "../../../../../Modals/ModalComponent"
import DepositWalletModal from '../../../../../../components/BuildComponents/RMC/Modals/DepositWallet'
import BonusModal from "../../../Modals/BonusWallet";

const Control = ({className, id, item, value, setValue}) => {
  
  const [visible, setVisible] = useState(false)
  const [visibleBonus, setVisibleBonus] = useState(false)

  const actions = [
    {
      action: (item?.entitySubType === 'bonus' ? () => setVisibleBonus(true) : () => setVisible(true))
    }
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
       {actions && actions.map((x, index) => {
          return(
            <div className={styles.action} onClick={x.action}>Edit</div>
          )
        })}
      </div>
      <Modal width='large' visible={visible} onClose={() => setVisible(false)}>
        <DepositWalletModal visible={visible} item={item} value={value} setValue={setValue} onClose={() => setVisible(false)} />
      </Modal>
      <Modal width='large' visible={visibleBonus} onClose={() => setVisibleBonus(false)}>
        <BonusModal visible={visibleBonus} item={item} value={value} setValue={setValue} onClose={() => setVisibleBonus(false)} />
      </Modal>
    </>
  );
};

export default Control;
