import React, { useContext, useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import ConfirmNavigationModal from "../ConfirmNavigationModal";
import Modal from "../Modals/ModalComponent";
import Icon from "../Icon";
import User from "./User";
import { useEffect } from "react";
import Theme from "../Theme";
import { ReactComponent as LeftArrow } from "../../media/images/leftArrow.svg";
import AccessWarningHeader from "../AccessWarningHeader";
import GlobalStateContext from "../Context/GlobalStates/GlobalStateContext";
import NotificationFeed from "./Notification"
import ActivityFeed from "./Activity"
import { routes } from "../../routes/routes";
import { ReactComponent as  Specter} from "../../media/images/Spectre.svg";
import { ReactComponent as  SpecterText} from "../../media/images/Specter_Text.svg";
import { ReactComponent as  Crown} from "../../media/images/crowns.svg";
import NavHeader from "../NavHeader";
import SecondaryHeader from './Navigation'
import QuickAction from "./QuickAction";

const Header = ({className, title, wide, onOpen, onClose, type, appName, setAppName, loaderType, setLoaderType, showLoader, setShowLoader, isChanged, setIsChanged}) => {

  const {appid} = useParams()
  const Navigate = useNavigate();
  const [visibleModal, setVisibleModal] = useState(false);
  const [lnk, setLnk] = useState()

  const onToggle = () => {
    setVisibleModal(false)
    setLnk()
  }

  const onProceed = () => {
    setVisibleModal(false)
    Navigate(lnk)
    setIsChanged(false)
  }

  const onNavigateLinks = (x) => {
    if (isChanged==true) {
      setLnk(x)
      setVisibleModal(true);
    } else {
      Navigate(x)
    }
  }

  const onBack = () => {
    if(appid){
      Navigate(`/app-overview/${appid}`)
    }else{
      Navigate(routes.Dashboard.path)
    }
  }

  const loaderConfigure = (loaderType) => {
    setLoaderType(loaderType)
    setShowLoader(true)
    setTimeout(() => {
        setShowLoader(false)
    }, 4000)
  }

  const resetEnvironment = () => {
    localStorage.setItem('environment', 'Development')
    loaderConfigure(5)
    onClose()
  }

  const upgradePlan = () => {
    Navigate(`/plans`)
  }

  return (
    <>
      <header className={cn(styles.header, className, {[styles.wide]: wide})}>
        <div className={styles.orgLogo} onClick={resetEnvironment}>
          <Link className={styles.logo} to="/home" onClick={onClose}>
            <SpecterText className={styles.pic}/>
          </Link>
        </div>
        <div className={styles.mainHeader}>
          <div className={styles.headContent}>
            <div className={styles.btn} onClick={upgradePlan}>
              <div className={styles.info}>
                <div className={styles.crown}><Crown /></div>
                <div className={styles.text}>Upgrade</div>
              </div>
              <div className={styles.btn2}></div>
            </div>
            <div className={styles.control} >
              <QuickAction className={styles.quickAction} />
            </div>
            <div className={styles.control}>
              <NotificationFeed className={styles.user} />
            </div>
            <div className={styles.headContent}>
              <div className={styles.control}>
                <User className={styles.user} />
              </div>
            </div>
          </div>
        </div>
        <Modal visible={visibleModal} onClose={() => onToggle()}>
          <ConfirmNavigationModal onProceed={()=>onProceed()} onClose={() => onToggle()}/>
        </Modal>
      </header>
      <NavHeader type={type} wide={wide} loaderType={loaderType} setLoaderType={setLoaderType} appName={appName} setAppName={setAppName} showLoader={showLoader} setShowLoader={setShowLoader} isChanged={isChanged} setIsChanged={setIsChanged} onToggle={onToggle} onProceed={onProceed} />
      {(window.location.pathname.includes('/create') || window.location.pathname.includes('/edit') || window.location.pathname.includes('/view') || window.location.pathname.includes('/app-overview') || window.location.pathname.includes('/env-information') || window.location.pathname.includes('/player-details') || window.location.pathname.includes('/tournament-result') || window.location.pathname.includes('/leaderboard-result') || window.location.pathname.includes('/instant-battle-result') || window.location.pathname.includes('/transactions') || (type === 'AppSettings' && (!window.location.pathname.includes('/app-document') && !window.location.pathname.includes('/app-accepted-members') && !window.location.pathname.includes('/app-invited-members')))) &&
        <SecondaryHeader title={title} type={type} appName={appName} isChanged={isChanged} setIsChanged={setIsChanged} onToggle={onToggle} onProceed={onProceed} onNavigate={onNavigateLinks} />
      }
    </>
  );
};

export default Header;
