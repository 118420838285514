import {GET_LEADERBOARD_RESULT, GET_LEADERBOARD_RESULT_SUCCESS, GET_LEADERBOARD_RESULT_ERROR, CREATE_LEADERBOARD_ERROR, CREATE_LEADERBOARD_SUCCESS, EDIT_LEADERBOARD, EDIT_LEADERBOARD_ERROR, EDIT_LEADERBOARD_SUCCESS, GET_LEADERBOARD, GET_LEADERBOARD_DETAIL, GET_LEADERBOARD_DETAIL_ERROR, GET_LEADERBOARD_DETAIL_SUCCESS, GET_LEADERBOARD_ERROR, GET_LEADERBOARD_SUCCESS, FILTER_LEADERBOARD_SUCCESS, FILTER_LEADERBOARD_ERROR} from "../../../actionTypes/actionTypes";
import LeaderboardServices from '../../../../services/engageServices/Leaderboard/leaderboardServices'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from "../../../../components/Toast";

export const getAllLeadboardAction = (body, showSkeleton) => {
    return async (dispatch) => {
        dispatch(getLeaderboard(null, null, GET_LEADERBOARD))
        await LeaderboardServices.getLeaderboard(body)
        .then(res=>{
            if(showSkeleton){
                showSkeleton()
            }
            let updatedLeaderboard = res.data.data.leaderboards && res.data.data.leaderboards.map(item => ({
                ...item,
                scheduleInstances: item.scheduleDetails && item.scheduleDetails[0] && item.scheduleDetails[0]?.scheduleInstances.sort((a, b) => {
                  const dateA = new Date(a.startDate);
                  const dateB = new Date(b.startDate);
                  return dateB - dateA;
                })
            }));
            dispatch(getLeaderboard(updatedLeaderboard, res.data.data.totalCount, GET_LEADERBOARD_SUCCESS))
        }).catch(err=>{
            dispatch(getLeaderboard(err.response, null, GET_LEADERBOARD_ERROR))
        })
    }
}

export const filterLeaderboardAction = (body, showSkeleton, isFiltered) => {
    return async (dispatch) => {
        await LeaderboardServices.getLeaderboard(body)
        .then(res => {
            if(showSkeleton){
                showSkeleton()
            }
            let updatedLeaderboard = res.data.data.leaderboards && res.data.data.leaderboards.map(item => ({
                ...item,
                scheduleInstances: item.scheduleDetails && item.scheduleDetails[0] && item.scheduleDetails[0]?.scheduleInstances.sort((a, b) => {
                  const dateA = new Date(a.startDate);
                  const dateB = new Date(b.startDate);
                  return dateB - dateA;
                })
            }));
            dispatch(filterLeaderboard(updatedLeaderboard, ((body?.search && body?.search !== '') ? res.data.data.searchCount: (body?.search === undefined && isFiltered) ? res.data.data.filterCount : res.data.data.totalCount), FILTER_LEADERBOARD_SUCCESS))
        }).catch(err=>{
            dispatch(filterLeaderboard(err.response, null, FILTER_LEADERBOARD_ERROR))
        })
    }
}

export const getLeadboardByIdAction = (body) => {
    return async (dispatch) => {
        dispatch(getLeaderboardById([], GET_LEADERBOARD_DETAIL))
        await LeaderboardServices.getLeaderboard(body)
        .then(res=>{
            dispatch(getLeaderboardById(res.data.data.leaderboards, GET_LEADERBOARD_DETAIL_SUCCESS))
        }).catch(err=>{
            dispatch(getLeaderboardById(err, GET_LEADERBOARD_DETAIL_ERROR))
        })
    }
}

export const getLeadboardResultAction = (body) => {
    return async (dispatch) => {
        await LeaderboardServices.getLeaderboardResult(body)
        .then(res=>{
            dispatch(getLeaderboardResult(res.data.data.leaderboardData, res.data.data.leaderboardDates, res.data.data.totalCount, GET_LEADERBOARD_RESULT_SUCCESS))
        }).catch(err=>{
            dispatch(getLeaderboardResult(err, null, null, GET_LEADERBOARD_RESULT_ERROR))
        })
    }
}

export const createLeaderboardAction = (body, navigate, appid, setDisable, visible, setVisible, setId) => {
    return async (dispatch) => {
        if(setDisable){
            setDisable(true)
        }
        await LeaderboardServices.createLeaderboard(body)
        .then(res=>{
            if(setVisible){
                setId(res.data.data.id)
                localStorage.removeItem('Ranks')
                setVisible(true)
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            }else{
                localStorage.removeItem('Ranks')
                if(navigate){
                    toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                    setTimeout(() => {
                        if(setDisable){
                            setDisable(false)
                        }
                        navigate(`/leaderboard/${appid}`)
                    }, 2000);
                }
            }
            return res;
        }).catch(error=>{
            dispatch(createLeaderboard(error, CREATE_LEADERBOARD_ERROR))
            if(setDisable){
                setDisable(false)
            }
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            return error;
        })
    }
}

export const editLeaderboardAction = (body, navigate, appid, setDisable, visible, setVisible) => {
    return async (dispatch) => {
        if(setDisable){
            setDisable(true)
        }
        dispatch(editLeaderboard([], EDIT_LEADERBOARD))
        await LeaderboardServices.editLeaderboard(body)
        .then(res=>{
            if(setVisible){
                localStorage.removeItem('Ranks')
                setVisible(true)
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            }else{
                localStorage.removeItem('Ranks')
                if(navigate){
                    toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                    setTimeout(() => {
                        if(setDisable){
                            setDisable(false)
                        }
                        navigate(`/leaderboard/${appid}`)
                    }, 2000);
                }
            }
            return res;
        }).catch(error=>{
            if(setDisable){
                setDisable(false)
            }
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}

export const archiveLeaderboardAction = (body, appid, onClose) => {
    return async (dispatch) => {
        await LeaderboardServices.archiveLeaderboard(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            let data = {projectId: appid, offset: 0, limit: 8}
            dispatch(getAllLeadboardAction(data))
            onClose()
            return res;
        }).catch(error=>{
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}

export const deleteLeaderboardAction = (body, appid, onClose) => {
    return async (dispatch) => {
        await LeaderboardServices.deleteLeaderboard(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            let data = {projectId: appid, offset: 0, limit: 8}
            dispatch(getAllLeadboardAction(data))
            onClose()
            return res;
        }).catch(error=>{
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}

export const getLeaderboard = (data, count, type) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}

export const getLeaderboardById= (data, type) => {
    return {
        type : type,
        payload : data
    }
}

export const getLeaderboardResult = (data, dates, count, type) => {
    return {
        type : type,
        dates : dates,
        totalCount : count,
        payload : data
    }
}

export const createLeaderboard = (data, type) => {
    return {
        type : type,
        payload : data
    }
}

export const editLeaderboard = (data, type) => {
    return {
        type : type,
        payload : data
    }
}

export const editPlayerProfile = (data, type) => {
    return {
        type : type,
        payload : data
    }
}

export const filterLeaderboard = (data, count, type) => {
    return {
        type : type,
        totalCount : count,
        payload : data
    }
}