import React, {useState, useRef, useEffect, useContext} from 'react'
import cn from 'classnames'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import styles from './scheduleModal.module.sass'
import { useNavigate,useParams } from 'react-router-dom'
import Radio from '../../Radio'
import moment from 'moment'
import Checkbox from '../../Checkbox'
import DatePicker from "react-datepicker";
import Item from '../../Schedule/Item'
import { format } from "date-fns";
import Dropdown from '../../../components/Dropdown';
import TimezoneDropdown from '../../../components/Dropdown/TimezoneDropdown';
import TooltipTitle from '../../../Tooltip/TooltipTitle'
import TextInput from '../../TextInput'
import { getAllMetaDataAction } from '../../../redux/action/common/commonAction'
import { createScheduleAchievementAction, createScheduleLiveOpsAction, createScheduleTaskAction, createScheduleTaskGroupAction, rescheduleLiveOpsAction, scheduleCompetition } from '../../../redux/action/engage/LiveOps/LiveOpsAction'
import Tooltips from '../../Tooltip'
import {ReactComponent as Internet} from '../../../media/images/icons/internet.svg'
import Timezones from '../../../jsonMockData/Timezones'
import app,{increaseUnreadCountForAllUsers} from "../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";
import GlobalStateContext from '../../Context/GlobalStates/GlobalStateContext.js'

const ScheduleModal = ({className, timeZone, name, liveOpsStatus, offset, type, as, id, scheduleId, liveOps, onClose}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams()
  const globalStates = useContext(GlobalStateContext)

  const [recurringOptions, setRecurringOption] = useState(null)
  const [recurring, setRecurring] = useState('')
  const [recurringId, setRecurringId] = useState(null)
  const [recurrenceCount, setRecurrenceCount] = useState(null)
  const [recurrenceFrequency, setRecurrenceFrequecy] = useState(null)
  const [scheduleType, setScheduleType] = useState(type === 'Leaderboard' ? true : false)
  const [isRecurring, setIsRecurring] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endDate, setEndDate] = useState('')
  const [endTime, setEndTime] = useState('')

  const [selectedZone, setSelectedZone] = React.useState("");
  const [selectedZoneID, setSelectedZoneID] = React.useState("");

  const onChangeStartDate = (e) => {
    setStartDate(e)
    setEndDate('')
  }

  const onChangeStartTime = (e) => {
    setStartTime(e.target.value)
  }

  const onChangeEndDate = (e) => {
    setEndDate(e)
  }

  const onChangeEndTime = (e) => {
    setEndTime(e.target.value)
  }

  let user = useSelector((state) => {
    return state.auth.user
  })

  const configureTimeZone = () => {
    if(timeZone != null && timeZone != ''){
      setSelectedZone(timeZone)
    }else{
      setSelectedZone(user.timezone)
    }
  }

  useEffect(() => {
    configureTimeZone()
  }, [user,timeZone])

  const [visibleStartDate, setVisibleStartDate] = useState(false);
  const [visibleStartTime, setVisibleStartTime] = useState(false);
  const [visibleEndDate, setVisibleEndDate] = useState(false);
  const [visibleEndTime, setVisibleEndTime] = useState(false);

  const db = getFirestore(app);

  const ActivityLog=async (resource_type,res,action_type="CREATE")=>{
        const user = JSON.parse(localStorage.getItem('user'))
        const memberID = user?.id
        const memberName = user?.name
        const orgId = (localStorage.getItem('orgId'))

        // Log activity in Firestore
        const activityLog = {
            timestamp: new Date().toISOString(),
            action_type: action_type,
            app_id:appid,
            resource: resource_type.toUpperCase(),
            resource_id: id,
            description: `${resource_type} '${name}' ${action_type=="CREATE"?"scheduled":"rescheduled"} successfully`,
            quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
            operation_status: "SUCCESS",
            activity_feed: true,
            priority: "LOW",
            performed_by_id: memberID,
            organization_id: orgId,
            performed_by:memberName,
            request_json:JSON.stringify(res),
        };
        await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
        console.log("Activity logged successfully");
  }
  
  const schedule = () => {
      let formatedStartDate = null
      let formatedEndDate = null
      if(startDate != '' && startTime !=''){
        const parsedDate = moment(startDate);
        const parsedTime = moment(startTime);
        const combinedDateTime = moment(parsedDate.format('YYYY-MM-DD') + ' ' + parsedTime.format('HH:mm:ss')).utc();
        const formattedDateTime = combinedDateTime.format('YYYY-MM-DD HH:mm:ss');
        formatedStartDate = formattedDateTime.concat('+00:00')
      }

      if(endDate != '' && endTime != ''){
        const parsedDate = moment(endDate);
        const parsedTime = moment(endTime);
        const combinedDateTime = moment(parsedDate.format('YYYY-MM-DD') + ' ' + parsedTime.format('HH:mm:ss')).utc();
        const formattedDateTime = combinedDateTime.format('YYYY-MM-DD HH:mm:ss');
        formatedEndDate = formattedDateTime.concat('+00:00')
      }

      let scheduleInformation = {
        timezone : selectedZone,
        // Leaderboard Id and Competition Id For Scheduling for leaderboard and competition
        ...((liveOps?.length > 0 && scheduleId != null) && {id : scheduleId}),
        ...((type == 'Leaderboard' || type == 'Tournament' || type == 'Instant Battle') && {scheduledId: "schedule_live_ops"}),
        ...(type == 'Leaderboard' && {leaderboardId : id}),
        ...(type == 'Leaderboard' && {offset : parseInt(offset)}),
        ...((type == 'Tournament' || type == 'Instant Battle') && {competitionId : id}),
        // Task Id and Task Group Id For Scheduling for leaderboard and competition
        ...(type == 'Task' && {taskId : id}),
        ...((type == 'Mission' || type == 'StepSeries') && {taskGroupId : id}),
        ...((type == 'Leaderboard' || type == 'Tournament' || type == 'Instant Battle') && {projectId : appid}),
        startDate : ((scheduleType && !isRecurring) ? '1970-01-01 00:00:00+00:00' : formatedStartDate),
        ...(!scheduleType && {endDate: formatedEndDate}),
        scheduleType : (scheduleType ? 'normal' : (!scheduleType && !isRecurring) ? 'normal' : 'recurring'),
        ...((!scheduleType && isRecurring) && {recurrenceCount : recurrenceCount}),
        intervalUnitId : (scheduleType ? 5 : !scheduleType && !isRecurring ? 6 : recurringId),
        ...((!scheduleType && isRecurring) && {recurrenceFrequency : recurrenceFrequency}),
      }

      try {
        if(type == 'Leaderboard' || type == 'Tournament' || type == 'Instant Battle'){
          if(liveOps?.length > 0){
            dispatch(rescheduleLiveOpsAction(scheduleInformation, type, as, appid, onClose, navigate, globalStates.setShowOverlay))
            ActivityLog(type,scheduleInformation,"UPDATE")
          }else{
            dispatch(createScheduleLiveOpsAction(scheduleInformation, type, as, appid, onClose, navigate, globalStates.setShowOverlay))
            ActivityLog(type,scheduleInformation)
          }
    
        }else if(type == 'Task'){
          if(liveOpsStatus == 'yet to start'){
            dispatch(createScheduleTaskAction(scheduleInformation, type, as, appid, onClose, navigate, globalStates.setShowOverlay))
            ActivityLog(type,scheduleInformation)
          }else{
            dispatch(createScheduleTaskAction(scheduleInformation, type, as, appid, onClose, navigate, globalStates.setShowOverlay))
            ActivityLog(type,scheduleInformation)
          }
        }else if(type == 'StepSeries' || type == 'Mission'){
          if(liveOpsStatus == 'yet to start'){
            dispatch(createScheduleTaskGroupAction(scheduleInformation, type, as, appid, onClose, navigate, globalStates.setShowOverlay))
            ActivityLog(type == 'StepSeries'?"Step Series":type,scheduleInformation)
          }else{
            dispatch(createScheduleTaskGroupAction(scheduleInformation, type, as, appid, onClose, navigate, globalStates.setShowOverlay))
            ActivityLog(type,scheduleInformation)
          }
        }
      } catch (error) {
        console.error("Error whie logging activity:", error);
      }
      
  }

  const configureMetaData = () => {
      setRecurringOption(allMetaData.leaderboardInterval && allMetaData.leaderboardInterval.filter(obj => obj.intervalName !== 'monthly' && obj.intervalName !== 'yearly' && obj.intervalName !== 'all_time' && obj.intervalName !== 'custom').map((obj) => {
        return {
          id: obj.id,
          name: (obj.intervalName.charAt(0).toUpperCase() + obj.intervalName.slice(1))?.replace(/_/g, ' '),
        };
      }))
  }
  
  const getMetaData = () => {
    dispatch(getAllMetaDataAction())
  }

  let allMetaData = useSelector((state) => {
    return state.common.metaData
  })

  useEffect(()=>{ 
    configureMetaData()
  }, [allMetaData])

  useEffect(()=>{
    getMetaData()
  },[])

  useEffect(() => {
    setEndDate('')
    setEndTime('')
  }, [recurringId])

  const [minStart, setMinStart] = useState(new Date())
  const [maxStart, setMaxStart] = useState(new Date())
  const [minEnd, setMinEnd] = useState(new Date())
  const [maxEnd, setMaxEnd] = useState(new Date())

  const onStartDate = (date, type) => {
    setStartDate(date);
    setVisibleStartDate(false)
    setStartTime('')
    setEndDate('')
    setEndTime('')
    // Parse the date strings into Moment objects
    const formattedDate1 = moment(date).format('DD-MM-YYYY');
    const formattedDate2 = moment(new Date()).format('DD-MM-YYYY');
    // Compare the dates
    if(formattedDate1 === formattedDate2) {
      setMinStart(new Date().setMinutes(new Date().getMinutes()))
      setMaxStart((new Date()).setHours(23, 59, 59, 999))
    }else {
      setMinStart((new Date()).setHours(0, 0, 0, 999))
      setMaxStart((new Date()).setHours(23, 59, 59, 999))
    } 
  }

  const onStartTime = (time) => {
    setStartTime(time);
    setVisibleStartTime(false)
    setEndDate('')
    setEndTime('')
  }

  const onEndDate = (date, type) => {
    setEndDate(date);
    setVisibleEndDate(false);
    if(type === 'Normal'){
      const formattedDate1 = moment(date).format('DD-MM-YYYY');
      const formattedDate2 = moment(new Date(startDate)).format('DD-MM-YYYY');
      // Compare the dates
      if(formattedDate1 === formattedDate2) {
        setMinEnd(new Date(startTime).setMinutes(new Date(startTime).getMinutes()))
        setMaxEnd((new Date()).setHours(23, 59, 59, 999))
      }else {
        setMinEnd((new Date()).setHours(0, 0, 0, 999))
        setMaxEnd((new Date()).setHours(23, 59, 59, 999))
      }
    }else if(type === 'Recurring'){
      const formattedDate1 = moment(date).format('DD-MM-YYYY');
      const formattedDate2 = moment(new Date(startDate)).format('DD-MM-YYYY');
      // Compare the dates
      if(formattedDate1 === formattedDate2) {
        if(recurringId === 7){
          const addedDate = moment(startTime).add(recurrenceFrequency, 'minutes').format('DD-MM-YYYY');
          const convertDate = moment(date).format('DD-MM-YYYY');
          if(addedDate === convertDate){
            setMinEnd(new Date(startTime).setMinutes(new Date(startTime).getMinutes() + 5))
            setMaxEnd(new Date(startTime).setMinutes(new Date(startTime).getMinutes() + ((recurrenceFrequency && recurrenceFrequency != null) ? recurrenceFrequency : 0)))
          }else{
            setMinEnd(new Date(startTime).setMinutes(new Date(startTime).getMinutes()))
            setMaxEnd((new Date()).setHours(23, 59, 59, 999))
          }
        }else if(recurringId === 8){
          const addedDate = moment(startTime).add(recurrenceFrequency, 'hours').format('DD-MM-YYYY');
          const convertDate = moment(date).format('DD-MM-YYYY');
          if(addedDate === convertDate){
            setMinEnd(new Date(startTime).setMinutes(new Date(startTime).getMinutes() + 5))
            setMaxEnd(new Date(startTime).setHours(new Date(startTime).getHours() + ((recurrenceFrequency && recurrenceFrequency != null) ? recurrenceFrequency : 0)))
          }else{
            setMinEnd(new Date(startTime).setMinutes(new Date(startTime).getMinutes()))
            setMaxEnd((new Date()).setHours(23, 59, 59, 999))
          }
        }else{
          setMinEnd(new Date(startTime).setMinutes(new Date(startTime).getMinutes()))
          setMaxEnd((new Date()).setHours(23, 59, 59, 999))
        }
      }else{
        if(recurringId === 1){
          if(moment(date).format('DD-MM-YYYY') === moment(startDate).add((recurrenceFrequency), 'days').format('DD-MM-YYYY')){
            setMinEnd((new Date()).setHours(0, 0, 0, 999))
            setMaxEnd(new Date(startTime).setMinutes(new Date(startTime).getMinutes()))
          }else{
            setMinEnd((new Date()).setHours(0, 0, 0, 999))
            setMaxEnd((new Date()).setHours(23, 59, 59, 999))
          }
        }else if(recurringId === 2){
          if(moment(date).format('DD-MM-YYYY') === moment(startDate).add((recurrenceFrequency * 7) , 'days').format('DD-MM-YYYY')){
            setMinEnd((new Date()).setHours(0, 0, 0, 999))
            setMaxEnd(new Date(startTime).setMinutes(new Date(startTime).getMinutes()))
          }else{
            setMinEnd((new Date()).setHours(0, 0, 0, 999))
            setMaxEnd((new Date()).setHours(23, 59, 59, 999))
          }
        }else if(recurringId === 7){
          if(moment(date).format('DD-MM-YYYY') === (moment(startDate).hours(moment(startTime).hours()).minutes(moment(startTime).minutes()).seconds(moment(startTime).seconds())).clone().add(((recurrenceFrequency && recurrenceFrequency != null) ? recurrenceFrequency : 0), 'minutes').format('DD-MM-YYYY')){
            const addedDate = moment(startTime).add(recurrenceFrequency, 'minutes');
            const currentDate = moment(date);
            const diff = moment.duration(addedDate.diff(currentDate));
            const exceededHours = diff.hours();
            const exceededMinutes = diff.minutes();
            setMinEnd((new Date()).setHours(0, 0, 0, 999))
            setMaxEnd((new Date()).setHours(exceededHours, exceededMinutes, 0, 999))
          }else{
            setMinEnd((new Date()).setHours(0, 0, 0, 999))
            setMaxEnd((new Date()).setHours(23, 59, 59, 999))
          }
        }else if(recurringId === 8){
          if(moment(date).format('DD-MM-YYYY') === (moment(startDate).hours(moment(startTime).hours()).minutes(moment(startTime).minutes()).seconds(moment(startTime).seconds())).clone().add(((recurrenceFrequency && recurrenceFrequency != null) ? recurrenceFrequency : 0), 'hours').format('DD-MM-YYYY')){
            const addedDate = moment(startTime).add(recurrenceFrequency, 'hours');
            const currentDate = moment(date);
            const diff = moment.duration(addedDate.diff(currentDate));
            const exceededHours = diff.hours();
            const exceededMinutes = diff.minutes();
            setMinEnd((new Date()).setHours(0, 0, 0, 999))
            setMaxEnd((new Date()).setHours(exceededHours, exceededMinutes, 0, 999))
          }else{
            setMinEnd((new Date()).setHours(0, 0, 0, 999))
            setMaxEnd((new Date()).setHours(23, 59, 59, 999))
          }
        }
      }
    }else{
      setMinEnd((new Date()).setHours(0, 0, 0, 999))
      setMaxEnd((new Date()).setHours(23, 59, 59, 999))
    }
  }

  useEffect(() => {
    showErrorMessage();
    const interval = setInterval(showErrorMessage, 5000); 
    return () => clearInterval(interval);
  }, [startDate, startTime])

  const [showError, setShowError] = useState(false)
  const showErrorMessage = () => {
    console.log(recurrenceFrequency)
    if(!scheduleType){
      if(startDate != '' && startTime != ''){
        const parsedDate = moment(startDate);
        const parsedTime = moment(startTime);
        const combinedDateTime = moment(parsedDate.format('YYYY-MM-DD') + ' ' + parsedTime.format('HH:mm:ss'))
        const formattedDateTime = combinedDateTime.format('YYYY-MM-DD HH:mm:ss');
        const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss')
        if(!(moment(formattedDateTime).isSameOrAfter(currentDateTime, 'minute'))){
          setStartDate('')
          setStartTime('')
          setEndDate('')
          setEndTime('')
          setRecurring('')
          setRecurringId(null)
          setRecurrenceCount(null)
          setRecurrenceFrequecy(null)
          setShowError(true);
        }else{
          setShowError(false);
        }
      }
    }else{
      setShowError(false)
    }
  }

  return (
        <div className={styles.scheduleModal}>
          <div className={styles.scheduleContainer}>
            <div className={styles.modalHeader}>
              <div className={styles.modalHeading}>
                <div className={styles.block}></div><span>{(liveOpsStatus == 'yet to start' || liveOps?.length > 0) ? 'Reschedule' : 'Schedule'}</span>
                <div className={styles.timeZone}>
                  <TimezoneDropdown usedFor='user' currency={<Internet size="16"/>} placeholder={"Preferred Time Zone"} setValue={setSelectedZone} setId={setSelectedZoneID} value={selectedZone} options={Timezones}/>
                </div>
              </div>
            </div>

            <div className={styles.modalBody}>
              <div className={styles.modalContent}>

                <div className={styles.group}>
                  {type === 'Leaderboard' &&
                    <div className={styles.fieldRadio}>
                      <div className={styles.radioLabel}>
                        <span>Schedule Type</span>
                      </div>
                        <div className={styles.variants}>
                          <Radio className={styles.radio} name="ScheduleType" value={scheduleType} onChange={() => {setScheduleType(true); setIsRecurring(false);setRecurring(''); setRecurringId(null);setStartDate(''); setEndDate(''); setStartTime(''); setEndTime('')}} content="All Time" />
                          <Radio className={styles.radio} name="ScheduleType" value={!scheduleType} onChange={() => {setScheduleType(false); setIsRecurring(false); setRecurring(''); setRecurringId(null); setStartDate(''); setEndDate(''); setStartTime(''); setEndTime('')}} content="Time Bound" />
                        </div>
                    </div>
                  }

                  <div className={styles.fieldRadio}>
                    <div className={styles.radioLabel}>
                      <span>{scheduleType ? 'Set Start Date/Time' : 'Is Recurring'}</span>
                    </div>
                    <div className={styles.variants}>
                      <Radio className={styles.radio} name="recurring" value={isRecurring} onChange={() => {setIsRecurring(true);setStartDate(''); setEndDate(''); setStartTime(''); setEndTime('')}} content="Yes" />
                      <Radio className={styles.radio} name="recurring" value={!isRecurring} onChange={() => {setIsRecurring(false);setStartDate(''); setEndDate(''); setStartTime(''); setEndTime('')}} content="No" />
                    </div>
                  </div>

                </div>

                {scheduleType && isRecurring &&
                  <>
                    <div className={styles.group}>
                      <div className={styles.field}>
                        <span className={styles.label}>Set Start Date<span><Tooltips className={styles.tooltip} title={`Select the start date. This is the date when your schedule will begin.`} icon="info"/></span></span>
                      </div>
                      <div className={styles.field}>
                        <span className={styles.label}>Set Start Time<span><Tooltips className={styles.tooltip} title={`Set the start time on the chosen date. This is the exact time when your schedule starts.`} icon="info"/></span></span>
                      </div>
                    </div>

                    <div className={styles.group}>
                      
                      <Item className={styles.field} category="Start Date" icon="calendar" value={startDate && format(startDate, "MMMM dd, yyyy")} visible={visibleStartDate} setVisible={setVisibleStartDate}>
                        <div className={styles.date}>
                          <DatePicker selected={startDate} onChange={(date) => {setStartDate(date); setVisibleStartDate(false)}} dateFormatCalendar={"MMMM yyyy"} inline />
                        </div>
                      </Item>

                      <Item className={styles.field} category="Start Time" icon="clock" value={startTime && format(startTime, "h:mm aa")} visible={visibleStartTime} setVisible={setVisibleStartTime}>
                        <div className={styles.date}>
                          <DatePicker selected={startTime} onChange={(time) => {setStartTime(time); setVisibleStartTime(false)}} showTimeSelect showTimeSelectOnly timeIntervals={5} timeCaption={false} dateFormat="h:mm aa" inline />
                        </div>
                      </Item>
                    </div>
                  </>
                }

                {!scheduleType && isRecurring && 
                  <>
                    <div className={styles.group}>
                      <div className={styles.field}>
                        <span className={styles.label}>Start Date and Time <span><Tooltips className={styles.tooltip} title={`Select the start date & time. This is the date & time when your schedule will begin.`} icon="info"/></span></span>
                      </div>
                    </div>

                    <div className={styles.group}>
                      
                      <Item className={styles.field} category="Start Date" icon="calendar" value={startDate && format(startDate, "MMMM dd, yyyy")} visible={visibleStartDate} setVisible={setVisibleStartDate}>
                        <div className={styles.date}>
                          <DatePicker minDate={new Date()} selected={startDate} onChange={(date) => {onStartDate(date)}} dateFormatCalendar={"MMMM yyyy"} inline />
                        </div>
                      </Item>

                      <Item className={styles.field} category="Start Time" icon="clock" value={startTime && format(startTime, "h:mm aa")} visible={visibleStartTime} setVisible={setVisibleStartTime}>
                        <div className={styles.date}>
                          <DatePicker minTime={minStart} maxTime={maxStart} selected={startTime} onChange={(time) => {onStartTime(time)}} showTimeSelect showTimeSelectOnly timeIntervals={5} timeCaption={false} dateFormat="h:mm aa" inline />
                        </div>
                      </Item>

                    </div>

                    <div className={cn(styles.label, {[styles.disabled] : (startDate == '' || startTime == '')})}>Repeat Every</div>

                    <div className={cn(styles.group, {[styles.disabled] : (startDate == '' || startTime == '')})}>
                      <TextInput position={`bottom`} errorMessage={recurring === 'Minutes' && recurrenceFrequency < 5 && 'Interval Length must be greater than or equal to 5 minutes'} disabled={(startDate == '' || startTime == '') ? true : false} tooltip={`Enter the number indicating how frequently the schedule should repeat. For example, if you enter '2' and select 'Days' in the interval dropdown, it means the schedule will repeat every 2 days.`} type='number' min='0' className={styles.field} label='Interval Length' value={recurrenceFrequency !== null ? recurrenceFrequency : ''} onChange={(e) => {setRecurrenceFrequecy(parseInt(e.target.value));setEndDate(''); setEndTime('')}} />
                      <div className={styles.field}>
                        <Dropdown disabled={(startDate == '' || startTime == '') ? true : false}  tooltip={`Choose the time unit for the recurrence. This sets whether the frequency you've entered is counted in minutes, hours, days, or weeks.`} label='Interval Unit' placeholder='Select Interval Unit' options={recurringOptions} value={recurring} setValue={setRecurring} setId={setRecurringId}/>
                      </div>
                    </div>
                    <div className={cn(styles.label, {[styles.disabled] : (startDate == '' || startTime == '')})}>End After</div>
                    <div className={cn(styles.group, {[styles.disabled] : (startDate == '' || startTime == '')})}>
                      <TextInput disabled={(startDate == '' || startTime == '') ? true : false}  tooltip={`Specify how many times the schedule should occur in total. This number determines when the repetition will stop.`} type='number' min='0' className={styles.field} label='Occurrences' value={recurrenceCount !== null ? recurrenceCount : ''} onChange={(e) => {setRecurrenceCount(parseInt(e.target.value))}}/>
                    </div>


                    <div className={cn(styles.group, {[styles.disabled] : (recurringId == null || recurrenceFrequency == null || recurrenceCount == null || (startDate == '' || startTime == ''))})}>
                      <div className={styles.field}>
                        <span className={styles.label}>First Occurence End Date and Time<span><Tooltips className={styles.tooltip} title={`Select the end date & time for the first instance. This is the date & time when your 1st instance will end.`} icon="info"/></span></span>
                      </div>
                    </div>

                    <div className={styles.group}>
                      <Item disabled={(recurringId == null || recurrenceFrequency == null || recurrenceCount == null || (startDate == '' || startTime == '')) ? true : false} className={styles.field} category="End Date" icon="calendar" value={endDate && format(endDate, "MMMM dd, yyyy")} visible={visibleEndDate} setVisible={setVisibleEndDate}>
                        <div className={styles.date}>
                          <DatePicker minDate={(startDate && recurringId != null) ? new Date(moment(startDate).format('YYYY-MM-DD')) : new Date()} maxDate={startDate && recurringId == 1 ? new Date(moment(startDate).clone().add((recurrenceFrequency), 'days').format('YYYY-MM-DD')) : startDate && recurringId == 2 ? new Date(moment(startDate).clone().add((recurrenceFrequency * 7) , 'days').format('YYYY-MM-DD')) : startDate && recurringId == 3 ? new Date(moment(startDate).clone().add(30, 'days').format('YYYY-MM-DD')) : startDate && recurringId == 4 ? new Date(moment(startDate).clone().add(365, 'days').format('YYYY-MM-DD')) : recurringId == 7 ? new Date((moment(startDate).hours(moment(startTime).hours()).minutes(moment(startTime).minutes()).seconds(moment(startTime).seconds())).clone().add(((recurrenceFrequency && recurrenceFrequency != null) ? recurrenceFrequency : 0), 'minutes').format('YYYY-MM-DD')) : recurringId == 8 ? new Date((moment(startDate).hours(moment(startTime).hours()).minutes(moment(startTime).minutes()).seconds(moment(startTime).seconds())).clone().add(((recurrenceFrequency && recurrenceFrequency != null) ? recurrenceFrequency : 0), 'hours').format('YYYY-MM-DD')) : ''} selected={endDate} onChange={(date) => {onEndDate(date, 'Recurring')}} dateFormatCalendar={"MMMM yyyy"} inline />
                        </div>
                      </Item>
                      <Item disabled={(recurringId == null || recurrenceFrequency == null || recurrenceCount == null || (startDate == '' || startTime == '')) ? true : false} className={styles.field} category="End Time" icon="clock" value={endTime && format(endTime, "h:mm aa")} visible={visibleEndTime} setVisible={setVisibleEndTime}>
                        <div className={styles.date}>
                          <DatePicker minTime={minEnd} maxTime={maxEnd} selected={endTime} onChange={(time) => {setEndTime(time); setVisibleEndTime(false)}} showTimeSelect showTimeSelectOnly timeIntervals={5} timeCaption={false} dateFormat="h:mm aa" inline />
                        </div>
                      </Item>

                    </div>
                  </>
                }

                {!scheduleType && !isRecurring && 
                  <>
                    <div className={styles.group}>
                      <div className={styles.field}>
                        <span className={styles.label}>Set Start Date and Start Time<span><Tooltips className={styles.tooltip} title={`Select the start date & time. This is the date & time when your schedule will begin.`} icon="info"/></span></span>
                      </div>
                      <div className={styles.field}>
                        <span className={styles.label}>Set End Date and End Time<span><Tooltips className={styles.tooltip} title={`Select the end date & time. This is the date & time when your schedule will end.`} icon="info"/></span></span>
                      </div>
                    </div>

                    <div className={styles.group}>
                      
                      <Item className={styles.field} category="Start Date" icon="calendar" value={startDate && format(startDate, "MMMM dd, yyyy")} visible={visibleStartDate} setVisible={setVisibleStartDate}>
                        <div className={styles.date}>
                          <DatePicker minDate={new Date()} selected={startDate} onChange={(date) => {onStartDate(date)}} dateFormatCalendar={"MMMM yyyy"} inline />
                        </div>
                      </Item>

                      <Item disabled={(startDate == '' || startDate == null|| startTime == null || startTime == '') ? true : false} className={styles.field} category="End Date" icon="calendar" value={endDate && format(endDate, "MMMM dd, yyyy")} visible={visibleEndDate} setVisible={setVisibleEndDate}>
                        <div className={styles.date}>
                          <DatePicker minDate={new Date(startDate)} selected={endDate} onChange={(date) => {onEndDate(date, 'Normal')}} dateFormatCalendar={"MMMM yyyy"} inline />
                        </div>
                      </Item>
                    </div>

                    <div className={styles.group}>

                      <Item disabled={(startDate == '' || startDate == null) ? true : false} className={styles.field} category="Start Time" icon="clock" value={startTime && format(startTime, "h:mm aa")} visible={visibleStartTime} setVisible={setVisibleStartTime}>
                        <div className={styles.date}>
                          <DatePicker minTime={minStart} maxTime={maxStart} selected={startTime} onChange={(time) => {onStartTime(time)}} showTimeSelect showTimeSelectOnly timeIntervals={5} timeCaption={false} dateFormat="h:mm aa" inline />
                        </div>
                      </Item>

                      <Item disabled={(startDate == '' || startDate == null || startTime == null || startTime == '') ? true : false} className={styles.field} category="End Time" icon="clock" value={endTime && format(endTime, "h:mm aa")} visible={visibleEndTime} setVisible={setVisibleEndTime}>
                        <div className={styles.date}>
                          <DatePicker minTime={minEnd} maxTime={maxEnd} selected={endTime} onChange={(time) => {setEndTime(time); setVisibleEndTime(false)}} showTimeSelect showTimeSelectOnly timeIntervals={5} timeCaption={false} dateFormat="h:mm aa" inline />
                        </div>
                      </Item>
                    </div>
                  </>
                }

              </div>


              {!showError && scheduleType && isRecurring && startDate != '' && startTime != '' &&
                <div className={styles.notice}>
                  {name} starts on {moment(startDate).format('MMM DD, YYYY')} at {moment(startTime).format('h.mm A')}.
                </div>
              }

              {!showError && !scheduleType && !isRecurring && startDate != '' && startTime != '' && endDate != '' && endTime != '' &&
                <div className={styles.notice}>
                  {name} starts on {moment(startDate).format('MMM DD, YYYY')} at {moment(startTime).format('h.mm A')} and ends on {moment(endDate).format('MMM DD, YYYY')} at {moment(endTime).format('h.mm A')}.
                </div>
              }

              {!showError && !scheduleType && isRecurring && startDate != '' && startTime != '' && endDate != '' && endTime != '' &&
                <div className={styles.notice}>
                  {name} starts on {moment(startDate).format('MMM DD, YYYY')} at {moment(startTime).format('h.mm A')} and ends on {moment(endDate).format('MMM DD, YYYY')} at {moment(endTime).format('h.mm A')}, repeating every {recurrenceFrequency} {recurring == 'Daily' ? 'Day' : recurring == 'Weekly' ? 'Week' : recurring} for total of {recurrenceCount} occurrences.
                </div>
              }

              {showError && <><p className={styles.error}>Selected Start date and time exceeded current date and time. Please select new start date and time to continue.</p></>}
            </div>

            <div className={styles.modalFooter}>
              <button className={cn("button-small", styles.button)} onClick={schedule}>{(liveOpsStatus == 'yet to start' || liveOps?.length > 0) ? 'Reschedule' : 'Schedule'}</button>
            </div>

          </div>
        </div>
  )
}

export default ScheduleModal