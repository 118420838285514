import React, {useEffect, useState, useRef, useContext} from 'react'
import cn from 'classnames'
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Toast from '../../../../../components/Toast';
import { WithContext as ReactTags } from "react-tag-input";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../../../../../components/Card'
import TextInput from '../../../../../components/TextInput';
import TextArea from '../../../../../components/TextArea';
import Tooltip from '../../../../../components/Tooltip';
import Control from './Control';
import GenreDropdown from '../../../../../components/MultiselectDropDown/GenreDropdown'
import PlatformDropdown from '../../../../../components/MultiselectDropDown/PlatformDropdown'
import LocationDropdown from '../../../../../components/MultiselectDropDown/LocationDropdown'
import Switch from '../../../../../components/Switch';
import { BASE_URL, GET_APPS } from '../../../../../resources/APIEndpoints';
import { editAppAction } from '../../../../../redux/action/apps/appsAction';
import Icon from '../../../../../components/Icon';
import styles from './gamesInformation.module.sass'
import Radio from "../../../../../components/Radio";
import Game from '../../../../../media/images/placeholders/Game.png'
import FileImages from '../../../../../components/File/Images'
import FileVideos from '../../../../../components/File/Videos';
import { ReactComponent as AddButtonIcon } from '../../../../../media/images/icons/add_button.svg'
import { ReactComponent as UploadFile } from '../../../../../media/images/icons/upload_file.svg';
import {ReactComponent as Remove} from '../../../../../media/images/icons/remove.svg'
import {ReactComponent as AddMetaIcon} from '../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as ImagePlaceholder} from '../../../../../media/images/icons/image_placeholder.svg'
import {ReactComponent as VideoPlaceholder} from '../../../../../media/images/icons/video_placeholder.svg'
import { createGameAction } from '../../../../../redux/action/builds/games/gamesAction';
import TagInputWithDropdown from "../../../../../components/TagInputWithDropdown";
import FileUploadSidebar from '../../../../../components/FileUploadSidebar';
import { getAllMediaAction, addMediaAction} from '../../../../../redux/action/AppSettings/Media/mediaAction';
import { routes } from '../../../../../routes/routes';
import TooltipTitle from '../../../../../Tooltip/TooltipTitle';
import JSONMetaDataValueInput from '../../../../../components/MetaInput'
import app,{increaseUnreadCountForAllUsers} from "../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";
import GlobalStateContext from '../../../../../components/Context/GlobalStates/GlobalStateContext';
import customAxios from '../../../../../utils/interceptor.js';

const gernePlaceHolder = ["Select Genre"]

const GameInfo = ({ className,isChanged, setIsChanged, onClose}) => {

    const { appid } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const logoRef = useRef()
    const screenshotRef = useRef()
    const videoRef = useRef()
    const tagRef = useRef()

    const globalStates = useContext(GlobalStateContext)
    const placeholder = Game
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const [gameIcon, setGameIcon] = useState(null)
    const [gameId, setGameId] = useState("")
    const [gameName, setGameName] = useState("");
    const [gameGerne, setGameGerne] = useState([])
    const [gameDescription, setGameDescription] = useState()
    const [platform, setPlatform] = useState([])
    const [locations, setLocations] = useState([])
    const [gameHowTo, setGameHowTo] = useState("")
    const [screenshot, setScreenshot] = useState([])
    const [video, setVideo] = useState([])
    const [isScreenShot, setIsScreenShot] = useState(false);
    const [isVideo, setIsVideo] = useState(false)
    const [tags, setTags] = useState([]);
    const [orientation, setorientation] = useState(true);
    const [genreOption, setGenreOption] = useState([])
    const [platformOption, setPlatformOption] = useState([])
    const [locationOption, setLocationOption] = useState([])
    const [catIndex,setCatIndex]= useState(null)

    // const extractImageFromCdn = async() => {
    //     const token = localStorage.getItem('token')
    //     // const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    //     const headers = {headers : {"Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}, responseType: 'blob'}
    //     let res = await axios.get(`https://cdn.staging.specterapp.xyz/org-cfcc8521-31a7-488a-b49f-0aad1226d254/app-b330ed4c-1188-4774-8ea5-8bf66b8341e1/icons/badge_blue.png`, headers)
    //     console.log(res)
    // }

    const extractImageFromCdn = async() => {
        try {
            const apiKey =  (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)
            const imageUrl = 'https://cdn.staging.specterapp.xyz/org-cfcc8521-31a7-488a-b49f-0aad1226d254/app-b330ed4c-1188-4774-8ea5-8bf66b8341e1/icons/badge_green.png';
            const response = await axios.get(imageUrl, {
              headers: {
                'Api-Key': apiKey, // Custom header for authorization
              },
              responseType: 'blob', // Ensure the response is treated as a binary file
            });
            // Create a URL for the image blob
            const imageBlobUrl = URL.createObjectURL(response.data);
            // Example: Set the image blob URL to an <img> tag
            const imgElement = document.getElementById('image-display');
            if (imgElement) {
              imgElement.src = imageBlobUrl;
            }
          } catch (error) {
            console.error('Error fetching image:', error);
          }
    }

    useEffect(() => {
        extractImageFromCdn()
    }, [])

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setGameIcon(res.data.getUrl)
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        });
    }

        // To Convert Base64 to Binary
        function convertDataURIToBinary(dataURI) {
            var BASE64_MARKER = ';base64,';
            var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));
              
            for(var i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            return array;
        }
        
        // To Convert file into base64 format
        const convertbase64 = (file) => {
            return new Promise((resolve, err)=>{
            const fileReader = new FileReader();
            fileReader.onload = (eve) =>{
                resolve(fileReader.result)
            }
            fileReader.readAsDataURL(file)
            })
        }

    const Cancel = () => {
        setGameIcon(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        logoRef.current.value = ''
    }

    const onGameName = (e) => {
        if(e.target.value == ''){
            setGameName("")
        }else{
            errors.nameError = ''
            setGameName(e.target.value)
        }
    }

    const onGameId = (e) => {
        if(e.target.value == ''){
            //errors.idError = 'Game ID is required'
            setGameId("")
        }else{
            errors.idError = ''
            setGameId(e.target.value)
        }
    }

    const onAppGerne = (e) => {
        setGameGerne(e.target.value)
    }

    const onGameDescription = (e) => {
        setGameDescription(e.target.value)        
    }

    const onSelectedCategoryLogo = async(e,i,x) =>{
        const tempCategory=[...platform]
        tempCategory[i].assetBundleUrl=e
        tempCategory[i]['assetName'] = x.name
        setPlatform(tempCategory)
    }

    const progressToastId = useRef(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadingMedia, setUploadingMedia] = useState([]);



    // A) Add item
    const addUploadingItem = (file) => {
        const newItem = { fileName: file.name, progress: 0 };
        setUploadingMedia((prev) => [...prev, newItem]);
        return newItem;
    };
    
    // B) Update item
    const updateUploadingItem = (fileName, progress) => {
        setUploadingMedia((prev) =>
        prev.map((item) =>
            item.fileName === fileName ? { ...item, progress } : item
        )
        );
    };
    
    // C) Remove item
    const removeUploadingItem = (fileName) => {
        setUploadingMedia((prev) =>
        prev.filter((item) => item.fileName !== fileName)
        );
    };


    // Show a toast if one doesn’t already exist
  const startProgressToast = () => {
    // if (!progressToastId.current) {
    //   progressToastId.current = toast(
    //     <Toast type="Info" messages="Starting upload..." />,
    //     {
    //       autoClose: false,
    //       closeOnClick: false,
    //       draggable: false,
    //       progress: 0,
    //     }
    //   );
    // }
  };


  const handleUploadProgress = (progressEvent,name) => {
    // if (!progressToastId.current) return; // no toast to update yet

    const { loaded, total } = progressEvent;
    const percent = Math.floor((loaded * 100) / total);
    updateUploadingItem(name, percent);

    // const fraction = percent / 100; // toastify expects a fraction (0-1)

    // toast.update(progressToastId.current, {
    //   progress: fraction,
    //   render: (
    //     <Toast
    //       type="Info"
    //       messages={`Uploading file... ${percent}%`}
    //     />
    //   ),
    // });
  };

  const dismissProgressToast = () => {
    // if (progressToastId.current) {
    //   toast.dismiss(progressToastId.current);
    //   progressToastId.current = null;
    // }
  };
  

    const uploadCategoryLogo = async(e,i,getMedia) =>{
        var file1 =null
        startProgressToast();
        file1 = e.target.files[0];
        addUploadingItem(file1);
        const file_binary1 = await convertbase64(file1)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file1.type}&projectId=${appid}&category=${`bundles`}&fileName=${file1.name}`)

        const tempCategory=[...platform]
        tempCategory[i].assetBundleUrl=res.data.getUrl;
        tempCategory[i]['assetName'] = file1.name
        e.target.value=null
        setPlatform(tempCategory);
        var config1 = {
            headers : {'Content-Type' : `${file1.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary1),
            onUploadProgress: ((z)=> handleUploadProgress(z, file1.name)),
        }
        axios(config1)
        .then(function (response) {
            let setSidebar=true
            if(setSidebar){
                let uploadLogo = {
                    projectId : appid,
                    contents : [{
                        contentType : file1.type,
                        fileName : file1.name,
                        category : "bundles",
                        url : res.data.getUrl,
                        fileSize : file1.size/1024
                    }]
                }
                dispatch(addMediaAction(uploadLogo, null, null, appid,'bundles',false))
                // setSidebar(false)
            }
            setUploadProgress(0);
            removeUploadingItem(file1.name);
            getMedia()
            setTimeout(()=>{
                dismissProgressToast();
            },[1500])
            // toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        });
    }


    const uploadFile = async(e) => {
        try{
            var file = e.target.files[0];   
            const file_binary = await convertbase64(file)
            const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=octet-stream`)
            var config = {
                headers : {'Content-Type' : `${file.type}`},
                method: 'put',
                url: res.data.postUrl,
                data :convertDataURIToBinary(file_binary),
            }
            let response = await axios(config)
            if(response){
                toast.success('File Uploaded Successfully')
                if(response.status == 200){
                    return res.data.getUrl
                }
            }
            return false
        }catch(err){
            return false
        }
    }

    const removeFile = (index) => {
        let platformList = [...platform]
        platformList[index].assetName = ''
        platformList[index].assetBundleUrl = ''
        setPlatform(platformList)
    }

    const onChangePlatform = async(e, index, type, id) => {
        let platformList = [...platform]
        if(type == 'file'){
            let res = await uploadFile(e)
            platformList[index][e.target.name] = res
            platformList[index]['assetName'] = e.target.files[0].name
            setPlatform(platformList)
        }else{
            if(/^\d*\.?\d\d*\.?\d*$/.test(e.target.value)){
                platformList[index][e.target.name] = e.target.value
                setPlatform(platformList)
            }if(e.target.value == ''){
                platformList[index][e.target.name] = ''
                setPlatform(platformList)
            }
        }
    }

    const [visibleCategorySidebar, setVisibleCategorySidebar] = useState(false)



    const onRemove = (selectedList, removedItem) => {
        setPlatform(oldValues => {return oldValues.filter(platform => platform.name !== removedItem.name)})
    }

    const onGameHowTo = (e) => {
        setGameHowTo(e.target.value)
    }

    const removeScreenShots = (index) => {
        let screenshotList = [...screenshot]
        let screenshotFields = screenshotList.filter((e,i,c)=>{
            return i != index
        })
        screenshotRef.current.value = ''
        setScreenshot(screenshotFields)
    }

    const removeVideos = (index) => {
        let videoList = [...video]
        let videoFields = videoList.filter((e,i,c)=>{
            return i != index
        })
        videoRef.current.value = ''
        setVideo(videoFields)
    }

    const onTagInput = () => {
        if(document.getElementById('tagsInput')){
            document.getElementById('tagsInput').focus()
        }
    }

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleInputChange = (e) => {

    }

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]);
        }else{
            toast.warning(<Toast type='Warning' messages='Only 10 tags are allowed'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        }
    };
  
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }


    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const getOptionalData = async() => {
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await axios.get(`${BASE_URL}/master-data`, headers)
        setLocationOption(res.data.data.countryMaster)
        setLocations(res.data.data.countryMaster)

        let Platforms = []
        res.data.data.gamePlatforms && res.data.data.gamePlatforms.map((e, i) =>{
            Platforms.push({platformId : e.id, name : (e.platformName !== 'ios' ? e.platformName.charAt(0).toUpperCase() + e.platformName.slice(1) : e.platformName === 'ios' ? 'iOS' : '-'), platform: (e.platformName !== 'ios' ? e.platformName.charAt(0).toUpperCase() + e.platformName.slice(1) : e.platformName === 'ios' ? 'iOS' : '-'), assetName : '', assetBundleUrl : '', assetBundleVersion : '', minimumAppVersion : ''})
        })
        setPlatformOption(Platforms)

        let genre = []
        res.data.data.gameGenerMaster && res.data.data.gameGenerMaster.forEach((e,i) => {
            genre.push({id : e.id, name : (e.genreName[0].toUpperCase() + e.genreName.slice(1))})
        })
        setGenreOption(genre)
    }

    useEffect(() => {
        getOptionalData()
    }, [])

    const [errors, setErrors] = useState({nameError : '', idError : '', gerneError : '', platformError : '', androidABError : '', androidABVError : '', androidMAVError : ''})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if (data.name == '') {
            error.nameError = 'Display Name is required';
            isValid = false;
            const element = document.getElementById('gameName');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if (data.gameId == '') {
            error.idError = 'Game ID is required';
            isValid = false;
            if(data.name != '') {
                const element = document.getElementById('gameId');
                const offset = 140;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        if (data.gameGenre.gameGenerId.length == 0) {
            error.gerneError = 'Game Genre is required';
            isValid = false;
            if(data.name != '' && data.gameId != ''){
                const element = document.getElementById('gameGenre');
                const offset = 100;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        if (data.gamePlatforms.length == 0) {
            error.platformError = 'Game Platform is required';
            isValid = false;
            if(data.name != '' && data.gameId != '' && data.gameGenre.gameGenerId.length > 0){
                const element = document.getElementById('gamePlatform');
                const offset = 100;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }
    const cancel = () => {
        navigate(`/games/${appid}`)
        setIsChanged(false)
    }
    
    
    // const sendNotification = async () => {
    //     const serverKey = process.env.SERVER_KEY; // Replace with your Firebase Server key
    //     const payload = {
    //       to: deviceToken,
    //       notification: {
    //         title: "Hello from React!",
    //         body: "This is a test notification.",
    //       },
    //     };
      
    //     try {
    //       const response = await fetch("https://fcm.googleapis.com/fcm/send", {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: `key=${serverKey}`,
    //         },
    //         body: JSON.stringify(payload),
    //       });
      
    //       if (!response.ok) {
    //         throw new Error("Failed to send notification");
    //       }
      
    //       const data = await response.json();
    //       console.log("Notification sent successfully:", data);
    //     } catch (error) {
    //       console.error("Error sending notification:", error);
    //     }
    //   };
      

    const db = getFirestore(app);

    const saveGame = async() => {

        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let finalTag = []
        tags && tags.forEach((e)=>{finalTag.push(e.text)})

        let countryId = []
        locations && locations.filter((e,i) => countryId.push(e.id))

        let gameGerneId = []
        gameGerne && gameGerne.filter((e,i) => gameGerneId.push(e.id))
        let game = {
            projectId: appid,
            isDefault : false,
            gameId: gameId,
            name: gameName,
            isGameScreenOrientationLandscape:orientation,
            description: gameDescription,
            howTo : gameHowTo,
            logo: gameIcon,
            ...(screenshot.length > 0 && {screenShotUrl : screenshot}),
            ...(video.length > 0 && {previewVideoUrl : video}),
            gameGenre: {gameGenerId: gameGerneId},
            gamePlatforms: platform && platform.map((obj) => {return { name: obj.name, assetBundleUrl: (obj.assetBundleUrl==""?null:obj.assetBundleUrl),assetName: (obj.assetName==""?null:obj.assetName), assetBundleVersion: (obj.assetBundleVersion==""?null:obj.assetBundleVersion), minimumGameVersion: (obj.minimumGameVersion==undefined?null:obj.minimumGameVersion), gamePlatformMasterId: obj.platformId}}),
            gameGeo: {countryId: countryId},
            meta : finalMetaData,
            tags : finalTag
        } 
        if (validation(game)) {
            try {
                // Await the response from createGameAction
                const res = await dispatch(createGameAction(game, navigate, appid, globalStates.setShowOverlay));
                // Access the response data (e.g., game ID)
                if (res) {
                    const gameIdFromResponse = res?.data?.data?.id;
    
                    setIsChanged(false);
                    const user = JSON.parse(localStorage.getItem('user'))
                    const memberID = user?.id
                    const memberName = user?.name
                    const orgId = (localStorage.getItem('orgId'))
    
                    // Log activity in Firestore
                    const activityLog = {
                        timestamp: new Date().toISOString(),
                        action_type: "CREATE",
                        app_id:appid,
                        resource: "GAME",
                        resource_id: gameIdFromResponse, // Use the ID from response
                        description: `Game '${gameName}' created successfully`,
                        quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
operation_status: "SUCCESS",
                        activity_feed: true,
                        priority: "LOW",
                        performed_by_id: memberID,
                        organization_id: orgId,
                        performed_by:memberName
                    };
                    await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
                }
            } catch (error) {

            }
        }
    }
    
    const [visibleHelp, setVisibleHelp] = useState(false)
    const [visibleVideo, setVisibleVideo] = useState(false)
    const [visibleImage, setVisibleImage] = useState(false)

    const [media, setMedia] = useState([])
    const [type, setType] = useState(0)

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : type
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })

    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }

    useEffect(() => {
        getMedia()
    }, [type])

    useEffect(() => {
        configureMedia()
    }, [files])

    const setchange = () => {
        if (
            (gameIcon==null||gameIcon==undefined)&&
            (gameName===''||gameName==="")&&
            (gameId===''||gameId==="")&&
            (gameGerne.length==0)&&
            (gameDescription===undefined||gameDescription==''||gameDescription=="")&&
            (platform.length==0)&&
            (locations.length==locationOption.length)&&
            (orientation==true)&&
            (isScreenShot==false)&&
            (isVideo==false)&&
            (screenshot.length==0)&&
            (video.length==0)&&
            (gameHowTo===undefined||gameHowTo==''||gameHowTo=="")&&
            (tags.length==0)&&
            (metaData.length==1 && metaData[0].key == "" && (metaData[0].value == "" || metaData[0].value == null))
        )
        {
            setIsChanged(false)
        }
        else 
        {
            setIsChanged(true)
        }
    }

    useEffect(()=>{
        setchange()
        
    },[gameIcon,gameId,gameName,gameGerne,gameDescription,platform,locations,gameHowTo,screenshot,video,isScreenShot,isScreenShot,isVideo,tags,orientation,metaData])

    function getFileNameFromUrl(url) {
        // Split the URL by '/' and take the last segment
        const segments = url.split('/');
        return segments[segments.length - 1];
      }

    return (
        <>
            <ToastContainer />
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-purple">
            <div className={styles.description}>
                    <div className={styles.preview}>
                        <div className={styles.previewImage}>
                            <img src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                        </div>
                        <div className={styles.previewInput}> 
                            <input type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={logoRef} onChange={(e)=>{uploadLogo(e)}}/>
                            <label className={styles.button} onClick={() => {setVisibleHelp(true); setType(0)}}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>
                        </div>
                        {gameIcon != null ?
                            <div className={styles.previewCancel}>
                                <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                            </div>
                        : ''}
                    </div>
                    <FileUploadSidebar media={media} setMedia={setMedia} types={type} id={`icon`} multiple={false} visible={visibleHelp} setVisible={setVisibleHelp} icon={gameIcon} setIcon={setGameIcon} setValue={setLogo} onClose={onClose} />
                    <div className={styles.group}>
                        <TextInput id='gameName' className={styles.field} tooltip={TooltipTitle.GameName} errorMessage={errors.nameError}  label="Display Name*" name="gameName" type="text" required onChange={(e)=>{onGameName(e)}}/>
                        <TextInput isId={true} value={gameId}  id='gameId' className={styles.field} tooltip={TooltipTitle.GameId} errorMessage={errors.idError}  label="Game ID*" name="gameID" type="text" required onChange={(e)=>{onGameId(e)}}/>
                    </div>
                <div className={styles.createItem}>   
                    <div className={styles.groupItem}>
                        <div className={styles.lockGroup}>
                            <GenreDropdown id='gameGenre' errorMessage={errors.gerneError} className={styles.selexctField} label="Select Genre*" tooltip={TooltipTitle.GameGenre} placeholder="Select Genre" options={genreOption} value={gameGerne} setValue={setGameGerne}/>
                        </div>                
                        <div className={styles.RGroup}>
                                <div className={styles.radioLabel}>
                                    <span>Screen Orientation</span>
                                </div>
                                <div className={styles.variants}>
                                    <Radio className={styles.radio} name="orientation" value={orientation} onChange={() => setorientation(true)} content="Landscape" />
                                    <Radio className={styles.radio} name="orientation" value={!orientation} onChange={() => setorientation(false)} content="Portrait" />
                                </div>
                        </div>
                    </div>
                </div>
                    <div className={styles.textGroup}>
                        <TextArea type='GAME_DESCRIPTION' value={gameDescription != null ? gameDescription : ''} tooltip={TooltipTitle.GameDescription} rows={5} label="Description" onChange={(e)=>{onGameDescription(e)}}/>
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Technical Details" classTitle="title-red">
                <div className={styles.cardDescription}>   
                    <div className={styles.appInformation}>
                        <div className={styles.appInformation}>
                            <PlatformDropdown tooltip={TooltipTitle.GamePlatform} id='gamePlatform' errorMessage={errors.platformError} label="Select Platform*" placeholder="Choose Game Platform" options={platformOption} value={platform} setValue={setPlatform} />
                        </div>
                    </div>
                    <FileUploadSidebar folderName='bundles' type={"store"} catIndex={catIndex} customUploadFunction={uploadCategoryLogo} customUploadingMedia={uploadingMedia} customSelectMediaFunction={onSelectedCategoryLogo} media={media} setMedia={setMedia} types='Images' id={`asset${'-'}`} multiple={false} visible={visibleCategorySidebar} setVisible={setVisibleCategorySidebar} onClose={onClose} />

                    <div className={styles.appInformation}>
                        {platform && platform.map((e,i)=>{
                            return(
                                <>
                                    <div key={e.name} className={styles.platformContainer}>
                                        <div className={styles.platformHeading}>
                                            <span>{e.name !== 'ios' ? e.name : "iOS"}</span>
                                        </div>
                                        <div className={styles.groupByThree}>
                                            <div className={styles.field}>
                                                <div className={styles.bundleLabel}><span>Asset Bundle</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.GameAssetBundle} icon="info"/></span></div>
                                                {(e.assetBundleUrl == '' ||e.assetBundleUrl == null)&&
                                                    <>
                                                        {/* <input type="file" id={`files-${i}`} className='file-input' name='assetBundleUrl' onChange={(evt)=>{onChangePlatform(evt, i, 'file', e.platformId)}}/>
                                                        <label for={`files-${i}`} className={styles.uploadBundleButton}><span className={styles.label}>Upload Bundle</span><span className={styles.icon}><UploadFile /></span></label> */}
                                                        <label for={`files-${i}`} className={styles.uploadBundleButton} onClick={() => {setCatIndex(i);setVisibleCategorySidebar(true)}}><span className={styles.label}>Upload Bundle</span><span className={styles.icon}><UploadFile /></span></label>  
                                                    </>
                                                }
                                                {e.assetBundleUrl != ''&&e.assetBundleUrl != null &&
                                                    <>
                                                        <label for={`files-${i}`} className={styles.uploadedFile}>
                                                            {/* <input type="file" id={`files-${i}`} name='assetBundleUrl' className='file-input' onChange={(evt)=>{onChangePlatform(evt, i, 'file', e.platformId)}}/> */}
                                                            <label for={`files-${i}`} className={styles.fileInput}><span className={styles.label} title={e.assetName} onClick={() => {setCatIndex(i);setVisibleCategorySidebar(true)}}>{e.assetName}</span></label>
                                                            <label className={styles.fileRemove}><span className={styles.icon} onClick={()=>{removeFile(i)}}><Icon className={styles.close} name='close-circle' size='24'/></span></label>
                                                        </label>
                                                    </>
                                                }
                                                </div>
                                                <TextInput tooltip={TooltipTitle.GameAssetBundleVerion} key={i} className={styles.field} label="Asset Bundle Version" name="assetBundleVersion" type="text" value={e.assetBundleVersion} onChange={(evt)=>{onChangePlatform(evt,i)}}/>
                                                <TextInput tooltip={TooltipTitle.GameMinimumVersion} key={i} className={styles.field} label="Minimum App Version" name="minimumGameVersion" type="text" value={e.minimumGameVersion} onChange={(evt)=>{onChangePlatform(evt,i)}} />
                                                <span className={styles.remove} onClick={()=>{onRemove(platform, e)}}><Remove className={styles.icon}/></span>
                                            </div>
                                            {e.assetBundleUrl != '' ? 
                                                <div className={styles.platformUrl}>
                                                    <a className={styles.bundleUrl} href={e.assetBundleUrl}>Download file</a>
                                                </div>
                                            : ''}
                                        </div>
                                    </>
                                )
                            })}
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Location" classTitle="title-blue">
                <div className={styles.cardDescription}>
                    <div className={styles.appInformation}>
                        <div className={styles.appInformation}>
                            <LocationDropdown tooltip={TooltipTitle.GameLocation} label="Choose Location" placeholder="Choose Location" options={locationOption} value={locations} setValue={setLocations} />
                        </div>
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Extra" classTitle="title-red">
                <div className={styles.cardDescription}>
                    <div className={styles.appInformation}>
                        <div className={styles.screenshot}>
                            <div className={styles.line}>
                                <div className={styles.info}>
                                    Add Screenshots<span><Tooltip className={styles.tooltip} title={TooltipTitle.GameScreenshot} icon="info"/></span>
                                </div>
                                <Switch className={styles.switch} value={isScreenShot} onChange={() => setIsScreenShot(!isScreenShot)} />
                            </div>
                            {isScreenShot ?
                                <div onClick={() => {setVisibleImage(true); setType(0)}}>
                                    <FileImages type="image" className={styles.field} title="Click or drop image" label="Cover images" setValue={setScreenshot} value={screenshot} data={screenshotRef}/>
                                </div>
                            : ''} 
                            <FileUploadSidebar media={media} setMedia={setMedia} types={type} id={`screenshot`} multiple={true} visible={visibleImage} setVisible={setVisibleImage} icon={screenshot} setIcon={setScreenshot} value={screenshot} setValue={setScreenshot} onClose={onClose} />
                            {isScreenShot && screenshot &&
                                <>
                                    <div className={styles.screenshotPreview}>
                                        <div className={styles.previewRow}>
                                            {screenshot && screenshot.map((e,i)=>{
                                                return(
                                                    <>
                                                        <div className={styles.previewCol}>
                                                            <div className={styles.previewContainer}>
                                                                <div className={styles.imgPreview}>
                                                                    <ImagePlaceholder />
                                                                </div>
                                                                <div className={styles.previewContent}>
                                                                    <div className={styles.screenshotTitle}>
                                                                        {/* {`screenshot - ${i+1}`} */}
                                                                        {getFileNameFromUrl(e)}
                                                                    </div>
                                                                    <div className={styles.precentageContainer}>
                                                                        <div id={`percentage${i+1}`} className={styles.percentage}>
                                                                            <div id ={`successfull${i+1}`} className={styles.successfull}></div>
                                                                        </div>
                                                                        <div className='progress-bar-images'></div>
                                                                        <div id={`view${i+1}`} className={styles.view}><Control type='Images' src={e} /></div>
                                                                    </div>
                                                                </div>
                                                                <div className={styles.close} onClick={(e)=>{removeScreenShots(i)}}>
                                                                    <Icon name="close" size="14"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </>
                            }
                        </div> 

                        <div className={styles.screenshot}>
                            <div className={styles.line}>
                                <div className={styles.info}>
                                    Add Video<span><Tooltip className={styles.tooltip} title={TooltipTitle.GameVideos} icon="info"/></span>
                                </div>
                                <Switch className={styles.switch} value={isVideo} onChange={() => setIsVideo(!isVideo)} />
                            </div>
                            {isVideo ?
                                <div onClick={() => {setVisibleVideo(true); setType(1)}}>
                                    <FileVideos type="video" className={styles.field} title="Click or drop videos" label="Cover videos" setValues={setVideo} value={video} data={videoRef}/>
                                </div>
                            : ''} 
                            <FileUploadSidebar media={media} setMedia={setMedia} types={type} id={`videos`} multiple={true} visible={visibleVideo} setVisible={setVisibleVideo} icon={video} setIcon={setVideo} value={video} setValue={setVideo} onClose={onClose} folderName={"videos"}/>
                            {isVideo && video &&
                                <>
                                    <div className={styles.screenshotPreview}>
                                        <div className={styles.previewRow}>
                                            {video && video.map((e,i)=>{
                                                return(
                                                    <>
                                                        <div className={styles.previewCol}>
                                                            <div className={styles.previewContainer}>
                                                                <div className={styles.imgPreview}>
                                                                    <VideoPlaceholder />
                                                                </div>
                                                                <div className={styles.previewContent}>
                                                                    <div className={styles.screenshotTitle}>
                                                                        {/* {`video - ${i+1}`} */}
                                                                        {getFileNameFromUrl(e)}
                                                                    </div>
                                                                    <div className={styles.precentageContainer}>
                                                                        <div id={`percentage_video${i+1}`} className={styles.percentage}>
                                                                            <div id = {`successfull_video${i+1}`} className={styles.successfull}></div>
                                                                        </div>
                                                                        <div className='progress-bar-videos'></div>
                                                                        <div id={`view_video${i+1}`} className={styles.view}><Control type='Videos' src={e} /></div>
                                                                    </div>
                                                                </div>
                                                                <div className={styles.close} onClick={(e)=>{removeVideos(i)}}>
                                                                    <Icon name="close" size="14" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </>
                            }
                        </div> 

                        <div className={className.video}>
                            <TextArea type='GAME_HOW_TO' tooltip={TooltipTitle.GameHowTo} rows={5} label="How-to" onChange={(e)=>{onGameHowTo(e)}} />
                        </div>      
                    </div>
                </div>
            </Card>
            
            <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                <div className={styles.cardDescription}>
                    
                <TagInputWithDropdown tooltip={TooltipTitle.GameTags} tags={tags} setTags={setTags}/>

                    <div className={styles.appInformation}>
                        <div className={styles.groupHeading}>
                            <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.GameMetaData} icon="info"/></span><span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>
                        </div>

                        {metaData && metaData.length > 0 &&
                            <div className={styles.groupMeta}>
                                <div className={styles.field}>Key</div>
                                <div className={styles.field}>Value</div>
                            </div>
                        }
                        
                        <div className={styles.groupMeta}>
                            {metaData && metaData.map((e,i)=>{
                                return(
                                    <>
                                        <TextInput className={styles.field} label="" name="key" type="text" required  onChange={(e)=>{onChangeKey(e,i)}}/>
                                        <JSONMetaDataValueInput key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                        {/* <TextInput className={styles.field} label="" name="value" type="text" required onChange={(e)=>{onChangeValue(e,i)}}/> */}
                                        <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><Remove className={styles.icon}/></span>
                                    </>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)}>
                <div className={styles.cardAction}>
                    <div className={styles.cardSave}>
                        <button className={cn(styles.button, 'button-save-small')} onClick={saveGame}>Save</button>
                    </div>
                    <div className={styles.cardCancel}>
                        <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default GameInfo