import React, { useEffect, useState } from "react";
import styles from "./row.module.sass";
import Control from "./Control/index";
import { Link, useParams } from "react-router-dom";
import Items from '../../../../../../media/images/placeholders/Items.png'
import CurrencyTooltip from '../../../../../CustomTooltips/CurrencyTooltip'
import TooltipGlodal from "../../../../../TooltipGlodal";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { extarctImageFromUrlAction } from "../../../../../../redux/action/common/commonAction";

const Row = ({ item, className, access, value, onChange, index, type, setValue }) => {
    
    const {appid} = useParams()
    const dispatch = useDispatch()

    const extractImageFromCdn = async(url, id) => {
        if(url !== null && url !== undefined && url.includes('cdn')){
            try {
                const response = await dispatch(extarctImageFromUrlAction(url))
                const logo = document.getElementById(`itemIcon_${id}`)
                if(logo){
                    logo.src = response
                }
            }catch (error){
                console.error('Error fetching image:', error);
            }
        }else{
            const logo = document.getElementById(`itemIcon_${id}`)
            if(logo){
                logo.src = Items
            }
        }
    }

    useEffect(() => {
        extractImageFromCdn(item.iconUrl, item.id)
    }, [item])

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <div className={styles.item}>
                        {item.iconUrl !== undefined ?
                            <div className={cn(styles.preview, className, {[styles.bg] : true})}>
                                <img id={`itemIcon_${item.id}`} alt="Item" />
                            </div>
                            :
                            <div className={styles.preview}></div>
                        }
                        <div className={styles.details}>
                            <div>{item.name}</div>
                            <div className={styles.itemId}>{item.itemId}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <Link to={`/view-item/${appid}/${item.id}`}>
                        <div className={styles.name}><TooltipGlodal width={200} itemId={`item${item.id}`} title={item.name}><div className={styles.itemName}><span id={`item${item.id}`}>{item.name}</span></div></TooltipGlodal></div>
                        <div className={styles.itemId}>{item.itemId}</div>
                    </Link>
                </div>
                <div className={styles.col}>
                    {item.inventoryItemPrices && item.inventoryItemPrices.slice(0,1).map((e,i)=>{
                        if(e.priceTypes == 'virtual currency' || e.priceTypes == 'RMG' || e.priceTypes == 'real money' || e.priceTypes == 'virtual'){
                            return (
                                <>
                                    <div className={styles.prices}>
                                        <span className={e.priceTypes == 'virtual currency' ? styles.virtual : styles.real}>{(e.currency && e.currency != null && e.currency.code != null) ? e.currency.code : (e.currency && e.currency != null && e.currency.code == null && e.currency.name!= null) ? (e.currency.name).slice(0,4) : ''}<span>&nbsp;</span>{e.price}</span>
                                        {(item.inventoryItemPrices && item.inventoryItemPrices.length > 1) ? 
                                            <CurrencyTooltip type='item' text='Currency' data={item.inventoryItemPrices}><span className={styles.count}>+ {item.inventoryItemPrices.length - 1}</span></CurrencyTooltip> 
                                        : ''}
                                    </div>
                                </>
                            )
                        }else if(e.priceTypes == 'IAP'){
                            return(
                                <>
                                    <div className={styles.prices}>
                                        <span className={styles.IAP}>{e.realWorldCurrency != null ? e.realWorldCurrency.code : '-'}<span>&nbsp;</span>{e.price}</span>
                                        {(item.inventoryItemPrices && item.inventoryItemPrices.length > 1) ? 
                                            <CurrencyTooltip type='item' text='Currency' data={item.inventoryItemPrices}><span className={styles.count}>+ {item.inventoryItemPrices.length - 1}</span></CurrencyTooltip> 
                                        : ''}
                                    </div>
                                </>
                            )
                        }
                    })} 
                </div>
                <div className={styles.col}>{item.isConsumable === true ? 'Consumable' : item.isConsumable === false ? 'Durable' : null}</div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 &&
                        <Control access={access} className={styles.control} appid={appid} id={item.id} name={item.name} status={item.archive} type='items' />
                    }
                </div>
            </div>
        </>
    );
};

export default Row;
