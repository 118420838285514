import React, { useEffect, useState } from "react";
import styles from './secondaryHeader.module.sass'
import cn from "classnames";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import Form from "../../Form";
import addIcon from '../../../media/images/icons/light.png'
import moment from "moment";
import Icon from "../../Icon";
import Control from '../../../screens/Organization/Apps/GetApps/Control'
import ControlMembers from '../../../screens/Organization/Settings/Members/Control'
import Sorting from "../SecondaryNavigation/Sort";

const OrganizationHeader = ({title, type, search, setSearch, setPage, data, sortIndex, setSortIndex, selected, setSelected, sort, setSort, value, setValue, setAppName, setShowLoader, setLoaderType, apps, setApps}) => {

    const Navigate = useNavigate()
    const { pathname } = useLocation()
    const {appid} = useParams()

    const [navLinks, setNavLinks] = useState([])
    let links = []

    const configureLinks = (title) => {
        switch(title){
            case "Apps" : 
                links = [
                    {
                        title: "Apps",
                        url: `/apps`,
                    }
                ]
                break;
            case "Usage" : 
                links = [
                    {
                        title: "Usage",
                        url: `/usage-analytics`,
                    }
                ]
                break;
            case "Organisation Settings" : 
            case "User Settings" : 
                links = [
                    {
                        title: "Organisation Settings",
                        url: `/settings-organization-profile`,
                    },
                    {
                        title: "User Settings",
                        url: `/settings-member-profile`,
                    }
                ]
                break;
            case "Accepted Members" : 
            case "Invited Members" : 
                links = [
                    {
                        title: "Accepted Members",
                        url: `/access-manager-accepted`,
                    },
                    {
                        title: "Invited Members",
                        url: `/access-manager-invited`,
                    }
                ]
                break;
            case "Billings" : 
                links = [
                    {
                        title: "Overview",
                        url: `/billing-overview`,
                    },
                    {
                        title: "Invoices",
                        url: `/invoices`,
                    },
                    {
                        title: "Settings",
                        url: `/billing-settings`
                    }
                ]
                break;
            case "Upgrade" : 
                links = [
                    {
                        title: "Add-Ons",
                        url: `/add-on`,
                    },
                    {
                        title: "Top-Ups",
                        url: `/top-up`,
                    }
                ]
                break;
            default :
            links =  [
                {
                    title: title, 
                    url: null
                }
            ]
        }
        setNavLinks(links)
    }

    useEffect(() => {
        configureLinks(title)
    }, [])

    return(
        <>
            <div className={styles.secondaryNavigation}>

                <div className={styles.secondaryContainer}>
                    <div className={styles.navigation}>
                        <div className={styles.wrapper}>
                            <div className={styles.list}>
                                {navLinks && navLinks.map((x,index)=>{
                                    return(
                                        <div className={cn(styles.item, {[styles.active]: pathname.slice(1).includes(x.url.slice(1))})} onClick={()=> Navigate(x?.url)} key={index}>
                                            {x.title}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={styles.actionDropdown}>
                                {title === 'Apps' &&
                                    <>
                                        <div className={styles.sort}>
                                            <Sorting setPage={setPage} value={value} setValue={setValue} data={data} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selected} setSelected={setSelected} sort={sort} setSort={setSort} />
                                        </div>
                                        <div className={styles.filter}>
                                            <Form size='sm' className={styles.form} placeholder={`Search ${title}`} type="text" name="search" icon="search" value={search} onChange={(e) => setSearch(e.target.value)} />
                                        </div>
                                    </>
                                }
                                {title === 'Apps' && <Control type='tiny' setAppName={setAppName} setShowLoader={setShowLoader} setLoaderType={setLoaderType}/>}
                                {(title === 'Invited Members' || title === 'Accepted Members') && <ControlMembers memberAction='add' useFor='org' type='small' apps={apps} setApps={setApps}/>}
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default OrganizationHeader